import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import MyModal from './../../CustomModal/CustomModal';
import DatePicker from "react-datepicker";
import moment from 'moment';

export class PatientDiagnosticDetail extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            PatientId: 0,
            PatientDiagnosticId: 0,
            DiagnosticAnalysis: "",
            DiagnosticDate: moment(new Date())._d,
            Outcome: "",
            docFiles: [],
            DocumentFile: "",
            redirect: false,
            modal: false,
            modalTitle: '',
            modalBody: ''
        };
        this.state = this.initialState;

    }

    //modal close button event
    handleModalClose = () => {
        this.setState({
            modal: false,
            modalTitle: '',
            modalBody: ''
        });
        if (this.state.redirect) {
            this.props.history.push('/patient/diagnostic/list');
        }
    }

    componentDidMount() {
        const param = this.props.match.params;

        if (param.id != undefined) {
            this.getData(param.id);
        }
        else {
            this.setState({ loading: false });
        }
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientDiagnostic/GetById?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata.diagnosticData;
                    var dData = result.data.outdata.docData;
                    this.setState({
                        PatientId: rData.patientId, Outcome: rData.outcome, DiagnosticAnalysis: rData.diagnosticAnalysis, 
                        // DiagnosticDate: rData.diagnosticDate == null ? "" : rData.diagnosticDate.slice(0, 10),
                        DiagnosticDate: rData.diagnosticDate == null ? "" : moment(rData.diagnosticDate)._d,
                        PatientDiagnosticId: rData.patientDiagnosticId,
                        loading: false, docFiles: dData
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    handleDateChange(date) {
        this.setState({ DiagnosticDate: date })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleFileInputChange(event) {
        const target = event.target;
        const value = target.files;

        this.setState({
            DocumentFile: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";
        let pid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));

        if (userToken != null) {
            pid = (userToken.patientId == null ? 0 : userToken.patientId);
        }

        const apiroute = window.$APIPath;
        if (this.state.PatientDiagnosticId == 0) {
            url = apiroute + '/api/PatientDiagnostic/Save';
        }
        else {
            url = apiroute + '/api/PatientDiagnostic/Update';
        }

        let data = JSON.stringify({
            PatientId: parseInt(pid),
            DiagnosticAnalysis: this.state.DiagnosticAnalysis,
            DiagnosticDate: this.state.DiagnosticDate,
            Outcome: this.state.Outcome,
            PatientDiagnosticId: parseInt(this.state.PatientDiagnosticId),
        })

        console.log(data);
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                if (this.state.DocumentFile != "") {
                    this.filesUploadDoc(result.data.outdata.patientDiagnosticId, result.data.message)
                }
                else {
                    this.setState({
                        modal: !this.state.modal,
                        modalTitle: 'Success',
                        modalBody: result.data.message,
                        redirect: true
                    });
                }
            }
            else {
                this.setState({
                    modal: !this.state.modal,
                    modalTitle: 'Error',
                    modalBody: result.data.message,
                    loading: false
                });
            }
        })
            .catch(error => {
                //console.log(error);
                this.setState({
                    modal: !this.state.modal,
                    modalTitle: 'Error',
                    modalBody: error.message
                });
                //this.setState({ authError: true, error: error });
            });
    }

    filesUploadDoc(id, msg) {
        const apiroute = window.$APIPath;
        let url = apiroute + '/api/PatientDiagnosticFile/Save?id=' + id + '';
        //alert(this.state.DocumentFile)
        let files = this.state.DocumentFile;
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append(`files[${i}]`, files[i])
        }
        axios.post(url, data, {
            // receive two    parameter endpoint url ,form data
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    modal: !this.state.modal,
                    modalTitle: 'Success',
                    modalBody: msg,
                    redirect: true
                });
            }
            else {
                this.setState({
                    modal: !this.state.modal,
                    modalTitle: 'Error',
                    modalBody: result.data.message,
                    loading: false
                });
            }
        }).catch(error => {
            console.log(error.message);
        });
    }

    //delete(active/inactive) button click
    deleteRow(e, id) {
        e.preventDefault();

        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientDiagnosticFile/Delete?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        loading: false,
                        modal: !this.state.modal,
                        modalTitle: 'Success',
                        modalBody: result.data.message
                    });
                    const param = this.props.match.params;

                    if (param.id != undefined) {
                        this.getData(param.id);
                    }
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    modal: !this.state.modal,
                    modalTitle: 'Error',
                    modalBody: error.message
                });
                this.setState({ loading: false, authError: true, error: error });
            });
    }

    render() {
        const { loading, DiagnosticAnalysis, Outcome, DiagnosticDate, docFiles, DocumentFile, errors } = this.state;

        return (
            <div className="mt-100">
                <div className="profile-pg">
                    <div className="container-fluid">
                      
                        <div className="row">
                            <div className="col-md-3">
                                <div className="user-profile-inner">
                                    <div className="list-group ">

                                        <a href="/patient/dashboard" className="list-group-item list-group-item-action">Dashboard</a>
                                        <a href="/patient/profile" className="list-group-item list-group-item-action">Patient Information</a>
                                        <a href="/patient/diagnostic/list" className="list-group-item list-group-item-action active">Diagnostic Information</a>
                                        <a href="/patient/disease/list" className="list-group-item list-group-item-action">Disease Information</a>
                                        <a href="/patient/treatment/list" className="list-group-item list-group-item-action">Treatment Information</a>
                                        <a href="/patient/prescription/list" className="list-group-item list-group-item-action">Prescription Information</a>
                                        <a href="/patient/emergency/list" className="list-group-item list-group-item-action">Emergency Contact</a>
                                        <a href="/patient/insurance/list" className="list-group-item list-group-item-action">Insurance Information</a>
                                        <a href="/patient/payment/detail" className="list-group-item list-group-item-action">Payment History</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="user-profile-inner">

                                    <div className="widgets-main bootstrap snippet p-3 m-0">
                                        <Form onSubmit={this.handleSubmit.bind(this)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-9">
                                                                    <h4>Patient Diagnostic Detail</h4>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <a href="/patient/diagnostic/list" className="primary-btn addButton">List</a>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group row my-4">
                                                                        <label for="file" class="col-12 col-form-label">Diagnostic Date</label>
                                                                        <div class="col-6">
                                                                            {/* <Input type="date" name="DiagnosticDate" tabIndex="1" min="1000-01-01" max="3000-12-31" className="form-control" value={DiagnosticDate} onChange={this.handleInputChange.bind(this)} placeholder="Enter a Diagnostic Date" required /> */}
                                                                            <DatePicker 
                                                                                selected={DiagnosticDate}
                                                                                onChange={this.handleDateChange.bind(this)}
                                                                                dateFormat="MM/dd/yyyy"
                                                                                placeholderText="mm/dd/yyyy"
                                                                                className="form-control"
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                               
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group row my-4">
                                                                                <label for="summary" class="col-12 col-form-label">Diagnostic Analysis*</label>
                                                                                <div class="col-12">
                                                                                    <Input className="form-control here" type="textarea" rows="8" cols="8" tabIndex="2" placeholder="Enter Diagnostic Analysis" name="DiagnosticAnalysis" value={DiagnosticAnalysis} onChange={this.handleInputChange.bind(this)} required />

                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group row my-4">
                                                                                <label for="summary" class="col-12 col-form-label">Outcome*</label>
                                                                                <div class="col-12">
                                                                                    <Input className="form-control here" type="textarea" tabIndex="2" rows="8" cols="8" placeholder="Enter Outcome" name="Outcome" value={Outcome} onChange={this.handleInputChange.bind(this)} required />
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group row my-4">
                                                                                <label for="date" class="col-12 col-form-label">Diagnostic File</label>
                                                                                <div class="col-12">
                                                                                    <Input type="file" name="DocumentFile" id="File" className="form-control" multiple="multiple" tabIndex="3" onChange={this.handleFileInputChange.bind(this)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <div className="form-group row my-4">
                                                                        {docFiles.length > 0 ? (
                                                                            docFiles
                                                                                .map((data, i) => {
                                                                                    return (
                                                                                        <div className="col-md-2" key={i}>
                                                                                            <span>{i + 1}.)</span>{" "}{data.fileName}
                                                                                            <Link className='btn btn-primary' to={data.fileName} target='_blank'><span><i className='fa fa-download'></i></span></Link>{" "}
                                                                                            <Link to="#" onClick={e => this.deleteRow(e, data.patientDiagnosticFileId)}><i className="fa fa-trash" /></Link>
                                                                                        </div>
                                                                                    )
                                                                                })) : (null)}
                                                                    </div>
                                                                    {/* {loading ?
                                                                        <div className="form-group row my-4 mx-0">
                                                                            <div className="animated fadeIn pt-1 text-center">Loading...</div>
                                                                        </div>
                                                                        : */}
                                                                        <div className="form-group row my-4 mx-0">
                                                                            <button className="primary-btn" type="submit" tabIndex="4" >Submit</button>
                                                                        </div>
                                                                    {/* } */}
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MyModal
                    handleModal={this.handleModalClose.bind(this)}
                    //modalAction={this.state.modalAction}
                    isOpen={this.state.modal}
                    modalBody={this.state.modalBody}
                    modalTitle={this.state.modalTitle}
                    modalOptions={this.state.modalOptions}
                />
            </div>
        );
    }
}
