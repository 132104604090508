import React, { Component } from 'react';
import {
    Collapse, Container, FormGroup, Badge, Label, Table, Navbar, NavbarBrand, NavbarToggler,
    NavItem, Form, NavLink, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter
    , Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
//import MyModal from './../../CustomModal/CustomModal';

export class PratitionerSample extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            patientpayments: [],
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',

            checkbox: [],
            patientId: "",
            patientAccessionId: "",
            patientSampleId: "",
            AllSamples: [],
            Samples: [],

            companyName: "",
            trackingCode: "",
            shipDate: moment(new Date())._d,
            AllTracking: [],
            errors: {
                patientSampleId: '',
                companyName: '',
                trackingCode: '',
                shipDate: ''
            },

            deleteId: '',
            modal: false
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //}

    componentDidMount() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            this.getListData();
        }
        else {
            return <Redirect to="/" />
        }
    }

    getListData() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.practitionerId == null ? 0 : userToken.practitionerId);

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PractitionerPatient/getPatientByPractionerForSample?id=' + pid;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;

                    this.setState({
                        patientpayments: rData.patientPaymentData,
                        AllSamples: rData.sampleTypeData,
                        loading: false
                    });
                }
                else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    handleDateChange(date) {
        let errors = this.state.errors;
        errors.shipDate = (!date) ? 'Please enter ship date.' : ''
        this.setState({ shipDate: date })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name == "patientAccessionId") {
            if (value != "") {
                this.setState({ loading: true });
                let samples = this.state.patientpayments.filter(ml => ml.patientAccessionId === parseInt(value));
                console.log(this.state.AllSamples);
                let dcId = samples[0].diseaseCategoryId;
                console.log(dcId);
                let filtersamples = this.state.AllSamples.filter(ml => ml.diseaseCategoryId === dcId);
                console.log(filtersamples);

                this.setState({
                    patientId: samples[0].patientId,
                    patientAccessionId: value,
                    Samples: filtersamples
                });

                if (samples.length > 0) {
                    let sids = samples[0].sampleTypeIds;
                    this.setState({
                        checkbox: (sids != "" && sids != null ? sids.split(",") : [])
                    });
                } else {
                    this.setState({
                        checkbox: []
                    });
                }
                this.getPatientSampleTrackingData(value, samples[0].patientId);
            }
            else {
                this.setState({
                    checkbox: [],
                    AllTracking: [],
                    patientAccessionId: "",
                    Samples: []
                });
            }
        }  else {

            this.setState({
                [name]: value
            });
        }

        let errors = this.state.errors;

        switch (name) {
            case 'companyName':
                errors.companyName = (!value) ? 'Please enter company name.' : ''
                break;
            case 'trackingCode':
                errors.trackingCode = (!value) ? 'Please enter tracking code.' : ''
                break;
            case 'shipDate':
                errors.shipDate = (!value) ? 'Please enter ship date.' : ''
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value }, () => {

        })
    }

    //add Sample
    handleSampleInputChange(event) {
        if (event != null) {
            let nCheckbox = this.state.checkbox.slice(); // create a new copy of state value
            if (this.isValueExist(nCheckbox, event.target.value)) { // check if the same value is preexisted in the array
                const index = nCheckbox.indexOf(event.target.value);
                nCheckbox.splice(index, 1); // removing the preexciting value 
            } else {
                nCheckbox.push(event.target.value); // inserting the value of checkbox in the array
            }
            this.setState({
                checkbox: nCheckbox,
                patientSampleId: nCheckbox.join(',')
            });
        }
    }

    //edit time set checkbox selected
    setCheckbox(id) {
        let nCheckbox = this.state.checkbox.slice(); // create a new copy of state value 
        if (this.isValueExist(nCheckbox, id)) { // check if the same value is preexisted in the array
            return true;
        } else {
            return false; // inserting the value of checkbox in the array
        }
    }

    isValueExist(data, event) {
        if (data.length == 0) {
            return false;
        }
        for (let i = 0; i <= data.length; i++) {
            if (event == data[i]) {
                return true;
            }
        }
        return false;
    }

    AddPatientSample(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let errors = this.state.errors;

        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }
        if (this.state.patientSampleId != null && this.state.patientSampleId != "") {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/PatientSample/Save';

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                PatientAccessionId: parseInt(this.state.patientAccessionId),
                SampleTypeIds: this.state.patientSampleId,
                PatientSampleId: 0,
                SampleTypeId: 0,
                createdBy: uid,
                createdByFlag: "P"
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false,
                        patientSampleId: '',
                        patientAccessionId: '',
                        Samples: []
                    }, this.getListData());
                    toast.success(result.data.message)
                }
                else {
                    errors.patientSampleId = result.data.message;
                    this.setState({ loading: false });
                }
            })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                });
        }
        else {
            errors.patientSampleId = "Please select sample.";
            this.setState({ loading: false });
        }
    }
    //end add Sample


    //Sample Tracking
    // getPatientSampleTrackingData(anid, pid) {
    //     const apiroute = window.$APIPath;
    //     const url = apiroute + '/api/PatientTrackingSample/GetByPatientId?id=' + pid + '&aid=' + anid;;

    //     axios.get(url, {
    //         headers: {
    //             'Content-Type': 'application/json; charset=utf-8'
    //         }
    //     })
    //         .then(result => {
    //             if (result.data.flag) {
    //                 var rData = result.data.outdata;

    //                 this.setState({
    //                     AllTracking: rData,
    //                     loading: false
    //                 });
    //             }
    //             else { this.setState({ loading: false }); }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             this.setState({
    //                 // authError: true, errorType: 'danger', error: error.message, 
    //                 loading: false
    //             });
    //             toast.error(error.message)
    //         });
    // }

    validateForm = (errors) => {
        let valid = true;

        if (this.state.companyName == undefined || this.state.companyName == '') {
            errors.companyName = 'Please enter company name.';
        }
        if (this.state.trackingCode == undefined || this.state.trackingCode == '') {
            errors.trackingCode = 'Please select tracking code.';
        }
        if (this.state.shipDate == undefined || this.state.shipDate == '') {
            errors.shipDate = 'Please select ship date.';
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    // AddPatientSampleTracking(e) {
    //     e.preventDefault();
    //     this.setState({ loading: true });

    //     let errors = this.state.errors;

    //     let uid = 0;
    //     var userToken = JSON.parse(localStorage.getItem('Usertoken'));
    //     if (userToken != null) {
    //         uid = (userToken.userId == null ? 0 : userToken.userId);
    //     }

    //     if (this.validateForm(this.state.errors)) {
    //         const apiroute = window.$APIPath;
    //         let url = apiroute + '/api/PatientTrackingSample/Save';

    //         let data = JSON.stringify({
    //             PatientId: parseInt(this.state.patientId),
    //             PatientAccessionId: parseInt(this.state.patientAccessionId),
    //             CompanyName: this.state.companyName,
    //             TrackingCode: this.state.trackingCode,
    //             ShipDate: this.state.shipDate,
    //             createdBy: uid,
    //             createdByFlag: "P"
    //         })

    //         axios.post(url, data, {
    //             headers: {
    //                 'Content-Type': 'application/json; charset=utf-8'
    //             }
    //         }).then(result => {
    //             if (result.data.flag) {
    //                 this.setState({
    //                     // authError: true,
    //                     // errorType: 'success',
    //                     // error: result.data.message,
    //                     loading: false,
    //                     companyName: '',
    //                     trackingCode: '',
    //                     shipDate: ''
    //                 }, this.getPatientSampleTrackingData(parseInt(this.state.patientAccessionId), parseInt(this.state.patientId)));
    //                 toast.success(result.data.message)
    //             }
    //             else {
    //                 this.setState({
    //                     // authError: true, errorType: 'danger', error: result.data.message, 
    //                     loading: false
    //                 });
    //                 toast.error(result.data.message)
    //             }
    //         })
    //             .catch(error => {
    //                 this.setState({
    //                     // authError: true, errorType: 'danger', error: error.message, 
    //                     loading: false
    //                 });
    //                 toast.error(error.message)
    //             });
    //     }
    //     else {
    //         this.setState({ loading: false });
    //     }
    // }

    // deleteRow(e, id) {
    //     e.preventDefault();

    //     this.setState({ loading: true });

    //     var uid;
    //     var userToken = JSON.parse(localStorage.getItem('Usertoken'));
    //     if (userToken != null) {
    //         uid = (userToken.userId == null ? 0 : userToken.userId);
    //     }

    //     const apiroute = window.$APIPath;
    //     const url = apiroute + '/api/PatientTrackingSample/Delete?id=' + id + '&userId=' + uid;

    //     axios.delete(url, {
    //         headers: {
    //             'Content-Type': 'application/json; charset=utf-8'
    //         }
    //     })
    //         .then(result => {
    //             if (result.data.flag) {
    //                 this.setState({
    //                     // authError: true,
    //                     // errorType: 'success',
    //                     // error: result.data.message,
    //                     loading: false,
    //                 }, this.getPatientSampleTrackingData(parseInt(this.state.patientAccessionId), parseInt(this.state.patientId)));
    //                 toast.success(result.data.message)
    //                 this.toggle()
    //             }
    //             else {
    //                 this.setState({
    //                     // authError: true,
    //                     // errorType: 'danger',
    //                     // error: result.data.message,
    //                     loading: false
    //                 });
    //                 toast.error(result.data.message)
    //             }
    //         })
    //         .catch(error => {
    //             //console.log(error);
    //             this.setState({
    //                 // authError: true, errorType: 'danger', error: error.message, 
    //                 loading: false
    //             });
    //             toast.error(error.message)
    //         });
    // }
    //Sample Tracking

    toggle(e, id) {
        this.setState({
          modal: !this.state.modal,
          deleteId: id
        });
      }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, patientpayments, error, errorType, authError,
            AllSamples, patientSampleId, patientId, patientAccessionId, companyName, trackingCode, shipDate, AllTracking,
            errors, modal, deleteId, Samples } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                                Specimen Collection
                                            </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/practitioner/patient/payment" className="kt-subheader__breadcrumbs-link">
                                                    Sample Collection                        </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.AddPatientSample.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Patient<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="select" tabIndex="1" name="patientAccessionId" value={patientAccessionId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Patient</option>
                                                                        {patientpayments
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.patientAccessionId}>{data.patientName + "-" + (data.accessionNo != null && data.accessionNo != "" ? data.accessionNo.replace(/-/g, "") : "")}</option>);
                                                                                { { /*"+  (" + data.accessionNo + ")"*/ } }
                                                                            })}
                                                                    </Input>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                            </div>
                                                        </div>
                                                        {patientId != "" ?
                                                            <div className="form-group row my-4">
                                                                <div className="col-md-6">
                                                                    <label className="col-12 col-form-label">Select Sample <span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className="col-12">
                                                                        {Samples.map((data, i) => {
                                                                            return (
                                                                                <FormGroup check row key={i}>
                                                                                    {this.setCheckbox(data.sampleTypeId) ? (
                                                                                        <Input className="form-check-input" type="checkbox" id={"chk" + data.sampleTypeId} checked name="inline-checkbox1" value={data.sampleTypeId} onChange={this.handleSampleInputChange.bind(this)} />
                                                                                    ) : (
                                                                                            <Input className="form-check-input" type="checkbox" id={"chk" + data.sampleTypeId} name="inline-checkbox1" value={data.sampleTypeId} onChange={this.handleSampleInputChange.bind(this)} />
                                                                                        )
                                                                                    }
                                                                                    <Label className="form-check-label" check htmlFor={" chk" + data.sampleTypeId}>{data.sampleTypeName}</Label>
                                                                                </FormGroup>
                                                                            )
                                                                        })}
                                                                        {errors.patientSampleId.length > 0 && <span className='error'>{errors.patientSampleId}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            : null}

                                                        {patientId != "" ?
                                                            (loading ?
                                                                <button tabIndex="10" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Confirm sample collection</button>
                                                                :
                                                                <button tabIndex="10" type="submit" className="btn btn-primary">Confirm sample collection</button>)
                                                            : null
                                                        }


                                                        {/*{patientId != "" ?*/}
                                                        {/*    <React.Fragment>*/}
                                                        {/*        <br />*/}
                                                        {/*        <br />*/}
                                                        {/*        <hr />*/}

                                                        {/*        <h4>Sample Tracking Detail</h4>*/}

                                                        {/*        <div className="form-group row my-4">*/}
                                                        {/*            <div className="col-md-6">*/}
                                                        {/*                <label className="col-12 col-form-label">Comapny Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>*/}
                                                        {/*                <div className="col-12">*/}
                                                        {/*                    <Input className="form-control here" type="text" maxLength="250" tabIndex="1" placeholder="Enter company name" name="companyName" value={companyName} onChange={this.handleInputChange.bind(this)} />*/}
                                                        {/*                    {errors.companyName.length > 0 && <span className='error'>{errors.companyName}</span>}*/}

                                                        {/*                    {errors.patientSampleId.length > 0 && <span className='error'>{errors.patientSampleId}</span>}*/}
                                                        {/*                </div>*/}
                                                        {/*            </div>*/}
                                                        {/*            <div className="col-md-6">*/}
                                                        {/*                <label className="col-12 col-form-label">Tracking Code<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>*/}
                                                        {/*                <div className="col-12">*/}
                                                        {/*                    <Input className="form-control here" type="text" maxLength="100" tabIndex="2" placeholder="Enter tracking code" name="trackingCode" value={trackingCode} onChange={this.handleInputChange.bind(this)} />*/}
                                                        {/*                    {errors.trackingCode.length > 0 && <span className='error'>{errors.trackingCode}</span>}*/}
                                                        {/*                </div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="form-group row my-4">*/}
                                                        {/*            <div className="col-md-6">*/}
                                                        {/*                <label className="col-12 col-form-label">Ship Date<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>*/}
                                                        {/*                <div className="col-12">*/}
                                                        {/*                    */}{/* <Input className="form-control here" type="date" max="9999-12-31" tabIndex="3" placeholder="Enter ship date" name="shipDate" value={shipDate} onChange={this.handleInputChange.bind(this)} /> */}
                                                        {/*                    <DatePicker*/}
                                                        {/*                        selected={shipDate}*/}
                                                        {/*                        onChange={this.handleDateChange.bind(this)}*/}
                                                        {/*                        dateFormat="MM/dd/yyyy"*/}
                                                        {/*                        placeholderText="mm/dd/yyyy"*/}
                                                        {/*                        className="form-control here"*/}
                                                        {/*                        showMonthDropdown*/}
                                                        {/*                        showYearDropdown*/}
                                                        {/*                        dropdownMode="select"*/}
                                                        {/*                        fixedHeight*/}
                                                        {/*                    />*/}
                                                        {/*                    {errors.shipDate.length > 0 && <span className='error'>{errors.shipDate}</span>}*/}
                                                        {/*                </div>*/}
                                                        {/*            </div>*/}
                                                        {/*            <div className="col-md-6">*/}
                                                        {/*                <label style={{ "visibility": "hidden" }}></label>*/}
                                                        {/*                <div className="col-12" style={{ "marginTop": "3%" }}>*/}
                                                        {/*                    {(*/}
                                                        {/*                        loading ?*/}
                                                        {/*                            <button tabIndex="4" type="button" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Add</button>*/}
                                                        {/*                            :*/}
                                                        {/*                            <button tabIndex="4" onClick={this.AddPatientSampleTracking.bind(this)} type="button" className="btn btn-primary">Add</button>*/}
                                                        {/*                    )}*/}
                                                        {/*                </div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </React.Fragment>*/}
                                                        {/*    : null}*/}

                                                        {/*{patientId != "" ?*/}
                                                        {/*    <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">*/}
                                                        {/*        <thead>*/}
                                                        {/*            <tr>*/}
                                                        {/*                <th>Company Name</th>*/}
                                                        {/*                <th>Tracking Code</th>*/}
                                                        {/*                <th>Ship Date</th>*/}
                                                        {/*                <th>Action</th>*/}
                                                        {/*            </tr>*/}
                                                        {/*        </thead>*/}
                                                        {/*        <tbody>*/}
                                                        {/*            {*/}
                                                        {/*                (AllTracking.length > 0 ? (*/}
                                                        {/*                    AllTracking*/}
                                                        {/*                        .map((data, i) => {*/}
                                                        {/*                            return (<tr key={i}>*/}
                                                        {/*                                <td>{data.companyName}</td>*/}
                                                        {/*                                <td>{data.trackingCode} </td>*/}
                                                        {/*                                <td>{moment(data.shipDate, "MM-DD-YYYY").format('MM/DD/YYYY')}</td>*/}
                                                        {/*                                <td>*/}
                                                        {/*                                    <Link to="#" onClick={e => this.toggle(e, data.trackingId)}><Button outline color="danger" size="sm"><i className="flaticon2-delete" />Delete</Button></Link>*/}
                                                        {/*                                </td>*/}
                                                        {/*                            </tr>*/}
                                                        {/*                            )*/}
                                                        {/*                        })) : (*/}
                                                        {/*                        <tr>*/}
                                                        {/*                            <td colSpan="4" className="tdCenter">No trackings found.</td></tr>*/}
                                                        {/*                    ))*/}
                                                        {/*            }*/}
                                                        {/*        </tbody>*/}
                                                        {/*    </table>*/}
                                                        {/*    : null}*/}
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {this.loading()}
                {/* {modal && 
                        <div style={{ marginLeft: "36%" }}>    
                            <Modal isOpen={modal} style={{ width: "500px" }} >
                                <ModalHeader>Confirm</ModalHeader>
                                <ModalBody>Are you sure want to delete this tracking data?</ModalBody>
                                <ModalFooter>
                                    <Button outline color="danger" onClick={e => this.deleteRow(e, deleteId)}>Delete</Button>
                                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    } */}
            </React.Fragment >
        );
    }
}
