import React, { Component } from 'react';
import {
    Collapse, Container, FormGroup, Badge, Label, Table, Navbar, NavbarBrand, NavbarToggler,
    NavItem, Form, NavLink, Input, Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../../CustomModal/CustomModal';

export class LaboratoryPayment extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            NGSLabPayments: [],
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            currentPage: 0,
            currentIndex: 0,
            pagesCount: 0,
            pageSize: window.$TotalRecord
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //}

    componentDidMount() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            this.getListData(0);
        }
        else {
            return <Redirect to="/" />
        }
    }

    getListData(pageNo) {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/NGSLabPayment/GetByNGSLabIdPaging';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            Id: pid,
            pageNo: pageNo,
            totalNo: window.$TotalRecord,
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;

                    this.setState({
                        NGSLabPayments: rData,
                        pagesCount: Math.ceil(result.data.totalRecord / window.$TotalRecord),
                        loading: false
                    });
                }
                else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //pagination
    handleClick(e, index, currIndex) {
        e.preventDefault();
        var pgcount = this.state.pagesCount - 1;
        var pgCurr = (index >= pgcount ? pgcount : index);
        this.setState({
            loading: true,
            currentPage: pgCurr,
            currentIndex: currIndex
        }, function () { this.getListData(pgCurr); });
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, NGSLabPayments, error, errorType, authError, currentPage, currentIndex, pagesCount, pageSize } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                            Payments
                           </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/laboratory/payment/list" className="kt-subheader__breadcrumbs-link">
                                                    Payments                        </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        {/* {authError ?
                                            <div>
                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                    <div className="alert-text">{error}</div>
                                                    <div className="alert-close">
                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                                        <div className="kt-portlet__body">
                                            <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                <thead>
                                                    <tr>
                                                        <th>Patient</th>
                                                        <th>Transaction Id</th>
                                                        <th>Amount</th>
                                                        <th>Remark</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    // !loading ? (
                                                        NGSLabPayments.length > 0 ? (
                                                        NGSLabPayments
                                                            .map((data, i) => {
                                                                return (<tr key={i}>
                                                                    <td>{data.patientName}<br />{data.accessionNo.replace(/-/g, "")}</td>
                                                                    <td>{data.transactionId}</td>
                                                                    <td>{data.transactionAmount}</td>
                                                                    <td>{data.remark}</td>
                                                                    <td><Badge color="success">{data.transactionStatus}</Badge></td>
                                                                </tr>
                                                                )
                                                            })) : (
                                                            <tr>
                                                                <td colSpan="5" className="tdCenter">No payment transactions found.</td></tr>
                                                            // )) : (
                                                            // <tr>
                                                            //     <td colSpan="5" className="tdCenter">Loading...</td></tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            {pagesCount > 0 ?
                                                <Pagination aria-label="Page navigation example" className="customPagination">
                                                    <PaginationItem disabled={currentIndex - 4 <= 0}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage - 5, currentIndex - 5)} previous href="#">
                                                            Prev
                    </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(pagesCount)].slice(currentIndex, currentIndex + 5).map((page, i) =>
                                                        <PaginationItem active={currentIndex + i === currentPage} key={currentIndex + i}>
                                                            <PaginationLink onClick={e => this.handleClick(e, currentIndex + i, currentIndex)} href="#">
                                                                {currentIndex + i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    )}
                                                    <PaginationItem disabled={currentIndex + 5 >= pagesCount}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage + 5, currentIndex + 5)} next href="#">
                                                            Next
                    </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.loading()}
                </div>
            </React.Fragment >
        );
    }
}
