import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { Sidebar } from 'components/Sidebar';
import { toast } from 'react-toastify';
//import MyModal from './../../CustomModal/CustomModal';


export class PractitionerProfile extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            PractitionerId: 0,
            UserId: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            UserName: "",
            Qualification: "",
            Email: "",
            ResidentCountryId: 233,
            countries: [],
            ResidentStateId: "",
            states: [],
            ResidentCityId: "",
            cities: [],
            Mobile: "",
            ResidentAddress: "",
            PhoneNumber: "",
            ResidentPostalCode: "",
            npiNumber: "",
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            IRBNo: "",
            OldIRBNo: "",
            IRBStatus: false,
            errors: {
                FirstName: '',
                MiddleName: '',
                LastName: '',
                UserName: '',
                Qualification: '',
                Email: '',
                ResidentCountryId: '',
                ResidentStateId: '',
                ResidentCityId: '',
                Mobile: '',
                ResidentAddress: '',
                PhoneNumber: '',
                ResidentPostalCode: '',
                IRBNo: '',
                clinicStreet: '',
                clinicStateId: '',
                clinicCityId: '',
                clinicPostalCode: '',
                isPipeline: '',
                npiNumber:''
            },
            pCurrentFlag: false,
            pCurrentStatus: "",
            clinicName: "",
            clinicStreet: "",
            clinicCityId: 0,
            clinicCities: [],
            clinicStateId: 0,
            clinicPostalCode: "",
            isPesPipeline: false,
            isHealthindexPipeline: false
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.getData();
    //    }
    //}

    componentDidMount() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            this.getCountry();
        }
        else {
            return <Redirect to="/" />
        }

    }

    getCountry() {
        const apiroute = window.$APIPath;
        // const url = apiroute + '/api/CognitoUserStore/getallcountry';
        const url  = apiroute+'/api/BE_Common/GetAllCountry'
        let data = JSON.stringify({
            isDeleted: false,
            searchString: ''
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    countries: result.data.outdata
                }, () => { this.getData() });
            }
            else {
                this.setState({ loading: false });
            }
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
        });
    }

    //get detail(for update)
    getData() {

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.userId == null ? 0 : userToken.userId);
        }

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/Practitioner/GetByUserId?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                console.log("Data::::",result)
                if (result.data.flag) {
                    var rData = result.data.outdata;

                    var irb = rData.irbStatus;
                    var irbno = rData.irbNo;
                    var firstnm = rData.firstName;
                    var lastnm = rData.lastName;
                    var phnno = rData.mobile;
                    var postalcd = rData.residentPostalCode;

                    if (
                        // (irbno != null && irbno != "") && 
                        (firstnm != null && firstnm != "") && (lastnm != null && lastnm != "") &&
                        (phnno != null && phnno != "") && (postalcd != null && postalcd != "")) {

                        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
                        localStorage.setItem('Usertoken', null);
                        userToken.irbStatus = rData.lastName;
                        userToken.irbNo = rData.lastName;
                        userToken.firstName = rData.lastName;
                        userToken.lastName = rData.lastName;
                        userToken.phoneNumber = rData.lastName;
                        userToken.residentPostalCode = rData.lastName;
                        localStorage.setItem('Usertoken', JSON.stringify(userToken));

                        // if (irb != "P" && irb != "R") {
                        //     this.setState({ pCurrentFlag: true });
                        // } else {
                        //     if (irb == "P") {
                        //         this.setState({ pCurrentStatus: "Your IRB No is not approved yet!!..", pCurrentFlag: false });
                        //     }
                        //     if (irb == "R") {
                        //         this.setState({ pCurrentStatus: "Your IRB number is not valid, please contact Customer Care at 469-619-7252 to register with the IRB’ (Done)!!..", pCurrentFlag: false });
                        //     }
                        // }
                    } else {
                        // this.setState({ pCurrentStatus: "Your profile is not updated. Please provide required details!!..", pCurrentFlag: false });
                    }

                    this.setState({
                        PractitionerId: rData.practitionerId, FirstName: rData.firstName, MiddleName: rData.middleName,
                        LastName: rData.lastName, UserName: rData.userName, Email: rData.email, Mobile: rData.mobile,
                        ResidentAddress: rData.residentAddress, Qualification: rData.qualification,
                        IRBNo: rData.irbNo, OldIRBNo: rData.irbNo, IRBStatus: (rData.irbStatus == "A" ? true : false),
                        PhoneNumber: rData.phoneNumber, ResidentPostalCode: rData.residentPostalCode, ResidentCountryId: rData.residentCountryId
                        //, ResidentStateId: rData.ResidentStateId, ResidentCityId: rData.ResidentCityId
                        , clinicName: rData.clinicName, clinicStreet: rData.clinicStreet, clinicPostalCode: rData.clinicPostalCode,
                        isPesPipeline: rData.isPesPipeline, isHealthindexPipeline: rData.isHealthindexPipeline, npiNumber: rData.npiNumber,
                        
                    }, () => {
                        if (rData.residentCountryId != null) {
                            // this.getStateData(rData.residentCountryId, rData.residentStateId, rData.residentCityId, rData.clinicStateId, rData.clinicCityId)
                            this.getStateData(233, rData.residentStateId, rData.residentCityId, rData.clinicStateId, rData.clinicCityId)
                        }
                        else {
                            this.setState({ loading: false });
                        }
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getStateData(ResidentCountryId, ResidentStateId, ResidentCityId, clinicStateId, clinicCityId) {

        const apiroute = window.$APIPath;

        const url = apiroute + '/api/BE_Common/GetStateByCountryId?id=' + ResidentCountryId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    //console.log(result.data);
                    this.setState({ states: result.data.outdata, ResidentStateId: ResidentStateId, clinicStateId: clinicStateId }, () => {
                        if (ResidentStateId != "" || ResidentStateId != null) {
                            this.getCityData(ResidentStateId, ResidentCityId)
                            this.getClinicCityData(clinicStateId, clinicCityId)
                        } else {
                            this.setState({ loading: false });
                        }
                    });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log(error);
            });
    }

    getCityData(ResidentStateId, ResidentCityId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/BE_Common/GetCityByStateId?id=' + ResidentStateId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                //console.log(result.data);
                this.setState({ cities: result.data.outdata, ResidentCityId: ResidentCityId, loading: false });
            } else {
                this.setState({ loading: false });
            }
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
        });
    }

    getClinicCityData(clinicStateId, clinicCityId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/BE_Common/GetCityByStateId?Id=' + clinicStateId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                //console.log(result.data);
                this.setState({ clinicCities: result.data.outdata, clinicCityId: clinicCityId, loading: false });
            } else {
                this.setState({ loading: false });
            }
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
        });
    }

    validateForm = (errors) => {
        let valid = true;

        if (this.state.FirstName == undefined || this.state.FirstName == '') {
            errors.FirstName = 'Please enter firstname.';
        }
        //if (this.state.MiddleName == undefined || this.state.MiddleName == '') {
        //    errors.MiddleName = 'Please enter middlename.';
        //}
        // if (this.state.IRBNo == undefined || this.state.IRBNo == '') {
        //     errors.IRBNo = 'Please enter IRB No.';
        // }
        if (this.state.LastName == undefined || this.state.LastName == '') {
            errors.LastName = 'Please enter lastname.';
        }
        if (this.state.Qualification == undefined || this.state.Qualification == '') {
            errors.Qualification = 'Please enter degree earned.';
        }
        if (this.state.Email == undefined || this.state.Email == '') {
            errors.Email = 'Please enter email.';
        }
        // if (this.state.isPesPipeline == false) {
        //     if (this.state.isHealthindexPipeline == false) {
        //       errors.isPipeline = 'Please select atleast one pipeline';
        //     }
        //   }
        //if (this.state.ResidentCountryId == undefined || this.state.ResidentCountryId == '') {
        //    errors.ResidentCountryId = 'Please select country.';
        //}
        //if (this.state.ResidentStateId == undefined || this.state.ResidentStateId == '') {
        //    errors.ResidentStateId = 'Please select state.';
        //}
        //if (this.state.ResidentCityId == undefined || this.state.ResidentCityId == '') {
        //    errors.ResidentCityId = 'Please select city.';
        //}
        //if (this.state.Mobile == undefined || this.state.Mobile == '') {
        //    errors.Mobile = 'Please enter mobile.';
        //}
        //if (this.state.ResidentAddress == undefined || this.state.ResidentAddress == '') {
        //    errors.ResidentAddress = 'Please enter resident address.';
        //}
        if (this.state.PhoneNumber == undefined || this.state.PhoneNumber == '') {
            errors.PhoneNumber = 'Please enter phone.';
        }
        // if (this.state.ResidentPostalCode == undefined || this.state.ResidentPostalCode == '') {
        //     errors.ResidentPostalCode = 'Please enter resident postal code.';
        // }
        if (this.state.clinicStreet == undefined || this.state.clinicStreet == '') {
            errors.clinicStreet = 'Please enter clinic street.';
        }
        if (this.state.clinicStateId == undefined || this.state.clinicStateId == '') {
            errors.clinicStateId = 'Please select state.';
        }
        if (this.state.clinicCityId == undefined || this.state.clinicCityId == '') {
            errors.clinicCityId = 'Please select city.';
        }
        if (this.state.clinicPostalCode == undefined || this.state.clinicPostalCode == '') {
            errors.clinicPostalCode = 'Please enter clinic postal code.';
        }
        // if (this.state.isPesPipeline == false && this.state.isHealthindexPipeline == false) {
        //     errors.isPipeline = 'Please select atleast one pipeline';
        // }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        if (!valid) {
            this.scrollToTop();

        }
        return valid;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const checked = target.checked;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        const validMobileRegex = RegExp(/^[0-9+() -]+$/);
        const validIRBRegex = RegExp(/^[0-9\.]+$/);
        const validNumberRegex = RegExp(/^[0-9]+$/);
        const validAlphaRegex = RegExp(/^[a-zA-Z \b]+$/);
        const validEmailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        switch (name) {
            case 'FirstName':
                errors.FirstName = (!value) ? 'Please enter firstname.' : (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.');
                this.setState({ FirstName: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;

            case 'MiddleName':
                errors.MiddleName = (value) ? (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.') : '';
                this.setState({ MiddleName: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;

            case 'LastName':
                errors.LastName = (!value) ? 'Please enter lastname.' : (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.');
                this.setState({ LastName: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;

            case 'Qualification':
                errors.Qualification = (!value) ? 'Please enter degree earned.' : '';
                break;

            case 'Email':
                errors.Email = (!value) ? 'Please enter email.' : (validEmailRegex.test(value) ? '' : 'Invalid Email.');
                break;

            //case 'ResidentCountryId':
            //    errors.ResidentCountryId = (!value) ? 'Please select country.' : '';
            //    break;

            //case 'ResidentStateId':
            //    errors.ResidentStateId = (!value) ? 'Please select state.' : '';

            //    break;
            //case 'ResidentCityId':
            //    errors.ResidentCityId = (!value) ? 'Please select city.' : '';
            //    break;

            case 'Mobile':
                errors.Mobile = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : 'Please enter phone.';
                this.setState({ Mobile: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            case 'npiNumber':
                errors.npiNumber = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ npiNumber: value.replace(/[^0-9]+$/, '') });
                break;

            //case 'ResidentAddress':
            //    errors.ResidentAddress = (!value) ? 'Please enter resident address.' : '';
            //    break;

            // case 'PhoneNumber':
            //     errors.PhoneNumber = (!value) ? "Please enter phone." : (validMobileRegex.test(value) ? '' : 'Only numbers allowed.');
            //     this.setState({ PhoneNumber: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
            //     break;

            // case 'ResidentPostalCode':
            //     errors.ResidentPostalCode = (!value) ? 'Please enter resident postal code.' : (validNumberRegex.test(value) ? '' : 'Only numbers allowed.');
            //     this.setState({ ResidentPostalCode: value.replace(/[^0-9]+$/, '') })
            //     break;

            case 'clinicStreet':
                errors.clinicStreet = (!value) ? 'Please enter clinic street.' : '';
                break;

            case 'clinicStateId':
                errors.clinicStateId = (!value) ? 'Please select state.' : '';
                break;

            case 'clinicCityId':
                errors.clinicCityId = (!value) ? 'Please select city.' : '';
                break;

            // case 'isPesPipeline':
            //     errors.isPipeline = (!checked) && (!this.state.isHealthindexPipeline) ? 'Please select atleast one pipeline' : '';
            //     break;

            // case 'isHealthindexPipeline':
            //     errors.isPipeline = (!checked) && (!this.state.isPesPipeline) ? 'Please select atleast one pipeline' : '';
            //     break;

            case 'clinicPostalCode':
                errors.clinicPostalCode = (!value) ? 'Please enter clinic postal code.' : (validNumberRegex.test(value) ? '' : 'Only numbers allowed.');
                this.setState({ clinicPostalCode: value.replace(/[^0-9]+$/, '') })
                break;

            case 'IRBNo':
                errors.IRBNo = (!value) ? "" : (validIRBRegex.test(value) ? '' : 'Only numbers allowed.');
                this.setState({ IRBNo: value.replace(/[^0-9\.]+$/, '') })
                break;

            //case 'ResidentPostalCode,':
            //    errors.ResidentPostalCode = (!value) ? 'Please enter resident postal code.' : '';
            //    break;

            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors }, () => {

            if (name == 'ResidentCountryId') {
                if (value != '') {
                    this.getStateData(value, "", "", "", "");
                    this.setState({ cities: [], ResidentCityId: "", clinicCities: [], clinicCityId: "" });
                }
                else {
                    this.setState({ states: [], ResidentStateId: "", cities: [], ResidentCityId: "", clinicCities: [], clinicStateId: "", clinicCityId: "" });
                }
            }
            if (name == 'ResidentStateId') {
                if (value != '') {
                    this.getCityData(value, "");
                }
                else {
                    this.setState({ cities: [], ResidentCityId: "" });
                }
            }
            if (name == 'clinicStateId') {
                if (value != '') {
                    this.getClinicCityData(value, "");
                }
                else {
                    this.setState({ clinicCities: [], clinicCityId: "" });
                }
            }

            if (name === "isPesPipeline") {
                if (checked === true) {
                    this.setState({ isPesPipeline: true })
                } else {
                    this.setState({ isPesPipeline: false })
                }
            }

            if (name === "isHealthindexPipeline") {
                if (checked === true) {
                    this.setState({ isHealthindexPipeline: true })
                } else {
                    this.setState({ isHealthindexPipeline: false })
                }
            }

        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let userid = (userToken.userId == null ? 0 : userToken.userId);

        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            if (this.state.PractitionerId == 0) {
                url = apiroute + '/api/Practitioner/Save';
            }
            else {
                url = apiroute + '/api/Practitioner/Update';
            }

            let data = JSON.stringify({
                PractitionerId: parseInt(this.state.PractitionerId),
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                MiddleName: this.state.MiddleName,
                UserName: this.state.UserName,
                Email: this.state.Email,
                ResidentAddress: this.state.ResidentAddress,
                PhoneNumber: this.state.PhoneNumber,
                Mobile: this.state.Mobile,
                ResidentPostalCode: this.state.ResidentPostalCode,
                Qualification: this.state.Qualification,
                ResidentCityId: ((this.state.ResidentCityId == "" || this.state.ResidentCityId == 0) ? null : parseInt(this.state.ResidentCityId)),
                ResidentCountryId: ((this.state.ResidentCountryId == "" || this.state.ResidentCountryId == 0) ? null : parseInt(this.state.ResidentCountryId)),
                ResidentStateId: ((this.state.ResidentStateId == "" || this.state.ResidentStateId == 0) ? null : parseInt(this.state.ResidentStateId)),
                UserId: parseInt(userid),
                IRBNo: this.state.IRBNo,
                OldIRBNo: this.state.OldIRBNo,
                clinicName: this.state.clinicName,
                clinicStreet: this.state.clinicStreet,
                clinicCityId: ((this.state.clinicCityId == "" || this.state.clinicCityId == 0) ? null : parseInt(this.state.clinicCityId)),
                clinicStateId: ((this.state.clinicStateId == "" || this.state.clinicStateId == 0) ? null : parseInt(this.state.clinicStateId)),
                clinicPostalCode: this.state.clinicPostalCode,
                isPesPipeline: this.state.isPesPipeline,
                isHealthindexPipeline: this.state.isHealthindexPipeline,
                npiNumber: this.state.npiNumber
            })

            // console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        // console.log(result.data.outdata);

                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // //error: result.data.message,
                            // error: "Profile updated successfully.",
                            loading: false
                        }, this.getData());
                        toast.success("Profile updated successfully.")
                        if (result.data.outdata.irbStatus == "A") {
                            setTimeout(() => this.setState({ redirect: true }), 3000);
                        }
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    //console.log(error);
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: error.message,
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            this.setState({
                // authError: true,
                // errorType: 'danger',
                // error: "Please fill all the fields.",
                loading: false
            });
            toast.error("Please fill all the fields.")
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/practitioner/dashboard" />
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    }


    render() {
        const { loading, FirstName, MiddleName, LastName, UserName, Email, ResidentCountryId,
            countries, ResidentStateId, states, ResidentCityId, cities, Mobile, ResidentAddress,
            IRBStatus, IRBNo, pCurrentFlag, pCurrentStatus, PhoneNumber, ResidentPostalCode, Qualification,
            errors, authError, error, errorType, clinicName, clinicStreet, clinicCityId, clinicStateId,
            clinicPostalCode, clinicCities, isPesPipeline, isHealthindexPipeline, npiNumber } = this.state;
        return (

            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                                Profile
                                            </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/practitioner/profile" className="kt-subheader__breadcrumbs-link">
                                                    Profile                      </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            {/* {!pCurrentFlag ?
                                                (pCurrentStatus != "" && pCurrentStatus != null ?
                                                    <div>
                                                        <div className="alert alert-danger alert-dismissible" role="alert">
                                                            <div className="alert-text" style={{ "textAlign": "Center" }}> {pCurrentStatus}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null)
                                                : null} */}

                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">IRB No</label>
                                                                <div className="col-12">
                                                                    {IRBStatus ?
                                                                        <Input disabled="disabled" className="form-control here" type="text" maxLength="50" tabIndex="1" placeholder="Enter your IRB no" name="IRBNo" value={IRBNo} onChange={this.handleInputChange.bind(this)} />
                                                                        :
                                                                        <Input className="form-control here" type="text" maxLength="50" tabIndex="1" placeholder="Enter your IRB no" name="IRBNo" value={IRBNo} onChange={this.handleInputChange.bind(this)} />
                                                                    }
                                                                    {errors.IRBNo.length > 0 && <span className='error'>{errors.IRBNo}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">First Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="2" placeholder="Enter your first name" name="FirstName" value={FirstName} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Middle Name</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="3" placeholder="Enter your middle name" name="MiddleName" value={MiddleName} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.MiddleName.length > 0 && <span className='error'>{errors.MiddleName}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Last Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="4" placeholder="Enter your last name" name="LastName" value={LastName} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.LastName.length > 0 && <span className='error'>{errors.LastName}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Degree Earned<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="text" name="Qualification" maxLength="200" tabIndex="5" className="form-control" placeholder="Enter a degree earned" value={Qualification} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.Qualification.length > 0 && <span className='error'>{errors.Qualification}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Email<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="50" tabIndex="6" name="Email" placeholder="Enter a valid email" value={Email} onChange={this.handleInputChange.bind(this)} required autoComplete="Email" disabled />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            {/*<div className="col-md-6">
                                                                <label className="col-12 col-form-label">User Name</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="100" tabIndex="5" placeholder="Enter your user name" name="UserName" value={UserName} onChange={this.handleInputChange.bind(this)} required autoComplete="UserName" disabled />
                                                                </div>
                                                            </div>*/}
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Phone<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="7" name="Mobile" placeholder="Enter your phone" value={Mobile} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.Mobile.length > 0 && <span className='error'>{errors.Mobile}</span>}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">NPI Number<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="10" tabIndex="8" name="npiNumber" placeholder="Enter your NPI Number" value={npiNumber} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.npiNumber.length > 0 && <span className='error'>{errors.npiNumber}</span>}
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Country</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="9" name="ResidentCountryId" value={ResidentCountryId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Country</option>
                                                                        {countries
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.ResidentCountryId.length > 0 && <span className='error'>{errors.ResidentCountryId}</span>}
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Secondary Phone</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="7" name="PhoneNumber" placeholder="Enter secondary phone" value={PhoneNumber} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.PhoneNumber.length > 0 && <span className='error'>{errors.PhoneNumber}</span>}
                                                                </div>
                                                            </div> */}
                                                        </div>

                                                        {/* <div className="form-group row my-4">
                                                           
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">State</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="10" name="ResidentStateId" value={ResidentStateId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select State</option>
                                                                        {states
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.ResidentStateId.length > 0 && <span className='error'>{errors.ResidentStateId}</span>}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">City</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="11" name="ResidentCityId" value={ResidentCityId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select City</option>
                                                                        {cities
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.ResidentCityId.length > 0 && <span className='error'>{errors.ResidentCityId}</span>}
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                        {/* <div className="form-group row my-4">
                                                            
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Resident Postal Code<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="12" name="ResidentPostalCode" placeholder="Enter a resident postal code" value={ResidentPostalCode} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.ResidentPostalCode.length > 0 && <span className='error'>{errors.ResidentPostalCode}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Resident Address</label>
                                                                <div className="col-12">
                                                                    <textarea className="form-control here" type="text" maxLength="500" tabIndex="13" name="ResidentAddress" placeholder="Enter a resident address" value={ResidentAddress} onChange={this.handleInputChange.bind(this)} ></textarea>
                                                                    {errors.ResidentAddress.length > 0 && <span className='error'>{errors.ResidentAddress}</span>}
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='page-title'>
                                                        <h1>Clinic</h1>
                                                    </div>
                                                    <div className="form-group row my-4">
                                                        <div className="col-md-6">
                                                            <label className="col-12 col-form-label">Clinic Name</label>
                                                            <div className="col-12">
                                                                <Input className="form-control here" type="text" maxLength="200" tabIndex="15" placeholder="Enter your clinic name" name="clinicName" value={clinicName} onChange={this.handleInputChange.bind(this)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="col-12 col-form-label">Clinic Street<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                            <div className="col-12">
                                                                <Input className="form-control here" type="text" maxLength="200" tabIndex="16" name="clinicStreet" placeholder="Enter clinic street" value={clinicStreet} onChange={this.handleInputChange.bind(this)} />
                                                                {errors.clinicStreet.length > 0 && <span className='error'>{errors.clinicStreet}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="form-group row my-4">

                                                        <div className="col-md-6">
                                                            <label className="col-12 col-form-label">Clinic State<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                            <div className="col-12">
                                                                <Input type="select" className="custom-select mb-3" tabIndex="17" name="clinicStateId" value={clinicStateId} onChange={this.handleInputChange.bind(this)}>
                                                                    <option value="">Select State</option>
                                                                    {states
                                                                        .map((data, i) => {
                                                                            return (<option key={i} value={data.id}>{data.name}</option>);
                                                                        })}
                                                                </Input>
                                                                {errors.clinicStateId.length > 0 && <span className='error'>{errors.clinicStateId}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <label className="col-12 col-form-label">Clinic City<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                            <div className="col-12">
                                                                <Input type="select" className="custom-select mb-3" tabIndex="18" name="clinicCityId" value={clinicCityId} onChange={this.handleInputChange.bind(this)}>
                                                                    <option value="">Select City</option>
                                                                    {clinicCities
                                                                        .map((data, i) => {
                                                                            return (<option key={i} value={data.id}>{data.name}</option>);
                                                                        })}
                                                                </Input>
                                                                {errors.clinicCityId.length > 0 && <span className='error'>{errors.clinicCityId}</span>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row my-4">
                                                        <div className="col-md-6">
                                                            <label className="col-12 col-form-label">Clinic Postal Code<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                            <div className="col-12">
                                                                <Input className="form-control here" type="text" maxLength="20" tabIndex="19" placeholder="Enter your clinic postal code" name="clinicPostalCode" value={clinicPostalCode} onChange={this.handleInputChange.bind(this)} />
                                                                {errors.clinicPostalCode.length > 0 && <span className='error'>{errors.clinicPostalCode}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='page-title'>
                                                            <h1>Pipeline<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></h1>
                                                        </div>
                                                        <div className='all-checkbox-alignment'>
                                                        <div className='checkbox-alignment'>
                                                            <div>
                                                                <Input type="checkbox" name="isPesPipeline" tabIndex="20" checked={isPesPipeline} onChange={this.handleInputChange.bind(this)} />
                                                            </div>
                                                            <div>
                                                                <span>ITI-PES</span>
                                                            </div>
                                                        </div>
                                                        <div className='checkbox-alignment'>
                                                            <div>
                                                                <Input type="checkbox" name="isHealthindexPipeline" tabIndex="21" checked={isHealthindexPipeline} onChange={this.handleInputChange.bind(this)} />
                                                            </div>
                                                            <div>
                                                                <span>HealthIndex</span>
                                                            </div>
                                                        </div>
                                                        {isPesPipeline == false && isHealthindexPipeline == false && <span className='error'>{errors.isPipeline}</span>}
                                                        </div> */}
                                                    <div className="form-group row my-4">

                                                    </div>
                                                </div>
                                                {loading ?
                                                    <button tabIndex="14" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                    :
                                                    <button tabIndex="14" type="submit" className="btn btn-primary">Submit</button>
                                                }
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
                    {this.loading()}
                </div>
            </React.Fragment >
        );
    }
}
