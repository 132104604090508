import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
//import MyModal from './../../CustomModal/CustomModal';
import Moment from 'react-moment';
import moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import FilePreview from "react-file-preview-latest";
import downloadIcon from '../../../assets/svg/download.svg';
import closeIcon from '../../../assets/svg/x.svg';

export class PatientProfile extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            activeTab: 0,
            loading: true,
            patientId: 0,
            basicInfo: "",
            //assignedpractitioner: "",
            //diseases: "",
            //diagnosticHistory: [],
            //emergencyContact: [],
            //insuranceDetail: [],
            //prescription: [],
            //treatmentReport: [],
            //assignedlaboratory: "",
            //assignedinstitute: "",
            //assignedmanufacturer: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            CountryId: "",
            StateId: "",
            CityId: "",
            Mobile: "",
            DateOfBirth: "",
            Age: "",
            Address: "",
            Sex: "M",
            PhoneNumber: "",
            PostalCode: "",
            countries: [],
            states: [],
            cities: [],
            patientAccessionDetail: [],
            //allpractitioners: [],
            //allngslaboratorys: [],
            //patientpayments: [],
            //milestones: [],
            //patientMilestoneId: '',
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            preview: false,
            url: "",
            errors: {
                StateId: '',
                CityId: '',
                CountryId: '',
                FirstName: '',
                MiddleName: '',
                LastName: '',
                UserName: '',
                Email: '',
                Mobile: '',
                Age: '',
                Address: '',
                PhoneNumber: '',
                PostalCode: '',
            },
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.getData();
    //    }
    //}

    componentDidMount() {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/CognitoUserStore/getallcountry';
        let data = JSON.stringify({
            isDeleted: false,
            searchString: ''
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                console.log("country", result.data.outdata);
                this.setState({
                    countries: result.data.outdata
                }, () => { this.getData() });
            }
            else {
                this.setState({ loading: false });
            }
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
        });
    }

    //get detail(for update)
    getData() {
        const apiroute = window.$APIPath;

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = (userToken.patientId == null ? 0 : userToken.patientId);
        const url = apiroute + '/api/Patient/getPatientInfobyId?id=' + id + '';

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                console.log(result);
                if (result.data.flag) {
                    var rData = result.data.outdata;
                    this.setState({
                        basicInfo: rData,
                        patientId: rData.patientId,
                        patientAccessionDetail: rData.patientAccessionMapping,
                        //assignedpractitioner: rData.practitionerPatient,
                        //assignedlaboratory: rData.laboratoryPatient,
                        //assignedinstitute: rData.institutePatient,
                        //assignedmanufacturer: rData.manufacturerPatient,
                        //diseases: rData.patientDisease[0],
                        //diagnosticHistory: rData.patientDiagnosticHistory,
                        //emergencyContact: rData.patientEmergencyContacts,
                        //insuranceDetail: rData.patientInsuranceDetails,
                        //prescription: rData.patientPrescription,
                        //treatmentReport: rData.patientTreatmentReport,
                        //AccessionNo: rData.accessionNo,
                        FirstName: rData.firstName, MiddleName: rData.middleName,
                        LastName: rData.lastName, UserName: rData.userName, Email: rData.email, Mobile: rData.mobile,
                        // DateOfBirth: (rData.dateOfBirth == null ? "" : moment(rData.dateOfBirth.slice(0, 10), "MM-DD-YYYY").format('YYYY-MM-DD')),
                        DateOfBirth: (rData.dateOfBirth == null ? "" : moment(rData.dateOfBirth)._d),
                        Age: rData.age, Address: rData.address, Sex: rData.sex,
                        PhoneNumber: rData.phoneNumber, PostalCode: rData.postalCode, CountryId: rData.countryId,
                    }, () => {
                        if (rData.countryId != null) {
                            this.getStateData(rData.countryId, rData.stateId, rData.cityId)
                        }
                        else {
                            this.setState({ loading: false });
                        }
                    });
                    console.log(this.state);
                }
                else {
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log(error);
            });
    }

    getStateData(CountryId, StateId, CityId) {

        const apiroute = window.$APIPath;

        const url = apiroute + '/api/CognitoUserStore/getallstate?Id=' + CountryId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    //console.log(result.data);
                    this.setState({ states: result.data.outdata, StateId: StateId }, () => {
                        if (StateId != "" || StateId != null) {
                            this.getCityData(StateId, CityId)
                        }
                        else {
                            this.setState({ loading: false });
                        }
                    });
                }
                else {
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log(error);
            });
    }

    getCityData(StateId, CityId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/CognitoUserStore/getallcity?Id=' + StateId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                //console.log(result.data);
                this.setState({ cities: result.data.outdata, CityId: CityId, loading: false });
            } else {
                this.setState({ loading: false });
            }
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
        });
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.CountryId == undefined || this.state.CountryId == '') {
            errors.CountryId = 'Please select resident country.';
        }
        //if (this.state.CityId == undefined || this.state.CityId == '') {
        //    errors.CityId = 'Please select resident city.';
        //}
        //if (this.state.StateId == undefined || this.state.StateId == '') {
        //    errors.StateId = 'Please select resident state.';
        //}

        if (this.state.FirstName == undefined || this.state.FirstName == '') {
            errors.FirstName = 'Please enter firstname.';
        }
        //if (this.state.MiddleName == undefined || this.state.MiddleName == '') {
        //    errors.MiddleName = 'Please enter MiddleName.';
        //}
        if (this.state.LastName == undefined || this.state.LastName == '') {
            errors.LastName = 'Please enter lastname.';
        }

        //if (this.state.Mobile == undefined || this.state.Mobile == '') {
        //    errors.Mobile = 'Please enter Mobile.';
        //}
        //if (this.state.Age == undefined || this.state.Age == '') {
        //    errors.Age = 'Please enter Age.';
        //}
        //if (this.state.Address == undefined || this.state.Address == '') {
        //    errors.Address = 'Please enter resident address.';
        //}
        if (this.state.PhoneNumber == undefined || this.state.PhoneNumber == '') {
            errors.PhoneNumber = 'Please enter PhoneNumber.';
        }
        if (this.state.PostalCode == undefined || this.state.PostalCode == '') {
            errors.PostalCode = 'Please enter resident postal/zip code.';
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }


    //numberOnly(event): boolean {
    //    const charCode = (event.which) ? event.which : event.keyCode;
    //    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    //        return false;
    //    }
    //    return true;

    //}

    handleDateChange(date) {
        var newAge = (date) ? this.calculate_age(date) : 0;
        this.setState({ DateOfBirth: date, Age: newAge })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        //const filteredInput = event.target.value.replace(/^[a-zA-Z\b]+$/, '');
        //const filteredInput = event.target.value.replace(/[^a-z]+/gi, '');
        //this.setState({ FirstName: filteredInput })

        let errors = this.state.errors;

        const validMobileRegex = RegExp(/^[0-9+() -]+$/);
        const validNumberRegex = RegExp(/^[0-9]+$/);
        const validAlphaRegex = RegExp(/^[a-zA-Z \b]+$/);
        const validAlphaNoRegex = RegExp(/^[a-zA-Z0-9 \b]+$/);

        switch (name) {
            case 'CountryId':
                errors.CountryId = (!value) ? 'Please select resident country.' : '';
                break;

            //case 'StateId':
            //    errors.StateId = (!value) ? 'Please select resident state.' : '';
            //    break;
            //case 'CityId':
            //    errors.CityId = (!value) ? 'Please select resident city.' : '';
            //    break;

            case 'FirstName':
                errors.FirstName = (!value) ? 'Please enter firstname.' : (validAlphaNoRegex.test(value) ? '' : 'Only alphanumeric allowed.');
                this.setState({ FirstName: value.replace(/[^a-zA-Z0-9 \b]+$/, '') })
                break;

            case 'MiddleName':
                errors.MiddleName = (value) ? (validAlphaNoRegex.test(value) ? '' : 'Only alphanumeric allowed.') : '';
                this.setState({ MiddleName: value.replace(/[^a-zA-Z0-9 \b]+$/, '') })
                break;

            case 'LastName':
                errors.LastName = (!value) ? 'Please enter lastname.' : (validAlphaNoRegex.test(value) ? '' : 'Only alphanumeric allowed.');
                this.setState({ LastName: value.replace(/[^a-zA-Z0-9 \b]+$/, '') })
                break;


            //case 'Mobile':
            //    errors.Mobile = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : '';
            //    this.setState({ Mobile: value.replace(/[^0-9+]+$/, '') })
            //    break;

            case 'DateOfBirth':
                var newAge = (value) ? this.calculate_age(value) : 0;
                this.setState({ Age: newAge });
                break;

            //case 'Address':
            //    errors.Address = (!value) ? 'Please enter resident address.' : '';
            //    break;

            case 'PhoneNumber':
                errors.PhoneNumber = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : 'Please enter phone.';
                this.setState({ PhoneNumber: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            case 'PostalCode':
                errors.PostalCode = (!value) ? 'Please enter resident postal/zip code.' : (validNumberRegex.test(value) ? '' : 'Only numbers allowed.');
                this.setState({ PostalCode: value.replace(/[^0-9]+$/, '') })
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors }, () => {

            if (name == 'CountryId') {
                if (value != '') {
                    this.getStateData(value, "", "");
                    this.setState({ cities: [], CityId: "" });
                }
                else {
                    this.setState({ states: [], StateId: "", cities: [], CityId: "" });
                }
            }
            if (name == 'StateId') {
                if (value != '') {
                    this.getCityData(value, "");
                }
                else {
                    this.setState({ cities: [], CityId: "" });
                }
            }

        })
    }

    calculate_age = (dob1) => {
        var today = new Date();
        var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        console.log(age_now);
        return age_now;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";
        let userid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            userid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            if (this.state.patientId == 0) {
                url = apiroute + '/api/Patient/Save';
            }
            else {
                url = apiroute + '/api/Patient/Update';
            }

            let data = JSON.stringify({
                patientId: parseInt(this.state.patientId),
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                MiddleName: this.state.MiddleName,
                UserName: this.state.UserName,
                Email: this.state.Email,
                Address: this.state.Address,
                PhoneNumber: this.state.PhoneNumber,
                //Mobile: this.state.Mobile,
                PostalCode: this.state.PostalCode,
                Sex: this.state.Sex,
                DateOfBirth: (this.state.DateOfBirth != null && this.state.DateOfBirth != "" ? this.state.DateOfBirth : null),
                CityId: ((this.state.CityId == "" || this.state.CityId == 0) ? null : parseInt(this.state.CityId)),
                CountryId: ((this.state.CountryId == "" || this.state.CountryId == 0) ? null : parseInt(this.state.CountryId)),
                StateId: ((this.state.StateId == "" || this.state.StateId == 0) ? null : parseInt(this.state.StateId)),
                //Age: (this.state.Age == '' ? 0 : parseInt(this.state.Age)),
                Age: (this.state.Age == '' || this.state.Age == null || this.state.Age == 0 ? null : this.state.Age.toString()),
                UserId: parseInt(userid),
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            //error: result.data.message,
                            // error: "Profile updated successfully.",
                            loading: false
                        }, this.getData());
                        toast.success("Profile updated successfully.")
                        setTimeout(() => this.setState({ redirect: true }), 3000);
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    //console.log(error);
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: error.message,
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            this.setState({
                // authError: true,
                // errorType: 'danger',
                // error: "Please fill all the fields.",
                loading: false
            });
            toast.error("Please fill all the fields.")
        }
    }

    toggleExpander = (e) => {
        const hiddenElement = e.currentTarget.nextSibling;
        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    }

    //file preview
    previewToggle (e, file) {
        this.setState({
        preview: !this.state.preview,
        url: file
        })
    }

    onError = (err) => console.log("Error:", err); // Write your own logic

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    toggle(tab) {
        this.setState({
            activeTab: tab,
        });
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/patient/dashboard" />
        }
    }

    render() {
        //const { loading, AccessionNo, FirstName, MiddleName, LastName, UserName, Email, CountryId, countries, StateId, states, CityId, cities, Mobile, DateOfBirth, Age, Address, Sex, PhoneNumber, PostalCode, errors } = this.state;
        const { loading, patientId, activeTab,
            //allpractitioners, allngslaboratorys, assignedlaboratory,
            //diseases, diagnosticHistory, emergencyContact, insuranceDetail,
            //assignedinstitute, assignedmanufacturer, prescription, treatmentReport,
            basicInfo, DateOfBirth, patientAccessionDetail, url, preview,
            assignedpractitioner, FirstName, MiddleName, LastName, UserName, Email, CountryId, countries,
            StateId, states, CityId, cities, Mobile, Age, Address, Sex, PhoneNumber,
            PostalCode, errors, error, errorType, authError
        } = this.state;

        return (

            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                                Profile                        
                                            </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/patient/profile" className="kt-subheader__breadcrumbs-link">
                                                    Profile                        </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <div className="kt-widget kt-widget--user-profile-3">
                                                <div className="kt-widget__top">
                                                    {/* <div className="kt-widget__media">
                                                        <img src="./assets/media/users/100_1.jpg" alt="image" />
                                                    </div>*/}
                                                    <div className="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-bolder kt-font-light kt-hidden">JM</div>
                                                    <div className="kt-widget__content">
                                                        <div className="kt-widget__head">
                                                            <div className="kt-widget__user"> <Link className="kt-widget__username">{basicInfo.firstName != null ? (basicInfo.firstName + " " + basicInfo.lastName) : ""}</Link>
                                                                <span className="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success">{basicInfo.sex == "M" ? "Male" : basicInfo.sex == "F" ? "Female" : "-"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-widget__subhead"> <Link><i className="flaticon2-new-email"></i>{basicInfo.email != null ? basicInfo.email : ""}</Link>
                                                            <Link><i className="flaticon2-calendar"></i>
                                                                {
                                                                    basicInfo.dateOfBirth != null && basicInfo.dateOfBirth != "" ?
                                                                        <React.Fragment>
                                                                            {moment(basicInfo.dateOfBirth).format('MM/DD/YYYY')}
                                                                            <span>{"(" + basicInfo.age + ")"}</span>
                                                                        </React.Fragment>
                                                                        : "NA"
                                                                }
                                                            </Link>
                                                            <Link><i className="flaticon2-phone"></i>{basicInfo.mobile != null ? basicInfo.mobile + (basicInfo.phoneNumber != null ? ", " + basicInfo.phoneNumber : "") : "NA"}</Link>
                                                        </div>
                                                        <div className="kt-widget__info">
                                                            <div className="kt-widget__desc">
                                                                <i className="flaticon2-placeholder">{basicInfo.address != null ? basicInfo.address + " - " + basicInfo.postalCode : "NA"}</i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*</div>*/}
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head" style={{ "background-color": "#eaf2ff" }}>
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Basic Details</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        <Form onSubmit={this.handleSubmit.bind(this)}>
                                                            {/* {authError ?
                                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                                    <div className="alert-text">{error}</div>
                                                                    <div className="alert-close">
                                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                                    </div>
                                                                </div>
                                                                : null} */}
                                                            <div className="kt-portlet__body">
                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">First Name:<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className="col-6">
                                                                        <Input className="form-control" type="text" maxLength="200" tabIndex="1" placeholder="Enter your first name" name="FirstName" value={FirstName} onChange={this.handleInputChange.bind(this)} />
                                                                        {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">Middle Name:</label>
                                                                    <div className="col-6">
                                                                        <Input className="form-control" type="text" maxLength="200" tabIndex="2" placeholder="Enter your middle name" name="MiddleName" value={MiddleName} onChange={this.handleInputChange.bind(this)} />
                                                                        {errors.MiddleName.length > 0 && <span className='error'>{errors.MiddleName}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">Last Name:<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className="col-6">
                                                                        <Input className="form-control" type="text" maxLength="200" tabIndex="3" placeholder="Enter your last name" name="LastName" value={LastName} onChange={this.handleInputChange.bind(this)} />
                                                                        {errors.LastName.length > 0 && <span className='error'>{errors.LastName}</span>}
                                                                    </div>
                                                                </div>
                                                                {/*<div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">User Name:</label>
                                                                    <div className="col-8">
                                                                        <Input className="form-control" type="text" readOnly maxLength="200" tabIndex="4" placeholder="Enter your user name" name="UserName" value={UserName} onChange={this.handleInputChange.bind(this)} />
                                                                        {errors.UserName.length > 0 && <span className='error'>{errors.UserName}</span>}
                                                                    </div>
                                                                </div>*/}
                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">Date of Birth:<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className="col-4">
                                                                        {/* <Input className="form-control" max="9999-12-31" type="date" min="1000-01-01" tabIndex="4" placeholder="Enter your date of birth" name="DateOfBirth" value={DateOfBirth} onChange={this.handleInputChange.bind(this)} /> */}
                                                                        <DatePicker
                                                                            selected={DateOfBirth}
                                                                            onChange={this.handleDateChange.bind(this)}
                                                                            dateFormat="MM/dd/yyyy"
                                                                            placeholderText="mm/dd/yyyy"
                                                                            className="form-control"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                        />
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <Input className="form-control" type="text" readOnly name="Age" value={Age} placeholder="Age" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">Sex:</label>
                                                                    <div className="col-6">
                                                                        <div className="kt-radio-inline">

                                                                            {Sex == "M" ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="M" onChange={this.handleInputChange.bind(this)} checked id="Male" name="Sex" tabIndex="5" /> Male
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="M" onChange={this.handleInputChange.bind(this)} id="Male" name="Sex" tabIndex="5" /> Male
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                            {Sex == "F" ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="F" onChange={this.handleInputChange.bind(this)} checked id="Female" name="Sex" tabIndex="6" /> Female
                                                                                <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="F" onChange={this.handleInputChange.bind(this)} id="Female" name="Sex" tabIndex="6" /> Female
							                                                        <span></span>
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*<div className="form-group form-group-xs row">*/}
                                                                {/*    <label className="col-4 col-form-label">Mobile:</label>*/}
                                                                {/*    <div className="col-6">*/}
                                                                {/*        <Input className="form-control" type="text" maxLength="20" tabIndex="7" placeholder="Enter your mobile" name="Mobile" value={Mobile} onChange={this.handleInputChange.bind(this)} />*/}
                                                                {/*        {errors.Mobile.length > 0 && <span className='error'>{errors.Mobile}</span>}*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}

                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">Phone:<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className="col-6">
                                                                        <Input className="form-control" type="text" maxLength="20" tabIndex="8" placeholder="Enter your phone" name="PhoneNumber" value={PhoneNumber} onChange={this.handleInputChange.bind(this)} />
                                                                        {errors.PhoneNumber.length > 0 && <span className='error'>{errors.PhoneNumber}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">Resident Address:</label>
                                                                    <div className="col-6">
                                                                        <Input className="form-control" type="textarea" maxLength="500" tabIndex="9" placeholder="Enter your residential address" name="Address" value={Address} onChange={this.handleInputChange.bind(this)} />
                                                                        {errors.Address.length > 0 && <span className='error'>{errors.Address}</span>}
                                                                    </div>
                                                                </div>

                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">Resident Country:<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className="col-6">
                                                                        <Input type="select" className="form-control" tabIndex="10" name="CountryId" value={CountryId} onChange={this.handleInputChange.bind(this)}>
                                                                            <option value="">Select Resident Country</option>
                                                                            {countries
                                                                                .map((data, i) => {
                                                                                    return (<option key={i} value={data.id}>{data.name}</option>);
                                                                                })}
                                                                        </Input>
                                                                        {errors.CountryId.length > 0 && <span className='error'>{errors.CountryId}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">Resident State:</label>
                                                                    <div className="col-6">
                                                                        <Input type="select" className="form-control" tabIndex="11" name="StateId" value={StateId} onChange={this.handleInputChange.bind(this)}>
                                                                            <option value="">Select Resident State</option>
                                                                            {states
                                                                                .map((data, i) => {
                                                                                    return (<option key={i} value={data.id}>{data.name}</option>);
                                                                                })}
                                                                        </Input>
                                                                        {errors.StateId.length > 0 && <span className='error'>{errors.StateId}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">Resident City:</label>
                                                                    <div className="col-6">
                                                                        <Input type="select" className="form-control" tabIndex="12" name="CityId" value={CityId} onChange={this.handleInputChange.bind(this)}>
                                                                            <option value="">Select Resident City</option>
                                                                            {cities
                                                                                .map((data, i) => {
                                                                                    return (<option key={i} value={data.id}>{data.name}</option>);
                                                                                })}
                                                                        </Input>
                                                                        {errors.CityId.length > 0 && <span className='error'>{errors.CityId}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group form-group-xs row">
                                                                    <label className="col-4 col-form-label">Resident Postal/Zip Code:<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className="col-6">
                                                                        <Input className="form-control" type="text" maxLength="20" tabIndex="13" placeholder="Enter your residential postal/zip code" name="PostalCode" value={PostalCode} onChange={this.handleInputChange.bind(this)} />
                                                                        {errors.PostalCode.length > 0 && <span className='error'>{errors.PostalCode}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="kt-portlet__foot">
                                                                <div className="kt-form__actions">
                                                                    {loading ?
                                                                        <button tabIndex="14" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                                        :
                                                                        <button tabIndex="14" type="submit" className="btn btn-primary">Submit</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-12">
                                                <div className="kt-portlet kt-portlet--tabs">
                                                    <div className="kt-portlet__head" style={{ "background-color": "#eaf2ff" }}>
                                                        <div className="kt-portlet__head-toolbar">
                                                            <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand" role="tablist">
                                                                {patientAccessionDetail.length > 0 ? (
                                                                    patientAccessionDetail
                                                                        .map((data, i) => {
                                                                            return (
                                                                                <li className="nav-item">
                                                                                    <a className={activeTab == i ? "nav-link active" : "nav-link" } onClick={() => { this.toggle(i); }} data-toggle="tab" href={"#kt_apps_contacts_view_tab_m" + i} role="tab">{data.accessionNo}</a>
                                                                                </li>
                                                                            )
                                                                        })) : null}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="kt-portlet__body">
                                                        <div className="tab-content">
                                                            {patientAccessionDetail.length > 0 ? (
                                                                patientAccessionDetail
                                                                    .map((data, i) => {
                                                                        return (
                                                                            <div className={activeTab == i ? "tab-pane active" : "tab-pane"} id={"#kt_apps_contacts_view_tab_m" + i} role="tabpanel">
                                                                                <div className="kt-portlet kt-portlet--tabs">
                                                                                    <div className="kt-portlet__head" style={{ "background-color": "#eaf2ff" }}>
                                                                                        <div className="kt-portlet__head-toolbar">
                                                                                            <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand" role="tablist">
                                                                                                <li className="nav-item">
                                                                                                    <a className="nav-link active" data-toggle="tab" href="#kt_apps_contacts_view_tab_0" role="tab">Info</a>
                                                                                                </li>
                                                                                                <li className="nav-item">
                                                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_1" role="tab">Diagnostics</a>
                                                                                                </li>
                                                                                                <li className="nav-item">
                                                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_2" role="tab">Prescription</a>
                                                                                                </li>
                                                                                                <li className="nav-item">
                                                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_3" role="tab">Treatments/Visit</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="kt-portlet__body">
                                                                                        <div className="tab-content">
                                                                                            <div className="tab-pane active" id="kt_apps_contacts_view_tab_0" role="tabpanel">
                                                                                                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                                                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                                                    <div className="kt-notes__items">
                                                                                                        <div className="col-xl-12">
                                                                                                            <h3 className="kt-portlet__head-title">
                                                                                                                Disease</h3>
                                                                                                            {data.patientDisease != "" && data.patientDisease != null ?
                                                                                                                <div className="form-group form-group-xs row">
                                                                                                                    <div className="col-4">
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">{data.patientDisease.diseaseName + " (" + data.patientDisease.diseaseCode + ")"}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="form-group form-group-xs row">
                                                                                                                    <div className="col-4">
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">Data Not Available</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>

                                                                                                        <div className="col-xl-12">
                                                                                                            <h3 className="kt-portlet__head-title">
                                                                                                                Practitioner</h3>
                                                                                                            {data.practitionerPatient != "" && data.practitionerPatient != null ?
                                                                                                                <div className="form-group form-group-xs row">
                                                                                                                    <div className="col-4">
                                                                                                                        <label className="col-form-label">Name</label>
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">{data.practitionerPatient.firstName + " " + data.practitionerPatient.lastName}</span>
                                                                                                                    </div>
                                                                                                                    <div className="col-4">
                                                                                                                        <label className="col-form-label">Email</label>
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">{data.practitionerPatient.email}</span>
                                                                                                                    </div>
                                                                                                                    <div className="col-4">
                                                                                                                        <label className="col-form-label">Phone</label>
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">{data.practitionerPatient.phoneNumber}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="form-group form-group-xs row">
                                                                                                                    <div className="col-4">
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>

                                                                                                        <div className="col-xl-12">
                                                                                                            <h3 className="kt-portlet__head-title">
                                                                                                                Institution</h3>
                                                                                                            {data.institutePatient != "" && data.institutePatient != null ?
                                                                                                                <div className="form-group form-group-xs row">
                                                                                                                    <div className="col-4">
                                                                                                                        <label className="col-form-label">Name</label>
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">{data.institutePatient.instituteName}</span>
                                                                                                                    </div>
                                                                                                                    <div className="col-4">
                                                                                                                        <label className="col-form-label">Email</label>
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">{data.institutePatient.instituteEmail}</span>
                                                                                                                    </div>
                                                                                                                    <div className="col-4">
                                                                                                                        <label className="col-form-label">Phone</label>
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">{data.institutePatient.institutePhone}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="form-group form-group-xs row">
                                                                                                                    <div className="col-4">
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>

                                                                                                        <div className="col-xl-12">
                                                                                                            <h3 className="kt-portlet__head-title">
                                                                                                                Laboratory</h3>
                                                                                                            {data.laboratoryPatient.length > 0 ? (
                                                                                                                data.laboratoryPatient
                                                                                                                    .map((ldata, i) => {
                                                                                                                        return (
                                                                                                                            <div className="form-group form-group-xs row">
                                                                                                                                <div className="col-4">
                                                                                                                                    <label className="col-form-label">Name</label>
                                                                                                                                    <span className="form-control-plaintext kt-font-bolder">{ldata.ngsLaboratoryName}</span>
                                                                                                                                </div>
                                                                                                                                <div className="col-4">
                                                                                                                                    <label className="col-form-label">Email</label>
                                                                                                                                    <span className="form-control-plaintext kt-font-bolder">{ldata.ngsLaboratoryEmail}</span>
                                                                                                                                </div>
                                                                                                                                <div className="col-4">
                                                                                                                                    <label className="col-form-label">Phone</label>
                                                                                                                                    <span className="form-control-plaintext kt-font-bolder">{ldata.ngsLaboratoryPhone}</span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }))
                                                                                                                :
                                                                                                                <div className="form-group form-group-xs row">
                                                                                                                    <div className="col-4">
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>

                                                                                                        <div className="col-xl-12">
                                                                                                            <h3 className="kt-portlet__head-title">
                                                                                                                Manufacturer</h3>
                                                                                                            {data.manufacturerPatient != "" && data.manufacturerPatient != null ?
                                                                                                                <div className="form-group form-group-xs row">
                                                                                                                    <div className="col-4">
                                                                                                                        <label className="col-form-label">Name</label>
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">{data.manufacturerPatient.manufacturerName}</span>
                                                                                                                    </div>
                                                                                                                    <div className="col-4">
                                                                                                                        <label className="col-form-label">Email</label>
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">{data.manufacturerPatient.manufacturerEmail}</span>
                                                                                                                    </div>
                                                                                                                    <div className="col-4">
                                                                                                                        <label className="col-form-label">Phone</label>
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">{data.manufacturerPatient.manufacturerPhone}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="form-group form-group-xs row">
                                                                                                                    <div className="col-4">
                                                                                                                        <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_1" role="tabpanel">
                                                                                                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                                                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                                                    <div className="kt-notes__items">
                                                                                                        {data.patientDiagnosticHistory.length > 0 ? (
                                                                                                            data.patientDiagnosticHistory
                                                                                                                .map((ddata, i) => {
                                                                                                                    return (
                                                                                                                        <div className="kt-notes__item">
                                                                                                                            <div className="kt-notes__media">
                                                                                                                                <span className="kt-notes__icon">
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                                                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                                                                                            <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fillRule="nonzero"></path>
                                                                                                                                            <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "></path>
                                                                                                                                        </g>
                                                                                                                                    </svg>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                            <div className="kt-notes__content">
                                                                                                                                <div className="date-info">
                                                                                                                                    <span className="kt-notes__desc">
                                                                                                                                        <Moment format="DD MMM YYYY">
                                                                                                                                            {ddata.diagnosticDate}
                                                                                                                                        </Moment>
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                                <div className="kt-notes__section">

                                                                                                                                    {/*<div className="kt-notes__info"> <p className="kt-notes__title">MRI
																		<br />Lumbo Sacral Spine<br />Findings</p>
                                                                                    </div>*/}
                                                                                                                                </div> <span className="kt-notes__body">
                                                                                                                                    {ddata.diagnosticAnalysis}
                                                                                                                                </span>
                                                                                                                                <div className="pt-2 note-dtails-steps"><span className="kt-widget1__number kt-font-danger">Outcome :  {data.outcome}</span></div>
                                                                                                                                <div className="kt-widget4">
                                                                                                                                    <br />
                                                                                                                                    {ddata.patientDiagnosticHistoryFile.length > 0 ? (
                                                                                                                                        ddata.patientDiagnosticHistoryFile
                                                                                                                                            .map((dataFile, i) => {
                                                                                                                                                return (
                                                                                                                                                    <div className="kt-widget4__item" key={dataFile.patientDiagnosticFileId} onClick={e => this.previewToggle(e, dataFile.filePath)}>
                                                                                                                                                        <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                                                                                            <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />
                                                                                                                                                        </div>
                                                                                                                                                        <a className="kt-widget4__title">
                                                                                                                                                            {dataFile.fileName}
                                                                                                                                                        </a>
                                                                                                                                                        {/*<div className="kt-widget4__tools">
                                                                                                                                <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">
                                                                                                                                    <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>
                                                                                                                                </a>
                                                                                                                            </div>*/}
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            })) : ("No files...")}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })) : (
                                                                                                            <span className="kt-notes__body">No diagnostics found.</span>
                                                                                                        )
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_2" role="tabpanel">
                                                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                                                    <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>te</th>
                                                                                                                <th colSpan="2">Description</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {data.patientPrescription.length > 0 ? (
                                                                                                                data.patientPrescription
                                                                                                                    .map((pdata, i) => {
                                                                                                                        return (
                                                                                                                            <React.Fragment>
                                                                                                                                <tr key={i} onClick={this.toggleExpander}>
                                                                                                                                    <td><Moment format="DD MMM YYYY">{pdata.prescribeDate}</Moment></td>
                                                                                                                                    <td>{pdata.prescriptionDescription}</td>
                                                                                                                                    <td className="tblexpand">
                                                                                                                                        {/* <img style={{ "width": "80%" }} src="./assets/media/icons/svg/Files/direct-download.svg" alt="" title="View Files" /> */}
                                                                                                                                        <svg id="color" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                            <path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.456-.477-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .998.791.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z" fill="#00bcd4"/>
                                                                                                                                            <path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z" fill="#607d8b"/>
                                                                                                                                            <path d="m12 2h-1.25c-.689 0-1.25.561-1.25 1.25v5.75h-2.75c-.659 0-.998.791-.542 1.268l5.25 5.5c.141.148.337.232.542.232z" fill="#00a4b9"/>
                                                                                                                                            <path d="m12 18h-10.25c-.965 0-1.75.785-1.75 1.75v.5c0 .965.785 1.75 1.75 1.75h10.25z" fill="#546d79"/>
                                                                                                                                        </svg>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr className="collapse" key={pdata.patientPrescriptionId}>
                                                                                                                                    <td className="kt-widget4" colSpan="3">
                                                                                                                                        {pdata.patientPrescriptionFile.length > 0 ? (
                                                                                                                                            pdata.patientPrescriptionFile
                                                                                                                                                .map((dataFile, i) => {
                                                                                                                                                    return (
                                                                                                                                                        <div className="kt-widget4__item" key={dataFile.patientPrescriptionFileId} onClick={e => this.previewToggle(e, dataFile.filePath)}>
                                                                                                                                                            <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                                                                                                <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />
                                                                                                                                                            </div>
                                                                                                                                                            <a className="kt-widget4__title">
                                                                                                                                                                {dataFile.fileName}
                                                                                                                                                            </a>
                                                                                                                                                            {/*<div className="kt-widget4__tools">
                                                                                                                                <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">
                                                                                                                                    <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>
                                                                                                                                </a>
                                                                                                                            </div>*/}
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                })) : ("No files...")}
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </React.Fragment>
                                                                                                                        )
                                                                                                                    })) : (
                                                                                                                <tr>
                                                                                                                    <td colSpan="2" className="tdCenter">No prescription found.</td></tr>
                                                                                                            )
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_3" role="tabpanel">
                                                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                                                    <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>Treatment Date</th>
                                                                                                                <th>Detail</th>
                                                                                                                <th colSpan="2">Description</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {data.patientTreatmentReport.length > 0 ? (
                                                                                                                data.patientTreatmentReport
                                                                                                                    .map((tdata, i) => {
                                                                                                                        return (
                                                                                                                            <React.Fragment>
                                                                                                                                <tr key={i} onClick={this.toggleExpander}>
                                                                                                                                    <td><Moment format="DD MMM YYYY">{tdata.treatmentDate}</Moment></td>
                                                                                                                                    <td>{tdata.treatmentDetail}</td>
                                                                                                                                    <td>{tdata.teatmentDescription}</td>
                                                                                                                                    <td className="tblexpand">
                                                                                                                                        {/* <img style={{ "width": "80%" }} src="./assets/media/icons/svg/Files/direct-download.svg" alt="" title="View Files" /> */}
                                                                                                                                        <svg id="color" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                            <path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.456-.477-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .998.791.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z" fill="#00bcd4"/>
                                                                                                                                            <path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z" fill="#607d8b"/>
                                                                                                                                            <path d="m12 2h-1.25c-.689 0-1.25.561-1.25 1.25v5.75h-2.75c-.659 0-.998.791-.542 1.268l5.25 5.5c.141.148.337.232.542.232z" fill="#00a4b9"/>
                                                                                                                                            <path d="m12 18h-10.25c-.965 0-1.75.785-1.75 1.75v.5c0 .965.785 1.75 1.75 1.75h10.25z" fill="#546d79"/>
                                                                                                                                        </svg>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr className="collapse" key={tdata.patientTreatmentReportId}>
                                                                                                                                    <td className="kt-widget4" colSpan="4">
                                                                                                                                        {tdata.patientTreatmentReportFile.length > 0 ? (
                                                                                                                                            tdata.patientTreatmentReportFile
                                                                                                                                                .map((dataFile, i) => {
                                                                                                                                                    return (
                                                                                                                                                        <div className="kt-widget4__item" key={dataFile.patientTreatmentreportFileId} onClick={e => this.previewToggle(e, dataFile.filePath)}>
                                                                                                                                                            <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                                                                                                <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />
                                                                                                                                                            </div>
                                                                                                                                                            <a className="kt-widget4__title">
                                                                                                                                                                {dataFile.fileName}
                                                                                                                                                            </a>
                                                                                                                                                            {/*<div className="kt-widget4__tools">
                                                                                                                                <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">
                                                                                                                                    <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>
                                                                                                                                </a>
                                                                                                                            </div>*/}
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                })) : ("No files...")}
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </React.Fragment>
                                                                                                                        )
                                                                                                                    })) : (
                                                                                                                <tr>
                                                                                                                    <td colSpan="3" className="tdCenter">No treatment found.</td></tr>
                                                                                                            )
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*                          <div className="col-xl-12">*/}
                                            {/*                              <div className="kt-portlet">*/}
                                            {/*                                  <div className="kt-portlet__head" style={{ "background-color": "#eaf2ff" }}>*/}
                                            {/*                                      <div className="kt-portlet__head-label">*/}
                                            {/*                                          <h3 className="kt-portlet__head-title">*/}
                                            {/*                                              Practioner</h3>*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                                  <div className="kt-form kt-form--label-right">*/}
                                            {/*                                      <div className="kt-portlet__body">*/}

                                            {/*                                          {assignedpractitioner != "" && assignedpractitioner != null ?*/}
                                            {/*                                              <div className="form-group form-group-xs row">*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <label className="col-form-label">Name</label>*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">{assignedpractitioner.firstName + " " + assignedpractitioner.lastName}</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <label className="col-form-label">Email</label>*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">{assignedpractitioner.email}</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <label className="col-form-label">Mobile No</label>*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">{assignedpractitioner.phoneNumber}</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                              </div>*/}
                                            {/*                                              :*/}
                                            {/*                                              <div className="form-group form-group-xs row">*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                              </div>*/}
                                            {/*                                          }*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                              </div>*/}
                                            {/*                          </div>*/}

                                            {/*                          <div className="col-xl-12">*/}
                                            {/*                              <div className="kt-portlet">*/}
                                            {/*                                  <div className="kt-portlet__head" style={{ "background-color": "#eaf2ff" }}>*/}
                                            {/*                                      <div className="kt-portlet__head-label">*/}
                                            {/*                                          <h3 className="kt-portlet__head-title">*/}
                                            {/*                                              Institution</h3>*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                                  <div className="kt-form kt-form--label-right">*/}
                                            {/*                                      <div className="kt-portlet__body">*/}

                                            {/*                                          {assignedinstitute != "" && assignedinstitute != null ?*/}
                                            {/*                                              <div className="form-group form-group-xs row">*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <label className="col-form-label">Name</label>*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">{assignedinstitute.instituteName}</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <label className="col-form-label">Email</label>*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">{assignedinstitute.instituteEmail}</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <label className="col-form-label">Mobile No</label>*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">{assignedinstitute.institutePhone}</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                              </div>*/}
                                            {/*                                              :*/}
                                            {/*                                              <div className="form-group form-group-xs row">*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                              </div>*/}
                                            {/*                                          }*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                              </div>*/}
                                            {/*                          </div>*/}

                                            {/*                          <div className="col-xl-12">*/}
                                            {/*                              <div className="kt-portlet">*/}
                                            {/*                                  <div className="kt-portlet__head" style={{ "background-color": "#eaf2ff" }}>*/}
                                            {/*                                      <div className="kt-portlet__head-label">*/}
                                            {/*                                          <h3 className="kt-portlet__head-title">*/}
                                            {/*                                              Laboratory</h3>*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                                  <div className="kt-form kt-form--label-right">*/}
                                            {/*                                      <div className="kt-portlet__body">*/}
                                            {/*                                          {assignedlaboratory.length > 0 ? (*/}
                                            {/*                                              assignedlaboratory*/}
                                            {/*                                                  .map((data, i) => {*/}
                                            {/*                                                      return (*/}
                                            {/*                                                          <div className="form-group form-group-xs row">*/}
                                            {/*                                                              <div className="col-4">*/}
                                            {/*                                                                  <label className="col-form-label">Name</label>*/}
                                            {/*                                                                  <span className="form-control-plaintext kt-font-bolder">{assignedlaboratory.ngsLaboratoryName}</span>*/}
                                            {/*                                                              </div>*/}
                                            {/*                                                              <div className="col-4">*/}
                                            {/*                                                                  <label className="col-form-label">Email</label>*/}
                                            {/*                                                                  <span className="form-control-plaintext kt-font-bolder">{assignedlaboratory.ngsLaboratoryEmail}</span>*/}
                                            {/*                                                              </div>*/}
                                            {/*                                                              <div className="col-4">*/}
                                            {/*                                                                  <label className="col-form-label">Mobile No</label>*/}
                                            {/*                                                                  <span className="form-control-plaintext kt-font-bolder">{assignedlaboratory.ngsLaboratoryPhone}</span>*/}
                                            {/*                                                              </div>*/}
                                            {/*                                                          </div>*/}
                                            {/*                                                      )*/}
                                            {/*                                                  }))*/}
                                            {/*                                              :*/}
                                            {/*                                              <div className="form-group form-group-xs row">*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                              </div>*/}
                                            {/*                                          }*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                              </div>*/}
                                            {/*                          </div>*/}

                                            {/*                          <div className="col-xl-12">*/}
                                            {/*                              <div className="kt-portlet">*/}
                                            {/*                                  <div className="kt-portlet__head" style={{ "background-color": "#eaf2ff" }}>*/}
                                            {/*                                      <div className="kt-portlet__head-label">*/}
                                            {/*                                          <h3 className="kt-portlet__head-title">*/}
                                            {/*                                              Manufacture</h3>*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                                  <div className="kt-form kt-form--label-right">*/}
                                            {/*                                      <div className="kt-portlet__body">*/}

                                            {/*                                          {assignedmanufacturer != "" && assignedmanufacturer != null ?*/}
                                            {/*                                              <div className="form-group form-group-xs row">*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <label className="col-form-label">Name</label>*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">{assignedmanufacturer.manufacturerName}</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <label className="col-form-label">Email</label>*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">{assignedmanufacturer.manufacturerEmail}</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <label className="col-form-label">Mobile No</label>*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">{assignedmanufacturer.manufacturerPhone}</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                              </div>*/}
                                            {/*                                              :*/}
                                            {/*                                              <div className="form-group form-group-xs row">*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                              </div>*/}
                                            {/*                                          }*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                              </div>*/}
                                            {/*                          </div>*/}

                                            {/*                          <div className="col-xl-12">*/}
                                            {/*                              <div className="kt-portlet">*/}
                                            {/*                                  <div className="kt-portlet__head" style={{ "background-color": "#eaf2ff" }}>*/}
                                            {/*                                      <div className="kt-portlet__head-label">*/}
                                            {/*                                          <h3 className="kt-portlet__head-title">*/}
                                            {/*                                              Disease</h3>*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                                  <div className="kt-form kt-form--label-right">*/}
                                            {/*                                      <div className="kt-portlet__body">*/}
                                            {/*                                          {diseases != "" && diseases != null ?*/}
                                            {/*                                              <div className="form-group form-group-xs row">*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">{diseases.diseaseName + " (" + diseases.diseaseCode + ")"}</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                              </div>*/}
                                            {/*                                              :*/}
                                            {/*                                              <div className="form-group form-group-xs row">*/}
                                            {/*                                                  <div className="col-4">*/}
                                            {/*                                                      <span className="form-control-plaintext kt-font-bolder">Data Not Available</span>*/}
                                            {/*                                                  </div>*/}
                                            {/*                                              </div>*/}
                                            {/*                                          }*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                              </div>*/}
                                            {/*                          </div>*/}

                                            {/*                          <div className="col-xl-12">*/}
                                            {/*                              <div className="kt-portlet kt-portlet--tabs">*/}
                                            {/*                                  <div className="kt-portlet__head" style={{ "background-color": "#eaf2ff" }}>*/}
                                            {/*                                      <div className="kt-portlet__head-toolbar">*/}
                                            {/*                                          <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand" role="tablist">*/}
                                            {/*                                              <li className="nav-item">*/}
                                            {/*                                                  <a className="nav-link active" data-toggle="tab" href="#kt_apps_contacts_view_tab_1" role="tab">Diagnostics</a>*/}
                                            {/*                                              </li>*/}
                                            {/*                                              <li className="nav-item">*/}
                                            {/*                                                  <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_2" role="tab">Prescription</a>*/}
                                            {/*                                              </li>*/}
                                            {/*                                              <li className="nav-item">*/}
                                            {/*                                                  <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_3" role="tab">Treatments/Visit</a>*/}
                                            {/*                                              </li>*/}
                                            {/*                                          </ul>*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                                  <div className="kt-portlet__body">*/}
                                            {/*                                      <div className="tab-content">*/}
                                            {/*                                          <div className="tab-pane active" id="kt_apps_contacts_view_tab_1" role="tabpanel">*/}
                                            {/*                                              <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>*/}
                                            {/*                                              <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>*/}
                                            {/*                                                  <div className="kt-notes__items">*/}
                                            {/*                                                      {diagnosticHistory.length > 0 ? (*/}
                                            {/*                                                          diagnosticHistory*/}
                                            {/*                                                              .map((data, i) => {*/}
                                            {/*                                                                  return (*/}
                                            {/*                                                                      <div className="kt-notes__item">*/}
                                            {/*                                                                          <div className="kt-notes__media">*/}
                                            {/*                                                                              <span className="kt-notes__icon">*/}
                                            {/*                                                                                  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">*/}
                                            {/*                                                                                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">*/}
                                            {/*                                                                                          <polygon points="0 0 24 0 24 24 0 24"></polygon>*/}
                                            {/*                                                                                          <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fillRule="nonzero"></path>*/}
                                            {/*                                                                                          <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "></path>*/}
                                            {/*                                                                                      </g>*/}
                                            {/*                                                                                  </svg>*/}
                                            {/*                                                                              </span>*/}
                                            {/*                                                                          </div>*/}
                                            {/*                                                                          <div className="kt-notes__content">*/}
                                            {/*                                                                              <div className="date-info">*/}
                                            {/*                                                                                  <span className="kt-notes__desc">*/}
                                            {/*                                                                                      <Moment format="DD MMM YYYY">*/}
                                            {/*                                                                                          {data.diagnosticDate}*/}
                                            {/*                                                                                      </Moment>*/}
                                            {/*                                                                                  </span>*/}
                                            {/*                                                                              </div>*/}
                                            {/*                                                                              <div className="kt-notes__section">*/}

                                            {/*                                                                                  */}{/*<div className="kt-notes__info"> <p className="kt-notes__title">MRI*/}{/*
																		*/}{/*<br />Lumbo Sacral Spine<br />Findings</p>*/}{/*
                  */}{/*                                                                  </div>*/}
                                            {/*                                                                              </div> <span className="kt-notes__body">*/}
                                            {/*                                                                                  {data.diagnosticAnalysis}*/}
                                            {/*                                                                              </span>*/}
                                            {/*                                                                              <div className="pt-2 note-dtails-steps"><span className="kt-widget1__number kt-font-primary">Outcome :  {data.outcome}</span></div>*/}
                                            {/*                                                                              <div className="kt-widget4">*/}
                                            {/*                                                                                  <br />*/}
                                            {/*                                                                                  {data.patientDiagnosticHistoryFile.length > 0 ? (*/}
                                            {/*                                                                                      data.patientDiagnosticHistoryFile*/}
                                            {/*                                                                                          .map((dataFile, i) => {*/}
                                            {/*                                                                                              return (*/}
                                            {/*                                                                                                  <div className="kt-widget4__item" key={dataFile.patientDiagnosticFileId}>*/}
                                            {/*                                                                                                      <div className="kt-widget4__pic kt-widget4__pic--icon">*/}
                                            {/*                                                                                                          <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />*/}
                                            {/*                                                                                                      </div>*/}
                                            {/*                                                                                                      <a href={dataFile.filePath} download className="kt-widget4__title">*/}
                                            {/*                                                                                                          {dataFile.fileName}*/}
                                            {/*                                                                                                      </a>*/}
                                            {/*                                                                                                      */}{/*<div className="kt-widget4__tools">*/}{/*
                  */}{/*                                                                                                              <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">*/}{/*
                  */}{/*                                                                                                                  <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>*/}{/*
                  */}{/*                                                                                                              </a>*/}{/*
                  */}{/*                                                                                                          </div>*/}
                                            {/*                                                                                                  </div>*/}
                                            {/*                                                                                              )*/}
                                            {/*                                                                                          })) : ("No files...")}*/}
                                            {/*                                                                              </div>*/}
                                            {/*                                                                          </div>*/}

                                            {/*                                                                      </div>*/}
                                            {/*                                                                  )*/}
                                            {/*                                                              })) : (*/}
                                            {/*                                                          <span className="kt-notes__body">No diagnostics found.</span>*/}
                                            {/*                                                      )*/}
                                            {/*                                                      }*/}
                                            {/*                                                  </div>*/}
                                            {/*                                              </div>*/}
                                            {/*                                          </div>*/}
                                            {/*                                          <div className="tab-pane" id="kt_apps_contacts_view_tab_2" role="tabpanel">*/}
                                            {/*                                              <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>*/}
                                            {/*                                                  <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">*/}
                                            {/*                                                      <thead>*/}
                                            {/*                                                          <tr>*/}
                                            {/*                                                              <th>Prescription Date</th>*/}
                                            {/*                                                              <th colSpan="2">Description</th>*/}
                                            {/*                                                          </tr>*/}
                                            {/*                                                      </thead>*/}
                                            {/*                                                      <tbody>*/}
                                            {/*                                                          {prescription.length > 0 ? (*/}
                                            {/*                                                              prescription*/}
                                            {/*                                                                  .map((data, i) => {*/}
                                            {/*                                                                      return (*/}
                                            {/*                                                                          <React.Fragment>*/}
                                            {/*                                                                              <tr key={i} onClick={this.toggleExpander}>*/}
                                            {/*                                                                                  <td><Moment format="DD MMM YYYY">{data.prescribeDate}</Moment></td>*/}
                                            {/*                                                                                  <td>{data.prescriptionDescription}</td>*/}
                                            {/*                                                                                  <td className="tblexpand"><img style={{ "width": "80%" }} src="./assets/media/icons/svg/Files/direct-download.svg" alt="" title="View Files" /></td>*/}
                                            {/*                                                                              </tr>*/}
                                            {/*                                                                              <tr className="collapse" key={data.patientPrescriptionId}>*/}
                                            {/*                                                                                  <td className="kt-widget4" colSpan="3">*/}
                                            {/*                                                                                      {data.patientPrescriptionFile.length > 0 ? (*/}
                                            {/*                                                                                          data.patientPrescriptionFile*/}
                                            {/*                                                                                              .map((dataFile, i) => {*/}
                                            {/*                                                                                                  return (*/}
                                            {/*                                                                                                      <div className="kt-widget4__item" key={dataFile.patientPrescriptionFileId}>*/}
                                            {/*                                                                                                          <div className="kt-widget4__pic kt-widget4__pic--icon">*/}
                                            {/*                                                                                                              <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />*/}
                                            {/*                                                                                                          </div>*/}
                                            {/*                                                                                                          <a href={dataFile.filePath} download className="kt-widget4__title">*/}
                                            {/*                                                                                                              {dataFile.fileName}*/}
                                            {/*                                                                                                          </a>*/}
                                            {/*                                                                                                          */}{/*<div className="kt-widget4__tools">*/}{/*
                  */}{/*                                                                                                              <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">*/}{/*
                  */}{/*                                                                                                                  <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>*/}{/*
                  */}{/*                                                                                                              </a>*/}{/*
                  */}{/*                                                                                                          </div>*/}
                                            {/*                                                                                                      </div>*/}
                                            {/*                                                                                                  )*/}
                                            {/*                                                                                              })) : ("No files...")}*/}
                                            {/*                                                                                  </td>*/}
                                            {/*                                                                              </tr>*/}
                                            {/*                                                                          </React.Fragment>*/}
                                            {/*                                                                      )*/}
                                            {/*                                                                  })) : (*/}
                                            {/*                                                              <tr>*/}
                                            {/*                                                                  <td colSpan="2" className="tdCenter">No prescription found.</td></tr>*/}
                                            {/*                                                          )*/}
                                            {/*                                                          }*/}
                                            {/*                                                      </tbody>*/}
                                            {/*                                                  </table>*/}
                                            {/*                                              </div>*/}
                                            {/*                                          </div>*/}
                                            {/*                                          <div className="tab-pane" id="kt_apps_contacts_view_tab_3" role="tabpanel">*/}
                                            {/*                                              <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>*/}
                                            {/*                                                  <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">*/}
                                            {/*                                                      <thead>*/}
                                            {/*                                                          <tr>*/}
                                            {/*                                                              <th>Treatment Date</th>*/}
                                            {/*                                                              <th>Detail</th>*/}
                                            {/*                                                              <th colSpan="2">Description</th>*/}
                                            {/*                                                          </tr>*/}
                                            {/*                                                      </thead>*/}
                                            {/*                                                      <tbody>*/}
                                            {/*                                                          {treatmentReport.length > 0 ? (*/}
                                            {/*                                                              treatmentReport*/}
                                            {/*                                                                  .map((data, i) => {*/}
                                            {/*                                                                      return (*/}
                                            {/*                                                                          <React.Fragment>*/}
                                            {/*                                                                              <tr key={i} onClick={this.toggleExpander}>*/}
                                            {/*                                                                                  <td><Moment format="DD MMM YYYY">{data.treatmentDate}</Moment></td>*/}
                                            {/*                                                                                  <td>{data.treatmentDetail}</td>*/}
                                            {/*                                                                                  <td>{data.teatmentDescription}</td>*/}
                                            {/*                                                                                  <td className="tblexpand"><img style={{ "width": "80%" }} src="./assets/media/icons/svg/Files/direct-download.svg" alt="" title="View Files" /></td>*/}
                                            {/*                                                                              </tr>*/}
                                            {/*                                                                              <tr className="collapse" key={data.patientTreatmentReportId}>*/}
                                            {/*                                                                                  <td className="kt-widget4" colSpan="4">*/}
                                            {/*                                                                                      {data.patientTreatmentReportFile.length > 0 ? (*/}
                                            {/*                                                                                          data.patientTreatmentReportFile*/}
                                            {/*                                                                                              .map((dataFile, i) => {*/}
                                            {/*                                                                                                  return (*/}
                                            {/*                                                                                                      <div className="kt-widget4__item" key={dataFile.patientTreatmentreportFileId}>*/}
                                            {/*                                                                                                          <div className="kt-widget4__pic kt-widget4__pic--icon">*/}
                                            {/*                                                                                                              <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />*/}
                                            {/*                                                                                                          </div>*/}
                                            {/*                                                                                                          <a href={dataFile.filePath} download className="kt-widget4__title">*/}
                                            {/*                                                                                                              {dataFile.fileName}*/}
                                            {/*                                                                                                          </a>*/}
                                            {/*                                                                                                          */}{/*<div className="kt-widget4__tools">*/}{/*
                  */}{/*                                                                                                              <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">*/}{/*
                  */}{/*                                                                                                                  <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>*/}{/*
                  */}{/*                                                                                                              </a>*/}{/*
                  */}{/*                                                                                                          </div>*/}
                                            {/*                                                                                                      </div>*/}
                                            {/*                                                                                                  )*/}
                                            {/*                                                                                              })) : ("No files...")}*/}
                                            {/*                                                                                  </td>*/}
                                            {/*                                                                              </tr>*/}
                                            {/*                                                                          </React.Fragment>*/}
                                            {/*                                                                      )*/}
                                            {/*                                                                  })) : (*/}
                                            {/*                                                              <tr>*/}
                                            {/*                                                                  <td colSpan="3" className="tdCenter">No treatment found.</td></tr>*/}
                                            {/*                                                          )*/}
                                            {/*                                                          }*/}
                                            {/*                                                      </tbody>*/}
                                            {/*                                                  </table>*/}
                                            {/*                                              </div>*/}
                                            {/*                                          </div>*/}
                                            {/*                                      </div>*/}
                                            {/*                                  </div>*/}
                                            {/*                              </div>*/}
                                            {/*                          </div>*/}

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {this.renderRedirect()}
                {this.loading()}
                {preview &&
                    <>
                        <div className='preview-popup'>
                            <div className='preview-popup-modal'>
                                <div className='preview-popup-header'>
                                    {url.split(".").splice(-1)[0] === "pdf" ? null : 
                                        <a href={url} download target={`_blank`}>
                                            <img src={downloadIcon} style={{ margin:"0 12px", cursor: "pointer" }} alt='download' />
                                        </a>
                                    }
                                    <img src={closeIcon} style={{ cursor: "pointer" }} alt='close' onClick={e => this.previewToggle(e, "")} />
                                </div>
                                <iframe src={url} title="previewFile" width="100%" height="90%" />
                            </div>
                        </div>
                    </>
                }
            </React.Fragment >
        );
    }
}
