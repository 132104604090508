import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../CustomModal/CustomModal';

export class Confirmation extends Component {
    static displayName = Confirmation.name;

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            Confirmationcode: '',
            loading: false,
            // authError: false,
            error: '',
            errorType: '',
            redirect: false,
            errors: {
                username: '',
                Confirmationcode: ''
            },
        };
    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/login');
    //    }
    //}

    componentDidMount() {
        const param = this.props.match.params;

        if (param.id != undefined) {
            this.setState({ username: param.id, loading: true });
            this.getData(param.id);
        }
    }

    //get detail
    getData(username) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/CognitoUserStore/CheckUserconfirmation';

        let data = JSON.stringify({
            username: username
        })

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    //console.log(this.state);
                    this.setState({ loading: false, redirect: true });
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    //input handle input change and validation
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'username':
                errors.username = (!value) ? 'Please enter email.' : ''
                break;
            case 'Confirmationcode':
                errors.Confirmationcode = (!value) ? 'Please enter confirmation code.' : ''
                break;
            default:
                //(!value) ? '' :'This field is required.'
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            //console.log(errors)

        })
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.username == undefined || this.state.username == '') {
            errors.username = 'Please enter email.';
        }
        if (this.state.Confirmationcode == undefined || this.state.Confirmationcode == '') {
            errors.Confirmationcode = 'Please enter confirmation code.';
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ loading: true });

        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            const url = apiroute + '/api/CognitoUserStore/confirmation';
            const username = this.state.username;
            const Confirmationcode = this.state.Confirmationcode;

            let data = JSON.stringify({
                Confirmationcode: Confirmationcode,
                username: username.toLowerCase()
                //username: username
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                    //'Access-Control-Allow-Origin': '*',
                    //'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                    //'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }
            })
                .then(result => {
                    debugger;
                    console.log(result);
                    if (result.data.flag) {
                        //localStorage.setItem('token', JSON.stringify(result.data.outdata));
                        //this.setState({
                        //    modal: !this.state.modal,
                        //    modalTitle: 'Success',
                        //    modalBody: "Confirmed Successfully.",
                        //    redirect: true,
                        //    loading: false
                        //});
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: "Confirmed Successfully.",
                            loading: false
                        });
                        toast.success("Confirmed Successfully.")
                        setTimeout(() => this.setState({ redirect: true }), 2000);

                    } else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)

                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({ 
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false });
                        toast.error(error.message)
                });
        }
        else {
            this.setState({ loading: false });
        }
    }

    handleResendCodeSubmit(e) {
        e.preventDefault();

        this.setState({ loading: true });

        if (this.state.username != undefined && this.state.username != '') {
            const apiroute = window.$APIPath;
            const url = apiroute + '/api/CognitoUserStore/ResendConfirmationCode';
            const username = this.state.username;

            let data = JSON.stringify({
                username: username
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                    //'Access-Control-Allow-Origin': '*',
                    //'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                    //'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }
            })
                .then(result => {
                    debugger;
                    console.log(result);
                    if (result.data.flag) {
                        //localStorage.setItem('token', JSON.stringify(result.data.outdata));
                        //this.setState({
                        //    modal: !this.state.modal,
                        //    modalTitle: 'Success',
                        //    modalBody: "Confirmed Successfully.",
                        //    redirect: true,
                        //    loading: false
                        //});
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.success(result.data.message)

                    } else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)

                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({ 
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false });
                        toast.error(error.message)
                });
        }
        else {
            this.setState({ loading: false });
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            //return <Redirect from="/" to="/login" />
            return <Redirect from="/" to={"/setpassword/" + this.state.username + ""} />
        }
    }

    // loading() {
    //     if (this.state.redirect) {
    //         return <div className="animated fadeIn pt-1 text-center">Loading...</div>
    //     }
    // }

    render() {
        const { username, Confirmationcode, loading, authError, error, errorType, errors } = this.state;
        return (

            <div className="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--transparent kt-aside--enabled kt-aside--fixed kt-page--loading">
                <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
                    <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed left-0">
                        <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand" kt-hidden-height="65">
                            <div className="kt-aside__brand-logo">
                                <Link to="/">
                                    <img alt="Logo" src="./assets/media/logos/logo-small.png" className="width-40" />
                                </Link>
                            </div>
                        </div>
                        <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-header-menu-wrapper opacity-1" id="kt_header_menu_wrapper">
                            <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
                            </div>
                        </div>
                        <div className="kt-header__topbar">
                            {/*<div className="kt-header__topbar-item kt-header__topbar-item--user logo-menu">
                                <div className="kt-header__topbar-wrapper">
                                    <div className="kt-header__topbar-user">
                                        <Link to="/Services" >Services</Link>
                                    </div>
                                </div>
                                <div className="kt-header__topbar-wrapper">
                                    <div className="kt-header__topbar-user">
                                        <Link to="/HealthcareProfessional">Healthcare Professional</Link>
                                    </div>
                                </div>
                                <div className="kt-header__topbar-wrapper"> 
                                    <div className="kt-header__topbar-user">
                                        <Link to="/Caregivers">Patient and Caregivers</Link>
                                    </div>
                                </div>

                                <div className="kt-header__topbar-wrapper"> 
                                    <div className="kt-header__topbar-user">

                                        <Link to="/login" className="btn btn-brand btn-elevate">Login</Link>
                                    </div>
                                </div>
                                <div className="kt-header__topbar-wrapper"> 
                                    <div className="kt-header__topbar-user">

                                        <Link to="/register" className="btn btn-brand btn-elevate">Register</Link>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                    <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v5 kt-login--signin" id="kt_login">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile" style={{ backgroundImage: 'url(./assets/media/bg/bg-3.jpg)' }}>
                            <div className="kt-login__left">
                                <div className="kt-login__wrapper">
                                    <div className="kt-login__content">
                                        <Link className="kt-login__logo" to="/">
                                            <img src="./assets/media/logos/logo-small.png" />
                                        </Link>
                                        <h3 className="kt-login__title">JOIN OUR NEO7LOGIX PORTAL</h3>
                                        {/*<div className="form-group">
                                            <label htmlFor="exampleSelectd">Click Here to Get Full Prescribing Information</label>
                                            <select className="form-control" id="exampleSelectd">
                                                <option>Select Prodcut</option>
                                                <option>PBIMA - PES</option>
                                                <option>SOLVx-COVID-19</option>
                                            </select>
                                        </div>*/}
                                        <ul className="location-link">
                                            <li className="item">
                                                <i className="fa fa-map-marker"></i>
                                                <span style={{ "color": "#000", "fontWeight": "400" }}>539 W. Commerce St #2886 Dallas, TX 75208</span>
                                            </li>
                                            {/*<li className="item">
                                                <i className="fa fa-clock" aria-hidden="true"></i>
                                                <span>
                                                    <p>Monday - Friday</p>
                                                    <p>09.00AM to 08:00PM</p>
                                                </span>
                                            </li>*/}
                                            <li className="item">
                                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                                <span><a href="mailto:office@neo7logix.com">office@neo7logix.com</a></span>
                                            </li>
                                            <li className="item">
                                                <i className="fa fa-phone" aria-hidden="true"></i>
                                                <span style={{ "color": "#000", "fontWeight": "400" }}>+‪(469) 619-7252</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-login__divider">
                                <div></div>
                            </div>
                            <div className="kt-login__right">
                                <div className="kt-login__wrapper">
                                    <div className="kt-login__signin">
                                        <div className="kt-login__head">
                                            <h3 className="kt-login__title">Confirmation</h3>
                                        </div>
                                        <div className="kt-login__form">
                                            <Form className="kt-form" onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                        <div className="alert-text">{error}</div>
                                                        <div className="alert-close">
                                                            <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                        </div>
                                                    </div>
                                                    : null} */}
                                                <div className="form-group mb-4">
                                                    <Input className="mb-4" tabIndex="1" type="text" maxLength="100" placeholder="Email" value={username} name="username" onChange={this.handleInputChange.bind(this)} autoComplete="email" />
                                                    {errors.username.length > 0 && <span className='error'>{errors.username}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <Input type="text" tabIndex="2" placeholder="Confirmation code" maxLength="100" name="Confirmationcode" onChange={this.handleInputChange.bind(this)} autoComplete="Confirmation-code" />
                                                    {errors.Confirmationcode.length > 0 && <span className='error'>{errors.Confirmationcode}</span>}
                                                </div>
                                                <div className="row kt-login__extra">
                                                    <div className="col kt-align-right">
                                                        <a style={{ "color": "#3641de" }} onClick={this.handleResendCodeSubmit.bind(this)} id="kt_login_forgot" className="kt-link">Resend Code</a>
                                                    </div>
                                                </div>
                                                <div className="kt-login__actions">
                                                    {loading ?
                                                        <button tabIndex="3" type="submit" className="btn btn-brand btn-elevate kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Confirm</button>
                                                        :
                                                        <button tabIndex="3" type="submit" className="btn btn-brand btn-elevate">Confirm</button>
                                                    }
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderRedirect()}
            </div>
        );
    }
}
