import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import MyModal from './../../CustomModal/CustomModal';
import { toast } from 'react-toastify';

export class PatientEmergencyDetail extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            PatientId: 0,
            PatientEmergencyId: 0,
            FirstName: "",
            MiddleName: "",
            LastName: "",
            Email: "",
            CountryId: "",
            countries: [],
            StateId: "",
            states: [],
            CityId: "",
            cities: [],
            errors: {
                CountryId: '',
                StateId: '',
                CityId: '',
                FirstName: '',
                LastName: '',
                Email: '',
                PhoneNumber: '',
                Mobile: '',
                Address: '',
                MiddleName: '',
                PostalCode: ''
            },
            Mobile: "",
            Address: "",
            PhoneNumber: "",
            PostalCode: "",
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
        };
        this.state = this.initialState;
    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/patient/emergency/list');
    //    }
    //}

    componentDidMount() {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/CognitoUserStore/getallcountry';
        let data = JSON.stringify({
            isDeleted: false,
            searchString: ''
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    countries: result.data.outdata
                }, () => {
                    const param = this.props.match.params;

                    if (param.id != undefined) {
                        this.getData(param.id);
                    }
                    else {
                        this.setState({ loading: false });
                    }
                });
            } else {
                this.setState({ loading: false });
            }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientEmergencyContact/GetById?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;
                    this.setState({
                        PatientId: rData.patientId, FirstName: rData.firstName, MiddleName: rData.middleName,
                        LastName: rData.lastName, Email: rData.email, Mobile: rData.mobile,
                        Address: rData.address, PatientEmergencyId: id,
                        PhoneNumber: rData.phoneNumber, PostalCode: rData.postalCode, CountryId: rData.countryId
                        //, StateId: rData.StateId, CityId: rData.CityId
                    }, () => {
                        if (rData.countryId != null) {
                            this.getStateData(rData.countryId, rData.stateId, rData.cityId)
                        } else { this.setState({ loading: false }); }
                    });

                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getStateData(CountryId, StateId, CityId) {

        const apiroute = window.$APIPath;

        const url = apiroute + '/api/CognitoUserStore/getallstate?Id=' + CountryId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    //console.log(result.data);
                    this.setState({ states: result.data.outdata, StateId: StateId }, () => {
                        if (StateId != "" || StateId != null) {
                            this.getCityData(StateId, CityId)
                        }
                        else { this.setState({ loading: false }); }
                    });
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getCityData(StateId, CityId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/CognitoUserStore/getallcity?Id=' + StateId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                //console.log(result.data);
                this.setState({ cities: result.data.outdata, CityId: CityId, loading: false });
            } else { this.setState({ loading: false }); }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        //if (this.state.CityId == undefined || this.state.CityId == '') {
        //    errors.CityId = 'Please select city.';
        //}
        //if (this.state.CountryId == undefined || this.state.CountryId == '') {
        //    errors.CountryId = 'Please select country.';
        //}
        //if (this.state.StateId == undefined || this.state.StateId == '') {
        //    errors.StateId = 'Please select state.';
        //}
        if (this.state.FirstName == undefined || this.state.FirstName == '') {
            errors.FirstName = 'Please enter firstname.';
        }
        if (this.state.LastName == undefined || this.state.LastName == '') {
            errors.LastName = 'Please enter lastname.';
        }
        if (this.state.Email == undefined || this.state.Email == '') {
            errors.Email = 'Please enter email.';
        }
        // if (this.state.PhoneNumber == undefined || this.state.PhoneNumber == '') {
        //    errors.PhoneNumber = 'Please enter secondary phone.';
        // }
        if (this.state.Mobile == undefined || this.state.Mobile == '') {
           errors.Mobile = 'Please enter primary phone.';
        }
        //if (this.state.Address == undefined || this.state.Address == '') {
        //    errors.Address = 'Please enter Address.';
        //}
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        const validMobileRegex = RegExp(/^[0-9+() -]+$/);
        const validNumberRegex = RegExp(/^[0-9]+$/);
        const validAlphaRegex = RegExp(/^[a-zA-Z \b]+$/);
        const validEmailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        switch (name) {
            //case 'CountryId':
            //    errors.CountryId = (!value) ? 'Please select country.' : '';
            //    break;
            //case 'StateId':
            //    errors.StateId = (!value) ? 'Please select state.' : '';
            //    break;
            //case 'CityId':
            //    errors.CityId = (!value) ? 'Please select city.' : '';
            //    break;
            case 'FirstName':
                errors.FirstName = (!value) ? 'Please enter firstname.' : (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.');
                this.setState({ FirstName: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;

            case 'MiddleName':
                errors.MiddleName = (value) ? (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.') : '';
                this.setState({ MiddleName: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;

            case 'LastName':
                errors.LastName = (!value) ? 'Please enter lastname.' : (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.');
                this.setState({ LastName: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;

            case 'Mobile':
                errors.Mobile = (!value) ? 'Please enter primary phone.' : (validMobileRegex.test(value) ? '' : 'Only numbers allowed.');
                this.setState({ Mobile: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            case 'Email':
                errors.Email = (!value) ? 'Please enter email.' : (validEmailRegex.test(value) ? '' : 'Invalid Email.');
                break;

            case 'PhoneNumber':
                errors.PhoneNumber = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ PhoneNumber: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            case 'PostalCode':
                errors.PostalCode = (value) ? (validNumberRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ PostalCode: value.replace(/[^0-9]+$/, '') })
                break;
            //case 'Address':
            //    errors.Address = (!value) ? 'Please enter Address.' : '';
            //    break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors }, () => {

            if (name == 'CountryId') {
                if (value != '') {
                    this.getStateData(value, "", "");
                    this.setState({ cities: [], CityId: "" });
                }
                else {
                    this.setState({ states: [], StateId: "", cities: [], CityId: "" });
                }
            }
            if (name == 'StateId') {
                if (value != '') {
                    this.getCityData(value, "");
                }
                else {
                    this.setState({ cities: [], CityId: "" });
                }
            }

        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.patientId == null ? 0 : userToken.patientId);

        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            if (this.state.PatientEmergencyId == 0) {
                url = apiroute + '/api/PatientEmergencyContact/Save';
            }
            else {
                url = apiroute + '/api/PatientEmergencyContact/Update';
            }

            let data = JSON.stringify({
                PatientId: parseInt(pid),
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                MiddleName: this.state.MiddleName,
                Email: this.state.Email,
                Address: this.state.Address,
                PhoneNumber: this.state.PhoneNumber,
                Mobile: this.state.Mobile,
                PostalCode: this.state.PostalCode,
                CityId: ((this.state.CityId == "" || this.state.CityId == 0) ? null : parseInt(this.state.CityId)),
                CountryId: ((this.state.CountryId == "" || this.state.CountryId == 0) ? null : parseInt(this.state.CountryId)),
                StateId: ((this.state.StateId == "" || this.state.StateId == 0) ? null : parseInt(this.state.StateId)),
                PatientEmergencyContactId: parseInt(this.state.PatientEmergencyId),
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            redirect: true
                        });
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    //console.log(error);
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            this.setState({ loading: false });
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/patient/emergency/list" />
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, FirstName, MiddleName, LastName, Email, CountryId, countries, StateId, states, CityId, cities, Mobile, Address, PhoneNumber, PostalCode, errors, error, errorType, authError } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                                <Link to="/patient/emergency/list" className="kt-subheader__title">
                                                    Emergency Contacts
                                                </Link>
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="/patient/emergency/detail" className="kt-subheader__breadcrumbs-link">
                                                    Emergency Contact Information                       </Link>
                                            </div>
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/patient/emergency/list" className="btn btn-primary btnBorder">
                                                Emergency Contacts
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">First Name<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="1" placeholder="Enter your first name" name="FirstName" value={FirstName} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Middle Name</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="2" placeholder="Enter your middle name" name="MiddleName" value={MiddleName} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.MiddleName.length > 0 && <span className='error'>{errors.MiddleName}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Last Name<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="3" placeholder="Enter your last name" name="LastName" value={LastName} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.LastName.length > 0 && <span className='error'>{errors.LastName}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Email<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="50" tabIndex="4" name="Email" placeholder="Enter a valid email address" value={Email} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.Email.length > 0 && <span className='error'>{errors.Email}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Primary Phone<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="5" name="Mobile" placeholder="Enter primary phone" value={Mobile} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.Mobile.length > 0 && <span className='error'>{errors.Mobile}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Secondary Phone</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="6" name="PhoneNumber" placeholder="Enter secondary phone" value={PhoneNumber} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.PhoneNumber.length > 0 && <span className='error'>{errors.PhoneNumber}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-12">
                                                                <label className="col-12 col-form-label">Address</label>
                                                                <div className="col-12">
                                                                    <textarea className="form-control here" type="text" maxLength="500" tabIndex="7" name="Address" placeholder="Enter a address" value={Address} onChange={this.handleInputChange.bind(this)}></textarea>
                                                                    {errors.Address.length > 0 && <span className='error'>{errors.Address}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Country</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="8" name="CountryId" value={CountryId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Country</option>
                                                                        {countries
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.CountryId.length > 0 && <span className='error'>{errors.CountryId}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">State</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="9" name="StateId" value={StateId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select State</option>
                                                                        {states
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.StateId.length > 0 && <span className='error'>{errors.StateId}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">City</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="10" name="CityId" value={CityId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select City</option>7
                                                                                    {cities
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.CityId.length > 0 && <span className='error'>{errors.CityId}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Postal Code</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="11" name="PostalCode" placeholder="Enter a postal code" value={PostalCode} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.PostalCode.length > 0 && <span className='error'>{errors.PostalCode}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {loading ?
                                                            <button tabIndex="12" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                            :
                                                            <button tabIndex="12" type="submit" className="btn btn-primary">Submit</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
                    {this.loading()}
                </div>
            </React.Fragment >
        );
    }
}
