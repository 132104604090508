import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import DatePicker from "react-datepicker";
import MyModal from './../../CustomModal/CustomModal';
import moment from 'moment';

export class PatientPrescriptionDetail extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            PatientId: 0,
            PatientPrescriptionId: 0,
            PrescriptionDescription: "",
            PrescribeDate: moment(new Date())._d,
            docFiles: [],
            errors: {
                PrescriptionDescription: '',
                PrescribeDate: '',
            },
            DocumentFile: "",
            redirect: false,
            modal: false,
            modalTitle: '',
            modalBody: ''
        };
        this.state = this.initialState;

    }

    //modal close button event
    handleModalClose = () => {
        this.setState({
            modal: false,
            modalTitle: '',
            modalBody: ''
        });
        if (this.state.redirect) {
            this.props.history.push('/patient/prescription/list');
        }
    }

    componentDidMount() {
        const param = this.props.match.params;

        if (param.id != undefined) {
            this.getData(param.id);
        }
        else {
            this.setState({ loading: false });
        }
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientPrescription/GetById?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata.prescriptionData;
                    var dData = result.data.outdata.docData;
                    this.setState({
                        PatientId: rData.patientId, PrescriptionDescription: rData.prescriptionDescription, 
                        // PrescribeDate: rData.prescribeDate == null ? "" : rData.prescribeDate.slice(0, 10),
                        PrescribeDate: rData.prescribeDate == null ? "" : moment(rData.prescribeDate)._d,
                        PatientPrescriptionId: rData.patientPrescriptionId,
                        loading: false, docFiles: dData
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    validateForm = (errors) => {
        let valid = true;

        if (this.state.PrescriptionDescription == undefined || this.state.PrescriptionDescription == '') {
            errors.PrescriptionDescription = 'Please enter prescription.';
        }
        if (this.state.PrescribeDate == undefined || this.state.PrescribeDate == '') {
            errors.PrescribeDate = 'Please select date.';
        }
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleDateChange(date) {
        let errors = this.state.errors;
        errors.PrescribeDate = (!date) ? 'Please select date.' : '';
        this.setState({ PrescribeDate: date })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'PrescriptionDescription':
                errors.PrescriptionDescription = (!value) ? 'Please enter prescription.' : '';

                break;
            case 'PrescribeDate':
                errors.PrescribeDate = (!value) ? 'Please select date.' : '';
                break;



            default:

                break;
        }

    }

    handleFileInputChange(event) {
        const target = event.target;
        const value = target.files;

        this.setState({
            DocumentFile: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.patientId == null ? 0 : userToken.patientId);
        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            if (this.state.PatientPrescriptionId == 0) {
                url = apiroute + '/api/PatientPrescription/Save';
            }
            else {
                url = apiroute + '/api/PatientPrescription/Update';
            }

            let data = JSON.stringify({
                PatientId: parseInt(pid),
                PrescriptionDescription: this.state.PrescriptionDescription,
                PrescribeDate: this.state.PrescribeDate,
                PatientPrescriptionId: parseInt(this.state.PatientPrescriptionId),
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        if (this.state.DocumentFile != "") {
                            this.filesUploadDoc(result.data.outdata.patientPrescriptionId, result.data.message)
                        }
                        else {
                            this.setState({
                                modal: !this.state.modal,
                                modalTitle: 'Success',
                                modalBody: result.data.message,
                                redirect: true
                            });
                        }
                    }
                    else {
                        this.setState({
                            modal: !this.state.modal,
                            modalTitle: 'Error',
                            modalBody: result.data.message,
                            loading: false
                        });
                    }
                })
                .catch(error => {
                    //console.log(error);
                    this.setState({
                        modal: !this.state.modal,
                        modalTitle: 'Error',
                        modalBody: error.message
                    });
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            this.setState({
                modal: !this.state.modal,
                modalTitle: 'Error',
                modalBody: "Please fill all the fields."
            });
            this.setState({ loading: false });
        }
    }

    filesUploadDoc(id, msg) {
        const apiroute = window.$APIPath;
        let url = apiroute + '/api/PatientPrescriptionFile/Save?id=' + id + '';
        //alert(this.state.DocumentFile)
        let files = this.state.DocumentFile;
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append(`files[${i}]`, files[i])
        }
        axios.post(url, data, {
            // receive two    parameter endpoint url ,form data
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    modal: !this.state.modal,
                    modalTitle: 'Success',
                    modalBody: msg,
                    redirect: true
                });
            }
            else {
                this.setState({
                    modal: !this.state.modal,
                    modalTitle: 'Error',
                    modalBody: result.data.message,
                    loading: false
                });
            }
        }).catch(error => {
            console.log(error.message);
        });
    }

    //delete(active/inactive) button click
    deleteRow(e, id) {
        e.preventDefault();

        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientPrescriptionFile/Delete?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        loading: false,
                        modal: !this.state.modal,
                        modalTitle: 'Success',
                        modalBody: result.data.message
                    });
                    const param = this.props.match.params;

                    if (param.id != undefined) {
                        this.getData(param.id);
                    }
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    modal: !this.state.modal,
                    modalTitle: 'Error',
                    modalBody: error.message
                });
                this.setState({ loading: false, authError: true, error: error });
            });
    }

    render() {
        const { loading, PrescriptionDescription, PrescribeDate, docFiles, DocumentFile, errors } = this.state;

        return (
            <div className="mt-100">
                <div className="profile-pg">
                    <div className="container-fluid">
                      
                        <div className="row">
                            <div className="col-md-3">
                                <div className="user-profile-inner">
                                    <div className="list-group ">

                                        <a href="/patient/dashboard" className="list-group-item list-group-item-action">Dashboard</a>
                                        <a href="/patient/profile" className="list-group-item list-group-item-action">Patient Information</a>
                                        <a href="/patient/diagnostic/list" className="list-group-item list-group-item-action">Diagnostic Information</a>
                                        <a href="/patient/disease/list" className="list-group-item list-group-item-action">Disease Information</a>
                                        <a href="/patient/treatment/list" className="list-group-item list-group-item-action">Treatment Information</a>
                                        <a href="/patient/prescription/list" className="list-group-item list-group-item-action active">Prescription Information</a>
                                        <a href="/patient/emergency/list" className="list-group-item list-group-item-action">Emergency Contact</a>
                                        <a href="/patient/insurance/list" className="list-group-item list-group-item-action">Insurance Information</a>
                                        <a href="/patient/payment/detail" className="list-group-item list-group-item-action">Payment Details</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="user-profile-inner">

                                    <div className="widgets-main bootstrap snippet p-3 m-0">
                                        <Form onSubmit={this.handleSubmit.bind(this)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-9">
                                                                    <h4>Patient Prescription Detail</h4>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <a href="/patient/prescription/list" className="primary-btn addButton">List</a>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group row my-4">
                                                                        <div className="col-md-6">
                                                                            <label className="col-12 col-form-label">Prescription Date</label>
                                                                            <div className="col-12">
                                                                                {/* <Input type="date" name="PrescribeDate" tabIndex="1" min="1000-01-01" max="3000-12-31" className="form-control" value={PrescribeDate} onChange={this.handleInputChange.bind(this)} placeholder="Enter a date" /> */}
                                                                                <DatePicker 
                                                                                    selected={PrescribeDate}
                                                                                    onChange={this.handleDateChange.bind(this)}
                                                                                    dateFormat="MM/dd/yyyy"
                                                                                    placeholderText="mm/dd/yyyy"
                                                                                    className="form-control"
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                />
                                                                                {errors.PrescribeDate.length > 0 && <span className='error'>{errors.PrescribeDate}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row my-4">
                                                                        <div className="col-md-12">
                                                                            <label className="col-12 col-form-label">Description</label>
                                                                            <div className="col-12">
                                                                                <Input className="form-control here" type="textarea" rows="8" cols="8" tabIndex="2" placeholder="Enter your description" name="PrescriptionDescription" value={PrescriptionDescription} onChange={this.handleInputChange.bind(this)} />
                                                                                {errors.PrescriptionDescription.length > 0 && <span className='error'>{errors.PrescriptionDescription}</span>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="form-group row my-4">
                                                                        <div className="col-md-6">
                                                                            <label className="col-12 col-form-label">Prescription File</label>
                                                                            <div className="col-12">
                                                                                <Input type="file" name="DocumentFile" id="File" className="form-control" multiple="multiple" tabIndex="3" onChange={this.handleFileInputChange.bind(this)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row my-4">
                                                                        {docFiles.length > 0 ? (
                                                                            docFiles
                                                                                .map((data, i) => {
                                                                                    return (
                                                                                        <div className="col-md-2" key={i}>
                                                                                            <span>{i + 1}.)</span>{data.fileName}
                                                                                            <Link className='btn btn-primary' to={data.document} target='_blank'><span><i className='fa fa-download'></i></span></Link>{" "}
                                                                                            <Link to="#" onClick={e => this.deleteRow(e, data.PatientPrescriptionId)}><i className="fa fa-trash" /></Link>
                                                                                        </div>
                                                                                    )
                                                                                })) : (null)}
                                                                    </div>
                                                                    {/* {loading ?
                                                                        <div className="form-group row my-4 mx-0">
                                                                            <div className="animated fadeIn pt-1 text-center">Loading...</div>
                                                                        </div>
                                                                        : */}
                                                                        <div className="form-group row my-4 mx-0">
                                                                            <button className="primary-btn" type="submit" tabIndex="4" >Submit</button>
                                                                        </div>
                                                                    {/* } */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MyModal
                    handleModal={this.handleModalClose.bind(this)}
                    //modalAction={this.state.modalAction}
                    isOpen={this.state.modal}
                    modalBody={this.state.modalBody}
                    modalTitle={this.state.modalTitle}
                    modalOptions={this.state.modalOptions}
                />
            </div>
        );
    }
}
