import React, { Component } from 'react';
import { Collapse, Container, PaginationItem, Pagination, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input, Table } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
//import MyModal from './../../CustomModal/CustomModal';
import Moment from 'react-moment';
import moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import FilePreview from "react-file-preview-latest";
import downloadIcon from '../../../assets/svg/download.svg';
import closeIcon from '../../../assets/svg/x.svg';
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    FormGroup,
    Row,
    Label,
    Nav,
    TabContent,
} from "reactstrap";
import _, { slice } from "lodash"
export class PractitionerPatient extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            analysisModal: false,
            analysisText: "",
            analysisHeader: "",
            patientId: 0,
            patientAccessionId: 0,
            basicInfo: "",
            assignedinstitution: "",
            diseases: "",
            diagnosticHistory: [],
            emergencyContact: [],
            insuranceDetail: [],
            prescription: [],
            treatmentReport: [],
            assignedlaboratory: "",
            assignedmanufacturer: "",
            patientdiseaseId: "",
            Alldiseases: [],
            showdisease: false,
            patientReports: {},
            preview: false,
            url: "",

            patientaccessionmapping: "",

            diagnosticAnalysis: "",
            diagnosticDate: moment(new Date())._d,
            outcome: "",
            diagnosticDocFiles: [],
            diagnosticdocumentFile: "",
            showdiagnosticerror: "",
            showdiagnostic: false,

            patientPrescriptionId: "",
            prescriptionDescription: "",
            prescribeDate: moment(new Date())._d,
            prescriptiondocumentFile: "",
            prescriptiondocFiles: [],
            showprescriptionerror: "",
            showprescription: false,
            showeditprescription: false,
            showdeleteprescription: false,
            modal_prescri_file: false,

            treatmentDescription: "",
            treatmentDetail: "",
            patientTreatmentReportId: "",

            treatmentDate: moment(new Date())._d,
            treatmentdocumentFile: "",
            treatmentdocFiles: [],
            showtreatmenterror: "",
            showtreatment: false,
            showedittreatment: false,
            showdeletetreatment: false,
            modal_treatmen_file: false,
            peptideStatus: {},
            peptide: {},
            redirect: false,
            showEmergencyContact: false,
            showInsurance: false,
            // authError: false,
            error: '',

            errorType: '',
            errors: {
                patientdiseaseId: '',

                diagnosticAnalysis: '',
                diagnosticDate: '',
                outcome: '',

                prescriptionDescription: '',
                prescribeDate: '',

                treatmentDescription: '',
                treatmentDetail: '',
                treatmentDate: '',


                CountryId: '',
                StateId: '',
                CityId: '',
                FirstName: '',
                LastName: '',
                Email: '',
                PhoneNumber: '',
                Mobile: '',
                Address: '',
                MiddleName: '',
                PostalCode: '',

                InsuranceCompany: '',
                HealthPlan: '',
                MemberID: '',
                GroupNumber: '',
                PhoneNumber_Insurance: '',
                Address_Insurance: '',
                PolicyNumber: '',
                // DocumentFile: ''
            },
            // PatientId: 0,
            PatientEmergencyId: 0,
            FirstName: "",
            MiddleName: "",
            LastName: "",
            Email: "",
            CountryId: "",
            countries: [],
            StateId: "",
            states: [],
            CityId: "",
            cities: [],
            Mobile: "",
            Address: "",
            PhoneNumber: "",
            PostalCode: "",

            pemergency: [],
            slDelete: false,
            currentPage: 0,
            currentIndex: 0,
            pagesCount: 0,
            pageSize: window.$TotalRecord,
            deleteId: '',
            modal: false,



            PatientInsuranceId: 0,
            InsuranceCompany: "",
            HealthPlan: "",
            MemberID: "",
            GroupNumber: "",
            PhoneNumber_Insurance: "",
            Address_Insurance: "",
            PolicyNumber: "",
            docFiles: [],
            DocumentFile: "",
            modal_In: false,
            modal_In_file: false,
            diseasecatname: ""
            // authError: false,
        };
        this.state = this.initialState;
        this.toggle = this.toggle.bind(this);
        this.toggle_In = this.toggle_In.bind(this);
        this.toggle_In_file = this.toggle_In_file.bind(this);
        this.toggle_prescri_file = this.toggle_prescri_file.bind(this);
        this.toggle_treatment_file = this.toggle_treatment_file.bind(this);


    }
    getMainData() {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/Disease/getalldisease';
        let data = JSON.stringify({
            isDeleted: true,
            searchString: ''
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                let currentdisease = result.data.outdata.filter(ds => ds.diseaseName.toLowerCase() != "healthindex");
                this.setState({
                    Alldiseases: currentdisease
                }, () => {
                    const param = this.props.match.params;

                    if (param.id != undefined) {
                        this.getData();
                    }
                });
            }
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
        });
    }

    componentDidMount() {
        let param = this.props.match.params;

        this.getMainData()
        // console.log(this.state.treatmentReport);
        this.getAllCountry_EC();
        // let data = this.state?.Alldiseases.filter((ele) => ele?.diseaseId == this.state?.diseases.diseaseId)

        // let value = data[0]?.category;
        // alert(value);
        // this.setState({ diseasecatname: value });
    }

    getCityData_EC(StateId, CityId) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/BE_Common/GetCityByStateId?Id=' + StateId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                //console.log(result.data);
                this.setState({ cities: result.data.outdata, CityId: CityId, loading: false });
            }
            else { this.setState({ loading: false }); }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    // getData_Insurance(id) {
    //     const apiroute = window.$APIPath;
    //     const url = apiroute + '/api/PatientInsurance/GetById?id=' + id;

    //     axios.get(url, {
    //         headers: {
    //             'Content-Type': 'application/json; charset=utf-8'
    //         }
    //     })
    //         .then(result => {
    //             if (result.data.flag) {
    //                 var rData = result.data.outdata.insuranceData;
    //                 var dData = result.data.outdata.docData;
    //                 console.log(dData);
    //                 this.setState({
    //                     patientId: rData.patientId, InsuranceCompany: rData.insuranceCompany, HealthPlan: rData.healthPlan,
    //                     GroupNumber: rData.groupNumber, PhoneNumber_Insurance: rData.phoneNumber, Address_Insurance: rData.address,
    //                     MemberID: rData.memberID, PolicyNumber: rData.policyNumber, PatientInsuranceId: rData.patientInsuranceId,
    //                     loading: false, docFiles: dData
    //                 });
    //                 //console.log(this.state);
    //             } else { this.setState({ loading: false }); }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             this.setState({ loading: false });
    //         });
    // }


    getAllCountry_EC() {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/BE_Common/GetAllCountry';
        let data = JSON.stringify({
            isDeleted: false,
            searchString: ''
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    countries: result.data.outdata
                }, () => {
                    // const param = this.props.match.params;

                    // if (param.id != undefined) {
                    //     this.getData_EC(param.id);
                    // }
                    // else {
                    //     this.setState({ loading: false });
                    // }
                });
            } else {
                this.setState({ loading: false });
            }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const param = this.props.match.params;
        const url = apiroute + '/api/Patient/getPatientsInfobyAccessionId?id=' + param.id + '';
        this.setState({ loading: true })
        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                console.log("ResultEMEEMEM", result);
                if (result.data.flag) {
                    var rData = result.data.outdata;
                    this.setState({
                        basicInfo: rData,
                        patientId: rData.patientId,
                        patientAccessionId: rData.patientAccessionMapping.patientAccessionId,
                        assignedinstitution: rData.institutePatient,
                        assignedlaboratory: rData.laboratoryPatient,
                        assignedmanufacturer: rData.manufacturerPatient,

                        patientaccessionmapping: rData.patientAccessionMapping,

                        diseases: rData.patientDisease[0],
                        diagnosticHistory: rData.patientDiagnosticHistory,
                        emergencyContact: rData.patientEmergencyContacts,
                        insuranceDetail: rData.patientInsuranceDetails,
                        docFiles: rData.patientInsuranceDetails,
                        prescription: rData.patientPrescription,
                        prescriptiondocFiles: rData.patientPrescription,
                        treatmentReport: rData.patientTreatmentReport,
                        treatmentdocFiles: rData.patientTreatmentReport,
                        loading: false,
                        peptideStatus: rData?.peptideStatus || {},
                        peptides: rData?.peptideStatus || {},
                        patientReports: rData?.patientReports
                    });
                    // this.getMainData();
                    console.log(this.state);
                    // alert('111');
                }
                else {
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                })


                toast.error(error.message)
            });
    }
    isPeptideDisabled = (currentStatus, nextStatus, prevStatus) => {
        try {
            console.log(this.state.peptideStatus[currentStatus], "vvgvgvgvgggvg", currentStatus)
            console.log(this.state?.peptideStatus, "hhhhhhhhhhhhhhhhhhh");
            let checked = this.state?.peptideStatus[currentStatus]
            let peptide = this.state?.peptides
            // let checked = state[currentStatus]

            let prev = peptide[prevStatus];
            let next = peptide[nextStatus]
            console.log({ checked, next: !next ? checked && next : checked || prev, mainNext: next, prev, peptide, })
            if (!checked) { return true }
            if (!next) {
                return checked && next
            } else {
                return checked || prev
            }
        } catch (error) {
            return true
        }
    }
    toggleExpander = (e) => {
        const hiddenElement = e.currentTarget.nextSibling;
        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    }

    //add disease
    handleCloseDisease = () => {
        this.setState({
            showdisease: false
        });
    }

    handleShowDisease = () => {
        this.setState({
            showdisease: true,
        });
    }

    handleDiseaseInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'patientdiseaseId':
                errors.patientdiseaseId = (!value) ? "Please select disease." : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {

        })
    }

    AddPatientDisease(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let errors = this.state.errors;

        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.state.patientdiseaseId != null && this.state.patientdiseaseId != "") {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/PatientDisease/Save';

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                DiseaseId: parseInt(this.state.patientdiseaseId),
                PatientDiseaseId: 0,
                createdBy: uid,
                createdByFlag: "P"
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false,
                        showdisease: false,
                        patientdiseaseId: ''
                    }, this.getData(this.state.patientId));
                    toast.success(result.data.message)
                }
                else {
                    errors.patientdiseaseId = result.data.message;
                    this.setState({ loading: false });
                }
            })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false, showdisease: false
                    });
                    toast.error(error.message)
                });
        }
        else {
            errors.patientdiseaseId = "Please select disease.";
            this.setState({ loading: false });
        }
    }
    //end add disease

    //add diagnostic
    handleCloseDiagnostic = () => {
        this.setState({
            showdiagnostic: false
        });
        let errors = this.state.errors;
        errors.diagnosticAnalysis = "";
        errors.outcome = "";
    }

    handleShowDiagnostic = () => {
        this.setState({
            showdiagnostic: true,
        });
    }
    handleCloseInsurance() {
        this.setState({
            showInsurance: false,
            errors: {
                patientdiseaseId: '',

                diagnosticAnalysis: '',
                diagnosticDate: '',
                outcome: '',

                prescriptionDescription: '',
                prescribeDate: '',

                treatmentDescription: '',
                treatmentDetail: '',
                treatmentDate: '',


                CountryId: '',
                StateId: '',
                CityId: '',
                FirstName: '',
                LastName: '',
                Email: '',
                PhoneNumber: '',
                Mobile: '',
                Address: '',
                MiddleName: '',
                PostalCode: '',

                InsuranceCompany: '',
                HealthPlan: '',
                MemberID: '',
                GroupNumber: '',
                PhoneNumber_Insurance: '',
                Address_Insurance: '',
                PolicyNumber: '',
                // DocumentFile: ''
            },
            PatientInsuranceId: 0,
            InsuranceCompany: "",
            HealthPlan: "",
            MemberID: "",
            GroupNumber: "",
            PhoneNumber_Insurance: "",
            Address_Insurance: "",
            PolicyNumber: "",
            // docFiles: [],

        });
    }
    handleShowInsurance() {
        this.setState({
            showInsurance: true,
        });
    }

    handleDiagnosticDateChange(date) {
        let errors = this.state.errors;
        errors.diagnosticDate = (!date) ? "Please enter diagnostic date." : '';
        this.setState({ diagnosticDate: date })
    }

    handleDiagnosticInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'diagnosticDate':
                errors.diagnosticDate = (!value) ? "Please enter diagnostic date." : '';
                break;
            case 'diagnosticAnalysis':
                errors.diagnosticAnalysis = (!value) ? "Please enter diagnostic analysis." : '';
                break;
            case 'outcome':
                errors.outcome = (!value) ? "Please enter outcome." : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {

        })
    }

    handlePmrSentToClinicSUbmit = () => {

        try {
            const apiroute = window.$APIPath;
            const url = apiroute + '/api/BE_DesignOrder/CreateDesignOrder';
            let uid = 0;
            var userToken = JSON.parse(localStorage.getItem("AUserToken"));
            if (userToken != null) {
                uid = userToken.userId == null ? 0 : userToken.userId;
            }
            let data = {
                patientId: Number(this.state.patientId),
                patientAccessionId: Number(this.state.patientAccessionId),
                createdBy: uid,
                statusUpdatedDate: this.state.peptideStatus.pmrSendToCilnicDate
            }
            axios.post(url, data, {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            }).then((res) => {

                let { flag, message } = res.data
                if (flag) {

                    toast.success("PMR sent to clinic. Waiting for the Clinic to send the PMR to Pharmacy")
                } else {
                    console.log({ res, flag, message })
                    toast.error(message || "Something wen wrong ")
                }
            })

        } catch (error) {
            toast.error("Error while updating")


        }

    }
    handleClinicSentPmrToPharmacy = () => {
        return new Promise(async (resolve) => {
            try {
                const apiroute = window.$APIPath;
                const url = apiroute + '/api/DesignOrder/UpdateClinicSendPMRToPharmacy';
                let uid = 0;
                var userToken = JSON.parse(localStorage.getItem("AUserToken"));
                if (userToken != null) {
                    uid = userToken.userId == null ? 0 : userToken.userId;
                }
                let peptideStatus = this.state.peptideStatus
                let x = new Date(peptideStatus?.clinicSendPMRToPharmacyDate)

                let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
                let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
                x.setHours(hoursDiff);
                x.setMinutes(minutesDiff);
                let data = {
                    "id": peptideStatus?.designOrderId,  //design order id
                    "statusUpdatedDate": x,
                    "statusUpdatedBy": uid
                }
                await axios.post(url, data, {
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                }).then(res => {
                    let { flag, message } = res.data;
                    this.getData()
                    if (flag) {

                        toast.success("PMR sent to Pharmacy")
                    } else {
                        toast.error(message)
                    }
                })

            } catch (error) {
                toast.error("Error while updating")


            }
        })
    }
    handlePeptidesReceviedByclinic = () => {
        return new Promise(async (resolve) => {
            try {
                const apiroute = window.$APIPath;
                const url = apiroute + '/api/DesignOrder/UpdatePeptidesReceivedByClinic';
                let uid = 0;
                var userToken = JSON.parse(localStorage.getItem("AUserToken"));
                if (userToken != null) {
                    uid = userToken.userId == null ? 0 : userToken.userId;
                }

                let peptideStatus = this.state.peptideStatus;
                let x = new Date(peptideStatus?.peptidesReceivedByClinicDate)

                let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
                let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
                x.setHours(hoursDiff);
                x.setMinutes(minutesDiff);
                let data = {
                    "id": peptideStatus?.designOrderId,  //design order id
                    "statusUpdatedDate": x,
                    "statusUpdatedBy": uid
                }
                axios.post(url, data, {
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                }).then(res => {
                    let { flag, message } = res.data
                    this.getData()
                    if (flag) {
                        toast.success("Peptides Received By Clinic")

                    } else {
                        toast.error(message)
                    }
                })
                resolve(true)
            } catch (error) {
                toast.error("Error while updating")
                resolve(true)

            }
        })

    }
    handleDiagnosticFileInputChange(event) {
        const target = event.target;
        const value = target.files;

        this.setState({
            diagnosticdocumentFile: value
        });
    }
    handlePeptidesTreatmentStarted = () => {
        return new Promise(async (resolve) => {
            try {
                const apiroute = window.$APIPath;
                const url = apiroute + '/api/DesignOrder/UpdateTreatmentStarted';
                let uid = 0;
                var userToken = JSON.parse(localStorage.getItem("AUserToken"));
                if (userToken != null) {
                    uid = userToken.userId == null ? 0 : userToken.userId;
                }
                let peptideStatus = this.state.peptideStatus
                let x = new Date(peptideStatus?.treatmentStartedDate)

                let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
                let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
                x.setHours(hoursDiff);
                x.setMinutes(minutesDiff);
                let data = {
                    "id": peptideStatus?.designOrderId,  //design order id
                    "statusUpdatedDate": x,
                    "statusUpdatedBy": uid
                }
                axios.post(url, data, {
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                }).then(res => {
                    let { flag, message } = res.data
                    this.getData()
                    if (flag) {

                        toast.success("Treatment Started")
                    } else { toast.error(message) }
                })
                resolve(true)
            } catch (error) {
                toast.error("Error while updating")
                resolve(true)

            }
        })
    }

    validateDiagnosticForm = (errors) => {
        let valid = true;

        if (this.state.diagnosticAnalysis == undefined || this.state.diagnosticAnalysis == '') {
            errors.diagnosticAnalysis = 'Please enter diagnostic analysis.';
        }
        if (this.state.diagnosticDate == undefined || this.state.diagnosticDate == '') {
            errors.diagnosticDate = 'Please enter diagnostic date.';
        }
        if (this.state.outcome == undefined || this.state.outcome == '') {
            errors.outcome = 'Please enter outcome.';
        }
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    AddPatientDiagnostic(e) {
        e.preventDefault();
        this.setState({ loading: true, showdiagnosticerror: "", });

        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.validateDiagnosticForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/BE_PatientDiagnostic/Save';

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                PatientAccessionId: parseInt(this.state.patientAccessionId),
                DiagnosticAnalysis: this.state.diagnosticAnalysis,
                DiagnosticDate: this.state.diagnosticDate,
                Outcome: this.state.outcome,
                PatientDiagnosticId: parseInt(0),
                createdBy: uid,
                createdByFlag: "P"
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    if (this.state.diagnosticdocumentFile != "") {
                        let dData = result.data.outdata;
                        this.filesUploadDocDiagnostic(dData.patientDiagnosticId, result.data.message)
                        this.setState({

                            diagnosticAnalysis: "",
                            outcome: "",
                            diagnosticDate: "",
                            diagnosticdocumentFile: "",

                        })
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            showdiagnostic: false,
                            diagnosticAnalysis: "",
                            outcome: "",
                            diagnosticDate: "",
                            diagnosticdocumentFile: "",
                            showdiagnostic: false
                        }, this.getData(),);

                        toast.success(result.data.message)
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        showdiagnosticerror: result.data.message
                    });
                }
            })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false,
                    });
                    toast.error(error.message)
                });
        }
        else {
            this.setState({ loading: false });
        }
    }

    filesUploadDocDiagnostic(id, msg) {
        const apiroute = window.$APIPath;
        let url = apiroute + '/api/BE_PatientDiagnosticFile/Save?id=' + id + '';
        //alert(this.state.diagnosticdocumentFile)
        let files = this.state.diagnosticdocumentFile;
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append(`files[${i}]`, files[i])
        }
        axios.post(url, data, {
            // receive two    parameter endpoint url ,form data
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    // authError: true,
                    // errorType: 'success',
                    // error: msg,
                    loading: false,
                    showdiagnostic: false
                }, this.getData());
                toast.success(msg)
            }
            else {
                this.setState({
                    loading: false,
                    showdiagnosticerror: result.data.message
                });
            }
        }).catch(error => {
            this.setState({
                // authError: true, errorType: 'danger', error: error.message, 
                loading: false,
            });
            toast.error(error.message)
        });
    }
    //end add diagnostic

    //add prescription
    handleClosePrescription = () => {
        this.setState({
            showprescription: false,
            prescriptionDescription: "",
            prescribeDate: "",
            prescriptiondocumentFile: "",

        });
        let errors = this.state.errors;
        errors.prescribeDate = '';
        errors.prescriptionDescription = "";

    }

    handlePrescribeDateChange(date) {
        let errors = this.state.errors;
        errors.prescribeDate = (!date) ? "Please enter prescription date." : '';
        this.setState({ prescribeDate: date })
    }

    handleShowPrescription = () => {
        this.setState({
            showprescription: true,
        });
    }

    handlePrescriptionInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'prescribeDate':
                errors.prescribeDate = (!value) ? "Please enter prescription date." : '';
                break;
            case 'prescriptionDescription':
                errors.prescriptionDescription = (!value) ? "Please enter prescription description." : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {

        })
    }
    handleEmergencyContactInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        const validMobileRegex = RegExp(/^[0-9+() -]+$/);
        const validNumberRegex = RegExp(/^[0-9]+$/);
        const validAlphaRegex = RegExp(/^[a-zA-Z \b]+$/);
        const validEmailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        switch (name) {
            //case 'CountryId':
            //    errors.CountryId = (!value) ? 'Please select country.' : '';
            //    break;
            //case 'StateId':
            //    errors.StateId = (!value) ? 'Please select state.' : '';
            //    break;
            //case 'CityId':
            //    errors.CityId = (!value) ? 'Please select city.' : '';
            //    break;
            case 'FirstName':
                errors.FirstName = (!value) ? 'Please enter first name.' : (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.');
                this.setState({ FirstName: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;

            case 'MiddleName':
                errors.MiddleName = (value) ? (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.') : '';
                this.setState({ MiddleName: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;

            case 'LastName':
                errors.LastName = (!value) ? 'Please enter last name.' : (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.');
                this.setState({ LastName: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;

            case 'Mobile':
                errors.Mobile = (!value) ? 'Please enter primary phone.' : (validMobileRegex.test(value) ? '' : 'Only numbers allowed.');
                this.setState({ Mobile: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            case 'Email':
                errors.Email = (!value) ? 'Please enter email.' : (validEmailRegex.test(value) ? '' : 'Invalid Email.');
                break;

            case 'PhoneNumber':
                errors.PhoneNumber = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ PhoneNumber: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            case 'PostalCode':
                errors.PostalCode = (value) ? (validNumberRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ PostalCode: value.replace(/[^0-9]+$/, '') })
                break;
            //case 'Address':
            //    errors.Address = (!value) ? 'Please enter Address.' : '';
            //    break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors }, () => {

            if (name == 'CountryId') {
                if (value != '') {
                    this.getStateData_EC(value, "", "");
                    this.setState({ cities: [], CityId: "" });
                }
                else {
                    this.setState({ states: [], StateId: "", cities: [], CityId: "" });
                }
            }
            if (name == 'StateId') {
                if (value != '') {
                    this.getCityData_EC(value, "");
                }
                else {
                    this.setState({ cities: [], CityId: "" });
                }
            }

        })
    }
    handleInputChangeInsurance(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        const validNumberRegex = RegExp(/^[0-9]+$/);
        const validMobileRegex = RegExp(/^[0-9+() -]+$/);

        switch (name) {
            case 'HealthPlan':
                errors.HealthPlan = (!value) ? 'Please enter health plan.' : '';
                break;
            case 'GroupNumber':
                errors.GroupNumber = (!value) ? 'Please enter group number.' : '';
                break;
            case 'PhoneNumber_Insurance':
                errors.PhoneNumber_Insurance = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : 'Please enter phone.';
                this.setState({ PhoneNumber_Insurance: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                //errors.PhoneNumber = (!value) ? 'Please enter phone number.' : '';
                break;
            case 'Address_Insurance':
                errors.Address_Insurance = (!value) ? 'Please enter address.' : '';
                break;
            case 'InsuranceCompany':
                errors.InsuranceCompany = (!value) ? 'Please enter insurance company name.' : '';
                break;
            case 'MemberID':
                errors.MemberID = (!value) ? 'Please enter member id.' : '';
                break;
            case 'PolicyNumber':
                errors.PolicyNumber = (!value) ? 'Please enter policy number.' : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors }, () => {

        })

    }
    handleFileInputChange_Insurance(event) {
        console.log("e.tartget", event.target.files)
        const target = event.target;
        const value = target.files;
        const name = target.name;



        this.setState({
            DocumentFile: value
        });
    }


    handlePrescriptionFileInputChange(event) {
        const target = event.target;
        const value = target.files;

        this.setState({
            prescriptiondocumentFile: value
        });
    }

    validatePrescriptionForm = (errors) => {
        let valid = true;

        if (this.state.prescriptionDescription == undefined || this.state.prescriptionDescription == '') {
            errors.prescriptionDescription = 'Please enter prescription description.';
        }
        if (this.state.prescribeDate == undefined || this.state.prescribeDate == '') {
            errors.prescribeDate = 'Please enter prescription date.';
        }
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    AddPatientPrescription(e) {
        e.preventDefault();
        this.setState({ loading: true, showprescriptionerror: "" });

        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.validatePrescriptionForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/PatientPrescription/Save';

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                PatientAccessionId: parseInt(this.state.patientAccessionId),
                PrescriptionDescription: this.state.prescriptionDescription,
                PrescribeDate: this.state.prescribeDate,
                PatientPrescriptionId: parseInt(0),
                createdBy: uid,
                createdByFlag: "P"
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    if (this.state.prescriptiondocumentFile != "") {
                        let dData = result.data.outdata;
                        this.filesUploadDocPrescription(dData.patientPrescriptionId, result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            showprescription: false,
                            prescriptionDescription: "",
                            prescribeDate: "",
                            prescriptiondocumentFile: "",
                        });
                        this.getData();

                        toast.success(result.data.message)
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        showprescriptionerror: result.data.message
                    });
                }
            })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false,
                    });
                    toast.error(error.message)
                });
        }
        else {
            this.setState({ loading: false });
        }
    }
    validateForm = (errors) => {
        let valid = true;

        //if (this.state.CityId == undefined || this.state.CityId == '') {
        //    errors.CityId = 'Please select city.';
        //}
        //if (this.state.CountryId == undefined || this.state.CountryId == '') {
        //    errors.CountryId = 'Please select country.';
        //}
        //if (this.state.StateId == undefined || this.state.StateId == '') {
        //    errors.StateId = 'Please select state.';
        //}
        if (this.state.FirstName == undefined || this.state.FirstName == '') {
            errors.FirstName = 'Please enter first name.';
        }
        if (this.state.LastName == undefined || this.state.LastName == '') {
            errors.LastName = 'Please enter last name.';
        }
        if (this.state.Email == undefined || this.state.Email == '') {
            errors.Email = 'Please enter email.';
        }
        // if (this.state.PhoneNumber == undefined || this.state.PhoneNumber == '') {
        //    errors.PhoneNumber = 'Please enter secondary phone.';
        // }
        if (this.state.Mobile == undefined || this.state.Mobile == '') {
            errors.Mobile = 'Please enter primary phone.';
        }
        //if (this.state.Address == undefined || this.state.Address == '') {
        //    errors.Address = 'Please enter Address.';
        //}
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    validateForm_Insurance = (errors) => {
        let valid = true;

        if (this.state.InsuranceCompany == undefined || this.state.InsuranceCompany == '') {
            errors.InsuranceCompany = 'Please enter insurance company name.';
        }
        if (this.state.MemberID == undefined || this.state.MemberID == '') {
            errors.MemberID = 'Please enter member id.';
        }
        if (this.state.HealthPlan == undefined || this.state.HealthPlan == '') {
            errors.HealthPlan = 'Please select health plan.';
        }
        if (this.state.PolicyNumber == undefined || this.state.PolicyNumber == '') {
            errors.PolicyNumber = 'Please enter policy number.';
        }
        if (this.state.GroupNumber == undefined || this.state.GroupNumber == '') {
            errors.GroupNumber = 'Please enter group number.';
        }
        if (this.state.PhoneNumber_Insurance == undefined || this.state.PhoneNumber_Insurance == '') {
            errors.PhoneNumber_Insurance = 'Please enter phone.';
        }
        if (this.state.Address_Insurance == undefined || this.state.Address_Insurance == '') {
            errors.Address_Insurance = 'Please enter address.';
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }
    AddPatientInsurance(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";
        console.log("Add patient ", this.validateForm_Insurance(this.state.errors))

        if (this.validateForm_Insurance(this.state.errors)) {
            const param = this.props.match.params;
            let pid2 = param.id;
            var userToken = JSON.parse(localStorage.getItem('Usertoken'));
            let pid = (userToken.patientId == null ? 0 : userToken.patientId);
            const apiroute = window.$APIPath;
            if (this.state.PatientInsuranceId == 0) {
                url = apiroute + '/api/PatientInsurance/Save';
            }
            else {
                url = apiroute + '/api/PatientInsurance/Update';
            }

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                InsuranceCompany: this.state.InsuranceCompany,
                HealthPlan: this.state.HealthPlan,
                MemberID: this.state.MemberID,
                PolicyNumber: this.state.PolicyNumber,
                GroupNumber: this.state.GroupNumber,
                PhoneNumber: this.state.PhoneNumber_Insurance,
                Address: this.state.Address_Insurance,
                PatientInsuranceId: parseInt(this.state.PatientInsuranceId),

            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        if (this.state.DocumentFile != "") {
                            this.filesUploadDoc_Insurance(result.data.outdata.patientInsuranceId, result.data.message)
                        }
                        else {
                            this.setState({
                                // authError: true,
                                // errorType: 'success',
                                // error: result.data.message,
                                loading: false,
                                redirect: true,
                                showInsurance: false,


                            });
                            this.temp2();
                            this.getData();
                            toast.success(result.data.message)
                        }
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,

                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 

                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        } else {
            this.setState({ loading: false });
        }
    }

    filesUploadDoc_Insurance(id, msg) {
        const param = this.props.match.params;
        const apiroute = window.$APIPath;
        let url = apiroute + '/api/PatientInsuranceFile/Save?id=' + id + '&aid=' + param.id;
        // alert(this.state.DocumentFile)
        let files = this.state.DocumentFile;
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append(`files[${i}]`, files[i])
        }

        axios.post(url, data, {
            // receive two    parameter endpoint url ,form data
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    // authError: true,
                    // errorType: 'success',
                    // error: msg,
                    redirect: true,
                    loading: false,
                    showInsurance: false,
                })
                this.temp2();
                this.getData();
                // this.getData();
                toast.success(msg)
            }
            else {
                this.setState({
                    // authError: true,
                    // errorType: 'danger',
                    // error: result.data.message,
                    loading: false,

                });
                toast.error(result.data.message)
            }
        }).catch(error => {
            console.log(error.message);
            this.setState({ redirect: true, showInsurance: false, loading: false });
        });
    }

    deleteDoc_Insurance(e, id) {
        e.preventDefault();
        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientInsuranceFile/Delete?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.success(result.data.message)
                    // this.temp2();
                    this.toggle_In_file()
                    this.getData();
                    const param = this.props.match.params;

                    // if (param.id != undefined) {
                    //     this.getData(param.id);
                    // }
                    // this.toggle()
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }


    toggle(e, id) {
        this.setState({
            modal: !this.state.modal,
            deleteId: id
        });
    }
    toggle_In(e, id) {
        this.setState({
            modal_In: !this.state.modal_In,
            deleteId: id
        });
    }
    toggle_In_file(e, id) {
        this.setState({
            modal_In_file: !this.state.modal_In_file,
            deleteId: id
        });
    }
    toggle_prescri_file(e, id) {
        this.setState({
            modal_prescri_file: !this.state.modal_prescri_file,
            deleteId: id
        });
    }
    toggle_treatment_file(e, id) {
        this.setState({
            modal_treatmen_file: !this.state.modal_treatmen_file,
            deleteId: id
        });
    }
    deleteRow_Insurance(e, id) {
        // e.preventDefault();

        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientInsurance/Delete?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.success(result.data.message)
                    this.toggle_In()
                    this.getData();

                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }
    temp() {
        this.setState({
            PatientEmergencyId: 0,
            FirstName: '',
            LastName: '',
            MiddleName: '',
            Email: '',
            Mobile: '',
            PhoneNumber: '',
            Address: '',
            PostalCode: '',
            CountryId: '',
            StateId: '',
            CityId: ''
        })
    }
    temp2() {
        this.setState({
            PatientInsuranceId: 0,
            InsuranceCompany: "",
            HealthPlan: "",
            MemberID: "",
            GroupNumber: "",
            PhoneNumber_Insurance: "",
            Address_Insurance: "",
            PolicyNumber: "",
            docFiles: [],
            DocumentFile: "",
        })
    }

    AddPatientEmergencyContact(e) {
        e.preventDefault();
        console.log("PatienEmergencyContactId", this.state.PatientEmergencyId)
        this.setState({ loading: true });
        let url = "";
        const param = this.props.match.params;

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.patientId == null ? 0 : this.state.patientId);
        let pid2 = param.id;
        // console.log("PID:::", pid)

        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            if (this.state.PatientEmergencyId == 0) {
                url = apiroute + '/api/PatientEmergencyContact/Save';
            }
            else {
                url = apiroute + '/api/PatientEmergencyContact/Update';
            }

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                MiddleName: this.state.MiddleName,
                Email: this.state.Email,
                Address: this.state.Address,
                PhoneNumber: this.state.PhoneNumber,
                Mobile: this.state.Mobile,
                PostalCode: this.state.PostalCode,
                CityId: ((this.state.CityId == "" || this.state.CityId == 0) ? null : parseInt(this.state.CityId)),
                CountryId: ((this.state.CountryId == "" || this.state.CountryId == 0) ? null : parseInt(this.state.CountryId)),
                StateId: ((this.state.StateId == "" || this.state.StateId == 0) ? null : parseInt(this.state.StateId)),
                PatientEmergencyContactId: parseInt(this.state.PatientEmergencyId),
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            redirect: true,
                            showEmergencyContact: false,
                        }, this.temp())
                        this.getData()

                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    //console.log(error);
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            this.setState({ loading: false });
        }
    }
    deleteRowEmergencyContact(e, id) {
        // e.preventDefault();

        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientEmergencyContact/Delete?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        // temp:result.data.isActive,
                        loading: false
                    }, this.temp());
                    this.getData();
                    toast.success(result.data.message)
                    this.toggle()
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }
    // handleShowEditEmergencyContact(id) {
    //     const apiroute = window.$APIPath;
    //     const url = apiroute + '/api/PatientEmergencyContact/GetById?id=' + id;

    //     axios.get(url, {
    //         headers: {
    //             'Content-Type': 'application/json; charset=utf-8'
    //         }
    //     })
    //         .then(result => {
    //             if (result.data.flag) {
    //                 var rData = result.data.outdata;
    //                 this.setState({
    //                     patientId: rData.patientId, FirstName: rData.firstName, MiddleName: rData.middleName,
    //                     LastName: rData.lastName, Email: rData.email, Mobile: rData.mobile,
    //                     Address: rData.address, PatientEmergencyId: id,
    //                     PhoneNumber: rData.phoneNumber, PostalCode: rData.postalCode, CountryId: rData.countryId
    //                     //, StateId: rData.StateId, CityId: rData.CityId
    //                 }, () => {
    //                     if (rData.countryId != null) {
    //                         this.getStateData_EC(rData.countryId, rData.stateId, rData.cityId)
    //                     } else { this.setState({ loading: false }); }
    //                 });

    //             } else { this.setState({ loading: false }); }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             this.setState({ loading: false });
    //         });
    // }

    handleShowEditEmergencyContactPopup(e, id, fN, lN, mN, Email, mobile, phN, add, postalCode, CD, SD, CiD) {
        e.preventDefault();
        // this.handleShowEditEmergencyContactPopup(id) 
        this.setState({
            showEmergencyContact: true,
            PatientEmergencyId: id,
            FirstName: fN,
            LastName: lN,
            MiddleName: mN,
            Email: Email,
            Mobile: mobile,
            PhoneNumber: phN,
            Address: add,
            PostalCode: postalCode,
            CountryId: CD,
            StateId: SD,
            CityId: CiD

        }, this.getStateData_EC(CD, SD, CiD))
        console.log("pid", id)

    }
    getStateData_EC(CountryId, StateId, CityId) {

        const apiroute = window.$APIPath;

        const url = apiroute + '/api/BE_Common/GetStateByCountryId?Id=' + CountryId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                console.log("CityId", CityId)
                if (result.data.flag) {
                    console.log("500", result.data);
                    this.setState({ states: result.data.outdata, StateId: StateId }, () => {
                        if (StateId != "" || StateId != null) {
                            console.log("CityId", StateId)

                            this.getCityData_EC(StateId, CityId)
                        }
                        else { this.setState({ loading: false }); }
                    });
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    handleShowEditInsurancePopup(e, id, IC, PN, HP, MId, GN, PH, Add) {
        this.setState({
            showInsurance: true,
            PatientInsuranceId: id,
            InsuranceCompany: IC,
            PolicyNumber: PN,
            HealthPlan: HP,
            MemberID: MId,
            GroupNumber: GN,
            PhoneNumber_Insurance: PH,
            Address_Insurance: Add

        })

        console.log("pidInsurance", id)
    }





    EditPatientPrescription(e) {
        //    alert("call edit");

        e.preventDefault();
        this.setState({ loading: true, showprescriptionerror: "" });

        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }
        console.log(uid);
        if (this.validatePrescriptionForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/BE_PatientPrescription/Update';

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                PatientAccessionId: parseInt(this.state.patientAccessionId),
                PrescriptionDescription: this.state.prescriptionDescription,
                PrescribeDate: this.state.prescribeDate,
                PatientPrescriptionId: Number(this.state.patientPrescriptionId),
                createdBy: Number(uid),
                createdByFlag: "P"
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    if (this.state.prescriptiondocumentFile != "") {
                        let dData = result.data.outdata;
                        this.filesUploadDocPrescription(dData.patientPrescriptionId, result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            showeditprescription: false,
                            prescriptionDescription: "",
                            prescribeDate: "",
                            prescriptiondocumentFile: "",
                        });
                        this.getData();

                        toast.success(result.data.message)
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        showprescriptionerror: result.data.message
                    });
                }
            })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false,
                    });
                    toast.error(error.message)
                });
        }
        else {
            this.setState({ loading: false });
        }
    }
    DeletePatientPrescription() {
        this.setState({
            showdeleteprescription: false,
        });

        const apiroute = window.$APIPath;
        let url = apiroute + "/api/PatientPrescription/Delete?id=" + this.state.patientPrescriptionId;

        axios
            .delete(url, {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            })
            .then((result) => {
                if (result.data.flag) {

                    toast.success(result.data.message);
                    this.getData();
                }
            })
            .catch((error) => {

                toast.error(error.message);
                this.setState({ authError: true, error: error });
            });
        this.setState({ loading: false });
    }
    filesUploadDocPrescription(id, msg) {
        const apiroute = window.$APIPath;
        let url = apiroute + '/api/PatientPrescriptionFile/Save?id=' + id + '';
        //alert(this.state.prescriptiondocumentFile)
        let files = this.state.prescriptiondocumentFile;
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append(`files[${i}]`, files[i])
        }
        axios.post(url, data, {
            // receive two    parameter endpoint url ,form data
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    // authError: true,
                    // errorType: 'success',
                    // error: msg,
                    loading: false,
                    showprescription: false,
                    showeditprescription: false,
                    prescriptionDescription: "",
                    prescribeDate: "",
                    prescriptiondocumentFile: ""
                });
                this.getData()
                toast.success(msg)
            }
            else {
                this.setState({
                    loading: false,
                    showprescriptionerror: result.data.message
                });
            }
        }).catch(error => {
            this.setState({
                // authError: true, errorType: 'danger', error: error.message, 
                loading: false,
            });
            toast.error(error.message)
        });
    }
    //end add prescription

    //add treatment
    handleCloseTreatment = () => {
        this.setState({
            showtreatment: false,
            treatmentDetail: '',
            treatmentDescription: '',
        });
        let errors = this.state.errors;
        errors.treatmentDate = "";
        errors.treatmentDetail = '';
        errors.treatmentDescription = "";

    }

    handleShowTreatment = () => {
        this.setState({
            showtreatment: true,
        });
    }
    handleCloseEmergencyContact() {
        this.setState({
            showEmergencyContact: false,
            errors: {
                patientdiseaseId: '',

                diagnosticAnalysis: '',
                diagnosticDate: '',
                outcome: '',

                prescriptionDescription: '',
                prescribeDate: '',

                treatmentDescription: '',
                treatmentDetail: '',
                treatmentDate: '',

                CountryId: '',
                StateId: '',
                CityId: '',
                FirstName: '',
                LastName: '',
                Email: '',
                PhoneNumber: '',
                Mobile: '',
                Address: '',
                MiddleName: '',
                PostalCode: '',
                InsuranceCompany: '',
                HealthPlan: '',
                MemberID: '',
                GroupNumber: '',
                PhoneNumber_Insurance: '',
                Address_Insurance: '',
                PolicyNumber: '',
            },
            // CountryId: '',
            // StateId: '',
            // CityId: '',
            // FirstName: '',
            // LastName: '',
            // Email: '',
            // PhoneNumber: '',
            // Mobile: '',
            // Address: '',
            // MiddleName: '',
            // PostalCode: '',

        }, this.temp());
    }
    handleShowEmergencyContact() {
        this.setState({
            showEmergencyContact: true,
        });
    }

    handleTreatmentDateChange(date) {
        let errors = this.state.errors;
        errors.treatmentDate = (!date) ? "Please enter treatment date." : '';
        this.setState({ treatmentDate: date })
    }

    handleTreatmentInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'treatmentDate':
                errors.treatmentDate = (!value) ? "Please enter treatment date." : '';
                break;
            case 'treatmentDetail':
                errors.treatmentDetail = (!value) ? "Please enter treatment detail." : '';
                break;
            case 'treatmentDescription':
                errors.treatmentDescription = (!value) ? "Please enter escription." : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {

        })
    }

    handleTreatmentFileInputChange(event) {
        const target = event.target;
        const value = target.files;

        this.setState({
            treatmentdocumentFile: value
        });
    }
    getDateForPeptide = (name) => {

        try {

            let peptide = this.state.peptideStatus;
            console.log({ peptide: peptide[name], name })
            if (peptide?.[name]) {

                return new Date(peptide?.[name]) || ''
            } else { return '' }

        } catch (error) {
            return "";
        }
    };
    validateTreatmentForm = (errors) => {
        let valid = true;

        if (this.state.treatmentDetail == undefined || this.state.treatmentDetail == '') {
            errors.treatmentDetail = 'Please enter treatment analysis.';
        }
        if (this.state.treatmentDate == undefined || this.state.treatmentDate == '') {
            errors.treatmentDate = 'Please enter treatment date.';
        }
        if (this.state.treatmentDescription == undefined || this.state.treatmentDescription == '') {
            errors.treatmentDescription = 'Please enter description.';
        }
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    AddPatientTreatment(e) {
        e.preventDefault();
        this.setState({ loading: true, showtreatmenterror: "" });

        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.validateTreatmentForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/BE_PatientTreatment/Save';

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                PatientAccessionId: parseInt(this.state.patientAccessionId),
                TreatmentDetail: this.state.treatmentDetail,
                TreatmentDate: this.state.treatmentDate,
                TeatmentDescription: this.state.treatmentDescription,
                PatientTreatmentReportId: parseInt(0),
                createdBy: uid,
                createdByFlag: "P"
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    if (this.state.treatmentdocumentFile != "") {
                        let dData = result.data.outdata;
                        this.filesUploadDocTreatment(dData.patientTreatmentReportId, result.data.message)
                        this.setState({

                            treatmentDetail: "",
                            treatmentDate: "",
                            treatmentDescription: "",
                            treatmentdocumentFile: "",

                        })
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            showtreatment: false,
                            treatmentDetail: "",
                            treatmentDate: "",
                            treatmentDescription: "",
                            treatmentdocumentFile: "",
                            PatientTreatmentReportId: parseInt(0),
                            createdBy: uid,
                            createdByFlag: "P"
                        });
                        this.getData();
                        toast.success(result.data.message)
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        showtreatmenterror: result.data.message
                    });
                }
            })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false,
                    });
                    toast.error(error.message)
                });
        }
        else {
            this.setState({ loading: false });
        }
    }
    EditPatientTreatment(e) {
        e.preventDefault();
        this.setState({ loading: true, showtreatmenterror: "" });

        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.validateTreatmentForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/BE_PatientTreatment/Update';

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                TreatmentDetail: this.state.treatmentDetail,
                TreatmentDate: this.state.treatmentDate,
                TeatmentDescription: this.state.treatmentDescription,
                PatientTreatmentReportId: Number(this.state.patientTreatmentReportId),
                createdBy: uid,
                createdByFlag: "P",
                PatientAccessionId: parseInt(this.state.patientAccessionId)
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    if (this.state.treatmentdocumentFile != "") {
                        let dData = result.data.outdata;
                        this.filesUploadDocTreatment(dData.patientTreatmentReportId, result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            showedittreatment: false,
                            treatmentDetail: "",
                            treatmentDate: "",
                            treatmentDescription: "",
                            treatmentdocumentFile: "",
                        });
                        this.getData();
                        toast.success(result.data.message)
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        showtreatmenterror: result.data.message
                    });
                }
            })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false,
                    });
                    toast.error(error.message)
                });
        }
        else {
            this.setState({ loading: false });
        }
    }
    DeletePatientTreatment() {
        this.setState({
            showdeletetreatment: false,
        })

        // var userToken = JSON.parse(localStorage.getItem("AUserToken"));
        // let userId = userToken.userId;

        // this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + "/api/BE_PatientTreatment/Delete?id=" + this.state.patientTreatmentReportId;

        axios
            .delete(url, {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            })
            .then((result) => {
                if (result.data.flag) {

                    toast.success(result.data.message);
                    this.getData();
                }
            })
            .catch((error) => {

                toast.error(error.message);
                this.setState({ authError: true, error: error });
            });
        this.setState({ loading: false });

    }
    filesUploadDocTreatment(id, msg) {
        const apiroute = window.$APIPath;
        let url = apiroute + '/api/BE_PatientTreatmentFile/Save?id=' + id + '';
        //alert(this.state.treatmentdocumentFile)
        let files = this.state.treatmentdocumentFile;
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append(`files[${i}]`, files[i])
        }
        axios.post(url, data, {
            // receive two    parameter endpoint url ,form data
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    // authError: true,
                    // errorType: 'success',
                    // error: msg,
                    loading: false,
                    showtreatment: false,
                    showedittreatment: false,
                    treatmentDate: '',
                    treatmentDescription: '',
                    treatmentDetail: ''

                });
                this.getData();
                toast.success(msg)
            }
            else {
                this.setState({
                    loading: false,
                    showtreatmenterror: result.data.message
                });
            }
        }).catch(error => {
            this.setState({
                // authError: true, errorType: 'danger', error: error.message, 
                loading: false,
            });
            toast.error(error.message)
        });
    }
    //end add treatment

    //file preview
    previewToggle(e, file) {
        this.setState({
            preview: !this.state.preview,
            url: file
        })
    }

    onError = (err) => console.log("Error:", err); // Write your own logic

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }
    handlePeptideCheckBox(e) {

        let target = e.target
        let name = target.name;
        let value = target.checked

        let peptideStatus = _.cloneDeep(this.state.peptideStatus)
        peptideStatus[name] = value;
        this.setState({ peptideStatus: peptideStatus })

    }
    handlePeptideDate(name, value) {
        console.log(value, "hbhhbhbhbhbhbhbhhbhb");
        let peptideStatus = this.state.peptideStatus
        peptideStatus[name] = value;
        this.setState({ peptideStatus: peptideStatus })
    }
    getReportName = (keyName) => {
        try {
            let report = this.state.patientReports[keyName]
            if (report) {
                let lindex = report.lastIndexOf("/")
                if (lindex !== -1) {
                    let length = report.length
                    return report.slice(Number(lindex) + 1, length)
                } else return `${keyName}_${this.state.patientAccessionId}_Report.pdf`
            } else return `${keyName}_${this.state.patientAccessionId}_Report.pdf`
        } catch (error) {
            return `${keyName}_${this.state.patientAccessionId}_Report.pdf`
        }
    }

    deleteDoc_Prescription(e, id) {
        e.preventDefault();

        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientPrescriptionFile/Delete?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        loading: false,
                        // modal: !this.state.modal,
                        // modalTitle: 'Success',
                        // modalBody: result.data.message
                    });
                    toast.success(result.data.message)

                    this.toggle_prescri_file()
                    this.getData();
                    const param = this.props.match.params;

                    // if (param.id != undefined) {
                    //     this.getData(param.id);
                    // }
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // modal: !this.state.modal,
                    // modalTitle: 'Error',
                    // modalBody: error.message
                    loading: false
                });
                toast.error(error.message)
                // this.setState({ loading: false, authError: true, error: error });
            });
    }

    deleteDoc_Treatment(e, id) {
        e.preventDefault();

        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientTreatmentFile/Delete?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        loading: false,
                        // modal: !this.state.modal,
                        // modalTitle: 'Success',
                        // modalBody: result.data.message
                    });
                    toast.success(result.data.message)
                    this.toggle_treatment_file()
                    this.getData();
                    const param = this.props.match.params;

                    // if (param.id != undefined) {
                    //     this.getData(param.id);
                    // }
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // modal: !this.state.modal,
                    // modalTitle: 'Error',
                    // modalBody: error.message
                    loading: false

                });
                toast.error(error.message)
                // this.setState({ loading: false, authError: true, error: error });
            });
    }
    Getdiseasedata() {
        let data = this.state?.Alldiseases?.filter((ele) => ele?.diseaseId == this.state?.diseases?.diseaseId);
        //  this.setState({ diseasecatname: value });
        return data[0]?.category
    }
    render() {
        const { loading, patientId, assignedlaboratory, assignedinstitution,
            diseases, diagnosticHistory, emergencyContact, insuranceDetail,
            prescription, treatmentReport, basicInfo, url, preview,
            patientaccessionmapping, patientAccessionId,
            Alldiseases, patientdiseaseId, showdisease,

            diagnosticAnalysis, diagnosticDate, outcome, diagnosticdocumentFile,
            showdiagnosticerror, showdiagnostic,

            prescriptionDescription, prescribeDate, prescriptiondocumentFile,
            prescriptiondocFiles, showprescriptionerror, showprescription,

            treatmentDescription, treatmentDetail, treatmentDate, treatmentdocumentFile,
            treatmentdocFiles, showtreatmenterror, showtreatment, assignedmanufacturer,

            peptideStatus, patientReports, peptides,
            errors, error, errorType, authError,

            FirstName, MiddleName, LastName, Email, CountryId, countries, StateId, states, CityId, cities, Mobile, Address, PhoneNumber, PostalCode,
            currentPage, currentIndex, pagesCount, pageSize, deleteId,
            InsuranceCompany, HealthPlan, MemberID, PolicyNumber, Address_Insurance, GroupNumber, PhoneNumber_Insurance,
            docFiles, DocumentFile, modal, modal_In, ErrDocumentFile,
            modal_In_file


        } = this.state;

        return (


            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                                <Link to="/practitioner/dashboard" className="kt-subheader__title">
                                                    Dashboard
                                                </Link>
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to={`/practitioner/patient/profile/${basicInfo.patientId}`} className="kt-subheader__breadcrumbs-link">
                                                    Patient Detail                        </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <div className="kt-widget kt-widget--user-profile-3">
                                                <div className="kt-widget__top">
                                                    {/*<div className="kt-widget__media">
                                                        <img src="./assets/media/users/100_1.jpg" alt="image" />
                                                    </div>
                                                    */}
                                                    <div className="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-bolder kt-font-light kt-hidden">JM</div>
                                                    <div className="kt-widget__content">
                                                        <div className="kt-widget__head">
                                                            <div className="kt-widget__user"> <Link className="kt-widget__username">{basicInfo.firstName != null ? (basicInfo.firstName + " " + basicInfo.lastName) : ""}{patientaccessionmapping.accessionNo != null ? " [" + patientaccessionmapping.accessionNo.replace(/-/g, "") + "]" : ""}</Link>
                                                                <span className="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success">{basicInfo.sex == "M" ? "Male" : basicInfo.sex == "F" ? "Female" : "-"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-widget__subhead"> <a href={`mailto: ${basicInfo.email}`}><i className="flaticon2-new-email"></i> {basicInfo.email != null ? basicInfo.email : ""}</a>
                                                            <Link><i className="flaticon2-calendar"></i>
                                                                {
                                                                    basicInfo.dateOfBirth != null && basicInfo.dateOfBirth != "" ?
                                                                        <React.Fragment>
                                                                            {moment(basicInfo.dateOfBirth).format('MM/DD/YYYY')}
                                                                            <span>{"(" + basicInfo.age + ")"}</span>
                                                                        </React.Fragment>
                                                                        : "NA"
                                                                }
                                                            </Link>
                                                            <Link><i className="flaticon2-phone"></i>
                                                                {basicInfo.mobile != null ? basicInfo.mobile + (basicInfo.phoneNumber != null ? ", " + basicInfo.phoneNumber : "") : "NA"}</Link>
                                                        </div>
                                                        {/* <div className="kt-widget__info">
                                                            <div className="kt-widget__desc">
                                                                <i className="flaticon2-placeholder">{basicInfo.address != null ? basicInfo.address + " - " + basicInfo.postalCode : "NA"}</i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*</div>*/}
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Basic Details</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form ">
                                                        {/* {authError ?
                                                            <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                                <div className="alert-text">{error}</div>
                                                                <div className="alert-close">
                                                                    <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                                </div>
                                                            </div>
                                                            : null} */}
                                                        <div className="kt-portlet__body">
                                                            <div className="form-group form-group-xs row">
                                                                <div className='col-4'>
                                                                    <label className=" col-form-label float-left kt-font-bolder">First Name:</label>
                                                                    <br />
                                                                    <span className="form-control-plaintext ">{basicInfo.firstName != null ? (basicInfo.firstName) : ""}</span>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <label className=" col-form-label float-left kt-font-bolder">Middle Name:</label>
                                                                    <br />
                                                                    <span className="form-control-plaintext ">{basicInfo.middleName != null ? (basicInfo.middleName) : ""}</span>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <label className=" col-form-label float-left kt-font-bolder">Last Name:</label>
                                                                    <br />
                                                                    <span className="form-control-plaintext ">{basicInfo.lastName != null ? (basicInfo.lastName) : ""}</span>
                                                                </div>
                                                            </div>
                                                            {/* <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Middle Name:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.middleName != null ? (basicInfo.middleName) : ""}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Last Name:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.lastName != null ? (basicInfo.lastName) : ""}</span>
                                                                </div>
                                                            </div> */}
                                                            <div className="form-group form-group-xs row">
                                                                <div className='col-4'>
                                                                    <label className=" col-form-label float-left kt-font-bolder">Date of Birth:</label>
                                                                    <br />
                                                                    <span className="form-control-plaintext ">
                                                                        {
                                                                            basicInfo.dateOfBirth != null && basicInfo.dateOfBirth != "" ?
                                                                                <React.Fragment>
                                                                                    {moment(basicInfo.dateOfBirth).format('MM/DD/YYYY')}

                                                                                </React.Fragment>
                                                                                : "NA"
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <label className=" col-form-label float-left kt-font-bolder">Age:</label>
                                                                    <br />
                                                                    <span className="form-control-plaintext ">{basicInfo.age + " Year(s)"}</span>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <label className=" col-form-label float-left kt-font-bolder">Sex:</label>
                                                                    <br />
                                                                    <span className="form-control-plaintext ">{basicInfo.sex == "M" ? "Male" : basicInfo.sex == "F" ? "Female" : "-"}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <div className='col-4'>
                                                                    <label className=" col-form-label float-left kt-font-bolder">Email:</label>
                                                                    <br />
                                                                    <a className="form-control-plaintext " href={`mailto: ${basicInfo.email}`}>{basicInfo.email != null ? (basicInfo.email) : ""}</a>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <label className=" col-form-label float-left kt-font-bolder">Primary Phone:</label>
                                                                    <br />
                                                                    <a className="form-control-plaintext  " href={`phoneto: ${basicInfo.mobile}`}>{basicInfo.mobile != null ? (basicInfo.mobile) : ""}</a>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <label className=" col-form-label float-left kt-font-bolder">Secondary Phone:</label>
                                                                    <br />
                                                                    <a className="form-control-plaintext" href={`phoneto: ${basicInfo.phoneNumber}`}>{basicInfo.phoneNumber != null ? (basicInfo.phoneNumber) : ""}</a>
                                                                </div>

                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <div className='col-12'>
                                                                    <label className=" col-form-label float-left kt-font-bolder">Address:</label>

                                                                    <br />

                                                                    <span className="form-control-plaintext">
                                                                        {!basicInfo.addressLine1
                                                                            ? ""
                                                                            : (basicInfo.addressLine1.match(/^\s+$/) === null ? (basicInfo.addressLine1 + ", ") : '')}

                                                                        <span className="">
                                                                            {basicInfo.city != null
                                                                                ? (basicInfo.city)
                                                                                : ""}
                                                                        </span>
                                                                        <span className="">
                                                                            {basicInfo.state != null
                                                                                ? (", " + basicInfo.state)
                                                                                : ""}
                                                                        </span>
                                                                        <span className="">
                                                                            {basicInfo.postalCode != null
                                                                                ? (", " + basicInfo.postalCode)
                                                                                : ""}
                                                                        </span>
                                                                        <span className="">
                                                                            {basicInfo.country != null
                                                                                ? (", " + basicInfo.country)
                                                                                : ""}
                                                                        </span>
                                                                    </span>
                                                                    {/* {basicInfo.addressLine1 != null ? (basicInfo.addressLine1 + ", ") : "NA"}
                                                                        {basicInfo.city != null ? (basicInfo.city) : ""}
                                                                        {basicInfo.state != null ? (", " + basicInfo.state) : ""}
                                                                        {(", " + basicInfo?.postalCode)}
                                                                        {basicInfo.country != null ? (", " + basicInfo.country) : ""}</span> */}

                                                                </div>
                                                            </div>



                                                            {/* <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Height:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.height != null ? (basicInfo.height) : ""}</span>
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Weight:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.weight != null ? (basicInfo.weight) : ""}</span>
                                                                </div>
                                                            </div> */}

                                                            {/* <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Address Line 2:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.addressLine2 != null ? basicInfo.addressLine2 + " - " + basicInfo.postalCode : "NA"}</span>
                                                                </div>
                                                            </div> */}




                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-xl-12">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Institution</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        <div className="kt-portlet__body">

                                                            {assignedinstitution != "" && assignedinstitution != null ?
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <label className="col-form-label">Name</label>
                                                                        <span className="form-control-plaintext kt-font-bolder">{assignedinstitution.instituteName}</span>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label className="col-form-label">Email</label>
                                                                        <span className="form-control-plaintext kt-font-bolder">{assignedinstitution.instituteEmail}</span>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label className="col-form-label">Phone</label>
                                                                        <span className="form-control-plaintext kt-font-bolder">{assignedinstitution.institutePhone}</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className="col-xl-12">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Laboratory</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        <div className="kt-portlet__body">
                                                            {assignedlaboratory.length > 0 ? (
                                                                assignedlaboratory
                                                                    .map((data, i) => {
                                                                        return (
                                                                            <div className="form-group form-group-xs row">
                                                                                <div className="col-4">
                                                                                    <label className="col-form-label">Name</label>
                                                                                    <span className="form-control-plaintext kt-font-bolder">{data.ngsLaboratoryName}</span>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <label className="col-form-label">Email</label>
                                                                                    <span className="form-control-plaintext kt-font-bolder">{data.ngsLaboratoryEmail}</span>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <label className="col-form-label">Phone</label>
                                                                                    <span className="form-control-plaintext kt-font-bolder">{data.ngsLaboratoryPhone}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }))
                                                                :
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className="col-xl-12">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Manufacture</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        <div className="kt-portlet__body">

                                                            {assignedmanufacturer != "" && assignedmanufacturer != null ?
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <label className="col-form-label">Name</label>
                                                                        <span className="form-control-plaintext kt-font-bolder">{assignedmanufacturer.manufacturerName}</span>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label className="col-form-label">Email</label>
                                                                        <span className="form-control-plaintext kt-font-bolder">{assignedmanufacturer.manufacturerEmail}</span>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label className="col-form-label">Phone</label>
                                                                        <span className="form-control-plaintext kt-font-bolder">{assignedmanufacturer.manufacturerPhone}</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {['with ctdna & cfdna testing', 'no ctdna & cfdna testing', 'cancer - no tumor', 'cancer with tumor'].includes(this.Getdiseasedata()?.toLowerCase().trim()) ? <>
                                                <div className="col-xl-12">
                                                    <div className="kt-portlet">
                                                        <div className="kt-portlet__head">
                                                            <div className="kt-portlet__head-label">
                                                                <h3 className="kt-portlet__head-title">
                                                                    Disease</h3>
                                                            </div>
                                                        </div>
                                                        <div className="kt-form kt-form--label-right">
                                                            <div className="kt-portlet__body">
                                                                {diseases != "" && diseases != null ?
                                                                    <div className="form-group form-group-xs row">
                                                                        <div className="col-4">
                                                                            <span className="form-control-plaintext">

                                                                                {diseases.diseaseName} {diseases.diseaseCode && " (" + diseases.diseaseCode + ")"} {diseases?.isMetastasis && <b style={{ color: "red" }}>- Metastasis</b>}
                                                                            </span>
                                                                        </div>
                                                                        <br />
                                                                        <div className="col-12">
                                                                            <span className="form-control-plaintext kt-font-bolder"> {diseases.tissue != null ? `Tissue - ${diseases.tissue}` : ""}</span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="form-group form-group-xs row">
                                                                        <div className="col-4">
                                                                            <Button className="btn btn-bold btn-label-brand btn-sm" onClick={() => this.handleShowDisease()}>
                                                                                Add Disease
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></> : null}
                                            <div className="col-xl-12">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title"> Neo7 Analysis Type</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        <div className="kt-portlet__body">
                                                            <div className="form-group form-group-xs row">
                                                                <div className="col-12">
                                                                    <span className="form-control-plaintext">

                                                                        {this.state.basicInfo?.patientDisease?.[0]?.parentDiseaseCategory ?
                                                                            `${this.state.basicInfo?.patientDisease?.[0]?.parentDiseaseCategory} - ${this.state.basicInfo?.patientDisease?.[0]?.diseaseCategory}` :
                                                                            `${this.state.basicInfo?.patientDisease?.[0]?.diseaseCategory}`
                                                                        }

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-xl-12">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Reports</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        <div className="kt-portlet__body">
                                                            {patientReports?.isPMRApproved && patientReports?.isPMRVerified ?
                                                                <>
                                                                    <table className="table table-borderless " style={{ width: "60%" }}>
                                                                        {/* <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">First</th>
                                  <th scope="col">Last</th>
                                  <th scope="col">Handle</th>
                                </tr>
                              </thead> */}

                                                                        <tbody className="m-2">
                                                                            {patientReports?.patientReport &&
                                                                                <tr className="px-2 d-flex justify-content-between  align-items-center ">
                                                                                    {/* <th scope="row"><i class="cil-document"></i>NB2100000_ITI_PES_Final_Report</th> */}
                                                                                    <td className="w-50" style={{ verticalAlign: "inherit", display: "flex", alignItems: "center" }}>
                                                                                        <i className="fa fa-file-pdf-o" style={{ margin: "5px" }}>
                                                                                        </i>{this.getReportName('patientReport')}
                                                                                        {/* {this.state.basicInfo?.patientReports?.patientReport ? this.state.basicInfo?.patientReports?.patientReport.} */}
                                                                                    </td>
                                                                                    {/* <td>Chrome HTML Document</td> */}
                                                                                    <td className="pr-4 ">
                                                                                        <a
                                                                                            href={patientReports?.pmrReport} download target={`_blank`} className="kt-widget4__title"
                                                                                            data-toggle="tooltip" data-html="true" data-placement="right" title="View Patient Report"
                                                                                            onClick={() => {
                                                                                                // this.previewPdfToggle(this.state.basicInfo.patientReports?.patientReport)
                                                                                            }}
                                                                                        >
                                                                                            <i className="flaticon2-download" aria-hidden="true"></i><span ></span></a>
                                                                                    </td>
                                                                                </tr>
                                                                            }

                                                                            {patientReports?.pmrReport &&
                                                                                <tr className="px-2 d-flex justify-content-between align-items-center " >
                                                                                    {/* <th scope="row"><i class="cil-document"></i>NB2100000_ITI_PES_Final_Report</th> */}
                                                                                    <td style={{ verticalAlign: "inherit", display: "flex", alignItems: "center" }}>
                                                                                        <i className="fa fa-file-pdf-o" style={{ margin: "5px" }}>
                                                                                        </i>
                                                                                        <div className="" >
                                                                                            {this.getReportName('pmrReport')}
                                                                                        </div>
                                                                                    </td>
                                                                                    {/* <td>Chrome HTML Document</td> */}
                                                                                    <td className="pr-4">
                                                                                        <a
                                                                                            href={patientReports?.pmrReport} download target={`_blank`} className="kt-widget4__title"

                                                                                            data-toggle="tooltip" data-html="true" data-placement="right" title="Download PMR Report"

                                                                                        >  <i className="flaticon2-download" aria-hidden="true"></i><span ></span></a>

                                                                                    </td>

                                                                                </tr>
                                                                            }
                                                                            {patientReports?.healthIndexReport &&
                                                                                <tr className="px-2 d-flex justify-content-between align-items-center ">
                                                                                    {/* <th scope="row"><i class="cil-document"></i>NB2100000_ITI_PES_Final_Report</th> */}
                                                                                    <td style={{ verticalAlign: "inherit", display: "flex", alignItems: "center" }}>
                                                                                        <i className="fa fa-file-pdf-o mr-2" style={{ margin: "5px" }}></i> {this.getReportName('healthIndexReport')}</td>
                                                                                    {/* <td>Chrome HTML Document</td> */}
                                                                                    <td className="pr-4">

                                                                                        {/* <a > */}
                                                                                        {/* {dataFile.fileName} */}
                                                                                        {/* </a> */}
                                                                                        <a
                                                                                            href={patientReports?.healthIndexReport} download target={`_blank`} className="kt-widget4__title"
                                                                                            // className="btn btn-success float-right "
                                                                                            data-toggle="tooltip" data-html="true" data-placement="right" title="Download Health Index Report"
                                                                                            onClick={() => {
                                                                                                // this.previewPdfToggle(this.state.basicInfo.patientReports.healthIndexReport)
                                                                                            }}
                                                                                        ><i className="flaticon2-download" aria-hidden="true"></i><span ></span></a></td>
                                                                                </tr>
                                                                            }
                                                                        </tbody>
                                                                    </table>

                                                                </> : "No Reports to Show"}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Peptide Status</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        <div className="kt-portlet__body">
                                                            <FormGroup check row>
                                                                <Row style={{ marginBottom: "3px" }}>
                                                                    <Col xs="1">
                                                                        <Input
                                                                            id="peptide_sentToClinic"
                                                                            className="ml-0"
                                                                            disabled
                                                                            checked={peptideStatus?.pmrSendToCilnic}
                                                                            type="checkbox"
                                                                            onChange={this.handlePeptideCheckBox.bind(this)}
                                                                            name='pmrSendToCilnic'
                                                                        />
                                                                    </Col>
                                                                    <Col xs="5" style={{ paddingTop: "0px" }}>
                                                                        <Label className="form-check-label" htmlFor='peptide_sentToClinic'>
                                                                            PMR Sent To Clinic
                                                                        </Label>
                                                                    </Col>
                                                                    <Col xs="3">
                                                                        <div className={`cus-date-picker `}>
                                                                            {/* {alert(this.getDateForPeptide(peptideStatus?.pmrSendToCilnicDate))} */}
                                                                            <DatePicker
                                                                                defaultValue=""
                                                                                dateFormat="MM/dd/yyyy"
                                                                                placeholderText="mm/dd/yyyy"
                                                                                disabled
                                                                                className="form-control here "
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                name="pmrSendToCilnicDate"
                                                                                selected={this.getDateForPeptide('pmrSendToCilnicDate')}
                                                                                onChange={(e) => this.handlePeptideDate('pmrSendToCilnicDate', e)}
                                                                                dropdownMode="select"
                                                                                fixedHeight
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs="3">
                                                                        {/* <button
                                                                            className='btn btn-primary btn-md'
                                                                            onClick={this.handlePmrSentToClinicSUbmit}
                                                                            disabled={!peptideStatus?.pmrSendToCilnic || !peptideStatus?.pmrSendToCilnicDate}
                                                                        >
                                                                            <i className="fa fa-dot-circle-o text-light"></i> {this.state?.basicInfo?.peptideStatus?.pmrSendToCilnic && !this.state.basicInfo?.peptideStatus.clinicSendPMRToPharmacy ? "Update" : "Submit"}
                                                                        </button> */}
                                                                    </Col>
                                                                    <Col xs="1">
                                                                        <Input
                                                                            disabled={!this.state?.basicInfo?.peptideStatus?.pmrSendToCilnic || this.state.basicInfo?.peptideStatus?.peptidesReceivedByClinic}
                                                                            checked={peptideStatus?.clinicSendPMRToPharmacy}
                                                                            onChange={this.handlePeptideCheckBox.bind(this)}
                                                                            id='peptide_csendtophr'
                                                                            className="ml-0"
                                                                            type="checkbox"
                                                                            name="clinicSendPMRToPharmacy"
                                                                        />
                                                                    </Col>
                                                                    <Col xs="5" style={{ paddingTop: "0px" }}>
                                                                        <Label className="form-check-label" htmlFor='peptide_csendtophr'>
                                                                            Clinic sent PMR TO Pharmacy
                                                                        </Label>
                                                                    </Col>
                                                                    <Col xs="3 pt-2">
                                                                        <div className={`cus-date-picker `}>
                                                                            <DatePicker
                                                                                name="clinicSendPMRToPharmacyDate"
                                                                                disabled={this.isPeptideDisabled('clinicSendPMRToPharmacy', 'peptidesReceivedByClinic', 'pmrSendToCilnic')}
                                                                                onChange={(e) => this.handlePeptideDate('clinicSendPMRToPharmacyDate', e)}
                                                                                // selected={peptideStatus?.clinicSendPMRToPharmacyDate || ""}
                                                                                selected={this.getDateForPeptide('clinicSendPMRToPharmacyDate')}
                                                                                minDate={new Date(this.state?.basicInfo?.peptideStatus?.pmrSendToCilnicDate) || ''}
                                                                                defaultValue=""
                                                                                dateFormat="MM/dd/yyyy"
                                                                                placeholderText="mm/dd/yyyy"
                                                                                className="form-control here "
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                fixedHeight
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs="3 pt-2">
                                                                        <button
                                                                            disabled={!this.state?.basicInfo?.peptideStatus?.pmrSendToCilnic || this.state.basicInfo?.peptideStatus?.peptidesReceivedByClinic}
                                                                            // className='btn btn-primary btn-md'
                                                                            className={`btn btn-${this.state?.basicInfo?.peptideStatus?.clinicSendPMRToPharmacy && !this.state.basicInfo?.peptideStatus?.peptidesReceivedByClinic ? "success" : "primary"} btn-md `}
                                                                            onClick={this.handleClinicSentPmrToPharmacy}
                                                                        // disabled={!peptideStatus?.clinicSendPMRToPharmacyDate || !peptideStatus?.clinicSendPMRToPharmacy}
                                                                        // disabled={!peptideStatus?.clinicSendPMRToPharmacy || peptides?.clinicSendPMRToPharmacy || peptideStatus?.clinicSendPMRToPharmacyDate}
                                                                        // disabled={!peptideStatus?.clinicSendPMRToPharmacyDate || !peptideStatus?.clinicSendPMRToPharmacy}
                                                                        >
                                                                            <i className="fa fa-dot-circle-o text-light"></i> {this.state?.basicInfo?.peptideStatus?.clinicSendPMRToPharmacy && !this.state.basicInfo?.peptideStatus?.peptidesReceivedByClinic ? "Update" : "Submit"}
                                                                        </button>
                                                                    </Col>
                                                                    <Col xs="1">


                                                                        <Input
                                                                            id='PEP_RecByClinic'
                                                                            checked={peptideStatus?.peptidesReceivedByClinic}
                                                                            disabled={!this.state?.basicInfo?.peptideStatus?.clinicSendPMRToPharmacy || this.state.basicInfo?.peptideStatus?.treatmentStarted}
                                                                            className="ml-0"
                                                                            type="checkbox"
                                                                            onChange={this.handlePeptideCheckBox.bind(this)}
                                                                            name='peptidesReceivedByClinic'

                                                                        />
                                                                    </Col>
                                                                    <Col xs="5" style={{ paddingTop: "0px" }}>
                                                                        <Label className="form-check-label" htmlFor='PEP_RecByClinic'>
                                                                            Peptides Received By Clinic
                                                                        </Label>
                                                                    </Col>
                                                                    <Col xs="3 pt-2">

                                                                        <div className={`cus-date-picker `}>
                                                                            <DatePicker
                                                                                onChange={(e) => this.handlePeptideDate('peptidesReceivedByClinicDate', e)}
                                                                                defaultValue=""
                                                                                minDate={new Date(this.state?.basicInfo?.peptideStatus?.clinicSendPMRToPharmacyDate) || ''}

                                                                                disabled={this.isPeptideDisabled('peptidesReceivedByClinic', 'treatmentStarted', 'clinicSendPMRToPharmacy')}
                                                                                // selected={peptideStatus?.peptidesReceivedByClinicDate || ""}
                                                                                selected={this.getDateForPeptide('peptidesReceivedByClinicDate')}
                                                                                dateFormat="MM/dd/yyyy"
                                                                                placeholderText="mm/dd/yyyy"
                                                                                className="form-control here "
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                fixedHeight
                                                                            />
                                                                        </div>
                                                                    </Col>{" "}
                                                                    <Col xs="3 pt-2">
                                                                        <button
                                                                            disabled={!this.state?.basicInfo?.peptideStatus?.clinicSendPMRToPharmacy || this.state.basicInfo?.peptideStatus?.treatmentStarted}
                                                                            className={`btn btn-${this.state?.basicInfo?.peptideStatus?.peptidesReceivedByClinic && !this.state.basicInfo?.peptideStatus.treatmentStarted ? "success" : "primary"} btn-md`}
                                                                            onClick={this.handlePeptidesReceviedByclinic}

                                                                        >
                                                                            <i className="fa fa-dot-circle-o text-light"></i> {this.state?.basicInfo?.peptideStatus?.peptidesReceivedByClinic && !this.state.basicInfo?.peptideStatus.treatmentStarted ? "Update" : "Submit"}
                                                                        </button>
                                                                    </Col>
                                                                    <Col xs="1">
                                                                        <Input
                                                                            onChange={this.handlePeptideCheckBox.bind(this)}
                                                                            checked={peptideStatus?.treatmentStarted || false}
                                                                            disabled={!this.state?.basicInfo?.peptideStatus?.peptidesReceivedByClinic || this.state.basicInfo?.peptideStatus?.treatmentStarted}
                                                                            name='treatmentStarted'
                                                                            id="peptide_treatmntstrt"
                                                                            className="ml-0"
                                                                            type="checkbox"



                                                                        />
                                                                    </Col>
                                                                    <Col xs="5" style={{ paddingTop: "0px" }}>
                                                                        <Label className="form-check-label" htmlFor='peptide_treatmntstrt'>
                                                                            Treatment Started
                                                                        </Label>
                                                                    </Col>
                                                                    <Col xs="3 pt-2">
                                                                        {" "}
                                                                        <div className={`cus-date-picker `}>

                                                                            {/* {console.log({ pep: peptides?.treatmentStarted, ap: peptideStatus?.treatmentStarted })} */}
                                                                            <DatePicker
                                                                                minDate={new Date(this.state?.basicInfo?.peptideStatus?.peptidesReceivedByClinicDate) || ''}
                                                                                disabled={peptides?.treatmentStarted || !peptideStatus?.treatmentStarted}
                                                                                // selected={peptideStatus?.treatmentStartedDate || ""}
                                                                                selected={this.getDateForPeptide('treatmentStartedDate')}
                                                                                className="form-control"
                                                                                defaultValue=""

                                                                                dateFormat="MM/dd/yyyy"
                                                                                placeholderText="mm/dd/yyyy"
                                                                                onChange={(e) => this.handlePeptideDate('treatmentStartedDate', e)}
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                fixedHeight
                                                                            />
                                                                        </div>
                                                                    </Col>{" "}
                                                                    <Col xs="3 pt-2">
                                                                        {console.log({
                                                                            pep: peptideStatus?.treatmentStarted,
                                                                            ap: peptideStatus?.treatmentStartedDate,
                                                                            e: _.isEmpty(peptideStatus?.treatmentStartedDate)
                                                                        })}
                                                                        <button
                                                                            className="btn btn-primary btn-md"
                                                                            disabled={!peptideStatus?.treatmentStarted || !_.isDate(peptideStatus?.treatmentStartedDate)}
                                                                            // disabled={!peptides?.treatMentStarted || peptideStatus?.treatmentStartedDate === '' || peptides?.treatmentStarted}
                                                                            onClick={this.handlePeptidesTreatmentStarted}
                                                                        >
                                                                            <i className="fa fa-dot-circle-o text-light"></i> Submit
                                                                        </button>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-12">
                                                <div className="kt-portlet kt-portlet--tabs">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-toolbar">
                                                            <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand" role="tablist">
                                                                <li className="nav-item">
                                                                    <a className="nav-link active" data-toggle="tab" href="#kt_apps_contacts_view_tab_1" role="tab">Diagnostics</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_2" role="tab">Prescription</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_3" role="tab">Treatments/Visit</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_4" role="tab">Emergency Contacts</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_5" role="tab">Insurances</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="kt-portlet__body">
                                                        <div className="tab-content">
                                                            <div className="tab-pane active" id="kt_apps_contacts_view_tab_1" role="tabpanel">
                                                                <div className="kt-notes__media">
                                                                    <span className="kt-notes__icon">
                                                                        <Button className="btn btn-bold btn-label-brand btn-sm" style={{ "marginBottom": "5px" }} onClick={() => this.handleShowDiagnostic()}>
                                                                            Add </Button>
                                                                    </span>
                                                                </div>
                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                    <div className="kt-notes__items">
                                                                        {diagnosticHistory.length > 0 ? (
                                                                            diagnosticHistory
                                                                                .map((data, i) => {
                                                                                    return (
                                                                                        <div className="kt-notes__item">
                                                                                            <div className="kt-notes__media">
                                                                                                <span className="kt-notes__icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                                                            <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fillRule="nonzero"></path>
                                                                                                            <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "></path>
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="kt-notes__content">
                                                                                                <div className="date-info">
                                                                                                    <span className="kt-notes__desc">
                                                                                                        <Moment format="MM/DD/YYYY">
                                                                                                            {data.diagnosticDate}
                                                                                                        </Moment>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="kt-notes__section">

                                                                                                    {/*<div className="kt-notes__info"> <p className="kt-notes__title">MRI
																		<br />Lumbo Sacral Spine<br />Findings</p>
                                                                                    </div>*/}
                                                                                                </div> <span className="kt-notes__body">
                                                                                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", float: "left" }}>
                                                                                                        {data.diagnosticAnalysis}

                                                                                                    </div>
                                                                                                    <a style={{
                                                                                                        display: data.diagnosticAnalysis?.length >= 24 ? "block" : "none",
                                                                                                        background: "none", textDecoration: "underline", color: "blue", cursor: "pointer"

                                                                                                    }}
                                                                                                        onClick={() => { this.setState({ analysisModal: true, analysisText: data.diagnosticAnalysis, analysisHeader: "Diagnostics - Analysis" }) }}>view more</a>
                                                                                                </span>
                                                                                                <br />
                                                                                                <div className="pt-2 note-dtails-steps"><span className="kt-widget1__number kt-font-danger float-left">Outcome :</span>

                                                                                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", float: "left" }}>
                                                                                                        &nbsp; 12211  {data.outcome}
                                                                                                    </div>
                                                                                                    <a style={{
                                                                                                        display: data.outcome?.length >= 24 ? "block" : "none",
                                                                                                        background: "none", textDecoration: "underline", color: "blue", cursor: "pointer"

                                                                                                    }}
                                                                                                        onClick={() => { this.setState({ analysisModal: true, analysisText: data.outcome, analysisHeader: "Diagnostics - Outcome" }) }}>view more</a>
                                                                                                </div>
                                                                                                <div className="kt-widget4">
                                                                                                    <br />
                                                                                                    {data.patientDiagnosticHistoryFile.length > 0 ? (
                                                                                                        data.patientDiagnosticHistoryFile
                                                                                                            .map((dataFile, i) => {
                                                                                                                return (
                                                                                                                    <div className="kt-widget4__item" key={dataFile.patientDiagnosticFileId} onClick={e => {

                                                                                                                        // this.previewToggle(e, dataFile.filePath);
                                                                                                                    }}>
                                                                                                                        <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                                                            <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />
                                                                                                                        </div>
                                                                                                                        <a href={window.$FileUrl + dataFile.filePath} download target={`_blank`} className="kt-widget4__title">
                                                                                                                            {dataFile.fileName}
                                                                                                                        </a>
                                                                                                                        {/* <a className="kt-widget4__title">
                                                                                                                            {dataFile.fileName}
                                                                                                                        </a> */}
                                                                                                                        {/*<div className="kt-widget4__tools">
                                                                                                                                <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">
                                                                                                                                    <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>
                                                                                                                                </a>
                                                                                                                            </div>*/}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })) : ("No files...")}
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    )
                                                                                })) : (
                                                                            <span className="kt-notes__body">No diagnostics found.</span>
                                                                        )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_2" role="tabpanel">
                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                    <div>
                                                                        <Button className="btn btn-bold btn-label-brand btn-sm btn-fr" onClick={() => this.handleShowPrescription()}>
                                                                            Add </Button>
                                                                    </div>
                                                                    <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Prescription Date</th>
                                                                                <th>Description</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {prescription.length > 0 ? (
                                                                                prescription
                                                                                    .map((data, i) => {
                                                                                        return (
                                                                                            <React.Fragment>
                                                                                                <tr key={i} onClick={this.toggleExpander}>
                                                                                                    <td><Moment format="MM/DD/YYYY">{data.prescribeDate}</Moment></td>
                                                                                                    <td >

                                                                                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", float: "left" }}>
                                                                                                            {data.prescriptionDescription}
                                                                                                        </div>
                                                                                                        <a style={{
                                                                                                            display: data.prescriptionDescription?.length >= 24 ? "block" : "none",
                                                                                                            background: "none", textDecoration: "underline", color: "blue", cursor: "pointer"

                                                                                                        }}
                                                                                                            onClick={() => { this.setState({ analysisModal: true, analysisText: data.prescriptionDescription, analysisHeader: "Prescription - Description" }) }}>view more</a>
                                                                                                    </td>
                                                                                                    <td className="tblexpand" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", }}>
                                                                                                        {/* <img style={{ "width": "80%" }} src="./assets/media/icons/svg/Files/direct-download.svg" alt="" title="View Files" /> */}
                                                                                                        <svg id="color" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.456-.477-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .998.791.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z" fill="#00bcd4" />
                                                                                                            <path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z" fill="#607d8b" />
                                                                                                            <path d="m12 2h-1.25c-.689 0-1.25.561-1.25 1.25v5.75h-2.75c-.659 0-.998.791-.542 1.268l5.25 5.5c.141.148.337.232.542.232z" fill="#00a4b9" />
                                                                                                            <path d="m12 18h-10.25c-.965 0-1.75.785-1.75 1.75v.5c0 .965.785 1.75 1.75 1.75h10.25z" fill="#546d79" />
                                                                                                        </svg>
                                                                                                        <Link to="#" className=''
                                                                                                            onClick={() => {
                                                                                                                this.setState({
                                                                                                                    showeditprescription: true,
                                                                                                                    prescriptionDescription: data.prescriptionDescription,
                                                                                                                    prescribeDate: new Date(data.prescribeDate),
                                                                                                                    patientPrescriptionId: data.patientPrescriptionId
                                                                                                                })
                                                                                                            }}
                                                                                                        >
                                                                                                            <Button outline color="info" size="sm"><i className="flaticon2-edit" />
                                                                                                                Edit
                                                                                                            </Button>
                                                                                                        </Link>


                                                                                                        <Link to="#" onClick={(e) => { this.setState({ showdeleteprescription: true, patientPrescriptionId: data.patientPrescriptionId }) }}>
                                                                                                            <Button outline color="danger" size="sm"><i className="flaticon2-delete" />
                                                                                                                Delete
                                                                                                            </Button>
                                                                                                        </Link>


                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr className="collapse" key={data.patientPrescriptionId}>
                                                                                                    <td className="kt-widget4" colSpan="3">
                                                                                                        {data.patientPrescriptionFile.length > 0 ? (
                                                                                                            data.patientPrescriptionFile
                                                                                                                .map((dataFile, i) => {
                                                                                                                    return (
                                                                                                                        <div className="kt-widget4__item" key={dataFile.patientPrescriptionFileId} onClick={e => {
                                                                                                                            // return this.previewToggle(e, dataFile.filePath);
                                                                                                                        }}>
                                                                                                                            <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                                                                <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />
                                                                                                                            </div>
                                                                                                                            <a href={window.$FileUrl + dataFile.filePath} download target={`_blank`} className="kt-widget4__title">
                                                                                                                                {dataFile.fileName}
                                                                                                                            </a>
                                                                                                                            {/* <a className="kt-widget4__title">
                                                                                                                                {dataFile.fileName}
                                                                                                                            </a> */}
                                                                                                                            {/*<div className="kt-widget4__tools">
                                                                                                                                <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">
                                                                                                                                    <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>
                                                                                                                                </a>
                                                                                                                            </div>*/}
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })) : ("No files...")}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })) : (
                                                                                <tr>
                                                                                    <td colSpan="2" className="tdCenter">No prescription found.</td></tr>
                                                                            )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_3" role="tabpanel">
                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                    <div>
                                                                        <Button className="btn btn-bold btn-label-brand btn-sm btn-fr" onClick={() => this.handleShowTreatment()}>
                                                                            Add </Button>
                                                                    </div>
                                                                    <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Treatment Date</th>
                                                                                <th>Detail</th>
                                                                                <th>Description</th>
                                                                                <th>action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {treatmentReport.length > 0 ? (
                                                                                treatmentReport
                                                                                    .map((data, i) => {
                                                                                        return (
                                                                                            <React.Fragment>
                                                                                                <tr key={i} onClick={this.toggleExpander}>
                                                                                                    <td><Moment format="MM/DD/YYYY">{data.treatmentDate}</Moment></td>
                                                                                                    <td >
                                                                                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", float: "left" }}>
                                                                                                            {data.treatmentDetail}

                                                                                                        </div>
                                                                                                        <a style={{
                                                                                                            display: data.treatmentDetail?.length >= 24 ? "block" : "none",
                                                                                                            background: "none", textDecoration: "underline", color: "blue", cursor: "pointer"

                                                                                                        }}
                                                                                                            onClick={() => { this.setState({ analysisModal: true, analysisText: data.treatmentDetail, analysisHeader: "Treatments/Visit - Detail" }) }}>view more</a>
                                                                                                    </td>
                                                                                                    <td >
                                                                                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", float: "left" }}>
                                                                                                            {data.teatmentDescription}
                                                                                                        </div>
                                                                                                        <a style={{
                                                                                                            display: data.teatmentDescription?.length >= 24 ? "block" : "none",
                                                                                                            background: "none", textDecoration: "underline", color: "blue", cursor: "pointer"

                                                                                                        }}
                                                                                                            onClick={() => { this.setState({ analysisModal: true, analysisText: data.teatmentDescription, analysisHeader: "Treatments/Visit - Description" }) }}>view more</a>
                                                                                                    </td>
                                                                                                    <td>

                                                                                                        {/* <img style={{ "width": "80%" }} src="./assets/media/icons/svg/Files/direct-download.svg" alt="" title="View Files" /> */}
                                                                                                        <svg id="color" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.456-.477-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .998.791.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z" fill="#00bcd4" />
                                                                                                            <path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z" fill="#607d8b" />
                                                                                                            <path d="m12 2h-1.25c-.689 0-1.25.561-1.25 1.25v5.75h-2.75c-.659 0-.998.791-.542 1.268l5.25 5.5c.141.148.337.232.542.232z" fill="#00a4b9" />
                                                                                                            <path d="m12 18h-10.25c-.965 0-1.75.785-1.75 1.75v.5c0 .965.785 1.75 1.75 1.75h10.25z" fill="#546d79" />
                                                                                                        </svg>
                                                                                                        <Link to="#" onClick={() => {
                                                                                                            this.setState({
                                                                                                                showedittreatment: true,
                                                                                                                treatmentDate: new Date(data.treatmentDate),
                                                                                                                treatmentDetail: data.treatmentDetail,
                                                                                                                treatmentDescription: data.teatmentDescription,
                                                                                                                // dataFiles: data.patientTreatmentReportFile.map(dataFile => dataFile.filePath),
                                                                                                                patientTreatmentReportId: data.patientTreatmentReportId
                                                                                                            })
                                                                                                        }}><Button outline color="info" size="sm"><i className="flaticon2-edit" />Edit</Button></Link>{" "}
                                                                                                        <Link to="#" onClick={(e) => { this.setState({ showdeletetreatment: true, patientTreatmentReportId: data.patientTreatmentReportId }); }}><Button outline color="danger" size="sm"><i className="flaticon2-delete" />Delete</Button></Link>





                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr className="collapse" key={data.patientTreatmentReportId}>
                                                                                                    <td className="kt-widget4" colSpan="4">
                                                                                                        {data.patientTreatmentReportFile.length > 0 ? (
                                                                                                            data.patientTreatmentReportFile
                                                                                                                .map((dataFile, i) => {
                                                                                                                    return (
                                                                                                                        <div className="kt-widget4__item" key={dataFile.patientTreatmentreportFileId} onClick={e => {
                                                                                                                            // return this.previewToggle(e, dataFile.filePath);
                                                                                                                        }}>
                                                                                                                            <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                                                                <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />
                                                                                                                            </div>
                                                                                                                            {/* <a className="kt-widget4__title">
                                                                                                                                {dataFile.fileName}
                                                                                                                            </a> */}
                                                                                                                            <a href={window.$FileUrl + dataFile.filePath} download target={`_blank`} className="kt-widget4__title">
                                                                                                                                {dataFile.fileName}
                                                                                                                            </a>
                                                                                                                            {/*<div className="kt-widget4__tools">
                                                                                                                                <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">
                                                                                                                                    <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>
                                                                                                                                </a>
                                                                                                                            </div>*/}
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })) : ("No files...")}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })) : (
                                                                                <tr>
                                                                                    <td colSpan="3" className="tdCenter">No treatment found.</td></tr>
                                                                            )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_4" role="tabpanel">
                                                                <div className="kt-notes kt-scroll kt-scroll--pull " data-scroll="true" style={{ "height": "650px" }}>
                                                                    <div>
                                                                        <Button className="btn btn-bold btn-label-brand btn-sm btn-fr" onClick={() => this.handleShowEmergencyContact()}>
                                                                            Add </Button>
                                                                    </div>
                                                                    <table className="table table-striped- table-bordered table-hover table-checkable " id="kt_table_1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Email</th>
                                                                                <th>Phone</th>
                                                                                <th>Address</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {console.log("eE", emergencyContact)}
                                                                            {emergencyContact.length > 0 ? (
                                                                                emergencyContact
                                                                                    .map((data, i, arr) => {
                                                                                        return (<tr key={i}>
                                                                                            <td>



                                                                                                {data.firstName + " " + data.lastName}</td>
                                                                                            <td>{data.email}</td>
                                                                                            <td>{data.mobile} <br />{data.phoneNumber}</td>
                                                                                            <td>
                                                                                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100px", float: "left" }}>  {data.address}
                                                                                                </div>
                                                                                                <a style={{
                                                                                                    display: data.address?.length >= 12 ? "block" : "none",
                                                                                                    background: "none", textDecoration: "underline", color: "blue", cursor: "pointer"

                                                                                                }}
                                                                                                    onClick={() => { this.setState({ analysisModal: true, analysisText: data.address, analysisHeader: "Emergency Contacts - Address" }) }}>view more</a>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Link to="#" onClick={e => this.toggle(e, data.patientEmergencyContactId)}><Button className="mr-2" outline color="danger" size="sm"><i className="flaticon2-delete" />Delete</Button></Link>
                                                                                                <Link to="#" onClick={e => this.handleShowEditEmergencyContactPopup(e, data.patientEmergencyContactId, data.firstName, data.lastName, data.middleName, data.email, data.mobile, data.phoneNumber, data.address, data.postalCode, data.countryId, data.stateId, data.cityId)}><Button outline color="info" size="sm"><i className="flaticon2-edit" />Edit</Button></Link>{" "}



                                                                                            </td>
                                                                                        </tr>
                                                                                        )
                                                                                    })) : (
                                                                                <tr>
                                                                                    <td colSpan="4" className="tdCenter">No emergency contacts found.</td>
                                                                                </tr>
                                                                            )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_5" role="tabpanel">
                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                    <div>
                                                                        <Button className="btn btn-bold btn-label-brand btn-sm btn-fr" onClick={() => this.handleShowInsurance()}>
                                                                            Add </Button>
                                                                    </div>
                                                                    <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Insurance Company</th>
                                                                                <th>Policy Number</th>
                                                                                <th>Health Plan</th>
                                                                                <th>Member ID</th>
                                                                                <th>Group Number</th>
                                                                                <th>Phone</th>
                                                                                <th>Address</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {console.log("InsuranceDetails::", insuranceDetail)}
                                                                            {insuranceDetail.length > 0 ? (
                                                                                insuranceDetail
                                                                                    .map((data, i) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr key={i} onClick={this.toggleExpander}>
                                                                                                    <td>{data.insuranceCompany}</td>
                                                                                                    <td>{data.policyNumber}</td>
                                                                                                    <td>
                                                                                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100px", float: "left" }}>
                                                                                                            {data.healthPlan}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100px", float: "left" }}>
                                                                                                            {data.memberID}
                                                                                                        </div>
                                                                                                        <br />
                                                                                                        <a style={{
                                                                                                            display: data.memberID?.length >= 12 ? "block" : "none",
                                                                                                            background: "none", textDecoration: "underline", color: "blue", cursor: "pointer"

                                                                                                        }}
                                                                                                            onClick={() => { this.setState({ analysisModal: true, analysisText: data.memberID, analysisHeader: "Insurances - Member ID" }) }}>view more</a>
                                                                                                    </td>
                                                                                                    <td>{data.groupNumber}</td>
                                                                                                    <td>{data.phoneNumber}</td>
                                                                                                    <td>
                                                                                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100px", float: "left" }}>
                                                                                                            {data.address}
                                                                                                        </div>
                                                                                                        <br />
                                                                                                        <a style={{
                                                                                                            display: data.address?.length >= 12 ? "block" : "none",
                                                                                                            background: "none", textDecoration: "underline", color: "blue", cursor: "pointer"

                                                                                                        }}
                                                                                                            onClick={() => { this.setState({ analysisModal: true, analysisText: data.address, analysisHeader: "Insurances - Address" }) }}>view more</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Link to="#" onClick={e => this.toggle_In(e, data.patientInsuranceId)}><Button outline className="mr-2" color="danger" size="sm"><i className="flaticon2-delete" />Delete</Button></Link>
                                                                                                        <Link to="#" onClick={e => this.handleShowEditInsurancePopup(e, data.patientInsuranceId, data.insuranceCompany, data.policyNumber, data.healthPlan, data.memberID, data.groupNumber, data.phoneNumber, data.address)}><Button outline color="info" size="sm"><i className="flaticon2-edit" />Edit</Button></Link>{" "}


                                                                                                    </td>
                                                                                                </tr>

                                                                                                <tr className="collapse" key={data.patientInsuranceId}>
                                                                                                    <td className="kt-widget4" colSpan="5">
                                                                                                        {data?.patientInsuranceFile?.length > 0 ? (
                                                                                                            data?.patientInsuranceFile
                                                                                                                .map((dataFile, i) => {
                                                                                                                    return (
                                                                                                                        <div className="kt-widget4__item" key={dataFile.patientInsuranceFileId} onClick={e => {
                                                                                                                            // return this.previewToggle(e, dataFile.filePath);
                                                                                                                        }}>
                                                                                                                            <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                                                                <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />
                                                                                                                            </div>
                                                                                                                            <a href={window.$FileUrl + dataFile.filePath} download target={`_blank`} className="kt-widget4__title">
                                                                                                                                {dataFile.fileName}
                                                                                                                            </a>
                                                                                                                            {/* <a className="kt-widget4__title">
                            {dataFile.fileName}
                        </a> */}
                                                                                                                            {/*<div className="kt-widget4__tools">
                            <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">
                                <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>
                            </a>
                        </div>*/}
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })) : ("No files...")}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </>

                                                                                        )
                                                                                    })) : (
                                                                                <tr>
                                                                                    <td colSpan="7" className="tdCenter">No insurance detail found.</td>
                                                                                </tr>
                                                                            )
                                                                            }
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                                {modal &&
                                                                    <div style={{ marginLeft: "36%" }}>
                                                                        <Modal isOpen={modal} style={{ width: "500px" }} >
                                                                            <ModalHeader>Confirm</ModalHeader>
                                                                            <ModalBody>Are you sure want to delete this emergency contact?</ModalBody>
                                                                            <ModalFooter>
                                                                                <Button outline color="danger" onClick={e => this.deleteRowEmergencyContact(e, deleteId)}>Delete</Button>
                                                                                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                                                            </ModalFooter>
                                                                        </Modal>
                                                                    </div>
                                                                }
                                                                {modal_In &&
                                                                    <div style={{ marginLeft: "36%" }}>
                                                                        <Modal isOpen={modal_In} style={{ width: "500px" }} >
                                                                            <ModalHeader>Confirm</ModalHeader>
                                                                            <ModalBody>Are you sure want to delete this insurance details?</ModalBody>
                                                                            <ModalFooter>
                                                                                <Button outline color="danger" onClick={e => this.deleteRow_Insurance(e, deleteId)}>Delete</Button>
                                                                                <Button color="secondary" onClick={this.toggle_In}>Cancel</Button>
                                                                            </ModalFooter>
                                                                        </Modal>
                                                                    </div>
                                                                }
                                                                {modal_In_file &&
                                                                    <div style={{ marginLeft: "36%" }}>
                                                                        <Modal isOpen={modal_In_file} style={{ width: "500px" }} >
                                                                            <ModalHeader>Confirm</ModalHeader>
                                                                            <ModalBody>Are you sure want to delete this insurance file?</ModalBody>
                                                                            <ModalFooter>
                                                                                <Button outline color="danger" onClick={e => this.deleteDoc_Insurance(e, deleteId)}>Delete</Button>
                                                                                <Button color="secondary" onClick={this.toggle_In_file}>Cancel</Button>
                                                                            </ModalFooter>
                                                                        </Modal>
                                                                    </div>
                                                                }
                                                                {this.state.modal_prescri_file &&
                                                                    <div style={{ marginLeft: "36%" }}>
                                                                        <Modal isOpen={this.state.modal_prescri_file} style={{ width: "500px" }} >
                                                                            <ModalHeader>Confirm</ModalHeader>
                                                                            <ModalBody>Are you sure want to delete this prescription file?</ModalBody>
                                                                            <ModalFooter>
                                                                                <Button outline color="danger" onClick={e => this.deleteDoc_Prescription(e, deleteId)}>Delete</Button>
                                                                                <Button color="secondary" onClick={this.toggle_prescri_file}>Cancel</Button>
                                                                            </ModalFooter>
                                                                        </Modal>
                                                                    </div>
                                                                }
                                                                {this.state.modal_treatmen_file &&
                                                                    <div style={{ marginLeft: "36%" }}>
                                                                        <Modal isOpen={this.state.modal_treatmen_file} style={{ width: "500px" }} >
                                                                            <ModalHeader>Confirm</ModalHeader>
                                                                            <ModalBody>Are you sure want to delete this treatment file?</ModalBody>
                                                                            <ModalFooter>
                                                                                <Button outline color="danger" onClick={e => this.deleteDoc_Treatment(e, deleteId)}>Delete</Button>
                                                                                <Button color="secondary" onClick={this.toggle_treatment_file}>Cancel</Button>
                                                                            </ModalFooter>
                                                                        </Modal>
                                                                    </div>
                                                                }



                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <Modal isOpen={showdisease} className="modal-dialog modal-sm">
                        <ModalHeader>
                            Add Disease
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Disease</label>
                                <Input type="select" className="custom-select mb-3" name="patientdiseaseId" value={patientdiseaseId} onChange={this.handleDiseaseInputChange.bind(this)}>
                                    <option value="">Select Disease</option>
                                    {Alldiseases
                                        .map((data, i) => {
                                            return (<option key={i} value={data.diseaseId}>{data.diseaseName}</option>);
                                        })}
                                </Input>
                                {errors.patientdiseaseId.length > 0 && <span className='error'>{errors.patientdiseaseId}</span>}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleCloseDisease}>
                                Close
                            </Button>
                            {loading ?
                                <Button color="primary" disabled onClick={this.AddPatientDisease.bind(this)}>
                                    Add
                                </Button> :
                                <Button color="primary" onClick={this.AddPatientDisease.bind(this)}>
                                    Add
                                </Button>}
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={showdiagnostic} className="modal-dialog modal-lg">
                        <ModalHeader>
                            Add Diagnostic
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Diagnostic Date<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                {/* <Input type="date" name="diagnosticDate" max="9999-12-31" tabIndex="1" min="1000-01-01" className="form-control" value={diagnosticDate} onChange={this.handleDiagnosticInputChange.bind(this)} placeholder="Enter a diagnostic date" /> */}
                                <DatePicker
                                    selected={diagnosticDate}
                                    onChange={this.handleDiagnosticDateChange.bind(this)}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="mm/dd/yyyy"
                                    className="form-control"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                {errors.diagnosticDate.length > 0 && <span className='error'>{errors.diagnosticDate}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Diagnostic Analysis<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                <Input type="textarea" name="diagnosticAnalysis" maxLength="500" tabIndex="2" className="form-control" value={diagnosticAnalysis} onChange={this.handleDiagnosticInputChange.bind(this)} placeholder="Enter a diagnostic analysis" />
                                {errors.diagnosticAnalysis.length > 0 && <span className='error'>{errors.diagnosticAnalysis}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Outcome<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                <Input type="textarea" name="outcome" maxLength="500" tabIndex="3" className="form-control" value={outcome} onChange={this.handleDiagnosticInputChange.bind(this)} placeholder="Enter a outcome" />
                                {errors.outcome.length > 0 && <span className='error'>{errors.outcome}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Diagnostic File</label>
                                <Input type="file" name="diagnosticdocumentFile" id="File" className="form-control" multiple="multiple" tabIndex="4" onChange={this.handleDiagnosticFileInputChange.bind(this)} />
                            </div>
                            {showdiagnosticerror != "" &&
                                <div>
                                    <span className='error'>{showdiagnosticerror}</span>
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleCloseDiagnostic}>
                                Close
                            </Button>
                            {loading ?
                                <Button color="primary" disabled onClick={this.AddPatientDiagnostic.bind(this)}>
                                    Add
                                </Button> : <Button color="primary" onClick={this.AddPatientDiagnostic.bind(this)}>
                                    Add
                                </Button>}
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={showprescription} className="modal-dialog modal-lg">
                        <ModalHeader>
                            Add Prescription
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Prescription Date<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                {/* <Input type="date" name="prescribeDate" max="9999-12-31" tabIndex="1" min="1000-01-01" className="form-control" value={prescribeDate} onChange={this.handlePrescriptionInputChange.bind(this)} placeholder="Enter a prescription date" /> */}
                                <DatePicker
                                    selected={prescribeDate}
                                    onChange={this.handlePrescribeDateChange.bind(this)}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="mm/dd/yyyy"
                                    className="form-control"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                {errors.prescribeDate.length > 0 && <span className='error'>{errors.prescribeDate}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Prescription Description<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                <Input type="textarea" name="prescriptionDescription" tabIndex="2" maxLength="500" className="form-control" value={prescriptionDescription} onChange={this.handlePrescriptionInputChange.bind(this)} placeholder="Enter a prescription description" />
                                {errors.prescriptionDescription.length > 0 && <span className='error'>{errors.prescriptionDescription}</span>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Prescription File</label>
                                <Input type="file" name="prescriptiondocumentFile" id="File" className="form-control" multiple="multiple" tabIndex="3" onChange={this.handlePrescriptionFileInputChange.bind(this)} />
                            </div>
                            {showprescriptionerror != "" &&
                                <div>
                                    <span className='error'>{showprescriptionerror}</span>
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleClosePrescription}>
                                Close
                            </Button>
                            {loading ?
                                <Button color="primary" disabled onClick={this.AddPatientPrescription.bind(this)}>
                                    Add
                                </Button> :
                                <Button color="primary" onClick={this.AddPatientPrescription.bind(this)}>
                                    Add
                                </Button>
                            }
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.showeditprescription} className="modal-dialog modal-lg">
                        <ModalHeader>
                            Update Prescription
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Prescription Date<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                {/* <Input type="date" name="prescribeDate" max="9999-12-31" tabIndex="1" min="1000-01-01" className="form-control" value={prescribeDate} onChange={this.handlePrescriptionInputChange.bind(this)} placeholder="Enter a prescription date" /> */}
                                <DatePicker
                                    selected={prescribeDate}
                                    onChange={this.handlePrescribeDateChange.bind(this)}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="mm/dd/yyyy"
                                    className="form-control"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                {errors.prescribeDate.length > 0 && <span className='error'>{errors.prescribeDate}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Prescription Description<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                <Input type="textarea" name="prescriptionDescription" tabIndex="2" maxLength="500" className="form-control" value={prescriptionDescription} onChange={this.handlePrescriptionInputChange.bind(this)} placeholder="Enter a prescription description" />
                                {errors.prescriptionDescription.length > 0 && <span className='error'>{errors.prescriptionDescription}</span>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Prescription File</label>
                                <Input type="file" name="prescriptiondocumentFile" id="File" className="form-control" multiple="multiple" tabIndex="3" onChange={this.handlePrescriptionFileInputChange.bind(this)} />
                            </div>
                            {showprescriptionerror != "" &&
                                <div>
                                    <span className='error'>{showprescriptionerror}</span>
                                </div>
                            }

                            <div className="form-group row my-4" style={{ "marginLeft": "1%" }}>
                                {prescriptiondocFiles?.length > 0 ? (
                                    prescriptiondocFiles
                                        .map((data, i) => {
                                            return (
                                                <div className="kt-widget4 mr-20">
                                                    {

                                                        data?.patientPrescriptionFile
                                                            ?.map((data2, i2) => {
                                                                // console.log("data2", this.state.PatientInsuranceId)
                                                                let a = (data2.fileName).indexOf("Patient_Insurance");
                                                                let b = (data2.fileName)


                                                                return (data2.patientPrescriptionId == this.state.patientPrescriptionId && this.state.patientPrescriptionId !== 0 ?
                                                                    <div className="kt-widget4__item d-flex align-items-center justify-content-start" key={i2}>
                                                                        <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                            <h5>{i2 + 1}.)</h5>{" "}
                                                                        </div>
                                                                        <span className='mr-3'>
                                                                            {
                                                                                data2.fileName
                                                                            }
                                                                        </span>
                                                                        <div className=''>
                                                                            <a href={window.$FileUrl + data2.filePath} download target="_blank" className="kt-widget4__title">
                                                                                <i className="flaticon2-download" />
                                                                            </a>
                                                                            <Link to="#" onClick={e => this.toggle_prescri_file(e, data2.patientPrescriptionFileId)}>
                                                                                <i className="flaticon2-delete" />
                                                                            </Link>


                                                                        </div>
                                                                    </div> : "")
                                                            })
                                                    }

                                                </div>
                                            )
                                        })) : (null)}
                            </div>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => {
                                this.setState({
                                    showeditprescription: false, prescriptionDescription: "",
                                    prescribeDate: "",
                                    prescriptiondocumentFile: "",
                                })
                                let errors = this.state.errors;
                                errors.prescribeDate = "";
                                errors.prescriptionDescription = "";
                            }}>
                                Close
                            </Button>
                            {loading ?
                                <Button color="primary" disabled onClick={this.EditPatientPrescription.bind(this)}>
                                    Edit
                                </Button> :
                                <Button color="primary" onClick={this.EditPatientPrescription.bind(this)}>
                                    Edit
                                </Button>
                            }
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.showdeleteprescription} className="modal-dialog modal-lg">
                        <ModalHeader>
                            Confirm
                        </ModalHeader>
                        <ModalBody>Are you sure want to remove this detail?</ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => {
                                this.setState({
                                    showdeleteprescription: false,
                                })
                            }}>
                                Close
                            </Button>

                            <Button color="primary" onClick={() => { this.DeletePatientPrescription() }}>
                                Delete
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={showtreatment} className="modal-dialog modal-lg">
                        <ModalHeader>
                            Add Treatment
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Treatment Date<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                {/* <Input type="date" name="treatmentDate" max="9999-12-31" tabIndex="1" min="1000-01-01" className="form-control" value={treatmentDate} onChange={this.handleTreatmentInputChange.bind(this)} placeholder="Enter a treatment date" /> */}
                                <DatePicker
                                    selected={treatmentDate}
                                    onChange={this.handleTreatmentDateChange.bind(this)}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="mm/dd/yyyy"
                                    className="form-control"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                {errors.treatmentDate.length > 0 && <span className='error'>{errors.treatmentDate}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Treatment Detail<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                <Input type="textarea" maxLength="500" name="treatmentDetail" tabIndex="2" className="form-control" value={treatmentDetail} onChange={this.handleTreatmentInputChange.bind(this)} placeholder="Enter a treatment details" />
                                {errors.treatmentDetail.length > 0 && <span className='error'>{errors.treatmentDetail}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Description<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                <Input type="textarea" name="treatmentDescription" maxLength="500" tabIndex="3" className="form-control" value={treatmentDescription} onChange={this.handleTreatmentInputChange.bind(this)} placeholder="Enter a treatment description" />
                                {errors.treatmentDescription.length > 0 && <span className='error'>{errors.treatmentDescription}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Treatment File(s)</label>
                                <Input type="file" name="treatmentdocumentFile" id="File" className="form-control" multiple="multiple" tabIndex="4" onChange={this.handleTreatmentFileInputChange.bind(this)} />
                            </div>
                            {showtreatmenterror != "" &&
                                <div>
                                    <span className='error'>{showtreatmenterror}</span>
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleCloseTreatment}>
                                Close
                            </Button>
                            {loading ?
                                <Button color="primary" disabled onClick={this.AddPatientTreatment.bind(this)}>
                                    Add
                                </Button> :
                                <Button color="primary" onClick={this.AddPatientTreatment.bind(this)}>
                                    Add
                                </Button>}
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.showEmergencyContact} className="modal-dialog modal-lg">
                        <ModalHeader>
                            {this.state.PatientEmergencyId == 0 ? "Add" : "Update"} Emergency Contact Details
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group row my-4">
                                <div className="col-md-6">
                                    <label className="col-12 col-form-label">First Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                    <div className="col-12">
                                        <Input className=" here" type="text" maxLength="200" tabIndex="1" placeholder="Enter your first name" name="FirstName" value={FirstName} onChange={this.handleEmergencyContactInputChange.bind(this)} />
                                        {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="col-12 col-form-label">Middle Name</label>
                                    <div className="col-12">
                                        <Input className="form-control here" type="text" maxLength="200" tabIndex="2" placeholder="Enter your middle name" name="MiddleName" value={MiddleName} onChange={this.handleEmergencyContactInputChange.bind(this)} />
                                        {errors.MiddleName.length > 0 && <span className='error'>{errors.MiddleName}</span>}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row my-4">
                                <div className="col-md-6">
                                    <label className="col-12 col-form-label">Last Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                    <div className="col-12">
                                        <Input className="form-control here" type="text" maxLength="200" tabIndex="3" placeholder="Enter your last name" name="LastName" value={LastName} onChange={this.handleEmergencyContactInputChange.bind(this)} />
                                        {errors.LastName.length > 0 && <span className='error'>{errors.LastName}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="col-12 col-form-label">Email<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                    <div className="col-12">
                                        <Input className="form-control here" type="text" maxLength="50" tabIndex="4" name="Email" placeholder="Enter a valid email address" value={Email} onChange={this.handleEmergencyContactInputChange.bind(this)} />
                                        {errors.Email.length > 0 && <span className='error'>{errors.Email}</span>}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row my-4">
                                <div className="col-md-6">
                                    <label className="col-12 col-form-label">Primary Phone<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                    <div className="col-12">
                                        <Input className="form-control here" type="text" maxLength="20" tabIndex="5" name="Mobile" placeholder="Enter primary phone" value={Mobile} onChange={this.handleEmergencyContactInputChange.bind(this)} />
                                        {errors.Mobile.length > 0 && <span className='error'>{errors.Mobile}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="col-12 col-form-label">Secondary Phone</label>
                                    <div className="col-12">
                                        <Input className="form-control here" type="text" maxLength="20" tabIndex="6" name="PhoneNumber" placeholder="Enter secondary phone" value={PhoneNumber} onChange={this.handleEmergencyContactInputChange.bind(this)} />
                                        {errors.PhoneNumber.length > 0 && <span className='error'>{errors.PhoneNumber}</span>}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row my-4">
                                <div className="col-md-12">
                                    <label className="col-12 col-form-label">Address</label>
                                    <div className="col-12">
                                        <textarea className="form-control here" type="text" maxLength="500" tabIndex="7" name="Address" placeholder="Enter a address" value={Address} onChange={this.handleEmergencyContactInputChange.bind(this)}></textarea>
                                        {errors.Address.length > 0 && <span className='error'>{errors.Address}</span>}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row my-4">
                                <div className="col-md-6">
                                    <label className="col-12 col-form-label">Country</label>
                                    <div className="col-12">
                                        <Input type="select" className="custom-select mb-3" tabIndex="8" name="CountryId" value={CountryId} onChange={this.handleEmergencyContactInputChange.bind(this)}>
                                            <option value="">Select Country</option>
                                            {countries
                                                .map((data, i) => {
                                                    return (<option key={i} value={data.id}>{data.name}</option>);
                                                })}
                                        </Input>
                                        {errors.CountryId.length > 0 && <span className='error'>{errors.CountryId}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="col-12 col-form-label">State</label>
                                    <div className="col-12">
                                        <Input type="select" className="custom-select mb-3" tabIndex="9" name="StateId" value={StateId} onChange={this.handleEmergencyContactInputChange.bind(this)}>
                                            <option value="">Select State</option>
                                            {states
                                                .map((data, i) => {
                                                    return (<option key={i} value={data.id}>{data.name}</option>);
                                                })}
                                        </Input>
                                        {errors.StateId.length > 0 && <span className='error'>{errors.StateId}</span>}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row my-4">
                                <div className="col-md-6">
                                    <label className="col-12 col-form-label">City</label>
                                    <div className="col-12">
                                        <Input type="select" className="custom-select mb-3" tabIndex="10" name="CityId" value={CityId} onChange={this.handleEmergencyContactInputChange.bind(this)}>
                                            <option value="">Select City</option>7
                                            {cities
                                                .map((data, i) => {
                                                    return (<option key={i} value={data.id}>{data.name}</option>);
                                                })}
                                        </Input>
                                        {errors.CityId.length > 0 && <span className='error'>{errors.CityId}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="col-12 col-form-label">Postal Code</label>
                                    <div className="col-12">
                                        <Input className="form-control here" type="text" maxLength="20" tabIndex="11" name="PostalCode" placeholder="Enter a postal code" value={PostalCode} onChange={this.handleEmergencyContactInputChange.bind(this)} />
                                        {errors.PostalCode.length > 0 && <span className='error'>{errors.PostalCode}</span>}
                                    </div>
                                </div>
                            </div>

                            {/* {loading ?
                                <button tabIndex="12" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                :
                                <button tabIndex="12" type="submit" className="btn btn-primary">Submit</button>
                            } */}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleCloseEmergencyContact.bind(this)}>
                                Close
                            </Button>
                            {loading ?
                                <Button color="primary" disabled onClick={this.AddPatientEmergencyContact.bind(this)}>
                                    {this.state.PatientEmergencyId == 0 ? "Add" : "Update"}
                                </Button> :
                                <Button color="primary" onClick={this.AddPatientEmergencyContact.bind(this)}>
                                    {this.state.PatientEmergencyId == 0 ? "Add" : "Update"}

                                </Button>
                            }
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.showInsurance} className="modal-dialog modal-lg">
                        <ModalHeader>
                            {this.state.PatientInsuranceId == 0 ? "Add" : "Update"} Insurance Details
                        </ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group row my-4">
                                        <div className="col-md-6">
                                            <label className="col-12 col-form-label">Insurance Company Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                            <div className="col-12">
                                                <Input className="form-control here" type="text" maxLength="200" tabIndex="1" placeholder="Enter your insurance company name" name="InsuranceCompany" value={InsuranceCompany} onChange={this.handleInputChangeInsurance.bind(this)} />
                                                {errors.InsuranceCompany.length > 0 && <span className='error'>{errors.InsuranceCompany}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-12 col-form-label">Policy Number<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                            <div className="col-12">
                                                <Input type="text" className="form-control here" maxLength="50" tabIndex="2" name="PolicyNumber" placeholder="Enter a policy number" value={PolicyNumber} onChange={this.handleInputChangeInsurance.bind(this)} />
                                                {errors.PolicyNumber.length > 0 && <span className='error'>{errors.PolicyNumber}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row my-4">
                                        <div className="col-md-6">
                                            <label className="col-12 col-form-label">Health Plan<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                            <div className="col-12">
                                                <Input type="text" name="HealthPlan" tabIndex="3" maxLength="200" className="form-control" value={HealthPlan} onChange={this.handleInputChangeInsurance.bind(this)} placeholder="Enter a health plan" />
                                                {errors.HealthPlan.length > 0 && <span className='error'>{errors.HealthPlan}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-12 col-form-label">Member ID<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                            <div className="col-12">
                                                <Input type="text" className="form-control here" maxLength="200" tabIndex="4" name="MemberID" placeholder="Enter a member id" value={MemberID} onChange={this.handleInputChangeInsurance.bind(this)} />
                                                {errors.MemberID.length > 0 && <span className='error'>{errors.MemberID}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row my-4">
                                        <div className="col-md-6">
                                            <label className="col-12 col-form-label">Group Number<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                            <div className="col-12">
                                                <Input type="text" name="GroupNumber" tabIndex="5" maxLength="200" className="form-control" value={GroupNumber} onChange={this.handleInputChangeInsurance.bind(this)} placeholder="Enter a group number" />
                                                {errors.GroupNumber.length > 0 && <span className='error'>{errors.GroupNumber}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-12 col-form-label">Phone<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                            <div className="col-12">
                                                <Input type="text" className="form-control here" maxLength="200" tabIndex="6" name="PhoneNumber_Insurance" placeholder="Enter your phone" value={PhoneNumber_Insurance} onChange={this.handleInputChangeInsurance.bind(this)} />
                                                {errors.PhoneNumber_Insurance.length > 0 && <span className='error'>{errors.PhoneNumber_Insurance}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row my-4">
                                        <div className="col-md-12">
                                            <label className="col-12 col-form-label">Address<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                            <div className="col-12">
                                                <Input type="textarea" name="Address_Insurance" tabIndex="7" maxLength="200" className="form-control" value={Address_Insurance} onChange={this.handleInputChangeInsurance.bind(this)} placeholder="Enter a address" />
                                                {errors.Address_Insurance.length > 0 && <span className='error'>{errors.Address_Insurance}</span>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row my-4">
                                        <div className="col-md-6">
                                            <label className="col-12 col-form-label">Policy File(s)</label>
                                            <div className="col-12">
                                                <Input type="file" name="DocumentFile" id="File" className="form-control" multiple="multiple" tabIndex="8" onChange={this.handleFileInputChange_Insurance.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        </div>
                                    </div>
                                    {console.log("DocFiles", docFiles)}
                                    <div className="form-group row my-4" style={{ "marginLeft": "1%" }}>
                                        {docFiles?.length > 0 ? (
                                            docFiles
                                                .map((data, i) => {
                                                    return (
                                                        <div className="kt-widget4 mr-20">
                                                            {

                                                                data?.patientInsuranceFile?.map((data2, i2) => {
                                                                    // console.log("data2", this.state.PatientInsuranceId)
                                                                    let a = (data2.fileName).indexOf("Patient_Insurance");
                                                                    let b = (data2.fileName)
                                                                    return (data2.patientInsuranceId == this.state.PatientInsuranceId && this.state.PatientInsuranceId !== 0 ?
                                                                        <div className="kt-widget4__item d-flex align-items-center justify-content-start" key={i2}>
                                                                            <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                <h5>{i2 + 1}.)</h5>{" "}
                                                                            </div>
                                                                            <span className='mr-3'>
                                                                                {
                                                                                    data2.fileName.slice((data2.fileName.indexOf("Patient_Insurance"))).replace("Patient_Insurance_", " ").slice(12)
                                                                                        .slice(data2.fileName.slice((data2.fileName.indexOf("Patient_Insurance"))).replace("Patient_Insurance_", " ").slice(12).indexOf('_') + 1)
                                                                                        .slice(
                                                                                            data2.fileName.slice((data2.fileName.indexOf("Patient_Insurance"))).replace("Patient_Insurance_", " ").slice(12)
                                                                                                .slice(data2.fileName.slice((data2.fileName.indexOf("Patient_Insurance"))).replace("Patient_Insurance_", " ").slice(12).indexOf('_') + 1).indexOf('_') + 1
                                                                                        )
                                                                                }
                                                                            </span>
                                                                            <div className=''>
                                                                                <a href={window.$FileUrl + data2.filePath} download target="_blank" className="kt-widget4__title">
                                                                                    <i className="flaticon2-download" />
                                                                                </a>
                                                                                <Link to="#" onClick={e => this.toggle_In_file(e, data2.patientInsuranceFileId)}>
                                                                                    <i className="flaticon2-delete" />
                                                                                </Link>
                                                                            </div>
                                                                        </div> : "")
                                                                })
                                                            }

                                                        </div>
                                                    )
                                                })) : (null)}
                                    </div>
                                    {/* {loading ?
                <button tabIndex="9" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                :
                <button tabIndex="9" type="submit" className="btn btn-primary">Submit</button>
            } */}
                                </div>
                            </div>

                            {/* {loading ?
        <button tabIndex="12" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
        :
        <button tabIndex="12" type="submit" className="btn btn-primary">Submit</button>
    } */}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleCloseInsurance.bind(this)}>
                                Close
                            </Button>
                            {loading ?
                                <Button color="primary" disabled onClick={this.AddPatientInsurance.bind(this)}>
                                    {this.state.PatientInsuranceId == 0 ? "Add" : "Update"}

                                </Button> :
                                <Button color="primary" onClick={this.AddPatientInsurance.bind(this)}>
                                    {this.state.PatientInsuranceId == 0 ? "Add" : "Update"}

                                </Button>
                            }
                        </ModalFooter>
                    </Modal>




                    {this.loading()}
                </div>
                <Modal isOpen={this.state.showedittreatment} className="modal-dialog modal-lg">
                    <ModalHeader>
                        Update Treatment
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label htmlFor="recipient-name" className="form-control-label">Treatment Date<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                            {/* <Input type="date" name="treatmentDate" max="9999-12-31" tabIndex="1" min="1000-01-01" className="form-control" value={treatmentDate} onChange={this.handleTreatmentInputChange.bind(this)} placeholder="Enter a treatment date" /> */}
                            <DatePicker
                                selected={treatmentDate}
                                onChange={this.handleTreatmentDateChange.bind(this)}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="mm/dd/yyyy"
                                className="form-control"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                            {errors.treatmentDate.length > 0 && <span className='error'>{errors.treatmentDate}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="recipient-name" className="form-control-label">Treatment Detail<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                            <Input type="textarea" maxLength="200" name="treatmentDetail" tabIndex="2" className="form-control" value={treatmentDetail} onChange={this.handleTreatmentInputChange.bind(this)} placeholder="Enter a treatment details" />
                            {errors.treatmentDetail.length > 0 && <span className='error'>{errors.treatmentDetail}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="recipient-name" className="form-control-label">Description<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                            <Input type="textarea" name="treatmentDescription" tabIndex="3" className="form-control" value={treatmentDescription} onChange={this.handleTreatmentInputChange.bind(this)} placeholder="Enter a treatment description" />
                            {errors.treatmentDescription.length > 0 && <span className='error'>{errors.treatmentDescription}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="recipient-name" className="form-control-label">Treatment File(s)</label>
                            <Input type="file" name="treatmentdocumentFile" id="File" className="form-control" multiple="multiple" tabIndex="4" onChange={this.handleTreatmentFileInputChange.bind(this)} />
                        </div>
                        {showtreatmenterror != "" &&
                            <div>
                                <span className='error'>{showtreatmenterror}</span>
                            </div>
                        }

                        <div className="form-group row my-4" style={{ "marginLeft": "1%" }}>
                            {treatmentdocFiles?.length > 0 ? (
                                treatmentdocFiles
                                    .map((data, i) => {
                                        return (
                                            <div className="kt-widget4 mr-20">
                                                {

                                                    data?.patientTreatmentReportFile
                                                        ?.map((data2, i2) => {
                                                            // console.log("data2", this.state.PatientInsuranceId)
                                                            let a = (data2.fileName).indexOf("Patient_Insurance");
                                                            let b = (data2.fileName)

                                                            return (data2.patientTreatmentReportId == this.state.patientTreatmentReportId && this.state.patientTreatmentReportId !== 0 ?
                                                                <div className="kt-widget4__item d-flex align-items-center justify-content-start" key={i2}>
                                                                    <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                        <h5>{i2 + 1}.)</h5>{" "}
                                                                    </div>
                                                                    <span className='mr-3'>
                                                                        {
                                                                            data2.fileName
                                                                        }
                                                                    </span>
                                                                    <div className=''>
                                                                        <a href={window.$FileUrl + data2.filePath} download target="_blank" className="kt-widget4__title">
                                                                            <i className="flaticon2-download" />
                                                                        </a>


                                                                        <Link to="#" onClick={e => this.toggle_treatment_file(e, data2.patientTreatmentreportFileId)}>
                                                                            <i className="flaticon2-delete" />
                                                                        </Link>


                                                                    </div>
                                                                </div> : "")
                                                        })
                                                }

                                            </div>
                                        )
                                    })) : (null)}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => {
                            this.setState({
                                showedittreatment: false, treatmentDetail: "",
                                treatmentDate: "",
                                treatmentDescription: "",
                                treatmentdocumentFile: "",
                            })
                            let errors = this.state.errors;
                            errors.treatmentDate = "";
                            errors.treatmentDescription = "";
                            errors.treatmentDetail = ""
                        }}>
                            Close
                        </Button>
                        {loading ?
                            <Button color="primary" disabled onClick={this.EditPatientTreatment.bind(this)}>
                                Edit
                            </Button> :
                            <Button color="primary" onClick={this.EditPatientTreatment.bind(this)}>
                                Edit
                            </Button>}
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.showdeletetreatment} className="modal-dialog modal-lg">
                    <ModalHeader>
                        Confirm
                    </ModalHeader>
                    <ModalBody>Are you sure want to remove this detail?</ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => {
                            this.setState({
                                showdeletetreatment: false,
                            })
                        }}>
                            Close
                        </Button>

                        <Button color="primary" onClick={() => { this.DeletePatientTreatment() }}>
                            Delete
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.analysisModal} className="modal-dialog modal-lg h-500"  >
                    <ModalHeader toggle={() => {
                        this.setState({
                            analysisModal: false,
                            analysisText: '',
                            analysisHeader: "",
                        })
                    }}>{this.state.analysisHeader}</ModalHeader>
                    <ModalBody>
                        <div className="form-group" style={{ wordBreak: 'break-word' }}>
                            {this.state.analysisText}
                        </div>

                    </ModalBody>

                </Modal>
                {preview &&
                    <>
                        <div className='preview-popup'>
                            <div className='preview-popup-modal'>
                                <div className='preview-popup-header'>
                                    {url.split(".").splice(-1)[0] === "pdf" ? null :
                                        <a href={url} download target={`_blank`}>
                                            <img src={downloadIcon} style={{ margin: "0 12px", cursor: "pointer" }} alt='download' />
                                        </a>
                                    }
                                    <img src={closeIcon} style={{ cursor: "pointer" }} alt='close' onClick={e => this.previewToggle(e, "")} />
                                </div>
                                <iframe src={url} title="previewFile" width="100%" height="90%" />
                            </div>
                        </div>
                    </>
                }
            </React.Fragment >
        );
    }
}
