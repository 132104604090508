import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import React, { Component, Suspense } from 'react';

import { Container } from 'reactstrap';
import { Footer } from './Footer';
import { NavMenu } from './NavMenu';
import { Sidebar } from './Sidebar';

//import routes from '../routes.js';

const loading = () => <div id="loader-wrapper">
    <div id="loader"></div>
    <div className="loader-section section-left"></div>
    <div className="loader-section section-right"></div>
</div>;

export class Layout extends Component {
    static displayName = Layout.name;

    // renderRedirectLayout() {
    //     if (localStorage.getItem('Usertoken') != null) {
    //         var userToken = JSON.parse(localStorage.getItem('Usertoken'));
    //         var uType = userToken.userType;

    //         var currentPath = window.location.pathname;

    //         //alert(uType);

    //         if (uType == 1
    //             // && !currentPath.includes("/patient/")
    //         ) {
    //             return <Redirect from="/" to="/patient/dashboard" />
    //         } //Patient
    //         else if (uType == 2
    //             // && !currentPath.includes("/practitioner/")
    //         ) {
    //             alert("main layout")
    //             if (userToken.firstName && userToken.lastName && userToken.mobile && userToken.clinicPostalCode) {

    //                 return <Redirect exact from="/" to="/practitioner/dashboard" />
    //             } else {
    //                 return <Redirect exact from="/" to="/practitioner/profile" />
    //             }
    //         }  //Practitioner
    //         else if (uType == 3
    //             // && !currentPath.includes("/institute/")
    //         ) {
    //             return <Redirect from="/" to="/institute/dashboard" />
    //         } //Institution
    //         else if (uType == 6
    //             // && !currentPath.includes("/manufacturer/")
    //         ) {
    //             return <Redirect from="/" to="/manufacturer/dashboard" />
    //         } //Manufacturer
    //         else if (uType == 4
    //             // && !currentPath.includes("/laboratory/")
    //         ) {
    //             return <Redirect from="/" to="/laboratory/dashboard" />
    //         }//NeoLab
    //     }
    // }

    render() {

        return (
            <div className="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--transparent kt-aside--enabled kt-aside--fixed kt-page--loading" >
                <NavMenu userToken={JSON.parse(localStorage.getItem('Usertoken'))} />
                <Sidebar />
                {/* 
                 * Mobile menu
                 * <div id="kt_header_mobile" className="kt-header-mobile  kt-header-mobile--fixed " >
                    <div className="kt-header-mobile__logo">
                        <a href="demo12/index.html">
                            <img alt="Logo" src="./assets/media/logos/logo-small.png" />
                        </a>
                    </div>
                    <div className="kt-header-mobile__toolbar">
                        <button className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left" id="kt_aside_mobile_toggler"><span></span></button>
                        <button className="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler"><span></span></button>
                        <button className="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler"><i className="flaticon-more"></i></button>
                    </div> 
                </div>*/}
                {this.props.children}
                {/*window.location.pathname == '/healthcareworker' ? <Footer /> : null*/}
                {/*window.location.pathname == '/patientandcaregivers' ? <Footer /> : null */}

                {/* {this.renderRedirectLayout()} */}
                <div className="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
                    <div className="kt-container  kt-container--fluid ">
                        <div className="kt-footer__copyright">
                            2020 &nbsp;&copy;&nbsp;<Link to="/" target="_blank" className="kt-link">Neo7Logix</Link>
                        </div>
                    </div>
                </div>

                <div id="kt_scrolltop" className="kt-scrolltop">
                    <i className="fa fa-arrow-up"></i>
                </div>
            </div>

            /*  
               <div className="scroll-top">
                    <i className="ti-angle-up"></i>
                </div>
                <NavMenu />
          <Container>
            {this.props.children}
         </Container>
       </div>*/
        );
    }
}
{/*window.location.pathname == '/home' ? <Footer /> : null*/ }
//<Container fluid>
//    <Suspense fallback={loading()}>
//        <Switch>
//            {routes.map((route, idx) => {
//                return route.component ? (
//                    <Route
//                        key={idx}
//                        path={route.path}
//                        exact={route.exact}
//                        name={route.name}
//                        render={props => (
//                            <route.component {...props} />
//                        )} />
//                ) : (null);
//            })}
//            <Redirect from="/" to="/" />
//        </Switch>
//    </Suspense>
//</Container>