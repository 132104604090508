import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import downloadIcon from '../../../assets/svg/download.svg';
import closeIcon from '../../../assets/svg/x.svg';
//import MyModal from './../../CustomModal/CustomModal';

export class LaboratoryDataFile extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            patientactivities: [],
            analysisfiles: [],
            analysisdatafile: "",
            patientId: "",
            patientAccessionId: "",
            //ngsLaboratoryPatientActivityId: "",
            ngsLaboratoryPatientDataFileId: "",
            url: "",
            username: "",
            password: "",
            description: "",
            // authError: false,
            error: '',
            errorType: '',
            deleteId: '',
            modal: false,
            preview: false,
            fileUrl: "",
            number: "",
        };
        this.state = this.initialState;
        this.toggle = this.toggle.bind(this);

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //}

    componentDidMount() {
        const param = this.props.match.params;

        if (param.id != undefined) {
            this.setState({ patientAccessionId: param.id });
            this.getListData(param.id);
        }
        else {
            this.setState({ loading: false });
        }
    }

    //get detail(for update)
    getListData(pid) {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);
        }
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/NGSLaboratoryPatientDataFile/getNGSLaboratoryPatientDataFilesbyActivity?id=' + pid + '&lid=' + id+'';

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    let rData = result.data.outdata;
                    console.log(rData);
                    let patientId = 0;
                    //let patientAccessionId = 0;
                    if (rData.ngsLaboratoryPatientActivityData.length > 0) {
                        patientId = rData.ngsLaboratoryPatientActivityData[0].patientId;
                        //patientAccessionId = rData.ngsLaboratoryPatientActivityData[0].patientAccessionId;
                    }

                    this.setState({
                        patientactivities: rData.ngsLaboratoryPatientActivityData,
                        patientId: patientId,
                        //patientAccessionId: patientAccessionId,
                        //sampletypes: rData.sampleTypeData,
                        //analysisfiles: rData.ngsLaboratoryPatientDataFileData == null ? [] : rData.ngsLaboratoryPatientDataFileData,
                        analysisfiles: rData.ngsLaboratoryPatientDataFileData,
                        loading: false
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleFileInputChange(event) {
        const target = event.target;
        const value = target.files[0];
        //alert(target.files[0]);
        this.setState({
            analysisdatafile: value
        });
    }

    //handleSubmit(e) {
    //    e.preventDefault();
    //    this.setState({ loading: true });
    //    let url = "";

    //    var userToken = JSON.parse(localStorage.getItem('Usertoken'));

    //    //alert(this.state.analysisdatafile)
    //    if (this.state.analysisdatafile != "") {

    //        const apiroute = window.$APIPath;
    //        let lid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);
    //        /*let pid = this.state.patientId;*/
    //        let pid = this.state.patientAccessionId;
    //        let aids = this.state.ngsLaboratoryPatientActivityId;
    //        //let aids = Array.prototype.map.call(this.state.patientactivities, s => s.ngsLaboratoryPatientActivityId).toString();

    //        if (this.state.ngsLaboratoryPatientDataFileId == 0) {
    //            url = apiroute + '/api/NGSLaboratoryPatientDataFile/Save?id=' + pid + '&lid=' + lid + '&aids=' + aids + '';
    //        }
    //        else {
    //            let id = this.state.ngsLaboratoryPatientDataFileId;
    //            url = apiroute + '/api/NGSLaboratoryPatientDataFile/Update?id=' + id + '&pid=' + pid + '';
    //        }


    //        let files = this.state.analysisdatafile;
    //        const data = new FormData();
    //        data.append(`file`, files);
    //        //for (let i = 0; i < files.length; i++) {
    //        //    data.append(`files[${i}]`, files[i])
    //        //}
    //        axios.post(url, data, {
    //            // receive two    parameter endpoint url ,form data
    //        }).then(result => {
    //            console.log(result);
    //            if (result.data.flag) {

    //                if (this.state.url == '' && this.state.username == '' && this.state.password == '') {
    //                    this.setState({
    //                        // authError: true,
    //                        // errorType: 'success',
    //                        // error: result.data.message,
    //                        analysisdatafile: "",
    //                        loading: false
    //                    }, this.getListData(this.state.ngsLaboratoryPatientActivityId));
    //                    toast.success(result.data.message)
    //                } else {
    //                    this.setState({
    //                        analysisdatafile: ""
    //                    });
    //                    var rdata = result.data.outdata;
    //                    this.updateDetail(rdata.ngsLaboratoryPatientDataFileId, result.data.message);
    //                }

    //            }
    //            else {
    //                this.setState({
    //                    // authError: true,
    //                    // errorType: 'danger',
    //                    // error: result.data.message,
    //                    loading: false
    //                });
    //                toast.error(result.data.message)
    //            }
    //        }).catch(error => {
    //            console.log(error);
    //            this.setState({
    //                // authError: true, errorType: 'danger', error: error.message, 
    //                loading: false
    //            });
    //            toast.error(error.message)
    //        });
    //    }
    //    else {
    //        this.setState({
    //            // authError: true,

    //            // errorType: 'danger',
    //            // error: "Please select data file to upload.",
    //            loading: false
    //        });
    //        toast.error("Please select data file to upload.")
    //    }
    //}

    //upload data file
    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        if (this.state.analysisdatafile != "") {

            const apiroute = window.$APIPath;

            var userToken = JSON.parse(localStorage.getItem('Usertoken'));
            let lid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);
            let pid = this.state.patientId;
            let aid = this.state.patientAccessionId;
            let aids = Array.prototype.map.call(this.state.patientactivities, s => s.ngsLaboratoryPatientActivityId).toString();

            url = apiroute + '/api/NGSLaboratoryPatientDataFile/NewSave';
            
            let files = this.state.analysisdatafile;
            const data = new FormData();
            data.append(`PatientId`, pid);
            data.append(`PatientAccessionId`, aid);
            data.append(`NGSLaboratoryId`, lid);
            data.append(`NGSLaboratoryPatientActivityId`, aids);
            data.append(`file`, files);

            axios.post(url, data, {
            }).then(result => {
                console.log(result);
                if (result.data.flag) {

                    if (this.state.url == '' && this.state.username == '' && this.state.password == '') {
                        this.setState({
                            analysisdatafile: "",
                            showFile: false,
                            loading: false
                        }, this.getListData(this.state.patientAccessionId));
                        toast.success(result.data.message)
                    } else {
                        this.setState({
                            analysisdatafile: ""
                        });
                        var rdata = result.data.outdata;
                        this.updateDetail(rdata.ngsLaboratoryPatientDataFileId, result.data.message);
                    }

                }
                else {
                    this.setState({
                        showFileErr: result.data.message,
                        loading: false
                    });
                }
            }).catch(error => {
                console.log(error);
                this.setState({
                    showFileErr: error.message, loading: false
                });
            });
        }
        else {
            this.setState({
                showFileErr: "Please select data file to upload.",
                loading: false
            });
        }
    }

    //upload data file details
    updateDetail(id, msg) {
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let lid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

        const apiroute = window.$APIPath;
        url = apiroute + '/api/NGSLaboratoryPatientDataFile/UpdateDetails';
        //let aids = Array.prototype.map.call(this.state.patientactivities, s => s.ngsLaboratoryPatientActivityId).toString();
        //let aids = this.state.ngsLaboratoryPatientActivityId;

        let jdata = JSON.stringify({
            ngsLaboratoryPatientDataFileId: parseInt(id),
            ngsLaboratoryId: parseInt(lid),
            patientId: parseInt(this.state.patientId),
            patientAccessionId: parseInt(this.state.patientAccessionId),
            //ngsLaboratoryPatientActivityId: parseInt(aids),
            ngsLaboratoryPatientActivityId: 0,
            url: this.state.url,
            username: this.state.username,
            password: this.state.password,
            description: this.state.description,
            CreatedBy: parseInt(0)
        })

        console.log(jdata);
        axios.post(url, jdata, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // modal: true,
                        // modalTitle: 'Success',
                        // modalBody: msg,
                        loading: false,
                        ngsLaboratoryPatientDataFileId: "",
                        url: "",
                        password: "",
                        username: "",
                        description: "",
                    }, this.getListData(this.state.patientAccessionId));
                    toast.success(msg)
                }
                else {
                    this.setState({
                        showFileErr: result.data.message,
                        loading: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    showFileErr: error.message, loading: false
                });
                //this.setState({ modal: true, error: error });
            });
    }
    //updateDetail(id, msg) {
    //    let url = "";

    //    var userToken = JSON.parse(localStorage.getItem('Usertoken'));
    //    let uid = 0;
    //    let lid = 0;
    //    if (userToken != null) {
    //        lid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);
    //        uid = (userToken.userId == null ? 0 : userToken.userId);
    //    }

    //    const apiroute = window.$APIPath;
    //    url = apiroute + '/api/NGSLaboratoryPatientDataFile/UpdateDetails';
    //    //let aids = Array.prototype.map.call(this.state.patientactivities, s => s.ngsLaboratoryPatientActivityId).toString();
    //    let aids = this.state.ngsLaboratoryPatientActivityId;

    //    let jdata = JSON.stringify({
    //        ngsLaboratoryPatientDataFileId: parseInt(id),
    //        ngsLaboratoryId: parseInt(lid),
    //        patientId: parseInt(this.state.patientId),
    //        patientAccessionId: parseInt(this.state.patientAccessionId),
    //        ngsLaboratoryPatientActivityId: parseInt(aids),
    //        url: this.state.url,
    //        username: this.state.username,
    //        password: this.state.password,
    //        description: this.state.description,
    //        CreatedBy: parseInt(uid)
    //    })

    //    console.log(jdata);
    //    axios.post(url, jdata, {
    //        headers: {
    //            'Content-Type': 'application/json; charset=utf-8'
    //        }
    //    })
    //        .then(result => {
    //            if (result.data.flag) {
    //                this.setState({
    //                    // authError: true,
    //                    // errorType: 'success',
    //                    // error: msg,
    //                    loading: false,
    //                    ngsLaboratoryPatientDataFileId: "",
    //                    url: "",
    //                    password: "",
    //                    username: "",
    //                    description: "",
    //                }, this.getListData(this.state.ngsLaboratoryPatientActivityId));
    //                toast.success(msg)
    //            }
    //            else {
    //                this.setState({
    //                    // authError: true,
    //                    // errorType: 'danger',
    //                    // error: result.data.message,
    //                    loading: false
    //                });
    //                toast.error(result.data.message)
    //            }
    //        })
    //        .catch(error => {
    //            console.log(error);
    //            this.setState({
    //                // authError: true, errorType: 'danger', error: error.message, 
    //                loading: false
    //            });
    //            toast.error(error.message)
    //            //this.setState({ authError: true, error: error });
    //        });
    //}


    //delete(active/inactive) button click
    deleteRow(e, id) {
        e.preventDefault();

        this.setState({ loading: true });
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let uid = (userToken.userId == null ? 0 : userToken.userId);

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/NGSLaboratoryPatientDataFile/Delete?id=' + id + '&userId=' + uid + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData(this.state.patientAccessionId));
                    toast.success(result.data.message)
                    this.toggle()
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //edit button click
    editRow(e, id) {
        e.preventDefault();
        this.setState({ loading: true });
        let filterdata = this.state.analysisfiles.filter(
            ml => ml.ngsLaboratoryPatientDataFileId == id
        );
        if (filterdata.length > 0) {
            this.setState({
                ngsLaboratoryPatientDataFileId: id,
                url: filterdata[0].url,
                password: filterdata[0].password,
                username: filterdata[0].username,
                description: filterdata[0].description,
                loading: false
            });
        }
        else {
            this.setState({ loading: false });
        }

    }

    //download
    DownloadFile(e, filepath, filename) {
        //alert(filename);
        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        axios({
            url: apiroute + '/api/CognitoUserStore/downloadFile?fileName=' + filepath + '',
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            console.log(response);
            var fname = filepath.substring(filepath.lastIndexOf('/') + 1);
            //alert(fname);
            var fext = fname.substring(fname.lastIndexOf('.'));
            //alert(fext);
            filename = filename + fext;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            //const blob = new Blob([response.data], { type: 'application/pdf' });
            //const url = window.URL.createObjectURL(blob);
            //const link = document.createElement('a');
            //link.href = url;
            //link.setAttribute('download', filename);
            //document.body.appendChild(link);
            //link.click();
            //link.remove();
            //window.URL.revokeObjectURL(url);
            this.setState({ loading: false });
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });

    }

    //file preview
    previewToggle (e, file, number) {
        this.setState({
        preview: !this.state.preview,
        fileUrl: window.$FileUrl + file,
        number: number
        })
    }

    toggle(e, id) {
        this.setState({
          modal: !this.state.modal,
          deleteId: id
        });
      }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }
    render() {
        const { loading, patientactivities, analysisdatafile, analysisfiles, error, errorType, preview, fileUrl, number,
            authError, url, password, username, description, modal, deleteId } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                                <Link to="/laboratory/dashboard" className="kt-subheader__title">
                                                    Dashboard                       
                                                </Link>
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="#" className="kt-subheader__breadcrumbs-link">
                                                    Upload Analysis Data File                       </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        {/* {authError ?
                                            <div>
                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                    <div className="alert-text">{error}</div>
                                                    <div className="alert-close">
                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                                        <div className="kt-portlet__body">
                                            <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                <thead>
                                                    <tr>
                                                        <th>Sample Run</th>
                                                        <th>Sample Ref Number</th>
                                                        {/*<th>Lab Name</th>
                                                        <th>Lab Email, Mobile No <br /> Address</th>*/}
                                                        {/*<th>Analysis Description</th>*/}
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    // !loading ? (
                                                        //standards.map(function (data,i) {
                                                        patientactivities.length > 0 ? (
                                                            patientactivities
                                                                .map((data, i) => {
                                                            return (
                                                            <tr>
                                                                    <td>{data.sampleTypeName}</td>
                                                                    <td>{data.patientRefNo}</td>
                                                                {/*<td>{patientactivities.ngsLabName}</td>
                                                                <td>{patientactivities.ngsLabEmail + ", " + patientactivities.ngsLabPhoneNo}<br />{patientactivities.ngsLabAddress}</td>*/}
                                                                    {/*<td>{data.description}</td>*/}
                                                                    <td>
                                                                        {data.currentStatus == "Data File Ready" ?
                                                                            <span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor": "#4DBD74" }}>{data.currentStatus}</span>
                                                                            :
                                                                            data.currentStatus == "Analysis Data And File Transferred" ?
                                                                                <span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor": "#529546" }}>{data.currentStatus}</span>
                                                                                :
                                                                                data.currentStatus == "Not Available" || data.currentStatus == "Not Assigned" ?
                                                                                    <span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor": "#F86C6B" }}>{data.currentStatus}</span>
                                                                                    :
                                                                                    <span className="kt-shape-bg-color-3" style={{ "padding": "3px 4px", "color": "#fff" }}>{data.currentStatus}</span>
                                                                        }
                                                                </td>
                                                            </tr>
                                                            )
                                                                    })
                                                        ) : (
                                                                <tr>
                                                                    <td colSpan="4" className="tdCenter">No data files found.</td></tr>
                                                            // )) : (
                                                            // <tr>
                                                            //     <td colSpan="4" className="tdCenter">Loading...</td></tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            <hr />
                                            <div className="form-group row my-4">
                                                <div className="col-md-6">
                                                    <label className="col-12 col-form-label">Analysis File</label>
                                                    <div className="col-12">
                                                        <Input type="file" name="analysisdatafile" id="File" className="form-control" onChange={this.handleFileInputChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-12 col-form-label">URL</label>
                                                    <div className="col-12">
                                                        <Input type="text" name="url" id="url" maxLength="300" value={url} className="form-control" onChange={this.handleInputChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-12 col-form-label">Username</label>
                                                    <div className="col-12">
                                                        <Input type="text" name="username" id="username" maxLength="20" value={username} className="form-control" onChange={this.handleInputChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-12 col-form-label">Password</label>
                                                    <div className="col-12">
                                                        <Input type="password" name="password" id="password" maxLength="20" value={password} className="form-control" onChange={this.handleInputChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <label className="col-12 col-form-label">Description</label>
                                                    <div className="col-12">
                                                        <Input type="textarea" name="description" id="description" value={description} className="form-control" onChange={this.handleInputChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-12 col-form-label"></label>
                                                    <div className="col-12" style={{ "marginTop": "10px" }}>
                                                        {loading ?
                                                            <button type="button" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light mt-13" disabled="disabled">Upload</button>
                                                            :
                                                            <button type="button" onClick={this.handleSubmit.bind(this)} className="btn btn-primary mt-13">Upload</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                <thead>
                                                    <tr>
                                                        <th>URL</th>
                                                        <th>File Credentials</th>
                                                        <th>Description</th>
                                                        <th>File</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    // !loading ? (
                                                        //standards.map(function (data,i) {
                                                        analysisfiles.length > 0 ? (
                                                            analysisfiles
                                                                .map((data, i) => {
                                                                    return (<tr key={i}>
                                                                        <td>{data.url ? data.url : "-"}</td>
                                                                        <td>{data.username ?
                                                                            <i className="flaticon2-user"></i> : "-"} &nbsp; {data.username} <br />
                                                                            {data.password ?
                                                                                <i className="flaticon2-lock"></i> : null} &nbsp;{data.password}</td>
                                                                        <td>{data.description ? data.description : "-"}</td>
                                                                        <td>
                                                                            <div className="kt-widget4">
                                                                                <div className="kt-widget4__item" key={i}>
                                                                                    <Link style={{ "cursor": "pointer", "color": "#20A8D8" }} onClick={e => this.previewToggle(e, data.filePath, data.fileName)}><i className="flaticon2-download"></i></Link>
                                                                                    {/*<a href={data.filePath} download>
                                                                                        <i className="flaticon2-download"></i>
                                                                                    </a>*/}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {/*<Link onClick={e => this.editRow(e, data.ngsLaboratoryPatientDataFileId)}>
                                                                                <Button outline color="info" size="sm"><i className="flaticon2-edit" />Edit</Button>
                                                                            </Link>*/}

                                                                            <Link onClick={e => this.toggle(e, data.ngsLaboratoryPatientDataFileId)}>
                                                                                <Button outline color="danger" size="sm"><i className="flaticon2-delete" />Delete</Button>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>);
                                                                })
                                                        ) : (
                                                                <tr>
                                                                    <td colSpan="5" className="tdCenter">No data files found...</td></tr>
                                                            // )) : (
                                                            // <tr>
                                                            //     <td colSpan="5" className="tdCenter">Loading...</td></tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            {/*<div className="kt-widget4">
                                                    {analysisfiles.length > 0 ? (
                                                        analysisfiles
                                                            .map((data, i) => {
                                                                return (
                                                                    <div className="kt-widget4__item" key={i}>
                                                                        <span>{i + 1}.)</span>
                                                                        <a href={data.filePath} target="_blank">
                                                                            <i className="flaticon2-download"></i>
                                                                        </a>
                                                                        <a onClick={e => this.deleteRow(e, data.ngsLaboratoryPatientDataFileId)} className="btn btn-clean btn-icon btn-sm">
                                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                        </a>
                                                                    </div>

                                                                )
                                                            })) : (null)}
                                                </div>*/}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.loading()}
                    {modal && 
                        <div style={{ marginLeft: "36%" }}>    
                            <Modal isOpen={modal} style={{ width: "500px" }} >
                                <ModalHeader>Confirm</ModalHeader>
                                <ModalBody>Are you sure want to delete this laboratory patient data file?</ModalBody>
                                <ModalFooter>
                                    <Button outline color="danger" onClick={e => this.deleteRow(e, deleteId)}>Delete</Button>
                                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    }
                    {preview &&
                        <>
                            <div className='preview-popup'>
                                <div className='preview-popup-modal'>
                                    <div className='preview-popup-header'>
                                        {url.split(".").splice(-1)[0] === "pdf" ? null : 
                                            // <a href={url} download target={`_blank`}>
                                                <img src={downloadIcon} style={{ margin:"0 12px", cursor: "pointer" }} alt='download' onClick={e => this.DownloadFile(e, fileUrl, number)} />
                                            // </a>
                                        }
                                        <img src={closeIcon} style={{ cursor: "pointer" }} alt='close' onClick={e => this.previewToggle(e, "", "")} />
                                    </div>
                                    <iframe src={url} title="previewFile" width="100%" height="90%" />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </React.Fragment >
        );
    }
}
