import React, { Component } from 'react'
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

import { Layout } from './components/Layout';
import { Login } from './views/Login';
import { Register } from './views/Register';
import { Confirmation } from './views/Confirmation';
import { ResetPassword } from './views/ResetPassword';
import { ForgetPassword } from './views/ForgetPassword';
import { ChangePassword } from './views/ChangePassword';
import { Home } from './views/Home';
import { TermsOfUse } from './views/TermsOfUse';
import { Sitemap } from './views/Sitemap';
import { PrivacyStatements } from './views/PrivacyStatements';
import { HealthcareProfessional } from './views/HealthcareProfessional';
import { Caregivers } from './views/Caregivers';
import { ContactUs } from './views/ContactUs';
import { Achievements } from './views/Achievements';
import { Services } from './views/Services';

import { PatientDashboard } from './views/Patient/PatientDashboard/PatientDashboard';
import { PatientProfile } from './views/Patient/PatientProfile/PatientProfile';
import { PatientEmergency } from './views/Patient/PatientEmergency/PatientEmergency';
import { PatientEmergencyDetail } from './views/Patient/PatientEmergency/PatientEmergencyDetail';
import { PatientInsurance } from './views/Patient/PatientInsurance/PatientInsurance';
import { PatientInsuranceDetail } from './views/Patient/PatientInsurance/PatientInsuranceDetail';
import { PatientDisease } from './views/Patient/PatientDisease/PatientDisease';
import { PatientDiseaseDetail } from './views/Patient/PatientDisease/PatientDiseaseDetail';
import { PatientPrescription } from './views/Patient/PatientPrescription/PatientPrescription';
import { PatientPrescriptionDetail } from './views/Patient/PatientPrescription/PatientPrescriptionDetail';
import { PatientDiagnostic } from './views/Patient/PatientDiagnostic/PatientDiagnostic';
import { PatientDiagnosticDetail } from './views/Patient/PatientDiagnostic/PatientDiagnosticDetail';

import { PatientTreatment } from './views/Patient/PatientTreatment/PatientTreatment';
import { PatientTreatmentDetail } from './views/Patient/PatientTreatment/PatientTreatmentDetail';

import { PatientPayment } from './views/Patient/PatientPayment/PatientPayment';
import { PatientPaymentDetail } from './views/Patient/PatientPayment/PatientPaymentDetail';

import { PractitionerDashboard } from './views/Practitioner/PratitionerDashboard/PratitionerDashboard';
import { PractitionerProfile } from './views/Practitioner/PratitionerProfile/PratitionerProfile';
import { PractitionerContact } from './views/Practitioner/PractitionerContact/PractitionerContact';
import { PractitionerContactDetail } from './views/Practitioner/PractitionerContact/PractitionerContactDetail';
import { PractitionerMedicalLicense } from './views/Practitioner/PractitionerMedicalLicense/PractitionerMedicalLicense';
import { PractitionerMedicalLicenseDetail } from './views/Practitioner/PractitionerMedicalLicense/PractitionerMedicalLicenseDetail';
import { PractitionerPatient } from './views/Practitioner/PratitionerPatient/PratitionerPatient';
import { PractitionerPatientDetail } from './views/Practitioner/PratitionerPatient/PratitionerPatientDetail';
import { PratitionerPatientModify } from './views/Practitioner/PratitionerPatient/PratitionerPatientModify';
import { PratitionerPayment } from './views/Practitioner/PratitionerPayment/PratitionerPayment';
import { PratitionerPaymentDetail } from './views/Practitioner/PratitionerPayment/PratitionerPaymentDetail';
import { PPatientTimeline } from './views/Practitioner/PratitionerDashboard/PPatientTimeline';
import { PratitionerSample } from './views/Practitioner/PratitionerSample/PratitionerSample';
import { VibrantHealthXForm } from './views/Practitioner/PratitionerPatient/VibrantHealthXForm';

import { InstituteDashboard } from './views/Institute/InstituteDashboard/InstituteDashboard';
import { InstituteProfile } from './views/Institute/InstituteProfile/InstituteProfile';
import { InstituteContactPerson } from './views/Institute/InstituteContactPerson/InstituteContactPerson';
import { InstituteContactPersonDetail } from './views/Institute/InstituteContactPerson/InstituteContactPersonDetail';
import { InstitutePayment } from './views/Institute/InstitutePayment/InstitutePayment';
import { InstitutePaymentDetail } from './views/Institute/InstitutePayment/InstitutePaymentDetail';
import { InstitutePatient } from './views/Institute/InstitutePatient/InstitutePatient';
import { InstitutePatientDetail } from './views/Institute/InstitutePatient/InstitutePatientDetail';
import { InstitutePatientModify } from './views/Institute/InstitutePatient/InstitutePatientModify';
import { InstitutePractitioner } from './views/Institute/InstitutePractitioner/InstitutePractitioner';
import { InstitutePractitionerDetail } from './views/Institute/InstitutePractitioner/InstitutePractitionerDetail';
import { IPatientTimeline } from './views/Institute/InstituteDashboard/IPatientTimeline';

import { LPatientTimeline } from './views/Laboratory/LaboratoryDashboard/LPatientTimeline';
import { LaboratoryDashboard } from './views/Laboratory/LaboratoryDashboard/LaboratoryDashboard';
import { LaboratoryPatient } from './views/Laboratory/LaboratoryPatient/LaboratoryPatient';
import { LaboratoryProfile } from './views/Laboratory/LaboratoryProfile/LaboratoryProfile';
import { LaboratoryContact } from './views/Laboratory/LaboratoryContact/LaboratoryContact';
import { LaboratoryContactDetail } from './views/Laboratory/LaboratoryContact/LaboratoryContactDetail';
import { LaboratoryAnalysis } from './views/Laboratory/LaboratoryDashboard/LaboratoryAnalysis';
import { LaboratoryDataFile } from './views/Laboratory/LaboratoryDashboard/LaboratoryDataFile';
import { LaboratoryPayment } from './views/Laboratory/LaboratoryPayment/LaboratoryPayment';

import { MPatientTimeline } from './views/Manufacturer/ManufacturerDashboard/MPatientTimeline';
import { ManufacturerDashboard } from './views/Manufacturer/ManufacturerDashboard/ManufacturerDashboard';
import { ManufacturerPatient } from './views/Manufacturer/ManufacturerPatient/ManufacturerPatient';
import { ManufacturerProfile } from './views/Manufacturer/ManufacturerProfile/ManufacturerProfile';
import { ManufacturerAnalysis } from './views/Manufacturer/ManufacturerDashboard/ManufacturerAnalysis';
import { ManufacturerPayment } from './views/Manufacturer/ManufacturerPayment/ManufacturerPayment';

const loading = () => <div id="loader-wrapper">
    <div id="loader"></div>
    <div className="loader-section section-left"></div>
    <div className="loader-section section-right"></div>
</div>;

const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
          localStorage.getItem('Usertoken') !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
export default class Routes extends Component {
    
  render() {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop>
                    <React.Suspense fallback={loading()}>
                        <Switch>
                            <Route exact path="/home" component={Home} />
                            <Route exact path="/" component={Login} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/register" component={Login} />
                            <Route exact path="/confirmation/:id" component={Confirmation} />
                            <Route exact path="/setpassword/:id" component={ResetPassword} />
                            <Route exact path="/forgetpassword/:id" component={ForgetPassword} />
                            <Route exact path="/home" component={Home} />
                            <Route exact path="/TermsOfUse" component={TermsOfUse} />
                            <Route exact path="/Sitemap" component={Sitemap} />
                            <Route exact path="/PrivacyStatements" component={PrivacyStatements} />
                            <Route exact path="/HealthcareProfessional" component={HealthcareProfessional} />
                            <Route exact path="/Caregivers" component={Caregivers} />
                            <Route exact path="/ContactUs" component={ContactUs} />
                            <Route exact path="/Achievements" component={Achievements} />
                            <Route exact path="/Services" component={Services} />
                            <Layout>

                                <ProtectedRoute exact path="/patient/dashboard" component={PatientDashboard} />
                                <ProtectedRoute exact path="/patient/profile" component={PatientProfile} />

                                <ProtectedRoute exact path="/patient/emergency/list" component={PatientEmergency} />
                                <ProtectedRoute exact path="/patient/emergency/detail" component={PatientEmergencyDetail} />
                                <ProtectedRoute exact path="/patient/emergency/modify/:id" component={PatientEmergencyDetail} />

                                <ProtectedRoute exact path="/patient/disease/list" component={PatientDisease} />
                                <ProtectedRoute exact path="/patient/disease/detail" component={PatientDiseaseDetail} />
                                <ProtectedRoute exact path="/patient/disease/modify/:id" component={PatientDiseaseDetail} />

                                <ProtectedRoute exact path="/patient/insurance/list" component={PatientInsurance} />
                                <ProtectedRoute exact path="/patient/insurance/detail" component={PatientInsuranceDetail} />
                                <ProtectedRoute exact path="/patient/insurance/modify/:id" component={PatientInsuranceDetail} />

                                <ProtectedRoute exact path="/patient/prescription/list" component={PatientPrescription} />
                                <ProtectedRoute exact path="/patient/prescription/detail" component={PatientPrescriptionDetail} />
                                <ProtectedRoute exact path="/patient/prescription/modify/:id" component={PatientPrescriptionDetail} />

                                <ProtectedRoute exact path="/patient/diagnostic/list" component={PatientDiagnostic} />
                                <ProtectedRoute exact path="/patient/diagnostic/detail" component={PatientDiagnosticDetail} />
                                <ProtectedRoute exact path="/patient/diagnostic/modify/:id" component={PatientDiagnosticDetail} />

                                <ProtectedRoute exact path="/patient/treatment/list" component={PatientTreatment} />
                                <ProtectedRoute exact path="/patient/treatment/detail" component={PatientTreatmentDetail} />
                                <ProtectedRoute exact path="/patient/treatment/modify/:id" component={PatientTreatmentDetail} />

                                <ProtectedRoute exact path="/patient/payment/list" component={PatientPayment} />
                                <ProtectedRoute exact path="/patient/payment/detail" component={PatientPaymentDetail} />

                                <ProtectedRoute exact path="/practitioner/patient/analysisprogress" component={PPatientTimeline} />
                                <ProtectedRoute exact path="/practitioner/dashboard" component={PractitionerDashboard} />
                                <ProtectedRoute exact path="/practitioner/profile" component={PractitionerProfile} />
                                <ProtectedRoute exact path="/practitioner/contact/list" component={PractitionerContact} />
                                <ProtectedRoute exact path="/practitioner/contact/detail" component={PractitionerContactDetail} />
                                <ProtectedRoute exact path="/practitioner/contact/modify/:id" component={PractitionerContactDetail} />
                                <ProtectedRoute exact path="/practitioner/medical/list" component={PractitionerMedicalLicense} />
                                <ProtectedRoute exact path="/practitioner/medical/detail" component={PractitionerMedicalLicenseDetail} />
                                <ProtectedRoute exact path="/practitioner/medical/modify/:id" component={PractitionerMedicalLicenseDetail} />
                                <ProtectedRoute exact path="/practitioner/patient/profile/:id" component={PractitionerPatient} />
                                <ProtectedRoute exact path="/practitioner/patient/detail" component={PractitionerPatientDetail} />
                                <ProtectedRoute exact path="/practitioner/patient/modify/:id" component={PratitionerPatientModify} />
                                <ProtectedRoute exact path="/practitioner/patient/payment" component={PratitionerPayment} />
                                <ProtectedRoute exact path="/practitioner/patient/payment/detail" component={PratitionerPaymentDetail} />
                                <ProtectedRoute exact path="/practitioner/patient/specimencollection" component={PratitionerSample} />
                                <ProtectedRoute exact path="/practitioner/patient/vibranthealthform/:id/:paid" component={VibrantHealthXForm} />

                                <ProtectedRoute exact path="/institute/patient/analysisprogress" component={IPatientTimeline} />
                                <ProtectedRoute exact path="/institute/dashboard" component={InstituteDashboard} />
                                <ProtectedRoute exact path="/institute/profile" component={InstituteProfile} />
                                <ProtectedRoute exact path="/institute/contact/list" component={InstituteContactPerson} />
                                <ProtectedRoute exact path="/institute/contact/detail" component={InstituteContactPersonDetail} />
                                <ProtectedRoute exact path="/institute/contact/modify/:id" component={InstituteContactPersonDetail} />
                                <ProtectedRoute exact path="/institute/patient/payment" component={InstitutePayment} />
                                <ProtectedRoute exact path="/institute/patient/payment/detail" component={InstitutePaymentDetail} />
                                <ProtectedRoute exact path="/institute/patient/profile/:id" component={InstitutePatient} />
                                <ProtectedRoute exact path="/institute/patient/detail" component={InstitutePatientDetail} />
                                <ProtectedRoute exact path="/institute/patient/modify/:id" component={InstitutePatientModify} />
                                <ProtectedRoute exact path="/institute/practitioner/list" component={InstitutePractitioner} />
                                <ProtectedRoute exact path="/institute/practitioner/detail" component={InstitutePractitionerDetail} />
                                <ProtectedRoute exact path="/institute/practitioner/modify/:id" component={InstitutePractitionerDetail} />

                                <ProtectedRoute exact path="/laboratory/patientanalysisprogress" component={LPatientTimeline} />
                                <ProtectedRoute exact path="/laboratory/dashboard" component={LaboratoryDashboard} />
                                <ProtectedRoute exact path="/laboratory/patient/:id" component={LaboratoryPatient} />
                                <ProtectedRoute exact path="/laboratory/profile" component={LaboratoryProfile} />
                                <ProtectedRoute exact path="/laboratory/contact/list" component={LaboratoryContact} />
                                <ProtectedRoute exact path="/laboratory/contact/detail" component={LaboratoryContactDetail} />
                                <ProtectedRoute exact path="/laboratory/contact/modify/:id" component={LaboratoryContactDetail} />
                                <ProtectedRoute exact path="/laboratory/analysis/:id" component={LaboratoryAnalysis} />
                                <ProtectedRoute exact path="/laboratory/datafile/:id" component={LaboratoryDataFile} />
                                <ProtectedRoute exact path="/laboratory/payment" component={LaboratoryPayment} />

                                <ProtectedRoute exact path="/manufacturer/patientanalysisprogress" component={MPatientTimeline} />
                                <ProtectedRoute exact path="/manufacturer/dashboard" component={ManufacturerDashboard} />
                                <ProtectedRoute exact path="/manufacturer/patient/:id" component={ManufacturerPatient} />
                                <ProtectedRoute exact path="/manufacturer/profile" component={ManufacturerProfile} />
                                <ProtectedRoute exact path="/manufacturer/analysis/:id" component={ManufacturerAnalysis} />
                                <ProtectedRoute exact path="/manufacturer/payment" component={ManufacturerPayment} />

                                <Route exact path="/changepassword" component={ChangePassword} />
                            </Layout>

                        </Switch>
                    </React.Suspense>
                </ScrollToTop>
            </BrowserRouter>
        </>
    )
  }
}

// import React from 'react';
// //import { Login } from './views/Login';
// //import { Register } from './views/Register';

// //const CustomModal = React.lazy(() => import('./views/CustomModal/CustomModal'));

// //const Home = React.lazy(() => import('./views/Home'));
// const Login = React.lazy(() => import('./views/Login'));

// // https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
// const routes = [
//     //{ path: '/login', exact: true, name: 'Login', component: Login },
//     //{ path: '/register', exact: true, name: 'Register', component: Register },
//     //{ path: '/customModal', name: 'CustomModal', component: CustomModal },
//     { path: '/login', name: 'Login', component: Login },
// ];

// export default routes;