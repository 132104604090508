import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../../CustomModal/CustomModal';

export class ManufacturerProfile extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            userId: "",
            manufacturerId: 0,
            companyName: "",
            contactPerson: "",
            accessionNo: "",
            fax: "",
            userName: "",
            email: "",
            countryId: "",
            countries: [],
            stateId: "",
            states: [],
            cityId: "",
            cities: [],
            errors: {
                countryId: '',
                contactPerson: '',
                stateId: '',
                cityId: '',
                companyName: '',
                countryId: '',
                mobile: '',
                address: '',
                phoneNumber: '',
                postalCode: '',
                website: "",
            },
            website: "",
            mobile: "",
            address: "",
            phoneNumber: "",
            postalCode: "",
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.getData();
    //    }
    //}

    componentDidMount() {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/CognitoUserStore/getallcountry';
        let data = JSON.stringify({
            isDeleted: false,
            searchString: ''
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    countries: result.data.outdata
                }, () => { this.getData() });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    //get detail(for update)
    getData() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.userId == null ? 0 : userToken.userId);
        }

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/Manufacturer/GetByuserId?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;
                    console.log(result.data.outdata);
                    this.setState({
                        manufacturerId: rData.manufacturerId, companyName: rData.companyName, website: rData.website,
                        fax: rData.fax, userName: rData.userName, email: rData.email, mobile: rData.mobile, website: rData.website,
                        address: rData.address, accessionNo: rData.accessionNo, contactPerson: rData.contactPerson,
                        phoneNumber: rData.phoneNumber, postalCode: rData.postalCode, countryId: rData.countryId,
                        //, stateId: rData.stateId, cityId: rData.cityId
                    }, () => {
                        if (rData.countryId != null) {
                            this.getStateData(rData.countryId, rData.stateId, rData.cityId)
                        }
                        else { this.setState({ loading: false }); }
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    getStateData(countryId, stateId, cityId) {

        const apiroute = window.$APIPath;

        const url = apiroute + '/api/CognitoUserStore/getallstate?Id=' + countryId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    //console.log(result.data);
                    this.setState({ states: result.data.outdata, stateId: stateId }, () => {
                        if (stateId != "" || stateId != null) {
                            this.getCityData(stateId, cityId)
                        }
                        else { this.setState({ loading: false }); }
                    });
                }
                else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getCityData(stateId, cityId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/CognitoUserStore/getallcity?Id=' + stateId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                //console.log(result.data);
                this.setState({ cities: result.data.outdata, cityId: cityId, loading: false });
            }
            else { this.setState({ loading: false }); }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        //if (this.state.cityId == undefined || this.state.cityId == '') {
        //    errors.cityId = 'Please select city.';
        //}
        //if (this.state.stateId == undefined || this.state.stateId == '') {
        //    errors.stateId = 'Please select state.';
        //}
        //if (this.state.countryId == undefined || this.state.countryId == '') {
        //    errors.countryId = 'Please select country.';
        //}
        if (this.state.companyName == undefined || this.state.companyName == '') {
            errors.companyName = 'Please enter company name.';
        }
        //if (this.state.contactPerson == undefined || this.state.contactPerson == '') {
        //    errors.contactPerson = 'Please enter contact person.';
        //}
        if (this.state.mobile == undefined || this.state.mobile == '') {
           errors.mobile = 'Please enter primary phone.';
        }
        //if (this.state.website == undefined || this.state.website == '') {
        //    errors.website = 'Please enter website.';
        //}
        //if (this.state.address == undefined || this.state.address == '') {
        //    errors.address = 'Please enter address.';
        //}
        // if (this.state.phoneNumber == undefined || this.state.phoneNumber == '') {
        //    errors.phoneNumber = 'Please enter secondary phone.';
        // }
        //if (this.state.postalCode == undefined || this.state.postalCode == '') {
        //    errors.postalCode = 'Please enter postal code.';
        //}

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        const validMobileRegex = RegExp(/^[0-9+() -]+$/);
        const validNumberRegex = RegExp(/^[0-9]+$/);
        const validAlphaRegex = RegExp(/^[a-zA-Z \b]+$/);
        const validWebsiteRegex = RegExp(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/);

        switch (name) {
            //case 'stateId':
            //    errors.stateId = (!value) ? 'Please select state.' : '';

            //    break;
            //case 'cityId':
            //    errors.cityId = (!value) ? 'Please select city.' : '';
            //    break;

            //case 'countryId':
            //    errors.countryId = (!value) ? 'Please select Country.' : '';
            //    break;

            case 'companyName':
                errors.companyName = (!value) ? 'Please enter company name.' : (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.');
                this.setState({ companyName: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;

            case 'mobile':
                errors.mobile = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : 'Please enter primary phone.';
                this.setState({ mobile: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            //case 'Address':
            //    errors.Address = (!value) ? 'Please enter address.' : '';
            //    break;

            case 'phoneNumber':
                errors.phoneNumber = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ phoneNumber: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            case 'postalCode':
                errors.postalCode = (value) ? (validNumberRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ postalCode: value.replace(/[^0-9]+$/, '') })
                break;

            case 'website':
                errors.website = (value) ? (validWebsiteRegex.test(value) ? '' : 'Invalid website url.') : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors }, () => {

            if (name == 'countryId') {
                if (value != '') {
                    this.getStateData(value, "", "");
                    this.setState({ cities: [], cityId: "" });
                }
                else {
                    this.setState({ states: [], stateId: "", cities: [], cityId: "" });
                }
            }
            if (name == 'stateId') {
                if (value != '') {
                    this.getCityData(value, "");
                }
                else {
                    this.setState({ cities: [], cityId: "" });
                }
            }

        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let userid = (userToken.userId == null ? 0 : userToken.userId);

        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            if (this.state.manufacturerId == 0) {
                url = apiroute + '/api/Manufacturer/Save';
            }
            else {
                url = apiroute + '/api/Manufacturer/Update';
            }

            let data = JSON.stringify({
                ManufacturerId: parseInt(this.state.manufacturerId),
                companyName: this.state.companyName,
                contactPerson: this.state.contactPerson,
                fax: this.state.fax,
                website: this.state.website,
                userName: this.state.email,
                email: this.state.email,
                address: this.state.address,
                PhoneNumber: this.state.phoneNumber,
                mobile: this.state.mobile,
                postalCode: this.state.postalCode,
                cityId: ((this.state.cityId == "" || this.state.cityId == 0) ? null : parseInt(this.state.cityId)),
                countryId: ((this.state.countryId == "" || this.state.countryId == 0) ? null : parseInt(this.state.countryId)),
                stateId: ((this.state.stateId == "" || this.state.stateId == 0) ? null : parseInt(this.state.stateId)),
                userId: parseInt(userid),
                createdBy: parseInt(userid),
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // //error: result.data.message,
                            // error: "Profile updated successfully.",
                            loading: false
                        }, this.getData());
                        toast.success("Profile updated successfully.")
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    //console.log(error);
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            this.setState({ loading: false });
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, accessionNo, companyName, contactPerson, website, fax, userName, email, countryId, countries, stateId, states, cityId, cities, mobile, address, phoneNumber, postalCode,
            authError, error, errorType, errors } = this.state;

        return (

            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                                Profile                      
                                            </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/manufacturer/profile" className="kt-subheader__breadcrumbs-link">
                                                    Profile                      </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Company Name</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="1" placeholder="Enter your company name" name="companyName" value={companyName} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.companyName.length > 0 && <span className='error'>{errors.companyName}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Email</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="50" tabIndex="2" name="email" placeholder="Enter a valid email address" value={email} onChange={this.handleInputChange.bind(this)} autoComplete="email" disabled />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Website</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="50" tabIndex="3" placeholder="Enter your website" name="website" value={website} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.website.length > 0 && <span className='error'>{errors.website}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Fax</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="50" tabIndex="4" placeholder="Enter your fax" name="fax" value={fax} onChange={this.handleInputChange.bind(this)} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            {/*<div className="col-md-6">
                                                                <label className="col-12 col-form-label">User Name</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="100" tabIndex="5" placeholder="Enter your User Name" name="userName" value={userName} onChange={this.handleInputChange.bind(this)} autoComplete="userName" disabled />
                                                                </div>
                                                            </div>*/}
                                                            <div className="col-md-12">
                                                                <label className="col-12 col-form-label">Contact Person</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="5" placeholder="Enter your conatct person detail" name="contactPerson" value={contactPerson} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.contactPerson.length > 0 && <span className='error'>{errors.contactPerson}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Primary Phone</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="7" name="mobile" placeholder="Enter primary phone" value={mobile} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.mobile.length > 0 && <span className='error'>{errors.mobile}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Secondary Phone</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="6" name="phoneNumber" placeholder="Enter secondary phone" value={phoneNumber} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.phoneNumber.length > 0 && <span className='error'>{errors.phoneNumber}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Country</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="8" name="countryId" value={countryId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Country</option>
                                                                        {countries
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.countryId.length > 0 && <span className='error'>{errors.countryId}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">State</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="9" name="stateId" value={stateId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select State</option>
                                                                        {states
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.stateId.length > 0 && <span className='error'>{errors.stateId}</span>}
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">City</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="10" name="cityId" value={cityId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select City</option>
                                                                        {cities
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.cityId.length > 0 && <span className='error'>{errors.cityId}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Postal Code</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="11" name="postalCode" placeholder="Enter a postal code" value={postalCode} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.postalCode.length > 0 && <span className='error'>{errors.postalCode}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-12">
                                                                <label className="col-12 col-form-label">Address</label>
                                                                <div className="col-12">
                                                                    <textarea className="form-control here" type="text" maxLength="500" tabIndex="12" name="address" placeholder="Enter a address" value={address} onChange={this.handleInputChange.bind(this)} ></textarea>
                                                                    {errors.address.length > 0 && <span className='error'>{errors.address}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {loading ?
                                                            <button tabIndex="13" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                            :
                                                            <button tabIndex="13" type="submit" className="btn btn-primary">Submit</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.loading()}
                </div>
            </React.Fragment >
        );
    }
}
