import React, { Component } from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Table, Button
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../../CustomModal/CustomModal';

export class LaboratoryAnalysis extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            patientactivities: [],
            showpatientactivities: [],
            sampletypes: [],
            sampleTypeId: "",
            ngsLaboratoryPatientActivityId: "",
            patientId: "",
            patientAccessionId: "",
            title: "",
            description: "",
            // authError: false,
            error: '',
            errorType: '',
            show: false,
            showErr: '',
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //}

    handleClose = () => {
        this.setState({
            sampleTypeId: "",
            ngsLaboratoryPatientActivityId: "",
            patientId: "",
            show: false
        });
    }

    handleShow = (id, aid, pid) => {
        this.setState({
            sampleTypeId: id,
            ngsLaboratoryPatientActivityId: aid,
            patientId: pid,
            show: true,
        });
    }

    componentDidMount() {
        const param = this.props.match.params;

        if (param.id != undefined) {
            this.setState({ patientAccessionId: param.id });
            this.getListData(param.id);
        }
        else {
            this.setState({ loading: false });
        }
    }

    //get detail(for update)
    getListData(pid) {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);
        }
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/NGSLaboratoryPatientActivity/GetByPatientAccessionandLabId?id=' + pid + '&&lid=' + id + '';

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    let rData = result.data.outdata;
                    console.log(rData);
                    this.setState({
                        patientactivities: rData.ngsLaboratoryPatientActivityData, sampleTypeId: "",
                        //patientId: rData.ngsLaboratoryPatientActivityData.patientId,
                        sampletypes: rData.sampleTypeData, showpatientactivities: rData.ngsLaboratoryPatientActivityData, loading: false
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name == "sampleTypeId") {
            if (value != "" && value != "0") {
                let showdata = this.state.patientactivities.filter(pa => pa.sampleTypeId == parseInt(value));
                //console.log(showdata);
                if (showdata.length > 0) {
                    this.setState({
                        showpatientactivities: showdata,
                        title: (showdata[0].title ? showdata[0].title : ""),
                        description: (showdata[0].description ? showdata[0].description : ""),
                    });
                } else {
                    this.setState({
                        showpatientactivities: [],
                        title: '',
                        description: '',
                    });
                }
            }
            else if (value == "0") {
                this.setState({
                    showpatientactivities: this.state.patientactivities
                });
            }
            else {
                this.setState({
                    showpatientactivities: []
                });
            }
        }
    }

    //update title and description
    AddDetail(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        if (this.state.title != "" && this.state.description != "") {
            var userToken = JSON.parse(localStorage.getItem('Usertoken'));
            let pid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

            const apiroute = window.$APIPath;
            url = apiroute + '/api/NGSLaboratoryPatientActivity/UpdateDetail';
            console.log(this.state.showpatientactivities);
            //let ngsactivityId = this.state.showpatientactivities[0].ngsLaboratoryPatientActivityId;
            let ngsactivityId = this.state.ngsLaboratoryPatientActivityId;

            let data = JSON.stringify({
                ngsLaboratoryId: parseInt(pid),
                title: this.state.title,
                description: this.state.description,
                sampleTypeId: parseInt(this.state.sampleTypeId),
                patientId: parseInt(this.state.patientId),
                patientAccessionId: parseInt(this.state.patientAccessionId),
                ngsLaboratoryPatientActivityId: parseInt(ngsactivityId),
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            title: '',
                            description: '',
                            sampleTypeId: '',
                            ngsLaboratoryPatientActivityId: '',
                            show: false
                        }, this.getListData(this.state.patientAccessionId));
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            loading: false,
                            showErr: result.data.message
                        });
                    }
                })
                .catch(error => {
                    //this.setState({
                    //    authError: true, errorType: 'danger', error: error.message, loading: false
                    //});
                    this.setState({ loading: false, showErr: error.message });
                });
        } else {
            this.setState({
                loading: false,
                show: false
            });
        }
    }

    //update status
    UpdateSampleReceive(e, id, sid, ptid) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

        const apiroute = window.$APIPath;
        url = apiroute + '/api/NGSLaboratoryPatientActivity/UpdateNGSLaboratoryPatientActivitySampleReceived';

        let data = JSON.stringify({
            ngsLaboratoryId: parseInt(pid),
            SampleReceived: true,
            //sampleTypeId: parseInt(this.state.sampleTypeId),
            sampleTypeId: parseInt(sid),
            patientId: parseInt(ptid),
            patientAccessionId: parseInt(this.state.patientAccessionId),
            ngsLaboratoryPatientActivityId: parseInt(id),
        })

        console.log(data);
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData(this.state.patientAccessionId));
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    UpdateQCPass(e, id, sid, ptid) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

        const apiroute = window.$APIPath;
        url = apiroute + '/api/NGSLaboratoryPatientActivity/UpdateNGSLaboratoryPatientActivitySampleQCPassed';

        let data = JSON.stringify({
            ngsLaboratoryId: parseInt(pid),
            SampleQCPassed: true,
            //sampleTypeId: parseInt(this.state.sampleTypeId),
            sampleTypeId: parseInt(sid),
            patientId: parseInt(ptid),
            patientAccessionId: parseInt(this.state.patientAccessionId),
            ngsLaboratoryPatientActivityId: parseInt(id),
        })

        console.log(data);
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData(this.state.patientAccessionId));
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    UpdateQCFail(e, id, sid, ptid) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

        const apiroute = window.$APIPath;
        url = apiroute + '/api/NGSLaboratoryPatientActivity/UpdateNGSLaboratoryPatientActivitySampleQCFailed';

        let data = JSON.stringify({
            ngsLaboratoryId: parseInt(pid),
            SampleQCFailed: true,
            //sampleTypeId: parseInt(this.state.sampleTypeId),
            sampleTypeId: parseInt(sid),
            patientId: parseInt(ptid),
            patientAccessionId: parseInt(this.state.patientAccessionId),
            ngsLaboratoryPatientActivityId: parseInt(id),
        })

        console.log(data);
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData(this.state.patientAccessionId));
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    UpdateStartAnalysis(e, id, sid, ptid) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

        const apiroute = window.$APIPath;
        url = apiroute + '/api/NGSLaboratoryPatientActivity/UpdateNGSLaboratoryPatientActivityAnalysisStarted';

        let data = JSON.stringify({
            ngsLaboratoryId: parseInt(pid),
            AnalysisStarted: true,
            //sampleTypeId: parseInt(this.state.sampleTypeId),
            sampleTypeId: parseInt(sid),
            patientId: parseInt(ptid),
            patientAccessionId: parseInt(this.state.patientAccessionId),
            ngsLaboratoryPatientActivityId: parseInt(id),
        })

        console.log(data);
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData(this.state.patientAccessionId));
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    UpdateNewRequest(e, id, sid, ptid) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

        const apiroute = window.$APIPath;
        url = apiroute + '/api/NGSLaboratoryPatientActivity/UpdateNGSLaboratoryPatientActivityNewSampleRequested';

        let data = JSON.stringify({
            ngsLaboratoryId: parseInt(pid),
            NewSampleRequested: true,
            //sampleTypeId: parseInt(this.state.sampleTypeId),
            sampleTypeId: parseInt(sid),
            patientId: parseInt(ptid),
            patientAccessionId: parseInt(this.state.patientAccessionId),
            ngsLaboratoryPatientActivityId: parseInt(id),
        })

        console.log(data);
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData(this.state.patientAccessionId));
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    UpdateCompleteAnalysis(e, id, sid, ptid) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

        const apiroute = window.$APIPath;
        url = apiroute + '/api/NGSLaboratoryPatientActivity/UpdateNGSLaboratoryPatientActivityAnalysisCompleted';

        let data = JSON.stringify({
            ngsLaboratoryId: parseInt(pid),
            AnalysisCompleted: true,
            //sampleTypeId: parseInt(this.state.sampleTypeId),
            sampleTypeId: parseInt(sid),
            patientId: parseInt(ptid),
            patientAccessionId: parseInt(this.state.patientAccessionId),
            ngsLaboratoryPatientActivityId: parseInt(id),
        })

        console.log(data);
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData(this.state.patientAccessionId));
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    UpdateAnalysisQCPass(e, id, sid, ptid) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

        const apiroute = window.$APIPath;
        url = apiroute + '/api/NGSLaboratoryPatientActivity/UpdateNGSLaboratoryPatientActivityAnalysisQCPassed';

        let data = JSON.stringify({
            ngsLaboratoryId: parseInt(pid),
            AnalysisQCPassed: true,
            //sampleTypeId: parseInt(this.state.sampleTypeId),
            sampleTypeId: parseInt(sid),
            patientId: parseInt(ptid),
            patientAccessionId: parseInt(this.state.patientAccessionId),
            ngsLaboratoryPatientActivityId: parseInt(id),
        })

        console.log(data);
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData(this.state.patientAccessionId));
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    UpdateDataFileReady(e, id, sid, ptid) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

        const apiroute = window.$APIPath;
        url = apiroute + '/api/NGSLaboratoryPatientActivity/UpdateNGSLaboratoryPatientActivityDataFileReady';

        let data = JSON.stringify({
            ngsLaboratoryId: parseInt(pid),
            DataFileReady: true,
            //sampleTypeId: parseInt(this.state.sampleTypeId),
            sampleTypeId: parseInt(sid),
            patientId: parseInt(ptid),
            patientAccessionId: parseInt(this.state.patientAccessionId),
            ngsLaboratoryPatientActivityId: parseInt(id),
        })

        console.log(data);
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData(this.state.patientAccessionId));
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, sampletypes, sampleTypeId, title, description, showpatientactivities,
            patientactivities, error, errorType, authError,
            show, showErr, ngsLaboratoryPatientActivityId } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                                <Link to="/laboratory/dashboard" className="kt-subheader__title">
                                                    Dashboard
                                                </Link>
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="#" className="kt-subheader__breadcrumbs-link">
                                                    Analysis                       </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        {/* {authError ?
                                            <div>
                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                    <div className="alert-text">{error}</div>
                                                    <div className="alert-close">
                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                                        <div className="kt-portlet__body">
                                            {/*<div className="form-group row my-4">
                                                <label className="col-12 col-form-label">Sample Type</label>
                                                <div className="col-12">
                                                    <Input type="select" name="sampleTypeId" value={sampleTypeId} onChange={this.handleInputChange.bind(this)}>
                                                        <option value="">Select Sample Type</option>
                                                        //<option value="0">All</option>
                                                        {sampletypes
                                                            .map((data, i) => {
                                                                return (<option key={i} value={data.sampleTypeId}>{data.sampleTypeName}</option>);
                                                            })}
                                                    </Input>
                                                </div>
                                            </div>*/}

                                            <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                <thead>
                                                    <tr>
                                                        <th>Sample Run</th>
                                                        {/*<th>Lab Ref Number</th>
                                                        <th>Lab Name</th>
                                                        <th>Lab Email, Mobile No <br /> Address</th>*/}
                                                        {/*<th>Analysis Description</th>*/}
                                                        <th>Change Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        // !loading ? (
                                                        //standards.map(function (data,i) {
                                                        showpatientactivities.length > 0 ? (
                                                            showpatientactivities
                                                                .map((data, i) => {
                                                                    return (<tr key={i}>
                                                                        <td>{data.sampleTypeName}</td>
                                                                        {/*<td>{data.patientRefNo}</td>
                                                                        <td>{data.ngsLabName}</td>
                                                                        <td>{data.ngsLabEmail + ", " + data.ngsLabPhoneNo}<br />{data.ngsLabAddress}</td>*/}
                                                                        {/*<td>*/}
                                                                        {/*    {data.title != null ?*/}
                                                                        {/*        <React.Fragment>*/}
                                                                        {/*            <span>{data.title}</span>*/}
                                                                        {/*            <br />*/}
                                                                        {/*            <span>{data.description}</span>*/}
                                                                        {/*        </React.Fragment>*/}
                                                                        {/*        :*/}
                                                                        {/*        // <a className="btn btn-outline-info btn-pd-mt" onClick={() => this.handleShow(data.sampleTypeId, data.ngsLaboratoryPatientActivityId)}>Add</a>*/}
                                                                        {/*        <Button outline color="info" size="sm" onClick={() => this.handleShow(data.sampleTypeId, data.ngsLaboratoryPatientActivityId, data.patientId)}>Add</Button>*/}
                                                                        {/*    }*/}

                                                                        {/*</td>*/}
                                                                        <td>
                                                                            {data.currentStatus == "Data File Ready" ?
                                                                                <span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor": "#4DBD74" }}>{data.currentStatus}</span>
                                                                                :
                                                                                data.currentStatus == "Analysis Data And File Transferred" ?
                                                                                    <span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor": "#529546" }}>{data.currentStatus}</span>
                                                                                    :
                                                                                    data.currentStatus == "Not Available" || data.currentStatus == "Not Assigned" ?
                                                                                        <span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor": "#F86C6B" }}>{data.currentStatus}</span>
                                                                                        :
                                                                                        <span className="kt-shape-bg-color-3" style={{ "padding": "3px 4px", "color": "#fff" }}>{data.currentStatus}</span>
                                                                            }
                                                                            <br />
                                                                            {(data.currentStatus != "Not Available" ?
                                                                                (data.currentStatus == "Sample Not Received" || data.currentStatus == "New Sample Requested" ?
                                                                                    <a className="btn btn-outline-info btn-pd-mt" onClick={e => { if (window.confirm('Are you sure want to receive sample?')) this.UpdateSampleReceive(e, data.ngsLaboratoryPatientActivityId, data.sampleTypeId, data.patientId) }}>Receive Sample</a>
                                                                                    :
                                                                                    (data.currentStatus == "Sample Received" ?
                                                                                        <React.Fragment>
                                                                                            <a className="btn btn-outline-info btn-pd-mt" onClick={e => { if (window.confirm('Are you sure want to pass QC?')) this.UpdateQCPass(e, data.ngsLaboratoryPatientActivityId, data.sampleTypeId, data.patientId) }}>QC Pass</a>{" "}
                                                                                            <a className="btn btn-outline-warning btn-pd-mt" onClick={e => { if (window.confirm('Are you sure want to Fail QC?')) this.UpdateQCFail(e, data.ngsLaboratoryPatientActivityId, data.sampleTypeId, data.patientId) }}>QC Fail</a>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        (data.currentStatus == "Sample QC Passed" ?
                                                                                            <a className="btn btn-outline-info btn-pd-mt" onClick={e => { if (window.confirm('Are you sure want to start analysis?')) this.UpdateStartAnalysis(e, data.ngsLaboratoryPatientActivityId, data.sampleTypeId, data.patientId) }}>Start Analysis</a>
                                                                                            :
                                                                                            (data.currentStatus == "QC Failed" ?
                                                                                                <a className="btn btn-outline-info btn-pd-mt" onClick={e => { if (window.confirm('Are you sure want to send new sample request?')) this.UpdateNewRequest(e, data.ngsLaboratoryPatientActivityId, data.sampleTypeId, data.patientId) }}>Request New Sample</a>
                                                                                                :
                                                                                                (data.currentStatus == "Analysis Started" ?
                                                                                                    <a className="btn btn-outline-info btn-pd-mt" onClick={e => { if (window.confirm('Are you sure want to complete analysis?')) this.UpdateCompleteAnalysis(e, data.ngsLaboratoryPatientActivityId, data.sampleTypeId, data.patientId) }}>Complete Analysis</a>
                                                                                                    :
                                                                                                    (data.currentStatus == "Analysis Completed" ?
                                                                                                        <a className="btn btn-outline-info btn-pd-mt" onClick={e => { if (window.confirm('Are you sure want to pass QC for analysis?')) this.UpdateAnalysisQCPass(e, data.ngsLaboratoryPatientActivityId, data.sampleTypeId, data.patientId) }}>Analysis QC Pass</a>
                                                                                                        :
                                                                                                        (data.currentStatus == "Analysis QC Passed" ?
                                                                                                            <a className="btn btn-outline-info btn-pd-mt" onClick={e => { if (window.confirm('Are you sure that data file is ready?')) this.UpdateDataFileReady(e, data.ngsLaboratoryPatientActivityId, data.sampleTypeId, data.patientId) }}>Data File Ready</a>
                                                                                                            :
                                                                                                            (null)
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                                :
                                                                                null)}
                                                                        </td>
                                                                    </tr>);
                                                                })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="3" className="tdCenter">No analysis found...</td></tr>
                                                            // )) : (
                                                            // <tr>
                                                            //     <td colSpan="3" className="tdCenter">Loading...</td></tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            {/*<hr />
                                            sampleTypeId != "" && sampleTypeId != "0" ?
                                                <div>
                                                    <div className="form-group row my-4">
                                                        <div className="col-md-6">
                                                            <label className="col-12 col-form-label">Title</label>
                                                            <div className="col-12">
                                                                <Input className="form-control here" type="text" maxLength="100" tabIndex="1" placeholder="Enter title" name="title" value={title} onChange={this.handleInputChange.bind(this)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="col-12 col-form-label">Description</label>
                                                            <div className="col-12">
                                                                <Input className="form-control here" type="textarea" tabIndex="2" placeholder="Enter description" name="description" value={description} onChange={this.handleInputChange.bind(this)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {loading ?
                                                        <button tabIndex="3" type="button" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                        :
                                                        <button tabIndex="3" type="button" onClick={this.AddDetail.bind(this)} className="btn btn-primary">Submit</button>
                                                    }
                                                </div>
                                                : (null)*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal isOpen={show} className="modal-dialog modal-sm">
                        <ModalHeader>
                            Sample Ref No
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="form-control-label">Title</label>
                                    <Input className="form-control here" type="text" maxLength="100" tabIndex="1" placeholder="Enter title" name="title" value={title} onChange={this.handleInputChange.bind(this)} />
                                </div>
                                <label htmlFor="recipient-name" className="form-control-label">Description</label>
                                <Input className="form-control here" type="textarea" tabIndex="2" placeholder="Enter description" name="description" value={description} onChange={this.handleInputChange.bind(this)} />
                            </div>
                            {showErr != "" &&
                                <div>
                                    <span className='error'>{showErr}</span>
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleClose}>
                                Close
                            </Button>
                            <Button color="primary" onClick={this.AddDetail.bind(this)}>
                                Add
                            </Button>
                        </ModalFooter>
                    </Modal>
                    {this.loading()}
                </div>
            </React.Fragment >
        );
    }
}
