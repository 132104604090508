import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../style-new.css'
import axios from 'axios';

export class Achievements extends Component {
    static displayName = Achievements.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            logout: false,
        };

        this.handleClickLogout = this.handleClickLogout.bind(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleClickLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('Usertoken');
        localStorage.setItem('isLoggedIn', false);
        this.setState({ logout: true });
    }

    renderRedirecttohome() {
        if (this.state.logout) {
            return <Redirect from="/" to="/login" />
        }
    }


    render() {
       
        return (
            <React.Fragment>

            <header className="header-area" id="header-area">
                <div className="dope-nav-container breakpoint-off">
                    <div className="container">
                        <div className="row">
                            {/* dope Menu */}
                            <nav className="dope-navbar justify-content-between" id="dopeNav">

                                {/* Logo */}
                                <Link to="/" className="nav-brand"><img src="img/header-logo.jpg" alt="" /></Link>

                                {/* Navbar Toggler */}
                                <div className="dope-navbar-toggler">
                                    <span className="navbarToggler">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                </div>

                                {/* Menu */}
                                <div className="dope-menu">

                                    {/* close btn */}
                                    <div className="dopecloseIcon">
                                        <div className="cross-wrap">
                                            <span className="top"></span>
                                            <span className="bottom"></span>
                                        </div>
                                    </div>



                                    {localStorage.getItem('token') != null ?
                                        (
                                            <div className="dopenav">
                                                <div className="top-rightside">
                                                    {(JSON.parse(localStorage.getItem('Usertoken')) != null ?
                                                        (JSON.parse(localStorage.getItem('Usertoken')).userType != 1 ?
                                                            (JSON.parse(localStorage.getItem('Usertoken')).userType != 2 ?
                                                                (JSON.parse(localStorage.getItem('Usertoken')).userType != 3 ?
                                                                    (JSON.parse(localStorage.getItem('Usertoken')).userType == 4 ?
                                                                        <Link to="/laboratory/dashboard" className="primary-btn" >Dashboard</Link>

                                                                        :
                                                                        <Link to="/manufacturer/dashboard" className="primary-btn" >Dashboard</Link>

                                                                    )
                                                                    :
                                                                    <Link to="/institute/dashboard" className="primary-btn" >Dashboard</Link>

                                                                )
                                                                :
                                                                <Link to="/practitioner/dashboard" className="primary-btn" >Dashboard</Link>

                                                            )
                                                            :
                                                            <Link to="/patient/dashboard" className="primary-btn" >Dashboard</Link>

                                                        )
                                                        : "")
                                                    }
                                                        <Link onClick={this.handleClickLogout} className="primary-btn">Logout</Link>
                                                </div>
                                                <ul id="nav">
                                                    <li>
                                                        <Link to="/Services" >Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link  to="/HealthcareProfessional" >Healthcare Professionals </Link>

                                                    </li>
                                                    <li>
                                                        <Link to="/Caregivers" >Patients and Caregivers</Link>

                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="dopenav">
                                                <div className="top-rightside">
                                                    <div className="">
                                                        <ul id="nav">
                                                            <li>
                                                                <Link to="/Services" >Services</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/HealthcareProfessional" >Healthcare Professionals</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/Caregivers" >Patients and Caregivers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/login" className="primary-btn">Login</Link>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                  
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>


            <section className="resources-2">
                <div className="container margintop">
               
                <h3 >Achievements</h3>
            <div className="span"  >
            <p><span>Neo7Logix designs personalized peptide vaccines that “reset” a patient’s immune system to fight</span></p>
        <p><span>disease (cancer, neurodegenerative or autoimmune). &nbsp; Neo7Logix analyzes a patient’s blood, urine and in the case of cancer, tumor, using the latest proteomic and genetic techniques. The results reveal mutations and disease related products/services that cause faults and block the patient’s own immune response to fight and regulate the disease. &nbsp;We then use our patent pending PBIMA process to design specific and unique peptides that will best reset the immune system to resolve the medical condition. Every patient has an immune fingerprint of susceptibility and strength in disease. Neo7logix types the patient’s immune susceptibility and strengths and selects only the unique peptides related and needed in the reparative fix as a therapeutic treatment. &nbsp;</span></p>
        <p><span>&nbsp;</span></p>
        <p><span>This analysis and design approach was developed by Neo7Logix’s CEO, <strong>Dr. John A Catanzaro</strong>, in the late 1990’s before advanced proteomic and genetic analyses were available. &nbsp; Using a “First Generation” approach, <strong>Dr. Catanzaro</strong> treated over 500 patients and achieved a 58% efficacy rate of life extension of 3, 5 and over 10 years on incurable terminal patients. The “Second Generation” PBIMA/SMART approach developed by <strong>John A Catanzaro, Anton Yuryev, and Md Shamsuddin Sultna Khan</strong> currently used by Neo7Logix promises to provide significantly improved efficacy. &nbsp;A unique aspect to Neo7Logix’s peptide vaccines is that they do not have adverse side effects as compared to some other treatments like chemotherapy or radiation and they can be combined with other drugs or treatments to provide maximum therapeutic flexibility.&nbsp;</span></p>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            </div>
            </section>
            <footer className="footer-section section-gap-half">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 footer-left">
                                <Link to="/">
                                    <img src="img/header-logo.jpg" alt="" />
                                </Link>
                                <p><i className="ti-location-pin"></i> 539 W. Commerce St #2886<br /> Dallas, TX 75208 </p>

                                <p className="copyright-text"> Copyright © 2020 Neo7Logix LLC- All Rights Reserved.</p>
                            </div>
                            <div className="col-lg-7">
                                <div className="footer-title">
                                    <h2>WE'RE HERE TO HELP</h2>
                                    <a href="tel:+12067185467">
                                        <p><i className="ti-mobile"></i>+1-206-718-5467</p>
                                    </a>
                                    <a href="mailto:office@neo7logix.com">
                                        <p><i className="ti-email"></i> office@neo7logix.com</p>
                                    </a>
                                </div>
                                <ul className="footer-menu">
                                    <li>
                                        <Link to="/Sitemap" >Sitemap</Link>
                                    </li>
                                    <li>
                                        <Link to="/TermsOfUse" >Terms of Use</Link>
                                    </li>
                                    <li>
                                        <Link to="/PrivacyStatements" >Privacy Statement</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>

                <div className="scroll-top">
                    <i className="ti-angle-up"></i>
                </div>
            </React.Fragment>

            
        );
    }
}
