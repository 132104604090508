import React, { Component } from 'react';
import {
    Card,
    CardBody,
    CardFooter, Col,
    FormGroup, Row, Button,
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input, Label, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import MyModal from './../../CustomModal/CustomModal';
import Moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";

export class PractitionerPatientDetail extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            PatientId: 0,
            PractitionerId: 0,
            PractitionerPatientId: 0,
            diseaseCategoryName: "cancer",
            UserId: 0,
            FirstName: "",
            MiddleName: "",
            LastName: "",
            UserName: "",
            Email: "",
            CountryId: 233,
            OCountryId: 1,
            countries: [],
            StateId: "",
            states: [],
            CityId: "",
            cities: [],
            Mobile: "",
            DateOfBirth: "",
            Age: "0",
            Address1: "",
            // Address2: "",
            email: '',
            Sex: "M",
            PhoneNumber: "",
            PostalCode: "",
            TissueId: "",
            disease: [],
            Alldisease: [],
            diseasedetails: [],
            diseaseCat: [],
            diseaseCatId: 0,
            diseaseId: "",
            newDisease: "",
            newDiseaseCode: "",
            newTissue: "",
            redirect: false,
            // weight: "",
            // height: "",
            // authError: false,
            error: '',
            errorType: '',
            allTissues: [],
            orderOfCountries: [], samples: [],
            subDiseaseCatId: 0,
            labs: '',
            errors: {
                samplecheckbox: '',
                FirstName: '',
                MiddleName: '',
                LastName: '',
                CountryId: '',
                StateId: '',
                CityId: '',
                Mobile: '',
                DateOfBirth: '',
                //Age: '',
                Address1: '',
                // Address2: '',
                PhoneNumber: '',
                PostalCode: '',
                UserName: '',
                Email: '',
                TissueId: "",
                diseaseId: "",
                newDisease: "",
                newDiseaseCode: "",
                newTissue: "",
                // weight: '',
                // height: '',
                //OCountryId: '',
                previewDetailsModal: false,
            },
            newTissue: "", checkBox: [],
            metasis: 'no',
            subDiseaseName: ""
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/practitioner/patient/list');
    //    }
    //}

    componentDidMount() {
        this.getCountry();
    }
    getCountry() {
        const apiroute = window.$APIPath;
        const url = apiroute + "/api/BE_Common/GetPatientDropdown";
        let data = JSON.stringify({
            isDeleted: true,
            searchString: "",
            id: 0,
        });
        axios
            .post(url, data, {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            })
            .then((result) => {
                console.log(result.data.outdata.laboratoryData, "hhhhhhhhhhhhhh");
                console.log(result.data);
                if (result.data.flag) {
                    let dcid = 0;
                    let dcname = "";
                    let currentdisease = [];
                    let subDiseaseCatId = ''
                    if (result.data.outdata.diseaseCatData.length > 0) {

                        dcid = result.data.outdata.diseaseCatData[0].diseaseCategoryId;
                        // let subDcid=''
                        // if (result.data.outdata.diseaseCatData[0]?.subCategory?.length > 0) {
                        //   subDcid= result.data.outdata.diseaseCatData[0]?.subCategory[0]?.diseaseCategoryId
                        // }
                        dcname = result.data.outdata.diseaseCatData[0].diseaseCategoryName;

                        let catName = ""
                        if (result.data.outdata.diseaseCatData[0].subCategory?.length > 0) {
                            subDiseaseCatId = result.data.outdata.diseaseCatData[0].subCategory[0].diseaseCategoryId
                            catName = result.data.outdata.diseaseCatData[0].subCategory[0].diseaseCategoryName

                        }
                        console.log(`catName`, catName)
                        this.setState({
                            subDiseaseName: catName
                        })
                        // alert(subDiseaseCatId)
                        let dData = result.data.outdata.diseaseData
                        currentdisease = dData.filter(
                            (ds) => {
                                if (subDiseaseCatId) {

                                    return ds.diseaseCatId == subDiseaseCatId
                                } else {

                                    return ds.diseaseCatId == dcid
                                }
                                // return ds.diseaseCatId == subDiseaseCatId ? subDiseaseCatId : dcid;
                            }
                        );
                        // alert(`in ${currentdisease.length}`)
                    }
                    // alert(`out ${currentdisease.length}`)
                    // debugger;
                    console.log(dcid);
                    // alert(subDiseaseCatId)

                    console.log({ a: result.data.outdata.laboratoryData });
                    this.setState(
                        {
                            countries: result.data.outdata.countryData,
                            ocountries: result.data.outdata.ocountryData,
                            Alldisease: result.data.outdata.diseaseData,
                            allpractitioners: result.data.outdata.practitionerData,
                            diseaseCat: result.data.outdata.diseaseCatData,
                            diseaseCatId: dcid,
                            diseasename: dcname,
                            subDiseaseCatId,
                            // disease: currentdisease,
                            // currentdisease
                            // laboratoryData
                            // laboratoryData: result.data.outdata.laboratoryData,
                            //allTissues: result.data.outdata.tissueData
                        },
                        () => {
                            this.getlabdetailsBydiseaseCatId(subDiseaseCatId || dcid)
                            this.getStateData(233, "", "");
                            //const param = this.props.match.params;
                            //if (param.id != undefined) {
                            //  this.getData(param.id);
                            //} else {
                            //  this.setState({ loading: false });
                            //}
                        }
                    );
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }
    // getCountry() {
    //     const apiroute = window.$APIPath;
    //     const url = apiroute + '/api/BE_Common/GetPatientDropdown';
    //     let data = JSON.stringify({
    //         isDeleted: true,
    //         searchString: ''
    //     });
    //     axios.post(url, data, {
    //         headers: {
    //             'Content-Type': 'application/json; charset=utf-8'
    //         }
    //     }).then(result => {
    //         if (result.data.flag) {
    //             console.log("odata", result.data.outdata);

    //             //let currentdisease = result.data.outdata.diseaseData.filter(ds => ds.name.toLowerCase() != "healthindex");
    //             //currentdisease.push({ id: 0, name: 'Custom', isActive: true });

    //             let dcid = 0;
    //             let dcname = '';
    //             let currentdisease = [];
    //             let alldisease = result.data.outdata.diseaseData;
    //             if (result.data.outdata.diseaseCatData.length > 0) {
    //                 dcid = result.data.outdata.diseaseCatData[0].diseaseCategoryId;
    //                 dcname = result.data.outdata.diseaseCatData[0].diseaseCategoryName;
    //                 currentdisease = result.data.outdata.diseaseData.filter(ds => ds.diseaseCatId == dcid);
    //             }
    //             currentdisease.push({ id: 0, name: 'Custom', isActive: true });
    //             alldisease.push({ id: 0, name: 'Custom', isActive: true });
    //             this.getlabdetailsBydiseaseCatId(dcid)
    //             // console.log(dcname)
    //             this.setState({
    //                 countries: result.data.outdata.countryData,
    //                 Alldisease: alldisease,
    //                 orderOfCountries: result.data.outdata.ocountryData, allTissues: [],
    //                 diseaseCat: result.data.outdata.diseaseCatData,
    //                 diseaseCatId: dcid,
    //                 diseasename: dcname,
    //                 disease: currentdisease, loading: false,

    //                 //allTissues: result.data.outdata.tissueData
    //             }, () => { this.getStateData(233, "", ""); });
    //             //}, () => {
    //             //    const param = this.props.match.params;

    //             //    if (param.id != undefined) {
    //             //        this.setState({ PatientId: param.id });
    //             //        this.getData(param.id);
    //             //    }
    //             //    else { this.setState({ loading: false }); }
    //             //});
    //         }
    //     }).catch(error => {
    //         this.setState({ loading: false });
    //         console.log(error);
    //     });
    // }

    //getDisease() {
    //    var userToken = JSON.parse(localStorage.getItem('Usertoken'));
    //    let userId = (userToken.userId == null ? 0 : userToken.userId);

    //    const apiroute = window.$APIPath;

    //    let data = JSON.stringify({
    //        isDeleted: true,
    //        searchString: this.state.searchString,
    //        id: userId
    //    });

    //    axios.post(url, data, {
    //        headers: {
    //            'Content-Type': 'application/json; charset=utf-8'
    //        }
    //    }).then(result => {
    //        if (result.data.flag) {
    //            this.setState({
    //                disease: result.data.outdata
    //            }, () => {

    //                const param = this.props.match.params;

    //                if (param.id != undefined) {
    //                    this.setState({ PatientId: param.id });
    //                    this.getData(param.id);
    //                }
    //                else { this.setState({ loading: false }); }

    //            });
    //        } else {
    //            this.setState({ loading: false });
    //        }
    //    }).catch(error => {
    //        console.log(error);
    //        this.setState({ loading: false });
    //    });
    //}

    ////get detail(for update)
    //getData(id) {

    //    const apiroute = window.$APIPath;

    //    axios.get(url, {
    //        headers: {
    //            'Content-Type': 'application/json; charset=utf-8'
    //        }
    //    })
    //        .then(result => {
    //            if (result.data.flag) {
    //                console.log(result.data.outdata);
    //                var rData = result.data.outdata;
    //                this.setState({
    //                    PractitionerId: rData.practitionerId, PractitionerPatientId: rData.practitionerPatientId, UserId: rData.userId,
    //                    PatientId: rData.patientId, FirstName: rData.firstName, MiddleName: rData.middleName, diseaseId: rData.diseaseId,
    //                    LastName: rData.lastName, UserName: rData.userName, Email: rData.email, Mobile: rData.mobile,
    //                    DateOfBirth: (rData.dateOfBirth == null ? "" : Moment(rData.dateOfBirth.slice(0, 10), "MM-DD-YYYY").format('YYYY-MM-DD')), Age: rData.age, Address: rData.address, Sex: rData.sex,
    //                    PhoneNumber: rData.phoneNumber, PostalCode: rData.postalCode, Tissue: rData.tissue, CountryId: rData.countryId, OCountryId: rData.oCountryId
    //                    //, StateId: rData.StateId, CityId: rData.CityId
    //                }, () => {
    //                    if (rData.diseaseId != null) {
    //                        this.getTissueData(rData.diseaseId, rData.tissue)
    //                    }
    //                    if (rData.countryId != null) {
    //                        this.getStateData(rData.countryId, rData.stateId, rData.cityId)
    //                    }
    //                    else { this.setState({ loading: false }); }
    //                });
    //                //console.log(this.state);
    //            } else { this.setState({ loading: false }); }
    //        })
    //        .catch(error => {
    //            console.log(error);
    //            this.setState({ loading: false });
    //        });
    //}

    getStateData(CountryId, StateId, CityId) {

        const apiroute = window.$APIPath;

        const url = apiroute + '/api/BE_Common/GetStateByCountryId?Id=' + CountryId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    //console.log(result.data);
                    this.setState({ states: result.data.outdata, StateId: StateId }, () => {
                        if (StateId != "" || StateId != null) {
                            this.getCityData(StateId, CityId)
                        }
                        else { this.setState({ loading: false }); }
                    });
                }
                else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getCityData(StateId, CityId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/BE_Common/GetCityByStateId?Id=' + StateId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                //console.log(result.data);
                this.setState({ cities: result.data.outdata, CityId: CityId, loading: false });
            }
            else { this.setState({ loading: false }); }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    getTissueData(DiseaseId, TissueId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/Tissue/getdrpTissueByDiseaseId';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            id: parseInt(DiseaseId)
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                //console.log(result.data);
                let currentTissue = result.data.outdata;
                this.setState({ allTissues: currentTissue, Tissue: TissueId });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    getAllTissueData(DiseaseId, TissueId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/BE_Tissue/GetDRPAll';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            id: 0
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                console.log(result.data);
                let currentTissue = result.data.outdata;
                if (DiseaseId == 0) {
                    currentTissue.push({ id: 0, name: 'Custom', isActive: true });
                }
                this.setState({ allTissues: currentTissue, Tissue: TissueId });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.FirstName == undefined || this.state.FirstName == '') {
            errors.FirstName = 'Please enter first name.';
        }
        //if (this.state.MiddleName == undefined || this.state.MiddleName == '') {
        //    errors.MiddleName = 'Please enter middle name.';
        //}
        if (this.state.LastName == undefined || this.state.LastName == '') {
            errors.LastName = 'Please enter last name.';
        }
        if (this.state.diseasename == "Cancer With Tumor" || this.state.diseasename == "Cancer - No Tumor") {
            if (this.state.TissueId == undefined || this.state.TissueId == '') {
                errors.TissueId = 'Please select tissue';
            }
            if (this.state.diseaseId == undefined || this.state.diseaseId == '') {
                errors.diseaseId = 'Please select disease.';
            }
        } else {
            errors.TissueId = ''
            errors.diseaseId = ''
        }
        // if (this.state.OCountryId == undefined || this.state.OCountryId == '') {
        //    errors.OCountryId = 'Please select order of country.';
        // }
        if (this.state.CountryId == undefined || this.state.CountryId == '') {
            errors.CountryId = 'Please select country.';
        }
        if (this.state.StateId == undefined || this.state.StateId == '') {
            errors.StateId = 'Please select state.';
        }
        if (this.state.CityId == undefined || this.state.CityId == '') {
            errors.CityId = 'Please select city.';
        }
        if (this.state.Mobile == undefined || this.state.Mobile == '') {
            errors.Mobile = 'Please enter primary phone.';
        }
        if (this.state.DateOfBirth == undefined || this.state.DateOfBirth == '') {
            errors.DateOfBirth = 'Please enter date of birth.';
        }
        //if (this.state.Age == undefined || this.state.Age == '') {
        //    errors.Age = 'Please enter age.';
        //}
        //if (this.state.Address == undefined || this.state.Address == '') {
        //    errors.Address = 'Please enter address.';
        //}
        // if (this.state.PhoneNumber == undefined || this.state.PhoneNumber == '') {
        //    errors.PhoneNumber = 'Please enter secondary phone.';
        // }
        if (this.state.PostalCode == undefined || this.state.PostalCode == '') {
            errors.PostalCode = 'Please enter postal code.';
        }
        // if (this.state.weight == undefined || this.state.weight == '') {
        //     errors.weight = 'Please enter weight.';
        // }
        // if (this.state.height == undefined || this.state.height == '') {
        //     errors.height = 'Please enter height.';
        // }

        //if (this.state.UserName == undefined || this.state.UserName == '') {
        //    errors.UserName = 'Please enter user name.';
        //}
        if (this.state.email == undefined || this.state.email == '') {
            errors.Email = 'Please enter email.';
        }


        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false),
        );

        if (!valid) {
            this.scrollToTop();

        }
        return valid;
    }
    getlabdetailsBydiseaseCatId(diseaseCatId) {
        // alert(diseaseCatId)

        this.state.errors.samplecheckbox = '';
        const apiroute = window.$APIPath;

        const url = apiroute + "/api/SampleType/GetByDiseaseCatId?id=" + diseaseCatId;
        this.setState({ loading: true })
        axios
            .get(url, {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            }).then((result) => {

                if (result.data.flag) {
                    console.log("disease category", result.data);
                    // let labids = result?.data?.outdata?.map((ele) => ele.ngsLaboratoryId)
                    let samples = result?.data?.outdata || [];
                    let currentdisease = this.state.Alldisease.filter(ds => ds.diseaseCatId == diseaseCatId);
                    console.log(currentdisease);

                    currentdisease.unshift({ id: 0, name: "unknown" })

                    let diseaseId = currentdisease[1]?.id || '';
                    let diseasName = currentdisease[1]?.name || '';
                    console.log(diseaseId + " ------ " + diseasName)
                    // if (['8', '9', '10'].includes(String(diseaseCatId))) {
                    //     //  diseaseId

                    //     diseaseId = String(diseaseCatId)
                    //     // this.setState({ diseaseId: value })
                    // }
                    let checkBox = samples.map((ele) => String(ele.sampleTypeId))
                    this.setState({ samples, checkBox, disease: currentdisease, diseaseId, currentdiseasename: diseasName })
                }

            }).finally(() => { this.setState({ loading: false }) })
    }

    handleDateChange(date) {
        let errors = this.state.errors;
        errors.DateOfBirth = !date ? "Please enter date of birth." : "";
        var newAge = (date) ? this.calculate_age(date) : 0;
        this.setState({ DateOfBirth: date, Age: newAge })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const dataId = event.currentTarget.id
        // debugger;
        // this.setState({
        //     [name]: value
        // });

        // if (name == 'diseaseCatId') {
        //     let currentdiseasecat = this.state.diseaseCat.filter(ds => ds.diseaseCategoryId == value);
        //     if (currentdiseasecat.length > 0) {
        //         this.setState({ diseaseCategoryName: currentdiseasecat[0].diseaseCategoryName });
        //     }
        // }
        console.log(target, "targetttttt");
        console.log(value, "valueeeeeee");
        console.log(name, "nameeeee");


        // if (name === "diseaseCatId") {
        if (name == "diseaseCatId") {
            this.setState({
                TissueId: null,
                diseaseId: null,
                allTissues: []
            })
        }

        this.setState({
            [name]: value,
        });
        // } else if (name === "subDiseaseCatId") {
        //     this.setState({
        //         diseaseCatId: value
        //     })
        // }



        if (name == "diseaseCatId") {
            this.state.errors.samplecheckbox = '';
            this.state.errors.diseaseId = '';
            this.state.metasis = 'no';
            console.log("id", value);
            let index = this.state.diseaseCat.findIndex(ele => ele.diseaseCategoryId == value)
            let catId = value
            let catName = ""
            if (index >= 0) {
                if (this.state.diseaseCat[index]?.subCategory?.length > 0) {
                    catId = this.state.diseaseCat[index].subCategory[0].diseaseCategoryId
                    catName = this.state.diseaseCat[index].subCategory[0].diseaseCategoryName
                }
            }
            this.setState({
                subDiseaseName: catName
            })
            console.log(`${catId} - ${value}`);

            this.getlabdetailsBydiseaseCatId(catId)
            let currentdiseasecat = this.state.diseaseCat.filter(
                (ds) => ds.diseaseCategoryId == value
            );
            let subCategory = currentdiseasecat[0]?.subCategory
            // alert(currentdiseasecat.length)
            console.log({ currentdiseasecat })
            // alert(currentdiseasecat.length)
            let stateData = { diseasename: currentdiseasecat[0].diseaseCategoryName, subDiseaseCatId: "" }
            console.log(stateData)
            if (subCategory[0]?.diseaseCategoryId) {
                stateData.subDiseaseCatId = subCategory[0].diseaseCategoryId


            }
            if (currentdiseasecat.length > 0) {
                this.setState(stateData);
            }
        }
        if (name === 'subDiseaseCatId') {
            this.getlabdetailsBydiseaseCatId(value)
            this.setState({
                subDiseaseName: dataId,
            })

        }




        let errors = this.state.errors;

        const validMobileRegex = RegExp(/^[0-9+() -]+$/);
        const validNumberRegex = RegExp(/^[0-9]+$/);
        const validAlphaRegex = RegExp(/^[a-zA-Z \b]+$/);
        const validAlphaNoRegex = RegExp(/^[a-zA-Z0-9 \b]+$/);
        const validEmailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        switch (name) {
            case 'FirstName':
                errors.FirstName = (!value) ? 'Please enter firstname.' : (validAlphaNoRegex.test(value) ? '' : 'Only alphanumeric allowed.');
                this.setState({ FirstName: value.replace(/[^a-zA-Z0-9 \b]+$/, '') })
                break;

            case 'MiddleName':
                errors.MiddleName = (value) ? (validAlphaNoRegex.test(value) ? '' : 'Only alphanumeric allowed.') : '';
                this.setState({ MiddleName: value.replace(/[^a-zA-Z0-9 \b]+$/, '') })
                break;

            case 'LastName':
                errors.LastName = (!value) ? 'Please enter lastname.' : (validAlphaNoRegex.test(value) ? '' : 'Only alphanumeric allowed.');
                this.setState({ LastName: value.replace(/[^a-zA-Z0-9 \b]+$/, '') })
                break;

            case 'newDisease':
                errors.newDisease = (!value) ? 'Please enter new disease.' : '';
                break;

            case 'newDiseaseCode':
                errors.newDiseaseCode = (!value) ? 'Please enter code for new disease.' : '';
                break;

            case 'newTissue':
                errors.newTissue = (!value) ? 'Please enter new tissue.' : '';
                break;

            case 'diseaseId':
                errors.diseaseId = (!value) ? this.state.diseaseCategoryName == "cancer" ? 'Please select disease.' : '' : '';
                break;

            //case 'OCountryId':
            //    errors.OCountryId = (!value) ? 'Please select order of country.' : '';
            //    this.setState({ OCountryId: value });
            //    break;

            case 'TissueId':
                if (this.state.diseasename == "Cancer With Tumor" || this.state.diseasename == "Cancer - No Tumor") {

                    errors.TissueId = (!value) ? 'Please select tissue.' : '';
                } else {
                    errors.TissueId = ''
                }
                this.setState({ TissueId: value });
                break;

            case 'Mobile':
                errors.Mobile = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : 'Please enter primary phone.';
                this.setState({ Mobile: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;
            // case 'weight':
            //     errors.weight = (!value) ? 'Please enter weight.' : '';
            //     break;
            // case 'height':
            //     errors.height = (!value) ? 'Please enter height.' : '';
            //     break;

            case 'DateOfBirth':
                var newAge = (value) ? this.calculate_age(value) : 0;
                this.setState({ Age: newAge });
                break;

            //case 'Address':
            //    errors.Address = (!value) ? 'Please enter Address.' : '';
            //    break;

            case 'PhoneNumber':
                errors.PhoneNumber = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ PhoneNumber: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            case 'PostalCode':
                errors.PostalCode = (value) ? (validNumberRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ PostalCode: value.replace(/[^0-9]+$/, '') })
                break;

            case 'email':
                errors.Email = (!value) ? 'Please enter email.' : (validEmailRegex.test(value) ? '' : 'Invalid Email.');
                break;
            case "diseaseId":
                errors.diseaseId =
                    this.state.diseasename == "Cancer Patients"
                        ? !value
                            ? "Please select disease."
                            : ""
                        : "";
                break;
            case "CountryId":
                errors.CountryId = !value ? "Please select country." : "";
                break;
            case "StateId":
                errors.StateId = !value ? "Please select state." : "";
                break;
            case "CityId":
                errors.CityId = !value ? "Please select city." : "";
                break;



            //case 'UserName':
            //    errors.UserName = this.state.PatientId == 0 ? ((!value) ? 'Please enter username.' : '') : '';
            //    break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }

        this.setState({ errors }, () => {

            if (name == 'CountryId') {
                if (value != '') {
                    this.getStateData(value, "", "");
                    this.setState({ cities: [], CityId: "" });
                }
                else {
                    this.setState({ states: [], StateId: "", cities: [], CityId: "" });
                }
            }
            if (name == 'StateId') {
                if (value != '') {
                    this.getCityData(value, "");
                }
                else {
                    this.setState({ cities: [], CityId: "" });
                }
            }
            if (name == 'diseaseId') {
                if (value != '') {
                    let diseaseName = this.state.disease.filter(obj => obj.id == value)?.[0].name
                    this.setState({ allTissues: [], Tissue: "", currentdiseasename: diseaseName });
                    this.getTissueData(value, "");
                }
                else {
                    this.setState({ allTissues: [], Tissue: "" });
                }
            }
            // if (name == 'diseaseCatId') {
            //     // debugger;
            //     this.setState({ disease: [], diseaseId: "" });
            //     if (value != '') {
            //         let currentdisease = this.state.Alldisease.filter(ds => ds.diseaseCatId == value);
            //         if (this.state.diseaseCategoryName.toLowerCase() != 'cancer') {
            //             this.setState({ diseaseId: currentdisease[0]?.id });
            //         }
            //         else {
            //             currentdisease.push({ id: 0, name: 'Custom', isActive: true });
            //             if (currentdisease.length > 0) {
            //                 this.setState({ disease: currentdisease });
            //             }
            //         }

            //     }
            // }

        })
    }

    handleCheckBox(e) {

        let value = e.target.value;
        let arr = [...this.state.checkBox]
        if (this.state.checkBox.includes(value)) {
            // alert("exist")
            arr.splice(arr.indexOf(value), 1)
            this.state.errors.samplecheckbox = arr?.length === 0 ? 'please select sample' : '';
        } else {
            arr.push(value);

            this.state.errors.samplecheckbox = arr?.length > -1 ? '' : null;
        }

        this.setState({
            checkBox: arr
        })
    }

    calculate_age = (dob1) => {
        var today = new Date();
        var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        console.log(age_now);
        return age_now;
    }

    handleShowPreview(e) {
        e.preventDefault()
        this.setState({ loading: true, });
        if (this.validateForm(this.state.errors)) {
            this.setState({
                loading: false,
                previewDetailsModal: true
            })
        } else {
            this.setState({ loading: false, });
        }
    }
    handleSubmit(e) {
        // debugger;
        e.preventDefault();

        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        let uid = 0;

        if (userToken != null) {
            id = (userToken.practitionerId == null ? 0 : userToken.practitionerId);
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }
        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            if (this.state.PatientId == 0) {
                // url = apiroute + '/api/BE_PractitionerPatient/Save'
                url = apiroute + 'api/PractitionerPatient/SavePatient';
            }
            else {
                url = apiroute + '/api/PractitionerPatient/UpdatePatient';
            }

            // let data = JSON.stringify({
            //     practitionerPatientId: parseInt(this.state.PractitionerPatientId),
            //     practitionerId: parseInt(id),
            //     patientId: parseInt(this.state.PatientId),
            //     firstName: this.state.FirstName,
            //     lastName: this.state.LastName,
            //     middleName: this.state.MiddleName,
            //     userName: this.state.email,
            //     email: this.state.email,
            //     tissueId: ((this.state.TissueId == "" || this.state.TissueId == 0) ? null : parseInt(this.state.TissueId)),
            //     addressLine1: this.state.Address1,
            //     addressLine2: this.state.Address2,
            //     phoneNumber: this.state.PhoneNumber,
            //     mobile: this.state.Mobile,
            //     postalCode: this.state.PostalCode,
            //     sex: this.state.Sex,
            //     dateOfBirth: (this.state.DateOfBirth != null && this.state.DateOfBirth != "" ? this.state.DateOfBirth : null),
            //     cityId: ((this.state.CityId == "" || this.state.CityId == 0) ? null : parseInt(this.state.CityId)),
            //     countryId: ((this.state.CountryId == "" || this.state.CountryId == 0) ? null : parseInt(this.state.CountryId)),
            //     stateId: ((this.state.StateId == "" || this.state.StateId == 0) ? null : parseInt(this.state.StateId)),
            //     //Age: parseInt(this.state.Age),
            //     age: ((this.state.Age == '' || this.state.Age == null || this.state.Age == '0') ? null : this.state.Age.toString()),
            //     userId: parseInt(this.state.UserId),
            //     diseaseId: (this.state.diseaseId != "" ? parseInt(this.state.diseaseId) : null),
            //     createdBy: parseInt(uid),
            //     oCountryId: this.state.OCountryId,
            //     height: this.state.height,
            //     weight: this.state.weight,
            //     sampleTypes: this.state.checkBox.map((ele) => Number(ele)),
            //     diseaseCategoryId:
            //         this.state.diseaseCatId != ""
            //             ? this.state.diseasename == 'LRI Longevity' ? Number(this.state.subDiseaseCatId) : parseInt(this.state.diseaseCatId)
            //             : null,
            //     // diseaseCategoryId: (this.state.diseaseCatId != "" ? parseInt(this.state.diseaseCatId) : null)
            // })
            let x = new Date(this.state.DateOfBirth);
            let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
            let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
            x.setHours(hoursDiff);
            x.setMinutes(minutesDiff);
            let tissueId = this.state.TissueId
            if (tissueId) {
                tissueId = parseInt(this.state.TissueId)
            } else {
                tissueId = null
            }
            let data = {
                "practitionerPatientId": parseInt(this.state.PractitionerPatientId),
                "practitionerId": parseInt(id),
                "patientId": parseInt(this.state.PatientId),
                "userId": uid,
                "userName": this.state.email,
                "userType": 0,
                "firstName": this.state.FirstName,
                "middleName": this.state.MiddleName,
                "lastName": this.state.LastName,
                "dateOfBirth": (this.state.DateOfBirth != null && this.state.DateOfBirth != "" ? x : null),
                "age": ((this.state.Age == '' || this.state.Age == null || this.state.Age == '0') ? null : this.state.Age.toString()),
                "sex": this.state.Sex,
                "addressLine1": this.state.Address1,
                "addressLine2": '',
                "cityId": ((this.state.CityId == "" || this.state.CityId == 0) ? null : parseInt(this.state.CityId)),
                "stateId": ((this.state.StateId == "" || this.state.StateId == 0) ? null : parseInt(this.state.StateId)),
                "countryId": ((this.state.CountryId == "" || this.state.CountryId == 0) ? null : parseInt(this.state.CountryId)),
                "postalCode": this.state.PostalCode,
                "phoneNumber": this.state.PhoneNumber,
                "mobile": this.state.Mobile,
                "email": this.state.email,
                "diseaseId": (this.state.diseaseId != "" ? parseInt(this.state.diseaseId) : null) || null,
                "tissueId": tissueId,
                "createdBy": uid,
                "oCountryId": String(this.state.OCountryId),
                "diseaseCategoryId": this.state.subDiseaseCatId != "" ?
                    Number(this.state.subDiseaseCatId) : parseInt(this.state.diseaseCatId)
                ,

                // height: this.state.height,
                // weight: this.state.weight,
                "sampleTypes": this.state.checkBox.map((ele) => Number(ele)),
                "IsMetastasis": this.state.metasis == "yes" ? true : false,
                diseaseName: this?.state?.currentdiseasename || "",
                diseaseCategory: this.state.diseaseCat.filter((ele) => {
                    return ele.diseaseCategoryId == this.state.diseaseCatId
                }).map((ele) => {
                    return ele.diseaseCategoryName
                    // if (isParent) {
                    // if (Array.isArray(ele.subCategory) && ele.subCategory.length) {
                    //   let child = ele.subCategory.filter((e) => e.diseaseCategoryId == this.state.subDiseaseCatId)
                    //   return child[0].diseaseCategoryName
                    // } else {
                    // }
                    // } else {
                    //   return parent
                    // }
                })[0],

            }
            console.log(data);
            // return;
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {

                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            redirect: true
                        });
                        toast.success(result.data.message)
                    } else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        console.log(result.data)
                        toast.error(result?.data?.message)
                    }
                })

                .catch(error => {
                    //console.log(error);
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    console.log(error)
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }

        else {
            this.setState({
                //authError: true,
                //errorType: 'danger',
                //error: "Please fill all the fields.",
                loading: false
            });
        }
    }

    AddNewDisease(e) {
        // debugger;
        e.preventDefault();
        this.setState({ loading: true });
        let errors = this.state.errors;

        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        let uid = 0;
        if (userToken != null) {
            id = (userToken.practitionerId == null ? 0 : userToken.practitionerId);
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.state.newDisease != "" && this.state.newDisease != null && this.state.newDiseaseCode != "" && this.state.newDiseaseCode != null) {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/Disease/Save';

            let data = JSON.stringify({
                diseaseId: 0,
                diseaseName: this.state.newDisease,
                //EfoDiseasCode: this.state.EfoDiseasCode,
                DiseaseCode: this.state.newDiseaseCode,
                category: this.state.diseasename,
                //description: this.state.description,
                //accessionDigit: this.state.accessionDigit,
                createdBy: uid,
                createdByFlag: 'P'
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        let res = result.data.outdata;
                        console.log(res);
                        let currentdisease = this.state.disease;
                        currentdisease.push({ id: res.diseaseId, name: res.diseaseName, isActive: true });

                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            diseaseId: res.diseaseId,
                            disease: currentdisease,
                            loading: false,
                        }, () => { this.getTissueData(res.diseaseId, "") });
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    //console.log(error);
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            errors.newDisease = 'Please enter new disease.';
            errors.newDiseaseCode = 'Please enter code for new disease.';

            this.setState({ errors }, () => {
                this.setState({
                    loading: false
                });
            });

        }
    }

    AddNewTissue(e) {
        // debugger;
        e.preventDefault();
        this.setState({ loading: true });
        let errors = this.state.errors;

        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        let uid = 0;
        if (userToken != null) {
            id = (userToken.practitionerId == null ? 0 : userToken.practitionerId);
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.state.newTissue != "" && this.state.newTissue != null) {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/Tissue/Save';

            let data = JSON.stringify({
                diseaseIds: this.state.diseaseId.toString(),
                tissueId: 0,
                tissueName: this.state.newTissue,
                createdBy: uid,
                createdByFlag: 'P'
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        let res = result.data.outdata;
                        console.log(res);
                        let currenttissue = this.state.allTissues;
                        currenttissue.push({ id: res.tissueId, name: res.tissueName, isActive: true });

                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            TissueId: res.tissueId,
                            allTissues: currenttissue,
                            loading: false,
                        });
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    //console.log(error);
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            errors.newTissue = 'Please enter new tissue.';

            this.setState({ errors }, () => {
                this.setState({
                    loading: false
                });
            });

        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/practitioner/dashboard" />
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }


    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    }

    render() {
        const { loading, PatientId, PractitionerId, PractitionerPatientId, Sex, DateOfBirth,
            Age, FirstName, MiddleName, LastName, UserName, email, CountryId, countries,
            StateId, states, CityId, cities, Mobile, Address1,
            // Address2, 
            PhoneNumber, PostalCode, TissueId, diseaseId, disease,
            Qualification, errors, error, errorType, authError, allTissues, orderOfCountries, OCountryId, diseaseCategoryName, newDisease, newDiseaseCode, newTissue,
            diseaseCat, diseaseCatId, samples, checkBox, subDiseaseName } = this.state;
        return (

            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                                <Link to="/practitioner/dashboard" className="kt-subheader__title"> Dashboard </Link>
                                            </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/practitioner/dashboard" className="kt-subheader__breadcrumbs-link">
                                                    Dashboard                       </Link>
                                            </div> */}
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="/practitioner/patient/detail" className="kt-subheader__breadcrumbs-link">
                                                    Patient Detail                      </Link>
                                            </div>
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/practitioner/dashboard" className="btn btn-primary btnBorder">
                                                Dashboard
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.handleShowPreview.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label"><b>Neo7 Analysis Type</b></label>
                                                                <div className="col-12 ">
                                                                    <div className="kt-radio-inline col-12">
                                                                        {diseaseCat.map((data, i) => {
                                                                            return (
                                                                                <div className="custom-control custom-radio mb-2">

                                                                                    {
                                                                                        data.diseaseCategoryId == diseaseCatId ? (
                                                                                            <>

                                                                                                <Input
                                                                                                    type="radio"
                                                                                                    checked
                                                                                                    className="custom-control-input"
                                                                                                    value={data.diseaseCategoryId}
                                                                                                    onChange={this.handleInputChange.bind(this)}
                                                                                                    id={data.diseaseCategoryName}
                                                                                                    name="diseaseCatId"
                                                                                                    tabIndex={i + 1}
                                                                                                />
                                                                                                <Label
                                                                                                    className="custom-control-label"
                                                                                                    style={{ fontWeight: "500" }}
                                                                                                    htmlFor={data.diseaseCategoryName}
                                                                                                >
                                                                                                    {data.diseaseCategoryName
                                                                                                        // " (" +
                                                                                                        // data.productName +
                                                                                                        // ")"
                                                                                                    }
                                                                                                </Label>
                                                                                                {

                                                                                                    data.subCategory.map((ele) => {
                                                                                                        // if (Array.isArray(ele) && ele.length) {
                                                                                                        return (
                                                                                                            <div className="custom-control custom-radio ">

                                                                                                                <div className="custom-control custom-radio my-1">
                                                                                                                    {ele.diseaseCategoryId == this.state.subDiseaseCatId ? (<Input
                                                                                                                        type="radio"
                                                                                                                        checked
                                                                                                                        className="custom-control-input"
                                                                                                                        value={ele.diseaseCategoryId}
                                                                                                                        onChange={(e) => {
                                                                                                                            this.handleInputChange.bind(
                                                                                                                                ele.diseaseCategoryId
                                                                                                                            )
                                                                                                                        }}
                                                                                                                        id={ele.diseaseCategoryName}
                                                                                                                        name="subDiseaseCatId"
                                                                                                                    // tabIndex={i + 1}
                                                                                                                    />
                                                                                                                    ) : (
                                                                                                                        <Input
                                                                                                                            type="radio"
                                                                                                                            className="custom-control-input"
                                                                                                                            value={ele.diseaseCategoryId}
                                                                                                                            onChange={this.handleInputChange.bind(
                                                                                                                                this
                                                                                                                            )}
                                                                                                                            id={ele.diseaseCategoryName}
                                                                                                                            name="subDiseaseCatId"
                                                                                                                        />
                                                                                                                    )}
                                                                                                                    <Label
                                                                                                                        className="custom-control-label"
                                                                                                                        htmlFor={ele.diseaseCategoryName}
                                                                                                                    >
                                                                                                                        {ele.diseaseCategoryName
                                                                                                                            //  + " (" + ele.productName + ")"
                                                                                                                        }
                                                                                                                    </Label>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        );

                                                                                                    })}

                                                                                            </>
                                                                                        ) : (<>


                                                                                            <Label
                                                                                                className="custom-control-label"
                                                                                                style={{ fontWeight: "500" }}
                                                                                                htmlFor={data.diseaseCategoryName}
                                                                                            >
                                                                                                {data.diseaseCategoryName
                                                                                                    // +
                                                                                                    //     " (" +
                                                                                                    //     data.productName +
                                                                                                    //     ")"
                                                                                                }
                                                                                            </Label>


                                                                                            <Input
                                                                                                type="radio"
                                                                                                className="custom-control-input"
                                                                                                value={data.diseaseCategoryId}
                                                                                                onChange={this.handleInputChange.bind(this)}
                                                                                                id={data.diseaseCategoryName}
                                                                                                name="diseaseCatId"
                                                                                                tabIndex={i + 1}
                                                                                            />
                                                                                        </>
                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            );
                                                                        })}

                                                                        {/* {diseaseCat
                                                                            .map((data, i) => {
                                                                                return (
                                                                                    data.diseaseCategoryId == diseaseCatId ?
                                                                                        <label className="kt-radio">
                                                                                            <input type="radio" checked value={data.diseaseCategoryId} onChange={this.handleInputChange.bind(this)} id={data.diseaseCategoryName} name="diseaseCatId" tabIndex={i + 1} /> {data.diseaseCategoryName + " (" + data.productName + ")"}
                                                                                            <span></span>
                                                                                        </label>
                                                                                        :
                                                                                        <label className="kt-radio">
                                                                                            <input type="radio" value={data.diseaseCategoryId} onChange={this.handleInputChange.bind(this)} id={data.diseaseCategoryName} name="diseaseCatId" tabIndex={i + 1} /> {data.diseaseCategoryName + " (" + data.productName + ")"}
                                                                                            <span></span>
                                                                                        </label>
                                                                                );
                                                                            })} */}
                                                                    </div>
                                                                    {/* <div className="kt-radio-inline col-6">
                                                                        {Array.isArray(samples) && samples?.map((ele) => {
                                                                            return <div className="custom-control custom-radio mb-2">
                                                                                <input type="checkbox" />
                                                                                {ele?.sampleTypeName}</div>
                                                                        })}
                                                                    </div> */}
                                                                </div>



                                                                {/*<div className="col-12">*/}
                                                                {/*    <div className="kt-radio-inline">*/}
                                                                {/*        {diseaseCategoryName == "Cancer Patients" ?*/}
                                                                {/*            <label className="kt-radio">*/}
                                                                {/*                <input type="radio" value="Cancer Patients" onChange={this.handleInputChange.bind(this)} checked id="PBIMA" name="diseaseCategoryName" tabIndex="15" /> Cancer*/}
                                                                {/*                <span></span>*/}
                                                                {/*            </label>*/}
                                                                {/*            :*/}
                                                                {/*            <label className="kt-radio">*/}
                                                                {/*                <input type="radio" value="Cancer Patients" onChange={this.handleInputChange.bind(this)} id="PBIMA" name="diseaseCategoryName" tabIndex="15" /> Cancer*/}
                                                                {/*                <span></span>*/}
                                                                {/*            </label>*/}
                                                                {/*        }*/}
                                                                {/*        {diseaseCategoryName == "VibrantHealthX" ?*/}
                                                                {/*            <label className="kt-radio">*/}
                                                                {/*                <input type="radio" value="VibrantHealthX" onChange={this.handleInputChange.bind(this)} checked id="HealthIndex" name="diseaseCategoryName" tabIndex="16" /> Complete Health Score*/}
                                                                {/*                <span></span>*/}
                                                                {/*            </label>*/}
                                                                {/*            :*/}
                                                                {/*            <label className="kt-radio">*/}
                                                                {/*                <input type="radio" value="VibrantHealthX" onChange={this.handleInputChange.bind(this)} id="HealthIndex" name="diseaseCategoryName" tabIndex="16" /> Complete Health Score*/}
                                                                {/*                <span></span>*/}
                                                                {/*            </label>*/}
                                                                {/*        }*/}
                                                                {/*        {diseaseCategoryName == "Neurodegenerative" ?*/}
                                                                {/*            <label className="kt-radio">*/}
                                                                {/*                <input type="radio" value="Neurodegenerative" onChange={this.handleInputChange.bind(this)} checked id="Neurodegenerative" name="diseaseCategoryName" tabIndex="16" /> Neurodegenerative*/}
                                                                {/*                <span></span>*/}
                                                                {/*            </label>*/}
                                                                {/*            :*/}
                                                                {/*            <label className="kt-radio">*/}
                                                                {/*                <input type="radio" value="Neurodegenerative" onChange={this.handleInputChange.bind(this)} id="Neurodegenerative" name="diseaseCategoryName" tabIndex="16" /> Neurodegenerative*/}
                                                                {/*                <span></span>*/}
                                                                {/*            </label>*/}
                                                                {/*        }*/}
                                                                {/*        {diseaseCategoryName == "Autoimmunity" ?*/}
                                                                {/*            <label className="kt-radio">*/}
                                                                {/*                <input type="radio" value="Autoimmunity" onChange={this.handleInputChange.bind(this)} checked id="Autoimmunity" name="diseaseCategoryName" tabIndex="16" /> Autoimmunity*/}
                                                                {/*                <span></span>*/}
                                                                {/*            </label>*/}
                                                                {/*            :*/}
                                                                {/*            <label className="kt-radio">*/}
                                                                {/*                <input type="radio" value="Autoimmunity" onChange={this.handleInputChange.bind(this)} id="Autoimmunity" name="diseaseCategoryName" tabIndex="16" /> Autoimmunity*/}
                                                                {/*                <span></span>*/}
                                                                {/*            </label>*/}
                                                                {/*        }*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                            {/* <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Please Submit These Specimens: </label>
                                                                <div className="col-12 ">
                                                                    <div className="kt-radio-inline col-12 ">

                                                                        <div className="custom-control mb-2">
                                                                            {samples.map((data, i) => {
                                                                                return <>
                                                                                    <div xs="12" style={{ pointerEvents: 'none' }}>
                                                                                        <input type="checkbox"
                                                                                            checked
                                                                                            value={data.sampleTypeId}
                                                                                            className="custom-control-input "

                                                                                            id={data.sampleTypeName}
                                                                                        //onChange={this.handleCheckBox.bind(this)} 
                                                                                        />
                                                                                        <label for={data.sampleTypeName} className="custom-control-label" disabled > {data.sampleTypeName}</label>
                                                                                    </div>
                                                                                </>
                                                                            })}
                                                                            {errors.samplecheckbox.length > 0 && <span className='error'>{errors.samplecheckbox}</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}


                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-4">
                                                                <label className="col-12 col-form-label">First Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="15" tabIndex="1" placeholder="Enter patient first name" name="FirstName" value={FirstName}
                                                                        onChange={

                                                                            (event) => {
                                                                                let regex = /^([A-Za-z\s])+$/gi; //allow alphabetics only

                                                                                if (regex.test(event.target.value) || event.target.value === '')
                                                                                    this.handleInputChange.bind(this)(event)
                                                                            }
                                                                        } />
                                                                    {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label className="col-12 col-form-label">Middle Name</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="15" tabIndex="2" placeholder="Enter patient middle name" name="MiddleName" value={MiddleName}
                                                                        onChange={
                                                                            (event) => {
                                                                                let regex = /^([A-Za-z\s])+$/gi; //allow alphabetics only
                                                                                if (regex.test(event.target.value) || event.target.value === '')
                                                                                    this.handleInputChange.bind(this)(event)
                                                                            }
                                                                        } />
                                                                    {errors.MiddleName.length > 0 && <span className='error'>{errors.MiddleName}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label className="col-12 col-form-label">Last Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="15" tabIndex="3" placeholder="Enter patient last name" name="LastName" value={LastName}
                                                                        onChange={
                                                                            (event) => {
                                                                                let regex = /^([A-Za-z\s])+$/gi; //allow alphabetics only

                                                                                if (regex.test(event.target.value) || event.target.value === '')
                                                                                    this.handleInputChange.bind(this)(event)
                                                                            }
                                                                        } />
                                                                    {errors.LastName.length > 0 && <span className='error'>{errors.LastName}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">

                                                            {/*<div className="col-md-6">
                                                                <label className="col-12 col-form-label">User Name</label>
                                                                <div className="col-12">
                                                                    {PatientId != 0 ?
                                                                        <Input className="form-control here" type="text" maxLength="100" tabIndex="4" placeholder="Enter patient user name" name="UserName" value={UserName} onChange={this.handleInputChange.bind(this)} autoComplete="UserName" disabled />
                                                                        :
                                                                        <Input className="form-control here" type="text" maxLength="100" tabIndex="4" placeholder="Enter patient user name" name="UserName" value={UserName} onChange={this.handleInputChange.bind(this)} autoComplete="UserName" />
                                                                    }
                                                                    {PatientId == 0 ?
                                                                        errors.UserName.length > 0 && <span className='error'>{errors.UserName}</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>*/}
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Email<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    {PatientId != 0 ?
                                                                        <Input className="form-control here" type="text" maxLength="50" tabIndex="3" name="email" placeholder="Enter patient valid email address" value={email} onChange={this.handleInputChange.bind(this)} autoComplete="Email" disabled />
                                                                        :
                                                                        <Input className="form-control here" type="text" maxLength="50" tabIndex="3" name="email" placeholder="Enter patient valid email address" value={email} onChange={this.handleInputChange.bind(this)} autoComplete="Email" />
                                                                    }
                                                                    {PatientId == 0 ?
                                                                        errors.Email.length > 0 && <span className='error'>{errors.Email}</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <label className="col-12 col-form-label">Sex</label>
                                                                <div className="col-12">
                                                                    <div className="kt-radio-inline">
                                                                        {Sex == "M" ?
                                                                            <label className="kt-radio">
                                                                                <input type="radio" value="M" onChange={this.handleInputChange.bind(this)} checked id="Male" name="Sex" tabIndex="4" /> Male
                                                                                <span></span>
                                                                            </label>
                                                                            :
                                                                            <label className="kt-radio">
                                                                                <input type="radio" value="M" onChange={this.handleInputChange.bind(this)} id="Male" name="Sex" tabIndex="4" /> Male
                                                                                <span></span>
                                                                            </label>
                                                                        }
                                                                        {Sex == "F" ?
                                                                            <label className="kt-radio">
                                                                                <input type="radio" value="F" onChange={this.handleInputChange.bind(this)} checked id="Female" name="Sex" tabIndex="5" /> Female
                                                                                <span></span>
                                                                            </label>
                                                                            :
                                                                            <label className="kt-radio">
                                                                                <input type="radio" value="F" onChange={this.handleInputChange.bind(this)} id="Female" name="Sex" tabIndex="5" /> Female
                                                                                <span></span>
                                                                            </label>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Date Of Birth<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    {/* <Input type="date" max="9999-12-31" name="DateOfBirth" tabIndex="6" min="1000-01-01" className="form-control" value={DateOfBirth} onChange={this.handleInputChange.bind(this)} placeholder="Enter patient date of birth" /> */}
                                                                    <DatePicker
                                                                        selected={DateOfBirth}
                                                                        onChange={this.handleDateChange.bind(this)}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        placeholderText="mm/dd/yyyy"
                                                                        className="form-control"
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        maxDate={new Date()}
                                                                    />
                                                                    {<span className='error'>{errors.DateOfBirth}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Age</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="3" tabIndex="7" name="Age" placeholder="Enter patient age" value={Age} onChange={this.handleInputChange.bind(this)} disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Primary Phone<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="8" name="Mobile" placeholder="Enter primary phone" value={Mobile} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.Mobile.length > 0 && <span className='error'>{errors.Mobile}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Secondary Phone</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="9" name="PhoneNumber" placeholder="Enter secondary phone" value={PhoneNumber} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.PhoneNumber.length > 0 && <span className='error'>{errors.PhoneNumber}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className='col-12 col-form-label'>Weight<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input
                                                                        type="text"

                                                                        name="weight"
                                                                        value={this.state.weight}
                                                                        onChange={
                                                                            this.handleInputChange.bind(this)
                                                                        }
                                                                        maxLength="4"
                                                                        placeholder="Enter weight"
                                                                    />
                                                                    {errors.weight.length > 0 && <span className='error'>{errors.weight}</span>}

                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className='col-12 col-form-label'>Height<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input
                                                                        type="text"

                                                                        name="height"
                                                                        value={this.state.height}
                                                                        onChange={
                                                                            this.handleInputChange.bind(this)
                                                                        }
                                                                        maxLength="4"
                                                                        placeholder="Enter height"
                                                                    />
                                                                    {errors.height.length > 0 && <span className='error'>{errors.height}</span>}

                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Address</label>
                                                                <div className="col-12">
                                                                    <textarea className="form-control here" type="text" maxLength="500" tabIndex="14" name="Address1" placeholder="Enter address" value={Address1} onChange={this.handleInputChange.bind(this)}></textarea>
                                                                    {/* {errors.Address1.length > 0 && <span className='error'>{errors.Address1}</span>} */}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label"> Postal code<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="13" name="PostalCode" placeholder="Enter patient residential postal code" value={PostalCode} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.PostalCode.length > 0 && <span className='error'>{errors.PostalCode}</span>}
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Country<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="10" name="CountryId" value={CountryId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select  Country</option>
                                                                        {countries
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.CountryId.length > 0 && <span className='error'>{errors.CountryId}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label"> State<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="11" name="StateId" value={StateId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select  State</option>
                                                                        {states
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.StateId.length > 0 && <span className='error'>{errors.StateId}</span>}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label"> City<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="12" name="CityId" value={CityId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select  City</option>
                                                                        {cities
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.CityId.length > 0 && <span className='error'>{errors.CityId}</span>}
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-6"> */}
                                                            {/* <label className="col-12 col-form-label">Address Line 2</label> */}
                                                            {/* <div className="col-12"> */}
                                                            {/* <textarea className="form-control here" type="text" maxLength="500" tabIndex="14" name="Address2" placeholder="Enter address Line 2" value={Address2} onChange={this.handleInputChange.bind(this)}></textarea> */}
                                                            {/* {errors.Address2.length > 0 && <span className='error'>{errors.Address2}</span>} */}
                                                            {/* </div> */}
                                                            {/* </div> */}
                                                        </div>

                                                        {String(this?.state?.diseasename).toLowerCase() === 'cancer with tumor' || String(this?.state?.diseasename).toLowerCase() === 'cancer - no tumor' ?
                                                            <React.Fragment>
                                                                <div className="form-group row my-4">
                                                                    <div className="col-md-6">
                                                                        <label className="col-12 col-form-label">Disease<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                        <div className="col-12">
                                                                            <Input type="select" className="custom-select mb-3" tabIndex="15" name="diseaseId" value={diseaseId} onChange={this.handleInputChange.bind(this)}>
                                                                                <option value="">Select Disease</option>
                                                                                {disease
                                                                                    .map((data, i) => {
                                                                                        return (<option key={i} value={data.id}>{data.name}</option>);
                                                                                    })}
                                                                            </Input>
                                                                            {errors.diseaseId.length > 0 && <span className='error'>{errors.diseaseId}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="col-12 col-form-label">Tissue<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                        <div className="col-12">
                                                                            <Input type="select" className="custom-select mb-3" name="TissueId" value={TissueId} onChange={this.handleInputChange.bind(this)}>
                                                                                <option value="">Select Tissue</option>

                                                                                {allTissues
                                                                                    .map((data, i) => {
                                                                                        return (<option key={i} value={data.id}>{data.name}</option>);
                                                                                    })}
                                                                            </Input>
                                                                            {errors.TissueId.length > 0 && <span className='error'>{errors.TissueId}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <label className="col-12 col-form-label">Metastasis<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                        <div className="col-12">
                                                                            <div className="kt-radio-inline">
                                                                                {this.state.metasis == "yes" ?
                                                                                    <label className="kt-radio">
                                                                                        <input type="radio" value="yes" onChange={this.handleInputChange.bind(this)} checked id="Yes" name="metasis" tabIndex="16" /> Yes
                                                                                        <span></span>
                                                                                    </label>
                                                                                    :
                                                                                    <label className="kt-radio">
                                                                                        <input type="radio" value="yes" onChange={this.handleInputChange.bind(this)} id="Yes" name="metasis" tabIndex="16" /> Yes
                                                                                        <span></span>
                                                                                    </label>}
                                                                                {this.state.metasis == "no" ?
                                                                                    <label className="kt-radio">
                                                                                        <input type="radio" value="no" onChange={this.handleInputChange.bind(this)} checked id="No" name="metasis" tabIndex="17" /> No
                                                                                        <span></span>
                                                                                    </label>
                                                                                    :
                                                                                    <label className="kt-radio">
                                                                                        <input type="radio" value="no" onChange={this.handleInputChange.bind(this)} id="No" name="metasis" tabIndex="17" /> No
                                                                                        <span></span>
                                                                                    </label>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {diseaseId == 0 && diseaseId != "" ?
                                                                    <div className="form-group row my-4">
                                                                        <div className="col-md-6">
                                                                            <label className="col-12 col-form-label">New Diesease</label>
                                                                            <div className="col-12">
                                                                                <Input className="form-control here" type="text" maxLength="100" tabIndex="18" name="newDisease" placeholder="Enter new disease" value={newDisease} onChange={this.handleInputChange.bind(this)} />
                                                                                {errors.newDisease.length > 0 && <span className='error'>{errors.newDisease}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label className="col-12 col-form-label">Diesease Code</label>
                                                                            <div className="col-12">
                                                                                <Input className="form-control here" type="text" maxLength="50" tabIndex="19" name="newDiseaseCode" placeholder="Enter code for new disease" value={newDiseaseCode} onChange={this.handleInputChange.bind(this)} />
                                                                                {errors.newDiseaseCode.length > 0 && <span className='error'>{errors.newDiseaseCode}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label className="col-12 col-form-label"></label>
                                                                            <div className="col-12">
                                                                                {loading ?
                                                                                    <button tabIndex="20" type="button" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Add</button>
                                                                                    :
                                                                                    <button tabIndex="20" type="button" className="btn btn-primary" onClick={this.AddNewDisease.bind(this)}>Add</button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : null}

                                                                {TissueId == 0 && TissueId != "" ?
                                                                    <div className="form-group row my-4">
                                                                        <div className="col-md-6">
                                                                            <label className="col-12 col-form-label">New Tissue</label>
                                                                            <div className="col-12">
                                                                                <Input className="form-control here" type="text" maxLength="100" tabIndex="21" name="newTissue" placeholder="Enter new tissue" value={newTissue} onChange={this.handleInputChange.bind(this)} />
                                                                                {errors.newTissue.length > 0 && <span className='error'>{errors.newTissue}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="col-12 col-form-label"></label>
                                                                            <div className="col-12">
                                                                                {loading ?
                                                                                    <button tabIndex="22" type="button" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Add</button>
                                                                                    :
                                                                                    <button tabIndex="22" type="button" className="btn btn-primary" onClick={this.AddNewTissue.bind(this)}>Add</button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                            </React.Fragment>
                                                            : null}
                                                        {/* <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Order of Country</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" name="OCountryId" value={OCountryId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Order of Country</option>
                                                                        {orderOfCountries
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.OCountryId.length > 0 && <span className='error'>{errors.OCountryId}</span>}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {loading ?
                                                            <button tabIndex="23" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                            :
                                                            <button tabIndex="23" type="submit" className="btn btn-primary">Submit</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <Modal isOpen={this.state.previewDetailsModal} className="modal-dialog modal-md" style={{ maxWidth: "900px" }}>
                                    <ModalHeader>Confirm patient Details</ModalHeader>
                                    <ModalBody className="modal-body">
                                        <Card className="viewPatientForm">
                                            <CardBody>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label>First Name:</Label>
                                                            <span className="form-control p-0 border-0" readonly>
                                                                {this.state.FirstName != null
                                                                    ? this.state.FirstName
                                                                    : ""}
                                                            </span>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label>Middle Name:</Label>
                                                            <span className="form-control border-0 p-0">
                                                                {this.state.MiddleName != null
                                                                    ? this.state.MiddleName
                                                                    : ""}
                                                            </span>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label>Last Name:</Label>
                                                            <span className="form-control border-0 p-0">
                                                                {this.state.LastName != null
                                                                    ? this.state.LastName
                                                                    : ""}
                                                            </span>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label>Date of Birth:</Label>
                                                            <span className="form-control border-0 p-0">
                                                                {this.state.DateOfBirth != null &&
                                                                    this.state.DateOfBirth != "" ? (
                                                                    <React.Fragment>
                                                                        {Moment(this.state.DateOfBirth).format(
                                                                            "MM/DD/YYYY"
                                                                        )}
                                                                        {/*format('DD MMM YYYY')*/}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    "NA"
                                                                )}
                                                            </span>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label>Age:</Label>
                                                            <span className="form-control border-0 p-0">
                                                                {this.state.Age != null && this.state.Age != ""
                                                                    ? this.state.Age
                                                                    : "0"}
                                                                &nbsp;Year(s)
                                                            </span>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label>Sex:</Label>
                                                            <span className="form-control border-0 p-0">
                                                                {this.state.Sex == "M"
                                                                    ? "Male"
                                                                    : this.state.Sex == "F"
                                                                        ? "Female"
                                                                        : "-"}
                                                            </span>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label>Email:</Label>
                                                            <a
                                                                className="form-control border-0 p-0"
                                                                href={`mailto: ${this.state.email}`}
                                                            >
                                                                {this.state.email != null ? this.state.email : ""}
                                                            </a>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label>Primary Phone:</Label>
                                                            <span className="form-control border-0 p-0 shadow-none">
                                                                {this.state.Mobile != null ? this.state.Mobile : ""}
                                                            </span>

                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label>Secondary Phone:</Label>
                                                            <span className="form-control border-0 p-0 shadow-none">
                                                                {this.state.PhoneNumber != null
                                                                    ? this.state.PhoneNumber
                                                                    : ""}
                                                            </span>
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label>Neo7 analysis Type</Label>
                                                            <span className="form-control p-0 border-0">
                                                                {this.state.diseasename != null ? this.state.diseasename : ""} {this.state.subDiseaseName != "" ? ` - ${this.state.subDiseaseName} ` : ""}

                                                            </span>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                {
                                                    ['with ctdna & cfdna testing', 'no ctdna & cfdna testing', 'cancer - no tumor', 'cancer with tumor'].includes(this?.state?.diseasename?.toLowerCase()) ?
                                                        <>
                                                            <Row>

                                                                <Col md="4">
                                                                    <FormGroup>
                                                                        <Label>Disease:</Label>
                                                                        <span className="form-control p-0 border-0">
                                                                            {this.state.currentdiseasename != null ? this.state.currentdiseasename : ""}
                                                                            {this.state.metasis == 'yes' ? <b style={{ color: "red" }}>- Metastasis</b> : ""}
                                                                        </span>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md="4">
                                                                    <FormGroup>
                                                                        <Label>Tissue:</Label>
                                                                        <span className="form-control p-0 border-0">

                                                                            {this.state.allTissues.filter((obj) => obj.id == this.state.TissueId)?.[0]?.name || "N/a"}
                                                                        </span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                        </>
                                                        : null
                                                }
                                                <Row>
                                                    <Col md="12">
                                                        <FormGroup>
                                                            {console.log("add", this.state.Address1)}
                                                            <Label>Address :</Label>
                                                            <span
                                                                className="form-control border-0 p-0"
                                                                style={{ height: "70px" }}

                                                            >{!this.state.Address1
                                                                ? ""
                                                                : (this.state.Address1.match(/^\s+$/) === null ? (this.state.Address1 + ", ") : "")}
                                                                <span className="">
                                                                    {this.state.cities.filter((obj) => obj.id == this.state.CityId)?.[0]?.name}{", "}
                                                                </span>
                                                                <span className="">
                                                                    {this.state.states.filter(obje => obje.id == this.state.StateId)?.[0]?.name}{", "}
                                                                </span>
                                                                <span className="">
                                                                    {this.state.PostalCode}{", "}
                                                                </span>
                                                                <span className="">
                                                                    {this.state.countries.filter((obje) => obje.id == this.state?.CountryId)?.[0]?.name}
                                                                </span>

                                                            </span>

                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </CardBody>

                                        </Card>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={() => {
                                            this.setState({
                                                previewDetailsModal: false,
                                            })
                                        }} >
                                            Close
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={this.handleSubmit.bind(this)}
                                        >
                                            Confirm
                                        </Button>
                                    </ModalFooter>
                                </Modal>

                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
                    {this.loading()}
                </div >
            </React.Fragment >
        );
    }
}