import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../style-new.css'
import axios from 'axios';
//import MyModal from './../CustomModal/CustomModal';

export class Caregivers extends Component {
    static displayName = Caregivers.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            logout: false,
        };

        this.handleClickLogout = this.handleClickLogout.bind(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleClickLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('Usertoken');
        localStorage.setItem('isLoggedIn', false);
        this.setState({ logout: true });
    }

    renderRedirecttohome() {
        if (this.state.logout) {
            return <Redirect from="/" to="/login" />
        }
    }
    render() {
        return (


            <React.Fragment>


                {/* Start header section */}
                <header className="header-area" id="header-area">
                    <div className="dope-nav-container breakpoint-off">
                        <div className="container">
                            <div className="row">
                                {/* dope Menu */}
                                <nav className="dope-navbar justify-content-between" id="dopeNav">

                                    {/* Logo */}
                                    <Link to="/" className="nav-brand"><img src="img/header-logo.jpg" alt="" /></Link>

                                    {/* Navbar Toggler */}
                                    <div className="dope-navbar-toggler">
                                        <span className="navbarToggler">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </div>

                                    {/* Menu */}
                                    <div className="dope-menu">

                                        {/* close btn */}
                                        <div className="dopecloseIcon">
                                            <div className="cross-wrap">
                                                <span className="top"></span>
                                                <span className="bottom"></span>
                                            </div>
                                        </div>

                                        {/* Nav Start */}
                                        {/*<div className="dopenav">
                                            <div className="top-rightside">
                                                <div className="">
                                                    <a href="/login" className="primary-btn">Login</a>
                                                </div>
                                                <div className="header-icon">
                                                    <a href="#"><i className="fa fa-search" aria-hidden="true"></i></a>
                                                </div>
                                                <div className="header-icon">
                                                    <a href="#"><i className="fa fa-bars" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                            <ul id="nav">
                                                <li>
                                                    <a href="#">Healthcare Professionals</a>
                                                </li>
                                                <li>
                                                    <a href="#">Patients and Caregivers</a>
                                                </li>
                                            </ul>

                                        </div>*/}

                                        {localStorage.getItem('token') != null ?
                                            (
                                                <div className="dopenav">
                                                    <div className="top-rightside">
                                                        {(JSON.parse(localStorage.getItem('Usertoken')) != null ?
                                                            (JSON.parse(localStorage.getItem('Usertoken')).userType != 1 ?
                                                                (JSON.parse(localStorage.getItem('Usertoken')).userType != 2 ?
                                                                    (JSON.parse(localStorage.getItem('Usertoken')).userType != 3 ?
                                                                        (JSON.parse(localStorage.getItem('Usertoken')).userType == 4 ?
                                                                            <Link to="/laboratory/dashboard" className="primary-btn" >Dashboard</Link>

                                                                            :
                                                                            <Link to="/manufacturer/dashboard" className="primary-btn" >Dashboard</Link>

                                                                        )
                                                                        :
                                                                        <Link to="/institute/dashboard" className="primary-btn" >Dashboard</Link>

                                                                    )
                                                                    :
                                                                    <Link to="/practitioner/dashboard" className="primary-btn" >Dashboard</Link>

                                                                )
                                                                :
                                                                <Link to="/patient/dashboard" className="primary-btn" >Dashboard</Link>

                                                            )
                                                            : "")
                                                        }
                                                        <Link onClick={this.handleClickLogout} className="primary-btn">Logout</Link>
                                                    </div>
                                                    <ul id="nav">
                                                        <li>
                                                            <Link to="/Services" >Services</Link>
                                                        </li>
                                                        <li>
                                                            <Link className="navbar-item" to="/HealthcareProfessional" >Healthcare Professionals </Link>
                                                            {/* <a href="/HealthcareProfessional">Healthcare Professionals</a> */}
                                                        </li>
                                                        <li>
                                                            <Link to="/Caregivers" >Patients and Caregivers</Link>

                                                        </li>
                                                    </ul>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className="dopenav">
                                                    <div className="top-rightside">
                                                        <div className="">
                                                            <ul id="nav">
                                                                <li>
                                                                    <Link to="/Services" >Services</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/HealthcareProfessional" >Healthcare Professionals</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/Caregivers" >Patients and Caregivers</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/login" className="primary-btn">Login</Link>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                      
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {/* Nav End */}
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>



                <section className="section-pad bg-blue-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 d-flex align-items-center">
                                <div className="logo logo-sm-260 mb-2 ie-width-100">
                                    <img src="img/ft-logo.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <p className="lead">The NEO7LOGIX FAST STEP™ Program can help your eligible<sup>‡</sup> commercially insured patients cover their out-of-pocket prescription costs, including deductible, co-insurance, and co-payment.</p>
                                {/* <Link className="btn btn-primary">Visit NEO7LOGIX FAST STEP™ now</Link> */}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="resources">
                    <div className="container">
                        <div className="section-title">
                            <h2 className="text-center">Resources</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="resorce-inner">
                                    <div className="ricon">
                                        <img src="img/icon1.svg" className="ricon-inner" />
                                    </div>
                                    <div className="r-inner-text">
                                        <h3>PATIENT COUNSELOR</h3>
                                        <p>Connect today with our Patient Counselor, or schedule a visit with a Clinical Specialist</p>
                                        {/* <Link className="resources-btn">Find a Patient Counselor</Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="resorce-inner">
                                    <div className="ricon">
                                        <img src="img/icon2.svg" className="ricon-inner" />
                                    </div>
                                    <div className="r-inner-text">
                                        <h3>PATIENTS PERSONALIZED BENEFITS</h3>
                                        <p>NEO7LOGIX Patient Counselors can assist with submitting, storing, and retrieving all benefits for anyone currently on our</p>
                                        {/* <Link className="resources-btn">Visit the Benefit Center</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Start feature section */}
                <section className="feature-section section-gap-full" id="feature-section">
                    <div className="container">
                        <div className="section-title">
                            <h2 className="text-center">Select PBIMA-PPAS Personalized analytics services For</h2>
                        </div>
                        <div className="inner-div">
                            <h4>Cancer</h4>
                            <h4>Autoimmune Diseases</h4>
                            <h4>Neurodegenerative Diseases</h4>
                            <h4>Inflammation-driven Diseases</h4>
                        </div>
                    </div>
                </section>
                {/* End feature section */}

                <section className="resources-2">
                    <div className="container">
                        <div className="section-title" >
                            <h3 className="text-center" style={{ "textAlign": "left !important", "marginBottom": "20px" }}>Neo7logix Nurse Ambassadors†</h3>
                            <p>When patients enroll in Neo7logix Portal 360, their Neo7logix Clinical Specialist and Nurses serves as a single point of contact to help them find resources that are most important to them.</p>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="resorce-inner">
                                    <div className="ricon">
                                        <img src="img/icon1.svg" className="ricon-inner" />
                                    </div>
                                    <div className="r-inner-text">
                                        <h2>PAYMENT RESOURCES</h2>
                                        <p>Connect patients to Neo7Logix Billing department to help with insurance benefit and put them in touch with programs that may help them afford their personalized therapy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="resorce-inner">
                                    <div className="ricon">
                                        <img src="img/icon2.svg" className="ricon-inner" />
                                    </div>
                                    <div className="r-inner-text">
                                        <h2>REFERRALS TO RESOURCES FOR DAY-TO-DAY LIVING, TRANSPORTATION AND LODGING*</h2>
                                        <p>Refer patients to independent nonprofit organizagtions that may provide counseling, community resources, and assistance with personalized treatment-related information.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="resorce-inner">
                                    <div className="ricon">
                                        <img src="img/icon3.svg" className="ricon-inner" />
                                    </div>
                                    <div className="r-inner-text">
                                        <h2>MEDICATION ANSWERS</h2>
                                        <p>If your patients have questions about their Amgen medication we can help them get the answers they need.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <Link className="btn btn-primary retach-1">Download Enrollment Form</Link> */}
                    </div>
                </section>



                {/* End clients section */}


                <section className="bg-gradient-1 section-pad">
                    <div className="container">
                        <div className="section-title">
                            <h2 className="text-center">Resources to help you get started</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-2 mb-md-0">
                                <div className="media mb-1 mb-md-0">
                                    <div className="logo logo-rounded logo-rounded--less logo-max-160 logo-min-100 mr-2 logo-max-h-270">
                                        <img src="img/bottom2.jpg" />
                                    </div>
                                    <div className="media-body">
                                        <h5>Billing Professionals Resource Center</h5>
                                        <p>Access and reimbursement to webinars and useful links.</p>
                                        {/* <Link className="btn btn-primary retach-1">Visit the Resource Center</Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="media mb-1 mb-md-0">
                                    <div className="logo logo-rounded logo-rounded--less logo-max-160 logo-min-100 mr-2">
                                        <img src="img/bottom2.jpg" />
                                    </div>
                                    <div className="media-body">
                                        <h5>Benefit Verification Request</h5>
                                        <p>Simply download a request form to get started today.</p>
                                        {/* <Link className="btn btn-primary retach-2">Download BV Request Form</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-pad-less">
                    <div className="container">
                        <div className="hangnotes">
                            <p className="small fake-bullet">
                                <span className="bullet">*</span>Resources include referrals to independent nonprofit patient assistance programs. Eligibility for resources provided by independent nonprofit patient assistance programs is based on the
                nonprofits’ criteria. Amgen has no control over these programs and provides referrals as a courtesy only.
            </p>
                            <p className="small fake-bullet">
                                <sup className="bullet">†</sup>Amgen Nurse Ambassadors are only available to patients that are prescribed certain products. Nurse Ambassadors are there to support, not replace, your treatment plan and do not provide medical
                advice or case management services. Patients should always consult their healthcare provider regarding medical decisions or treatment concerns.
            </p>
                            <p className="small fake-bullet">
                                <sup className="bullet">‡</sup>Subject to program eligibility requirements and coverage limits. See AmgenFIRSTSTEP.com for details. This program is not open to patients receiving prescription reimbursement under any federal,
                state, or government-funded healthcare program, such as Medicare, Medicare Advantage, Medicare Part D, Medicaid, Medigap, Veterans Affairs (VA), the Department of Defense (DoD), or TRICARE<sup>®</sup>, or where otherwise
                prohibited by law.
            </p>
                        </div>
                    </div>
                </section>


                {/* Start footer section */}
                <footer className="footer-section section-gap-half">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 footer-left">
                                <Link to="/">
                                    <img src="img/header-logo.jpg" alt="" />
                                </Link>
                                <p><i className="ti-location-pin"></i> 539 W. Commerce St #2886<br /> Dallas, TX 75208 </p>
                                {/* <a href="mailto:office@neo7logix.com">
                                    <p><i className="ti-email"></i> office@neo7logix.com</p>
                                </a>
                                <a href="tel:+12067185467">
                                    <p><i className="ti-mobile"></i>+1-206-718-5467</p>
                                </a> */}
                                <p className="copyright-text"> Copyright © 2020 Neo7Logix LLC- All Rights Reserved.</p>
                            </div>
                            <div className="col-lg-7">
                                <div className="footer-title">
                                    <h2>WE'RE HERE TO HELP</h2>
                                    <a href="tel:+12067185467">
                                        <p><i className="ti-mobile"></i>+1-206-718-5467</p>
                                    </a>
                                    <a href="mailto:office@neo7logix.com">
                                        <p><i className="ti-email"></i> office@neo7logix.com</p>
                                    </a>
                                </div>
                                <ul className="footer-menu">
                                    {/* <li>
                                    <Link to="/ContactUs" >Contact Us</Link>
                                        
                                    </li> */}
                                    <li>
                                        <Link to="/Sitemap" >Sitemap</Link>

                                    </li>
                                    <li>
                                        <Link to="/TermsOfUse" >Terms of Use</Link>

                                    </li>
                                    <li>
                                        <Link to="/PrivacyStatements" >Privacy Statement</Link>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* End footer section */}

                <div className="scroll-top">
                    <i className="ti-angle-up"></i>
                </div>
            </React.Fragment>


        );
    }
}
