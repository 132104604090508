import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../../CustomModal/CustomModal';

export class PatientPaymentDetail extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            milestones: [],
            PatientId: 0,
            PatientPaymentId: 0,
            patientMilestoneId: "",
            TransactionId: "",
            TransactionAmount: "",
            Remark: "",
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            errors: {
                patientMilestoneId: '',
                TransactionId: '',
                TransactionAmount: '',
                Remark: ''
            },
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/patient/insurance/list');
    //    }
    //}

    componentDidMount() {
        this.getMilestone();
    }

    getMilestone() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let userId = (userToken.userId == null ? 0 : userToken.userId);

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PaymentMilestone/GetAll';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            id: userId
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;

                    this.setState({
                        milestones: rData,
                    }, () => {
                        const param = this.props.match.params;

                        if (param.id != undefined) {
                            this.getData(param.id);
                        }
                        else {
                            this.setState({ loading: false });
                        }
                    });
                }
                else {
                    this.setState({
                        // authError: true, errorType: 'danger', error: result.data.message, 
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientPayment/GetById?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata.insuranceData;
                    var dData = result.data.outdata.docData;
                    this.setState({
                        PatientId: rData.patientId, patientMilestoneId: rData.patientMilestoneId, TransactionId: rData.transactionId,
                        TransactionAmount: rData.transactionAmount, Remark: rData.remark, PatientPaymentId: rData.patientPaymentId,
                        loading: false
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name == "patientMilestoneId") {
            let milstoneamount = this.state.milestones.filter(ml => ml.paymentMilestoneId === parseInt(value));
            this.setState({
                TransactionAmount: milstoneamount[0].amount
            });
        }
        let errors = this.state.errors;

        switch (name) {
            case 'TransactionId':
                errors.TransactionId = (!value) ? 'Please enter transaction id.' : '';
                break;
            case 'patientMilestoneId':
                errors.patientMilestoneId = (!value) ? 'Please select milestone.' : '';
                break;
            case 'TransactionAmount':
                errors.TransactionAmount = (!value) ? 'Please enter transaction amount.' : '';
                break;
            case 'Remark':
                errors.Remark = (!value) ? 'Please enter remark.' : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {

        })
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.patientMilestoneId == undefined || this.state.patientMilestoneId == '') {
            errors.patientMilestoneId = 'Please select milestone.';
        }
        if (this.state.TransactionAmount == undefined || this.state.TransactionAmount == '') {
            errors.TransactionAmount = 'Please enter transaction amount.';
        }
        if (this.state.TransactionId == undefined || this.state.TransactionId == '') {
            errors.TransactionId = 'Please enter transaction id.';
        }
        if (this.state.Remark == undefined || this.state.Remark == '') {
            errors.Remark = 'Please enter remark.';
        }
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        if (this.validateForm(this.state.errors)) {
            var userToken = JSON.parse(localStorage.getItem('Usertoken'));
            let userId = userToken.userId;
            let pid = (userToken.patientId == null ? 0 : userToken.patientId);

            const apiroute = window.$APIPath;
            if (this.state.PatientPaymentId == 0) {
                url = apiroute + '/api/PatientPayment/Save';
            }
            else {
                url = apiroute + '/api/PatientPayment/Update';
            }

            

            let data = JSON.stringify({
                PatientId: parseInt(pid),
                PaymentMilestoneId: parseInt(this.state.patientMilestoneId),
                TransactionAmount: parseFloat(this.state.TransactionAmount),
                TransactionId: this.state.TransactionId,
                TransactionStatus: "Paid",
                Remark: this.state.remark,
                CreatedBy: parseInt(userId)
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            redirect: true
                        });
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        } else {
            this.setState({ loading: false });
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/patient/payment/list" />
        }
    }
    render() {
        const { loading, patientMilestoneId, milestones, TransactionId, TransactionAmount, Remark, errors, error, errorType, authError } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
                            <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
                                <div className="kt-aside__brand-logo">
                                    <Link to="/" style={{ "textAlign": "center" }}>
                                        <img alt="Logo" src="./assets/media/logos/logo-small.png" />
                                    </Link>
                                </div>
                                <div className="kt-aside__brand-tools">
                                    <button className="kt-aside__brand-aside-toggler" id="kt_aside_toggler"><span></span></button>
                                </div>
                            </div>
                            <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                                <div
                                    id="kt_aside_menu"
                                    className="kt-aside-menu "
                                    data-ktmenu-vertical="1"
                                    data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
                                    <ul className="kt-menu__nav">
                                        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/patient/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                        </li>
                                        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/patient/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Patient Information</span></Link>
                                        </li>
                                        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/patient/emergency/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-phone"></i><span className="kt-menu__link-text">Emergency Contact</span></Link>
                                        </li>
                                        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/patient/insurance/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-document"></i><span className="kt-menu__link-text">Insurance Information</span></Link>
                                        </li>
                                        {/*<li className="kt-menu__item  kt-menu__item--active" aria-haspopup="true"><Link to="/patient/payment/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></Link>
                                        </li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                                Patient
                           </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="/patient/payment/detail" className="kt-subheader__breadcrumbs-link">
                                                    Payment Detail                       </Link>
                                            </div>
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/patient/payment/list" className="btn btn-primary btnBorder">
                                                List
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Milestone </label>
                                                                <div className="col-12">
                                                                    <Input type="select" name="patientMilestoneId" value={patientMilestoneId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Milestone</option>
                                                                        {milestones
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.paymentMilestoneId}>{data.paymentMilestoneName + " (" + data.amount + ")"}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.patientMilestoneId.length > 0 && <span className='error'>{errors.patientMilestoneId}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Transaction Id</label>
                                                                <div className="col-12">
                                                                    <Input type="text" name="TransactionId" tabIndex="2" maxLength="50" className="form-control" value={TransactionId} onChange={this.handleInputChange.bind(this)} placeholder="Enter a transaction id" />
                                                                    {errors.TransactionId.length > 0 && <span className='error'>{errors.TransactionId}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Transaction Amount</label>
                                                                <div className="col-12">
                                                                    <Input type="number" className="form-control here" maxLength="50" tabIndex="3" name="TransactionAmount" placeholder="Enter a transaction amount" value={TransactionAmount} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.TransactionAmount.length > 0 && <span className='error'>{errors.TransactionAmount}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Remark</label>
                                                                <div className="col-12">
                                                                    <Input type="text" className="form-control here" maxLength="50" tabIndex="4" name="Remark" placeholder="Enter a remark" value={Remark} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.Remark.length > 0 && <span className='error'>{errors.Remark}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {loading ?
                                                            <button tabIndex="5" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                            :
                                                            <button tabIndex="5" type="submit" className="btn btn-primary">Submit</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
                </div>
            </React.Fragment >
        );
    }
}
