import React, { Component } from 'react';
import {
    Collapse, Table, Pagination, PaginationItem, Row, Modal, ModalHeader, ModalBody, ModalFooter,
    PaginationLink, Fade, Col, Card, CardHeader, CardBody, Button, Input, Label
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
//import MyModal from './../../CustomModal/CustomModal';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import form_icon from '../../../assets/svg/file-earmark-medical.svg'


export class PractitionerDashboard extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            openSearch: true,
            patients: [],
            subDiseaseCatId: "",
            diseaseCat: [],
            diseaseCatId: 0,

            searchString: '',
            // authError: false,
            error: '',
            errorType: '',
            patientId: "",
            //patientdiseaseId: "",
            //Alldiseases: [],
            //showdisease: false,
            errors: {
                samplecheckbox: '',
                patientdiseaseId: '',
                diseaseId: '',
                tissueId: '',
            },
            diseaseNotCount: 0,
            currentPage: 0,
            currentIndex: 0,
            pagesCount: 0,
            pageSize: window.$TotalRecord,
            collapseId: 0,
            anaDesignCount: 0,
            manuCount: 0,
            registeredCount: 0,
            treatmentCount: 0,

            patientAccessionId: '',
            accessionNo: '',
            diseaseName: "Cancer Patients",
            showPopup: false,
            diseaseId: '',
            disease: [],
            alldiseases: [],
            isHealthShow: false,

            tissueId: '',
            allTissues: [],
            sample: [],
            checkBox: [],
        };
        this.state = this.initialState;

    }

    componentDidMount() {
        this.getListData(0);
    }

    getlabdetailsBydiseaseCatId(diseaseCatId) {
        // alert(diseaseCatId)
        this.state.errors.samplecheckbox = '';
        const apiroute = window.$APIPath;
        const url = apiroute + "/api/SampleType/GetByDiseaseCatId?id=" + diseaseCatId;
        this.setState({ loading: true })
        axios
            .get(url, {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            }).then((result) => {
                if (result.data.flag) {
                    // let labids = result?.data?.outdata?.map((ele) => ele.ngsLaboratoryId)
                    let samples = result?.data?.outdata || [];
                    let checkBox = samples.map((ele) => String(ele.sampleTypeId))
                    this.setState({ sample: samples });
                    console.log(this.state.sample);
                    this.setState({ checkBox })
                }

            }).finally(() => { this.setState({ loading: false }) })

    }

    handleCheckBox(e) {
        let value = e.target.value;
        let arr = [...this.state.checkBox];

        if (this.state.checkBox.includes(value)) {
            // alert("exist")
            arr.splice(arr.indexOf(value), 1);
            this.state.errors.samplecheckbox = arr?.length === 0 ? 'please select sample' : '';
        } else {
            arr.push(value);
            this.state.errors.samplecheckbox = arr?.length > -1 ? '' : null;
        }

        this.setState({
            checkBox: arr
        })
        console.log("id", this.state.checkBox);
    }
    getListData(pageNo) {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.practitionerId == null ? 0 : userToken.practitionerId);
        }
        else {
            return <Redirect to="/" />
        }

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PractitionerPatient/GetPatientsByPractitionerIdPagination';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: this.state.searchString,
            Id: id,
            pageNo: pageNo,
            totalNo: window.$TotalRecord,
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                console.log("Resultrs:::", result);
                if (result.data.flag) {
                    this.setState({

                        patients: result.data.outdata.practitionerPatientData,
                        anaDesignCount: result.data.outdata.analysisCount + result.data.outdata.designCount,
                        manuCount: result.data.outdata.manuCount,
                        registeredCount: result.data.outdata.registeredCount,
                        treatmentCount: result.data.outdata.treatmentCount,
                        //Alldiseases: result.data.outdata.diseaseData,
                        pagesCount: Math.ceil(result.data.totalRecord / window.$TotalRecord),
                        loading: false
                    });
                    //if (result.data.outdata.practitionerPatientData.length > 0) {
                    //    let rData = result.data.outdata.practitionerPatientData;
                    //    let noDiseaseCount = rData.filter(
                    //        ml => ml.diseaseName == "" || ml.diseaseName == null
                    //    );
                    //    this.setState({
                    //        diseaseNotCount: noDiseaseCount.length
                    //    });
                    //}
                } else { this.setState({ loading: false }); }
                this.getDrpData();
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //getDrpData() {
    //    const apiroute = window.$APIPath;
    //    let data = JSON.stringify({
    //      isDeleted: true,
    //      searchString: '',
    //      id: 0
    //    });
    //    axios.post(url, data, {
    //      headers: {
    //        'Content-Type': 'application/json; charset=utf-8'
    //      }
    //    }).then(result => {
    //      if (result.data.flag) {
    //        this.setState({
    //          alldiseases: result.data.outdata,
    //        });
    //      } else {
    //        this.setState({ loading: false });
    //      }
    //    }).catch(error => {
    //      this.setState({ loading: false });
    //    });
    //  }

    getDrpData() {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/BE_Common/GetPatientDropdownEntity';
        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            id: 0
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            console.log(result.data)
            if (result.data.flag) {

                this.setState({
                    alldiseases: result.data.outdata.diseaseData,
                    diseaseCat: result.data.outdata.diseaseCatData
                });

                console.log(this.state);
            } else {
                this.setState({ loading: false });
            }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    //pagination
    handleClick(e, index, currIndex) {
        e.preventDefault();
        var pgcount = this.state.pagesCount - 1;
        var pgCurr = (index >= pgcount ? pgcount : index);
        this.setState({
            loading: true,
            currentPage: pgCurr,
            currentIndex: currIndex
        }, function () { this.getListData(pgCurr); });
    }

    //delete(active/inactive) button click
    deleteRow(e, id) {
        e.preventDefault();

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let userId = userToken.userId;
        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PractitionerPatient/DeletePatient?id=' + id + '&userId=' + userId;

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false,
                    }, this.getListData(0));
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    closeSearch = (e) => {
        if (this.state.searchString === '') {
            this.setState({
                openSearch: false
            });
        }
        else {
            this.setState(() => ({
                openSearch: false,
                loading: true,
                currentPage: 0,
                currentIndex: 0,
                pagesCount: 0,
                //pageSize: window.$TotalRecord,
                searchString: ''
            }), function () { this.getListData(0); });
        }
    }

    //search
    filter = (e) => {
        if (e.key == 'Enter') {
            const target = e.target;
            const value = target.value;

            this.setState(() => ({
                loading: true,
                currentPage: 0,
                currentIndex: 0,
                pagesCount: 0,
                //pageSize: window.$TotalRecord,
                searchString: value.trim()
            }), function () { this.getListData(0); });
        }
    }

    //add disease
    //handleCloseDisease = () => {
    //    this.setState({
    //        showdisease: false
    //    });
    //}

    //handleShowDisease = (pid) => {
    //    this.setState({
    //        patientId:pid,
    //        showdisease: true,
    //    });
    //}

    //handleDiseaseInputChange(event) {
    //    const target = event.target;
    //    const value = target.value;
    //    const name = target.name;

    //    this.setState({
    //        [name]: value
    //    });

    //    let errors = this.state.errors;

    //    switch (name) {
    //        case 'patientdiseaseId':
    //            errors.patientdiseaseId = (!value) ? "Please select disease." : '';
    //            break;
    //        default:
    //            //(!value) ? '' :'This standard is required.'
    //            break;
    //    }


    //    this.setState({ errors, [name]: value }, () => {

    //    })
    //}

    //AddPatientDisease(e) {
    //    e.preventDefault();
    //    this.setState({ loading: true });
    //    let errors = this.state.errors;

    //    let uid = 0;
    //    var userToken = JSON.parse(localStorage.getItem('Usertoken'));
    //    if (userToken != null) {
    //        uid = (userToken.userId == null ? 0 : userToken.userId);
    //    }

    //    if (this.state.patientdiseaseId != null && this.state.patientdiseaseId != "") {
    //        const apiroute = window.$APIPath;

    //        let data = JSON.stringify({
    //            PatientId: parseInt(this.state.patientId),
    //            DiseaseId: parseInt(this.state.patientdiseaseId),
    //            PatientDiseaseId: 0,
    //            createdBy: uid,
    //            createdByFlag: "P"
    //        })

    //        axios.post(url, data, {
    //            headers: {
    //                'Content-Type': 'application/json; charset=utf-8'
    //            }
    //        }).then(result => {
    //            if (result.data.flag) {
    //                this.setState({
    //                    authError: true,
    //                    errorType: 'success',
    //                    error: result.data.message,
    //                    loading: false,
    //                    showdisease: false,
    //                    patientdiseaseId: '',
    //                    patientId:''
    //                }, this.getListData(0));
    //            }
    //            else {
    //                errors.patientdiseaseId = result.data.message;
    //                this.setState({ loading: false });
    //            }
    //        })
    //            .catch(error => {
    //                this.setState({
    //                    authError: true, errorType: 'danger', error: error.message, loading: false,
    //                    showdisease: false
    //                });
    //            });
    //    }
    //    else {
    //        errors.patientdiseaseId = "Please select disease.";
    //        this.setState({ loading: false });
    //    }
    //}
    //end add disease

    getTissueData(DiseaseId, TissueId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/Tissue/getdrpTissueByDiseaseId';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            id: parseInt(DiseaseId)
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                this.setState({ allTissues: result.data.outdata, Tissue: TissueId });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    AddPatientAccessionNo(e) {
        e.preventDefault();
        this.setState({ loading: true });

        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('AUserToken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/Patient/UpdateDiseaseDetailsPopup';

            var did = 0;
            //if (this.state.diseaseName.toLowerCase() == 'complete health score') {
            //    let diseases = this.state.alldiseases.filter(dl => dl.name.toLowerCase() == 'healthindex');
            //    console.log(diseases);
            //    if (diseases) {
            //        did = diseases[0].id;
            //    }
            //} else {
            //    did = parseInt(this.state.id);
            //}


            if (this.state.diseaseName.toLowerCase() != 'cancer') {
                let currentdisease = this.state.alldiseases.filter(ds => ds.diseaseCatId == parseInt(this.state.diseaseCatId));
                did = currentdisease[0]?.id;
            } else {
                did = parseInt(this.state.diseaseId);
            }

            let data = JSON.stringify({
                patientAccessionId: parseInt(this.state.patientAccessionId),
                patientId: parseInt(this.state.patientId),
                DiseaseCategoryId: this.state.diseaseName == 'LRI Longevity' ? Number(this.state.subDiseaseCatId) : parseInt(this.state.diseaseCatId),
                diseaseId: parseInt(did),
                tissueId: parseInt(this.state.tissueId),
                accessionNo: this.state.accessionNo || "",
                createdBy: uid,
                sampleTypes: this.state.checkBox.map(ele => Number(ele)),
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    this.setState(() => ({
                        loading: false,
                        showPopup: false,
                        patientId: '',
                        patientAccessionId: '',
                        accessionNo: '',
                        diseaseId: '',
                        tissueId: '',
                        diseaseCatId: '',
                        disease: [],
                        diseaseName: "Cancer Patients"
                    }), function () {
                        // this.getListData(0);
                    });
                    toast.success(result.data.message)
                    this.getListData(0)
                }
                else {
                    this.setState({
                        loading: false,
                    });
                    toast.error(result.data.message)
                }
            })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        }
        else {
            this.setState({ loading: false });
        }
    }

    handleShowPopup = (pid, paid, ano, did, tid, dCatId) => {
        //let dname = "Cancer Patients";
        //if (did != 0) {
        //    let disease = this.state.alldiseases.filter(dl => dl.diseaseId == did);
        //    if (disease[0]?.diseaseName.toLowerCase() == 'healthindex') {
        //        dname = "VibrantHealthX";
        //    }
        //}

        console.log("callpopup");
        let dcid = dCatId;
        let findDid = this.state.diseaseCat.filter(ele => {
            let isDid = ele.diseaseCategoryId == dcid
            console.log({ d: ele.diseaseCategoryId, dcid })
            if (isDid) return true
            let sub = ele.subCategory;
            let subIds = Array.isArray(sub) && sub.map(e => String(e?.diseaseCategoryId))
            return subIds.includes(String(dcid))
        })
        let diseaseCatId;
        let subDiseasId;
        if (findDid.length) {
            let mainCatId = findDid[0].diseaseCategoryId

            if (mainCatId == dcid) {
                diseaseCatId = mainCatId
                if (mainCatId?.subCategory?.length) {
                    subDiseasId = mainCatId.subCategory[0].diseaseCategoryId
                }
            } else {
                let sub = findDid[0].subCategory;
                let subIds = Array.isArray(sub) && sub.map(e => String(e?.diseaseCategoryId))
                if (subIds.includes(String(dcid))) {
                    diseaseCatId = mainCatId;
                    subDiseasId = dcid;
                }
            }
        }
        // let findDesis = this.state.diseaseCat.find(ele => { 
        //     let isDid = ele.diseaseCategoryId == did
        //     if (!isDid) {
        //         let sub = ele?.subCategory
        //         if (Array.isArray(sub) && sub.length>=0) {
        //             let subCatIndex = sub.findIndex(e => e?.diseaseCategoryId == did)
        //             if (subCatIndex != -1) return true;else return false
        //          }else return false
        //     }else{ return  true}
        // })
        // let diseaseId;
        // let subDiseasId;
        // let isParent = findDesis?.diseaseCategoryId === did 
        // if (isParent) {
        //     diseaseId = findDesis?.diseaseCategoryId 
        //     if(findDesis.subC)
        // } else {
        //     let sub = findDesis.subCategory?.find(ele=>ele.diseaseCategoryId ==did)
        // }
        let diseaseCat = this.state.diseaseCat.filter(dl => dl.diseaseCategoryId == dCatId);
        let dcname = diseaseCat[0]?.diseaseCategoryName || '';
        let currentdisease = this.state.alldiseases.filter(ds => ds.diseaseCatId == dCatId);
        this.setState({
            diseaseCatId: diseaseCatId,
            disease: currentdisease,

            patientId: pid,
            patientAccessionId: paid,
            accessionNo: ano,
            diseaseId: did,
            tissueId: tid,
            diseaseName: dcname,
            showPopup: true,
            subDiseaseCatId: subDiseasId,
            findDid, dcid
        }, () => {
            this.getlabdetailsBydiseaseCatId(this.state.diseaseCatId)
            if (did != 0) {
                this.getTissueData(did, tid);
            }
        });

        console.log(" diseaseCatId", dcid)
    }

    //Accession No modal functions
    handleClosePopup = () => {
        this.setState({
            patientId: '',
            patientAccessionId: '',
            accessionNo: '',
            diseaseId: '',
            tissueId: '',
            diseaseName: "Cancer",
            showPopup: false,
            sample: [],
            checkBox: [], diseaseCatId: "",
            errors: { ...this.state.errors, diseaseId: '', tissueId: '' }
        });
    }

    handlePopupInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        // diseaseCatId
        // alert("122")
        this.setState({
            [name]: value
        });

        // if (name == 'diseaseCatId') {
        //     let currentdiseasecat = this.state.diseaseCat.filter(ds => ds.diseaseCategoryId == value);
        //     if (currentdiseasecat.length > 0) {
        //         this.setState({ diseaseName: currentdiseasecat[0].diseaseCategoryName });
        //     }
        // }

        if (name == "diseaseCatId") {
            console.log("id", value);

            this.state.errors.samplecheckbox = '';
            let index = this.state.diseaseCat.findIndex(ele => ele.diseaseCategoryId == value)
            let catId = value
            if (index >= 0) {
                if (this.state.diseaseCat[index]?.subCategory?.length > 0) {
                    catId = this.state.diseaseCat[index].subCategory[0].diseaseCategoryId
                }
            }
            // alert(`${catId} - ${value}`)
            this.getlabdetailsBydiseaseCatId(catId)
            let currentdiseasecat = this.state.diseaseCat.filter(
                (ds) => ds.diseaseCategoryId == value
            );
            let subCategory = currentdiseasecat[0]?.subCategory
            let stateData = { diseaseName: currentdiseasecat[0].diseaseCategoryName, }
            console.log(stateData)
            if (subCategory[0]?.diseaseCategoryId) {
                stateData.subDiseaseCatId = subCategory[0].diseaseCategoryId
            }
            if (currentdiseasecat.length > 0) {
                this.setState(stateData);
            }
        }
        if (name === 'subDiseaseCatId') {
            this.getlabdetailsBydiseaseCatId(value)
        }


        let errors = this.state.errors;

        switch (name) {
            case 'diseaseId':
                errors.diseaseId = (!value) ? (this.state.diseaseName.toLowerCase() == 'cancer' ? "Please select disease." : '') : '';
                break;
            case 'tissueId':
                errors.tissueId = (!value) ? (this.state.diseaseName.toLowerCase() == 'cancer' ? "Please select tissue." : '') : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {
            if (name == 'diseaseId') {
                if (value != '') {
                    this.setState({ allTissues: [], Tissue: "" });
                    this.getTissueData(value, "");
                }
                else {
                    this.setState({ allTissues: [], Tissue: "" });
                }
            }
            if (name == 'diseaseCatId') {
                // debugger;
                this.setState({ disease: [], diseaseId: "" });
                if (value != '') {
                    let currentdisease = this.state.alldiseases.filter(ds => ds.diseaseCatId == value);
                    if (currentdisease.length > 0) {
                        this.setState({ disease: currentdisease });
                    }
                }
            }
        })
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.diseaseName.toLowerCase() == 'cancer') {
            if (this.state.diseaseId == null || this.state.diseaseId == "") {
                errors.diseaseId = "Please select disease.";
            }
            if (this.state.tissueId == null || this.state.tissueId == "") {
                errors.tissueId = "Please select tissue.";
            }
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    setCollapse(cid) {
        let currentCid = this.state.collapseId;
        if (currentCid == cid) {
            this.setState({ collapseId: -1 });
        }
        else {
            this.setState({ collapseId: cid });
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, patients, error, errors, errorType, authError, diseaseNotCount, patientId, showPopup, diseaseName,
            diseaseId, tissueId, allTissues, alldiseases, patientdiseaseId, showdisease, accessionNo,
            currentPage, currentIndex, pagesCount, pageSize, collapseId, treatmentCount, registeredCount, manuCount, anaDesignCount,
            diseaseCat, diseaseCatId, disease, sample, isHealthShow } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                                Dashboard
                                            </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/practitioner/dashboard" className="kt-subheader__breadcrumbs-link">
                                                    Dashboard                       </Link>
                                            </div> */}
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/practitioner/patient/detail" className="btn btn-primary btnBorder">
                                                Add Patient
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        {/* {authError ?
                                            <div>
                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                    <div className="alert-text">{error}</div>
                                                    <div className="alert-close">
                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                                        <div className="kt-portlet__body">
                                            <Row>
                                                <Col xs="12" lg="12">
                                                    <Card>
                                                        <CardHeader>
                                                            <Row>
                                                                <Col xs="2">
                                                                    {/*<Input type="select" name="slDelete" onChange={this.handleChange}>*/}
                                                                    {/*  <option value="true">Active</option>*/}
                                                                    {/*  <option value="false">Inactive</option>*/}
                                                                    {/*</Input>*/}
                                                                </Col>
                                                                <Col xs="4">
                                                                </Col>
                                                                <Col xs="6">
                                                                    {
                                                                        this.state.openSearch ? (
                                                                            <div className="searchBox">
                                                                                <input type="text" placeholder="Search..." onKeyPress={this.filter} />
                                                                                <Link className="closeSearch" to="#" onClick={this.closeSearch}><i className="fa fa-close" /></Link>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="search" onClick={() => this.setState({ openSearch: true })}>
                                                                                <i className="fa fa-search" />
                                                                            </div>
                                                                        )}
                                                                </Col>
                                                            </Row>
                                                        </CardHeader>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            {/* {console.log("Dataasdfs:", patients)} */}

                                            <Row className="mt-4">
                                                <Col xs="6" sm="4" lg="3" >
                                                    <Card style={{ "backgroundColor": "#E2F0D9" }}>
                                                        <CardBody>
                                                            <div><b>{registeredCount}</b></div>
                                                            <div>Registered</div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col xs="6" sm="4" lg="3">
                                                    <Card style={{ "backgroundColor": "#DAE3F3" }}>
                                                        <CardBody>
                                                            <div><b>{anaDesignCount}</b></div>
                                                            <div>In Analysis & Design</div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col xs="6" sm="4" lg="3">
                                                    <Card style={{ "backgroundColor": "#FBE5D6" }}>
                                                        <CardBody>
                                                            <div><b>{manuCount}</b></div>
                                                            <div>In Manufacturing</div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col xs="6" sm="4" lg="3">
                                                    <Card style={{ "backgroundColor": "#F4F7DB" }}>
                                                        <CardBody>
                                                            <div><b>{treatmentCount}</b></div>
                                                            <div>In Treatment</div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {
                                                    // !loading ? (
                                                    patients.length > 0 ? (
                                                        patients.map((data, i) => {
                                                            return (
                                                                <Col xs="12" sm="12" md="12" style={{ "margin-top": "20px " }}>
                                                                    <Card style={{ "border": "1px solid #1C3A84" }}>

                                                                        <CardHeader style={{ "backgroundColor": "#1C3A84", "color": "white", "cursor": "pointer" }} >
                                                                            <Row style={{ "fontSize": "16px" }} key={i} onClick={() => this.setCollapse(i)}>
                                                                                <Col md="6">
                                                                                    <b>
                                                                                        <span>{data.firstName + " " + (data.middleName != null && data.middleName != "" ? " " + data.middleName + " " : "") + data.lastName}
                                                                                            {/* // " (" + data.email + ")"}</span> */}
                                                                                            {/* // <br /><span>{data.mobile} */}
                                                                                        </span>
                                                                                    </b>
                                                                                </Col>
                                                                                <Col md="5">
                                                                                    {/* {data.dateOfBirth == null ? "" :
                                                                                        <Moment format="MM/DD/YYYY">{data.dateOfBirth}</Moment>}<br /><b>{data.dateOfBirth == null ? "" : "" + data.age + " Years"}</b> */}
                                                                                </Col>
                                                                                <Col md="1">
                                                                                    <Link style={{ "color": "white" }} to={'/practitioner/patient/modify/' + data.practitionerPatientId}><i className="flaticon2-edit" /></Link>{" "}
                                                                                </Col>
                                                                            </Row>
                                                                        </CardHeader>
                                                                        <Fade timeout={this.state.timeout} in={this.state.fadeIn}>
                                                                            <Collapse isOpen={i == collapseId} id="collapseExample">
                                                                                <CardBody>
                                                                                    <Row>
                                                                                        <Table responsive bordered >
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Accession No</th>
                                                                                                    <th>Neo7 Analysis Type</th>
                                                                                                    {/* {["With ctDNA & cfDNA testing", "No ctDNA & cfDNA testing", "Cancer - No Tumor"].includes(data.patientAccessionMappings[0]?.diseaseCategory) ? "" : <th>Health Index Form</th>} */}
                                                                                                    {[2, 3].includes(data.patientAccessionMappings[0]?.diseaseCategoryId) ? "" : <th>Health Index Form</th>}

                                                                                                    {console.log(`data.patientAccessionMappings.`,)}

                                                                                                    {/* <th>Action</th> */}
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {data.patientAccessionMappings.length > 0 ? (
                                                                                                    data.patientAccessionMappings
                                                                                                        .map((adata, i) => {
                                                                                                            return (<tr key={i}>
                                                                                                                <td>
                                                                                                                    {adata.accessionNo != null && adata.accessionNo != "" ?
                                                                                                                        <Link to={'/practitioner/patient/profile/' + adata.patientAccessionId}><b>{adata.accessionNo.replace(/-/g, "")}</b></Link>
                                                                                                                        :
                                                                                                                        "Not Assigned"
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        adata.diseaseName != null && adata.diseaseName != "" ?
                                                                                                                            !(['2', '3', '4'].includes(String(adata.diseaseCategoryId))) ? adata.diseaseCategory : adata.diseaseCategory + ` - ` + adata.diseaseName
                                                                                                                            : "-"
                                                                                                                    }{adata.tissue != null ? `  (${adata.tissue})` : ""}
                                                                                                                    { //                                                                          <Button className="btn btn-bold btn-label-brand btn-sm" onClick={() => this.handleShowDisease(adata.patientId)}>
                                                                                                                        //                                                                              Add Disease
                                                                                                                        //</Button>
                                                                                                                    }

                                                                                                                </td>

                                                                                                                {/* {["With ctDNA & cfDNA testing", "No ctDNA & cfDNA testing", "Cancer - No Tumor"].includes(adata.diseaseCategory) ? "" : */}
                                                                                                                {[2, 3].includes(adata.diseaseCategoryId) ? "" :



                                                                                                                    <td>

                                                                                                                        {/*<Link to={'/practitioner/patient/modify/' + adata.practitionerPatientId}><i className="flaticon2-edit" /></Link>{" "}*/}
                                                                                                                        {/*<Link to="#" onClick={e => this.deleteRow(e, adata.practitionerPatientId)}><i className="flaticon2-delete" /></Link>*/}
                                                                                                                        {adata.diseaseName && adata.diseaseName === "Prevention" ?
                                                                                                                            adata.isPayment && adata.isPayment === true ?
                                                                                                                                <Link to={'/practitioner/patient/vibranthealthform/' + adata.patientId + "/" + adata.patientAccessionId}>
                                                                                                                                    <img src={form_icon} width="25px" alt='form' />
                                                                                                                                </Link>
                                                                                                                                : <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Payment Pending</span>
                                                                                                                            : <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Not Available</span>}
                                                                                                                    </td>
                                                                                                                }
                                                                                                                {/* <td>
                                                                                                                    <span onClick={() => this.handleShowPopup(adata.patientId, adata.patientAccessionId, adata.accessionNo, adata.diseaseID, adata.tissueId, adata.diseaseCategoryId)}>
                                                                                                                        <Button color="info" size="sm"><i className="flaticon2-edit" />

                                                                                                                        </Button>
                                                                                                                    </span>
                                                                                                                </td> */}
                                                                                                            </tr>);
                                                                                                        })
                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan="4" className="tdCenter">No accession no generated..</td></tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Collapse>
                                                                        </Fade>
                                                                    </Card>
                                                                </Col>
                                                            );
                                                        })
                                                    ) : (
                                                        <Col xs="12" sm="12" md="12" style={{ "fontSize": "1.2rem", "textAlign": "center", "margin": "15px " }}>No patient assigned or added..</Col>
                                                        // )) : (
                                                        // <Col xs="12" sm="12" md="12" style={{ "fontSize": "1.2rem", "textAlign": "center", "margin": "15px " }}>Loading....</Col>
                                                    )}
                                            </Row>
                                            <br />
                                            {pagesCount > 0 ?
                                                <Pagination aria-label="Page navigation example" className="customPagination">
                                                    <PaginationItem disabled={currentIndex - 4 <= 0}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage - 5, currentIndex - 5)} previous href="#">
                                                            Prev
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(pagesCount)].slice(currentIndex, currentIndex + 5).map((page, i) =>
                                                        <PaginationItem active={currentIndex + i === currentPage} key={currentIndex + i}>
                                                            <PaginationLink onClick={e => this.handleClick(e, currentIndex + i, currentIndex)} href="#">
                                                                {currentIndex + i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    )}
                                                    <PaginationItem disabled={currentIndex + 5 >= pagesCount}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage + 5, currentIndex + 5)} next href="#">
                                                            Next
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination> : null}
                                            {/*{diseaseNotCount > 0 ?
                                                <span className='error' style={{ "textAlign": "center" }}>Add disease to generate patient accession number*</span>
                                                : ""}*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*          <Modal isOpen={showdisease} className="modal-dialog modal-sm">*/}
                    {/*              <ModalHeader>*/}
                    {/*                  Add Disease*/}
                    {/*              </ModalHeader>*/}
                    {/*              <ModalBody>*/}
                    {/*                  <div className="form-group">*/}
                    {/*                      <label htmlFor="recipient-name" className="form-control-label">Disease</label>*/}
                    {/*                      <Input type="select" className="custom-select mb-3" name="patientdiseaseId" value={patientdiseaseId} onChange={this.handleDiseaseInputChange.bind(this)}>*/}
                    {/*                          <option value="">Select Disease</option>*/}
                    {/*                          {Alldiseases*/}
                    {/*                              .map((data, i) => {*/}
                    {/*                                  return (<option key={i} value={data.diseaseId}>{data.diseaseName}</option>);*/}
                    {/*                              })}*/}
                    {/*                      </Input>*/}
                    {/*                      {errors.patientdiseaseId.length > 0 && <span className='error'>{errors.patientdiseaseId}</span>}*/}
                    {/*                  </div>*/}
                    {/*              </ModalBody>*/}
                    {/*              <ModalFooter>*/}
                    {/*                  <Button color="secondary" onClick={this.handleCloseDisease}>*/}
                    {/*                      Close*/}
                    {/*</Button>*/}
                    {/*                  <Button color="primary" onClick={this.AddPatientDisease.bind(this)}>*/}
                    {/*                      Add*/}
                    {/*</Button>*/}
                    {/*              </ModalFooter>*/}
                    {/*          </Modal>*/}
                    <div style={{ marginLeft: "36%" }}>
                        <Modal isOpen={showPopup} className="modal-dialog modal-md" style={{ maxWidth: "900px" }}>
                            <ModalHeader>
                                {accessionNo != null && accessionNo != '' ? accessionNo : 'Add Details For New Accession'}
                            </ModalHeader>
                            <ModalBody >

                                <div className=" row w-100 pl-5 mx-auto">


                                    {/* {diseaseCat
                                        .map((data, i) => {
                                            return (
                                                <div className="custom-control custom-radio">
                                                    {data.diseaseCategoryId == diseaseCatId ?
                                                        <Input type="radio" checked className="custom-control-input" value={data.diseaseCategoryId} onChange={(e) => { this.handlePopupInputChange(e); this.getlabdetailsBydiseaseCatId(data.diseaseCategoryId) }} id={data.diseaseCategoryName} name="diseaseCatId" tabIndex={i + 1} />
                                                        :
                                                        <Input type="radio" className="custom-control-input" value={data.diseaseCategoryId} onChange={(e) => { this.handlePopupInputChange(e); this.getlabdetailsBydiseaseCatId(data.diseaseCategoryId) }} id={data.diseaseCategoryName} name="diseaseCatId" tabIndex={i + 1} />
                                                    }
                                                    <Label className="custom-control-label" htmlFor={data.diseaseCategoryName}>{data.diseaseCategoryName + " (" + data.productName + ")"}</Label>
                                                </div>
                                            );
                                        })} */}

                                    <div className="col-md-6">
                                        <div className='row'>
                                            <label htmlFor="recipient-name" className="form-control-label">Type:</label>
                                        </div>
                                        {diseaseCat.map((data, i) => {
                                            return (
                                                <div className="custom-control custom-radio mb-2">

                                                    {
                                                        data.diseaseCategoryId == diseaseCatId ? (
                                                            <>

                                                                <Input
                                                                    type="radio"
                                                                    checked
                                                                    className="custom-control-input"
                                                                    value={data.diseaseCategoryId}
                                                                    onChange={(e) => { this.handlePopupInputChange(e); }}
                                                                    // onChange={this.handleInputChange.bind(this)}
                                                                    id={data.diseaseCategoryName}
                                                                    name="diseaseCatId"
                                                                    tabIndex={i + 1}
                                                                />
                                                                <Label
                                                                    className="custom-control-label"
                                                                    style={{ fontWeight: "500" }}
                                                                    htmlFor={data.diseaseCategoryName}
                                                                >
                                                                    {data.diseaseCategoryName +
                                                                        " (" +
                                                                        data.productName +
                                                                        ")"}
                                                                </Label>
                                                                {

                                                                    data.subCategory.map((ele) => {

                                                                        // if (Array.isArray(ele) && ele.length) {
                                                                        return (
                                                                            <div className="custom-control custom-radio ">

                                                                                <div className="custom-control custom-radio my-1">
                                                                                    {ele.diseaseCategoryId == this.state.subDiseaseCatId ? (<Input
                                                                                        type="radio"
                                                                                        checked
                                                                                        className="custom-control-input"
                                                                                        value={ele.diseaseCategoryId}
                                                                                        onChange={(e) => { this.handlePopupInputChange(e); }}
                                                                                        // onChange={(e) => {
                                                                                        //     this.handleInputChange.bind(
                                                                                        //         ele.diseaseCategoryId
                                                                                        //     )
                                                                                        // }}
                                                                                        id={ele.diseaseCategoryName + ele.diseaseCategoryId}
                                                                                        name="subDiseaseCatId"
                                                                                    // tabIndex={i + 1}
                                                                                    />
                                                                                    ) : (
                                                                                        <Input
                                                                                            type="radio"
                                                                                            className="custom-control-input"
                                                                                            value={ele.diseaseCategoryId}
                                                                                            onChange={(e) => { this.handlePopupInputChange(e); }}
                                                                                            // onChange={this.handleInputChange.bind(
                                                                                            //     this
                                                                                            // )}
                                                                                            id={ele.diseaseCategoryName + ele.diseaseCategoryId}
                                                                                            name="subDiseaseCatId"
                                                                                        />
                                                                                    )}
                                                                                    <Label
                                                                                        className="custom-control-label"
                                                                                        htmlFor={ele.diseaseCategoryName + ele.diseaseCategoryId}
                                                                                    >
                                                                                        {ele.diseaseCategoryName + " (" + ele.productName + ")"}
                                                                                    </Label>
                                                                                </div>

                                                                            </div>
                                                                        );

                                                                    })}

                                                            </>
                                                        ) : (<>


                                                            <Label
                                                                className="custom-control-label"
                                                                style={{ fontWeight: "500" }}
                                                                htmlFor={data.diseaseCategoryName}
                                                            >
                                                                {data.diseaseCategoryName +
                                                                    " (" +
                                                                    data.productName +
                                                                    ")"}
                                                            </Label>


                                                            <Input
                                                                type="radio"
                                                                className="custom-control-input"
                                                                value={data.diseaseCategoryId}
                                                                onChange={(e) => { this.handlePopupInputChange(e); }}
                                                                id={data.diseaseCategoryName}
                                                                name="diseaseCatId"
                                                                tabIndex={i + 1}
                                                            />
                                                        </>
                                                        )
                                                    }

                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="col-12 ">Samples: </label>
                                        <div className="col-12 ">
                                            <div className="kt-radio-inline col-12 ">

                                                <div className="custom-control mb-2">
                                                    {sample.map((data, i) => {
                                                        return <>
                                                            <div xs="12">

                                                                <input type="checkbox"
                                                                    checked={this.state.checkBox.includes(String(data.sampleTypeId))}
                                                                    value={data.sampleTypeId} className="custom-control-input"
                                                                    id={data.sampleTypeName} onChange={this.handleCheckBox.bind(this)} />
                                                                <label for={data.sampleTypeName} className="custom-control-label"> {data.sampleTypeName}</label>
                                                            </div>
                                                        </>
                                                    })}
                                                    {errors.samplecheckbox.length > 0 && <span className='error'>{errors.samplecheckbox}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="custom-control custom-radio">*/}
                                    {/*    {diseaseName == "Cancer Patients" ?*/}
                                    {/*        <Input type="radio" checked className="custom-control-input" value="Cancer Patients" onChange={this.handlePopupInputChange.bind(this)} id="CancerPatients" name="diseaseName" tabIndex="1" />*/}
                                    {/*        :*/}
                                    {/*        <Input type="radio" className="custom-control-input" value="Cancer Patients" onChange={this.handlePopupInputChange.bind(this)} id="CancerPatients" name="diseaseName" tabIndex="1" />*/}
                                    {/*    }*/}
                                    {/*    <label className="custom-control-label" htmlFor="CancerPatients">Cancer</label>*/}
                                    {/*</div>*/}
                                    {/*<div className="custom-control custom-radio">*/}
                                    {/*    {diseaseName == "VibrantHealthX" ?*/}
                                    {/*        <Input type="radio" className="custom-control-input" value="VibrantHealthX" onChange={this.handlePopupInputChange.bind(this)} checked id="VibrantHealthX" name="diseaseName" tabIndex="2" />*/}
                                    {/*        : <Input type="radio" className="custom-control-input" value="VibrantHealthX" onChange={this.handlePopupInputChange.bind(this)} id="VibrantHealthX" name="diseaseName" tabIndex="2" />*/}
                                    {/*    }*/}
                                    {/*    <label className="custom-control-label" htmlFor="VibrantHealthX">Complete Health Score</label>*/}
                                    {/*</div>*/}
                                    {/*<div className="custom-control custom-radio">*/}
                                    {/*    {diseaseName == "Neurodegenerative" ?*/}
                                    {/*        <Input type="radio" className="custom-control-input" value="Neurodegenerative" onChange={this.handlePopupInputChange.bind(this)} checked id="Neurodegenerative" name="diseaseName" tabIndex="2" />*/}
                                    {/*        : <Input type="radio" className="custom-control-input" value="Neurodegenerative" onChange={this.handlePopupInputChange.bind(this)} id="Neurodegenerative" name="diseaseName" tabIndex="2" />*/}
                                    {/*    }*/}
                                    {/*    <label className="custom-control-label" htmlFor="Neurodegenerative">Neurodegenerative</label>*/}
                                    {/*</div>*/}
                                    {/*<div className="custom-control custom-radio">*/}
                                    {/*    {diseaseName == "Autoimmunity" ?*/}
                                    {/*        <Input type="radio" className="custom-control-input" value="Autoimmunity" onChange={this.handlePopupInputChange.bind(this)} checked id="Autoimmunity" name="diseaseName" tabIndex="2" />*/}
                                    {/*        : <Input type="radio" className="custom-control-input" value="Autoimmunity" onChange={this.handlePopupInputChange.bind(this)} id="Autoimmunity" name="diseaseName" tabIndex="2" />*/}
                                    {/*    }*/}
                                    {/*    <label className="custom-control-label" htmlFor="Autoimmunity">Autoimmunity</label>*/}
                                    {/*</div>*/}
                                </div>
                                {diseaseName.toLowerCase() == "cancer" ?
                                    <React.Fragment>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name" className="form-control-label">Disease</label>
                                            <Input type="select" className="custom-select mb-3" name="diseaseId" value={diseaseId} onChange={this.handlePopupInputChange.bind(this)} tabIndex="4">
                                                <option value="">Select Disease</option>
                                                {disease
                                                    ?.map((data, i) => {
                                                        return (<option key={i} value={data.id}>{data.name}</option>);
                                                    })}
                                            </Input>
                                            {errors.diseaseId.length > 0 && <span className='error'>{errors.diseaseId}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name" className="form-control-label">Tissue</label>
                                            <Input type="select" className="custom-select mb-3" name="tissueId" value={tissueId} onChange={this.handlePopupInputChange.bind(this)} tabIndex="5">
                                                <option value="">Select Tissue</option>
                                                {allTissues
                                                    .map((data, i) => {
                                                        return (<option key={i} value={data.id}>{data.name}</option>);
                                                    })}
                                            </Input>
                                            {errors.tissueId.length > 0 && <span className='error'>{errors.tissueId}</span>}
                                        </div>
                                    </React.Fragment>
                                    : null}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.handleClosePopup}>
                                    Close
                                </Button>
                                <Button color="primary" disabled={loading} size="sm" onClick={this.AddPatientAccessionNo.bind(this)}>
                                    Submit
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                    {this.loading()}
                </div>


            </React.Fragment >
        );
    }
}
