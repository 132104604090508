import React, { Component } from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input,
    Table, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../../CustomModal/CustomModal';

export class InstituteContactPerson extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            contacts: [],
            slDelete: false,
            // authError: false,
            error: '',
            errorType: '',
            currentPage: 0,
            currentIndex: 0,
            pagesCount: 0,
            pageSize: window.$TotalRecord,
            deleteId: '',
            modal: false
        };
        this.state = this.initialState;
        this.toggle = this.toggle.bind(this);

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //}

    componentDidMount() {
        this.getListData(0);
    }

    //get detail(for update)
    getListData(pageNo) {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.instituteId == null ? 0 : userToken.instituteId);
        }
        else {
            return <Redirect to="/" />
        }

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/InstituteContactPerson/GetByInstituteIdPaging';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            Id: id,
            pageNo: pageNo,
            totalNo: window.$TotalRecord,
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        contacts: result.data.outdata,
                        pagesCount: Math.ceil(result.data.totalRecord / window.$TotalRecord),
                        loading: false
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //pagination
    handleClick(e, index, currIndex) {
        e.preventDefault();
        var pgcount = this.state.pagesCount - 1;
        var pgCurr = (index >= pgcount ? pgcount : index);
        this.setState({
            loading: true,
            currentPage: pgCurr,
            currentIndex: currIndex
        }, function () { this.getListData(pgCurr); });
    }

    //delete(active/inactive) button click
    deleteRow(e, id) {
        e.preventDefault();

        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/InstituteContactPerson/Delete?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData(0));
                    toast.success(result.data.message)
                    this.toggle()
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    toggle(e, id) {
        this.setState({
          modal: !this.state.modal,
          deleteId: id
        });
      }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, contacts, error, errorType, authError, currentPage, currentIndex, pagesCount, pageSize, modal, deleteId } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                  
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                            Contact Persons
                           </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link to="#" className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i>  </Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/institute/contact/list" className="kt-subheader__breadcrumbs-link">
                                                    Contact Persons                       
                                                 </Link>
                                            </div> */}
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/institute/contact/detail" className="btn btn-primary btnBorder">
                                                Add Contacts
                                            </Link>
                                        </div>
                                    </div>
                                
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        {/* {authError ?
                                            <div>
                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                    <div className="alert-text">{error}</div>
                                                    <div className="alert-close">
                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                                        <div className="kt-portlet__body">
                                            <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>Department</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    // !loading ? (
                                                        //standards.map(function (data,i) {
                                                        contacts.length > 0 ? (
                                                            contacts
                                                                .map((data, i) => {
                                                                    return (<tr key={i}>
                                                                        <td>{data.name}</td>
                                                                        <td>{data.email}</td>
                                                                        <td>{data.mobile}</td>
                                                                        <td>{data.department}</td>
                                                                        <td>
                                                                            <Link to={'/institute/contact/modify/' + data.instituteContactPersonId}><Button outline color="info" size="sm"><i className="flaticon2-edit" />Edit</Button></Link>{" "}
                                                                            <Link to="#" onClick={e => this.toggle(e, data.instituteContactPersonId)}><Button outline color="danger" size="sm"><i className="flaticon2-delete" />Delete</Button></Link>
                                                                        </td>
                                                                    </tr>);
                                                                })
                                                        ) : (
                                                                <tr>
                                                                    <td colSpan="5" className="tdCenter">No contacts found.</td></tr>
                                                            // )) : (
                                                            // <tr>
                                                            //     <td colSpan="4" className="tdCenter">Loading...</td></tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            {pagesCount > 0 ?
                                                <Pagination aria-label="Page navigation example" className="customPagination">
                                                    <PaginationItem disabled={currentIndex - 4 <= 0}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage - 5, currentIndex - 5)} previous href="#">
                                                            Prev
                    </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(pagesCount)].slice(currentIndex, currentIndex + 5).map((page, i) =>
                                                        <PaginationItem active={currentIndex + i === currentPage} key={currentIndex + i}>
                                                            <PaginationLink onClick={e => this.handleClick(e, currentIndex + i, currentIndex)} href="#">
                                                                {currentIndex + i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    )}
                                                    <PaginationItem disabled={currentIndex + 5 >= pagesCount}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage + 5, currentIndex + 5)} next href="#">
                                                            Next
                    </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.loading()}
                    {modal && 
                        <div style={{ marginLeft: "36%" }}>    
                            <Modal isOpen={modal} style={{ width: "500px" }} >
                                <ModalHeader>Confirm</ModalHeader>
                                <ModalBody>Are you sure want to delete this contact person?</ModalBody>
                                <ModalFooter>
                                    <Button outline color="danger" onClick={e => this.deleteRow(e, deleteId)}>Delete</Button>
                                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    }
                </div>
            </React.Fragment >
        );
    }
}
