import React, { Component, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import MyModal from './../../CustomModal/CustomModal';
import Moment from 'moment';
import axios from 'axios';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

{/* <reference path="pratitionerpatientdetail.js" /> */ }

export class VibrantHealthXForm extends Component {

    constructor(props) {
        super(props);

        this.intialState = {
            loading: true,
            modal: false,
            deleteRowIndex: "",
            deleteName: "",
            deleteId: "",
            treatmentTypeDisabled: false,
            GenetictestingdataDisabled: false,
            SeePhysicianDisabled: false,
            TakingmedicationsDisabled: false,
            PatientId: 0,
            PatientAccessionId: 0,
            PractitionerId: 0,
            PractitionerPatientId: 0,
            HealthIndex: 0,
            healthIndexId: 0,
            UserId: 0,
            Name: "",
            Sex: "M",
            DateOfBirth: "",
            Address: "",
            StateId: "",
            stateName: "",
            states: [],
            CityId: "",
            cityName: "",
            cities: [],
            PostalCode: "",
            PhoneNumber: "",
            Mobile: "",
            Email: "",
            PreferredContact: "Home",
            ReceiveMail: "",
            AboutUs: "",
            EContactName: "",
            Relationship: "",
            EPhoneNumber: "",
            improve: [],
            FamilyHistory: [],
            GeneticTesting: [],
            FemaleOnly: [],
            OtherConcerns: "",
            treatments: "",
            treatmentType: "",
            FamilyHealth: "",
            WorkType: "",
            GeneticBackground: "",
            GeneticTest: "",
            FemaleAdditional: "",
            GeneticData: "No",
            DataSource: "",
            SeenPhysician: "",
            SeePhysician: [],
            SeePhysicianTxt: "",
            VisitReason: "",
            TakingMedication: "",
            GeneralHealth: "good",
            //FemaleOnly: "",
            NoOfPreg: "",
            SignatureFile: "",
            ChangeCondition: [],
            Condition: "",
            AditionalHealthProblem: "",
            Allergies: [],
            allergy: "",
            SupplementAllergies: [],
            SupplementAllergiesTxt: "",
            RecentDiagnostic: [],
            RecentDiagnosticTxt: "",
            Takingmedications: [],
            TakingmedicationsTxt: "",
            Genetictestingdata: [],
            GenetictestingdataTxt: "",
            countries: [],
            ChangeAllergicReaction: [],
            AllergicReaction: "",
            AllergiesMedication: "",
            LabTestResult: "",
            PhotographicConsent: "",
            Marketing: "",
            Signature: "",
            lstYourConcern: "",
            Date: Moment(new Date())._d,
            UserName: "",
            CountryId: "",
            error: '',
            errorType: '',
            errors: {
                Name: '',
                improve: '',
                Healthimprove: '',
                FamilyHistory: '',
                GeneticTest: "",
                GenetictestingdataTxt: "",
                SeePhysicianTxt: "",
                FemaleAdditional: "",
                allergy: "",
                SupplementAllergiesTxt: "",
                RecentDiagnosticTxt: "",
                PreferredContact: "",
                ReceiveMail: "",
                TakingmedicationsTxt: "",
                treatments: "",
                GeneticData: "",
                SeenPhysician: "",
                TakingMedication: "",
                GeneralHealth: "",
                PhotographicConsent: "",
                Marketing: "",
                // StateId: "",
                // CityId: "",
                // Mobile:"",
                DateOfBirth: "",
                // Mobile:"",
                // PhoneNumber: "",
                // PostalCode: "",
                Email: "",
            }

        };
        this.state = this.intialState;
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.getCountry();
        // this.getStateData();
    }

    getCountry() {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/BE_Common/GetPatientDropdown';
        let data = JSON.stringify({
            isDeleted: true,
            searchString: ''
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                console.log("dsada", result.data.outdata);
                this.setState({
                    countries: result.data.outdata.countryData,
                    //allTissues: result.data.outdata.tissueData
                }, () => {
                    const param = this.props.match.params;

                    if (param.id != undefined) {
                        this.setState({ PatientId: param.id, PatientAccessionId: param.paid });
                        this.getData(param.id, param.paid);
                    }
                    else { this.setState({ loading: false }); }
                });
            }
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
        });
    }

    getStateData(CountryId, StateId, CityId) {
        debugger;
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/BE_Common/GetStateByCountryId?Id=' + 233;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    //console.log(result.data);
                    this.setState({ states: result.data.outdata, StateId: StateId }, () => {
                        if (StateId != "" || StateId != null) {
                            this.getCityData(StateId, CityId)
                        }
                        else { this.setState({ loading: false }); }
                    });
                }
                else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getCityData(StateId, CityId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/BE_Common/GetCityByStateId?Id=' + StateId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                //console.log(result.data);
                this.setState({ cities: result.data.outdata, CityId: CityId, loading: false });
            }
            else { this.setState({ loading: false }); }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    getDisease() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let userId = (userToken.userId == null ? 0 : userToken.userId);

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/Disease/GetDRPAll';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: this.state.searchString,
            id: userId
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    disease: result.data.outdata
                }, () => {

                    const param = this.props.match.params;

                    if (param.id != undefined) {
                        this.setState({ PatientId: param.id });
                        this.getData(param.id);
                    }
                    else { this.setState({ loading: false }); }

                });
            } else {
                this.setState({ loading: false });
            }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    //get detail(for update)

    getData(id, paid) {
        // debugger;
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/HealthIndex/GetById?id=' + id + '&paid=' + paid;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    console.log(result.data.outdata);
                    let rData = result.data.outdata;
                    this.setState({
                        //PractitionerId: rData.practitionerId,
                        //PractitionerPatientId: rData.practitionerPatientId, UserId: rData.userId,
                        //PatientId: rData.patientId, FirstName: rData.firstName, MiddleName: rData.middleName, diseaseId: rData.diseaseId,
                        //LastName: rData.lastName, UserName: rData.userName, Email: rData.email, Mobile: rData.mobile,
                        //DateOfBirth: (rData.dateOfBirth == null ? "" : Moment(rData.dateOfBirth.slice(0, 10), "MM-DD-YYYY").format('YYYY-MM-DD')), Age: rData.age, Address: rData.address, Sex: rData.sex,
                        //PhoneNumber: rData.phoneNumber, PostalCode: rData.postalCode, Tissue: rData.tissue
                        //, CountryId: rData.countryId,
                        // Name: rData.patient.firstName + '' + rData.patient.lastName,
                        Name: rData.patient?.firstName,
                        Address: rData.patient?.addressLine1,
                        Sex: rData.patient?.sex,
                        cityName: rData.patient?.cityName,
                        stateName: rData.patient?.stateName,
                        Email: rData.patient?.email,
                        Mobile: rData.patient?.mobile,
                        PhoneNumber: rData.patient?.phoneNumber,
                        PostalCode: rData.patient?.postalCode,
                        // DateOfBirth: (rData.patient.dateOfBirth == null ? "" : Moment(rData.patient.dateOfBirth.slice(0, 10), "MM-DD-YYYY").format('YYYY-MM-DD')),
                        DateOfBirth: (rData.patient?.dateOfBirth == null ? "" : Moment(rData.patient?.dateOfBirth)._d),
                        Date: (rData.date === null ? Moment(new Date())._d : Moment(rData.date)._d),
                        AboutUs: rData.aboutHealthIndex,
                        PreferredContact: rData.preferredContact == null ? "Home" : rData.preferredContact,
                        ReceiveMail: rData.receiveMail.toString(),
                        EContactName: rData.emergencyContactName,
                        Relationship: rData.relationship,
                        EPhoneNumber: rData.ePhoneNumber,
                        improve: rData.hIPImproveYourHealthModels === null ? [] : rData.hIPImproveYourHealthModels,
                        OtherConcerns: rData.otherConcern,
                        FamilyHistory: rData.hIPFamilyHistotyModels === null ? [] : rData.hIPFamilyHistotyModels,
                        treatments: rData.currentlyReceivingAnyTreatments.toString(),
                        treatmentType: rData.anyTreatmentsIfYes,
                        WorkType: rData.healthHistory,
                        GeneticBackground: rData.geneticBackground,
                        GeneticTesting: rData.hIPPreviousGeneticTestingModels === null ? [] : rData.hIPPreviousGeneticTestingModels,
                        GeneticData: rData.currnetGeneticData.toString(),
                        SeenPhysician: rData.healthPractitionerPastYear.toString(),
                        TakingMedication: rData.currentlyTakingMedicationsorSupplements.toString(),
                        Takingmedications: rData.hIPTakingMedicationsorSupplementModels === null ? [] : rData.hIPTakingMedicationsorSupplementModels,
                        GeneralHealth: rData.generalHealth == null ? "good" : rData.generalHealth,
                        FemaleCurrently: rData.femaleAreYouCurrently,
                        NoOfPreg: rData.planingOfpregnancy,
                        healthIndexId: rData.healthIndexId,
                        SeePhysician: rData.hIPReasonForPracVisitModels === null ? [] : rData.hIPReasonForPracVisitModels,
                        Genetictestingdata: rData.hIPGeneticTestingDataforComparisons === null ? [] : rData.hIPGeneticTestingDataforComparisons,
                        FemaleOnly: rData.hIPAdditionalHealthProblems === null ? [] : rData.hIPAdditionalHealthProblems,
                        Allergies: rData.hipAllergyModel === null ? [] : rData.hipAllergyModel,
                        RecentDiagnostic: rData.hipDiagnosticTestResultModels === null ? [] : rData.hipDiagnosticTestResultModels,
                        SupplementAllergies: rData.hIPSupplementAllergyModels === null ? [] : rData.hIPSupplementAllergyModels,
                        Condition: rData.checkCondition,
                        ChangeCondition: rData.checkCondition === null ? [] : rData.checkCondition.split(","),
                        ChangeAllergicReaction: rData.allergicReactions === null ? [] : rData.allergicReactions.split(","),

                        //PhotographicConsent
                        PhotographicConsent: rData.photographicConsent.toString(),
                        Marketing: rData.photographedforMarketing.toString(),

                        loading: false,

                        //radio response for disable inputs
                        GenetictestingdataDisabled: rData.currnetGeneticData,
                        treatmentTypeDisabled: rData.currentlyReceivingAnyTreatments,
                        SeePhysicianDisabled: rData.healthPractitionerPastYear,
                        TakingmedicationsDisabled: rData.currentlyTakingMedicationsorSupplements

                        //, StateId: rData.StateId, CityId: rData.CityId
                    });
                    //}, () => {
                    //    if (rData.countryId != null) {
                    //        this.getStateData(rData.countryId, rData.stateId, rData.cityId)
                    //    }
                    //    else { this.setState({ loading: false }); }
                    //});
                    console.log("STATE", this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    //validationForm = (errors) => {
    //    let valid = true;

    //    if (this.state.Name == undefined || this.state.Name == '') {
    //        errors.Name = 'Please enter name.';
    //    }

    //    if (this.state.PatientId == 0) {
    //        if (this.state.Email == undefined || this.state.Email == '') {
    //            errors.Email = 'Please enter email.';
    //        }
    //    }

    //    Object.values(errors).forEach(
    //        // if we have an error string set valid to false
    //        (val) => val.length > 0 && (valid = false)
    //    );
    //}

    handleBirthDateChange(date) {
        let errors = this.state.errors
        errors.DateOfBirth = ""
        this.setState({ DateOfBirth: date })
    }

    handleDateChange(date) {
        this.setState({ Date: date })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        const validMobileRegex = RegExp(/^[0-9+]+$/);
        const validNumberRegex = RegExp(/^[0-9]+$/);
        const validAlphaRegex = RegExp(/^[a-zA-Z \b]+$/);
        const validAlphaNoRegex = RegExp(/^[a-zA-Z0-9 \b]+$/);
        const validEmailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        switch (name) {
            case 'Name':
                errors.Name = (!value) ? 'Please enter Name.' : (validAlphaNoRegex.test(value) ? '' : 'Only alphanumeric allowed.');
                this.setState({ Name: value.replace(/[^a-zA-Z0-9 \b]+$/, '') })
                break;

            case 'Email':
                errors.Email = (!value) ? 'Please enter email.' : (validEmailRegex.test(value) ? '' : 'Invalid Email.');
                break;

            case 'DateOfBirth':
                errors.DateOfBirth = (!this.state.DateOfBirth) ? 'Please enter date of birth' : '';
                // var newAge = (value) ? this.calculate_age(value) : 0;
                // this.setState({ Age: newAge });
                break;

            case 'PhoneNumber':
                errors.PhoneNumber = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ PhoneNumber: value.replace(/[^0-9+]+$/, '') })
                break;

            case 'Mobile':
                errors.Mobile = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ Mobile: value.replace(/[^0-9+]+$/, '') })
                break;

            case 'GeneticData':
                errors.GeneticData = '';
                if (value == "No") {
                    this.setState({ GenetictestingdataDisabled: false })
                    errors.GenetictestingdataTxt="";
                } else {
                    this.setState({ GenetictestingdataDisabled: true })
                }
                break;

            case 'Marketing':
                errors.Marketing = '';
                break;

            case 'PhotographicConsent':
                errors.PhotographicConsent = '';
                break;

            case 'TakingMedication':
                errors.TakingMedication = '';
                if (value === "No") {
                    this.setState({ TakingmedicationsDisabled: false })
                    errors.TakingmedicationsTxt="";
                } else {
                    this.setState({ TakingmedicationsDisabled: true })
                }
                break;

            case 'ReceiveMail':
                errors.ReceiveMail = '';
                break;

            case 'treatments':
                errors.treatments = '';
                if (value === "No") {
                    this.setState({ treatmentTypeDisabled: false })
                } else {
                    this.setState({ treatmentTypeDisabled: true })
                }
                break;

            case 'GeneralHealth':
                errors.GeneralHealth = '';
                break;

            case 'PreferredContact':
                errors.PreferredContact = '';
                break;

            case 'SeenPhysician':
                errors.SeenPhysician = '';
                if (value === "No") {
                    this.setState({ SeePhysicianDisabled: false })
                    errors.SeePhysicianTxt=""
                } else {
                    this.setState({ SeePhysicianDisabled: true })
                }
                break;

            default:
                // (!value) ? '' :'This standard is required.'
                break;

        }

        this.setState({ errors }, () => {

            if (name == 'StateId') {
                if (value != '') {
                    this.getCityData(value, "");
                }
                else {
                    this.setState({ cities: [], CityId: "" });
                }
            }
        })
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/practitioner/dashboard" />
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    handleFileInputChange(event) {
        const target = event.target;
        const value = target.files[0];
        console.log(value);

        this.setState({
            SignatureFile: value
        });
    }

    //Delete
    deleteRow(e, i, fieldType, id) {
        const apiroute = window.$APIPath;

        if (fieldType === "Healthimprove") {
            let data = this.state.improve;
            let errors = this.state.errors;
            errors.Healthimprove = "";

            data.splice(i, 1)

            this.setState({
                improve: data,
            });
            var url = apiroute + '/api/HealthIndex/DeleteHIPImproveYourHealth?id=' + id + '';

        } else if (fieldType === "FamilyHealth") {
            let data = this.state.FamilyHistory;
            let errors = this.state.errors;
            errors.FamilyHistory = "";

            data.splice(i, 1)

            this.setState({
                FamilyHistory: data,
            });
            var url = apiroute + '/api/HealthIndex/DeleteHIPFamilyHistoty?id=' + id + '';

        } else if (fieldType === "GeneticTest") {
            let data = this.state.GeneticTesting;
            let errors = this.state.errors;
            errors.GeneticTest = "";

            data.splice(i, 1)

            this.setState({
                GeneticTesting: data,
            });
            var url = apiroute + '/api/HealthIndex/DeleteHIPPreviousGeneticTesting?id=' + id + '';

        } else if (fieldType === "GenetictestingdataTxt") {
            let data = this.state.Genetictestingdata;
            let errors = this.state.errors;
            errors.GenetictestingdataTxt = "";

            data.splice(i, 1)

            this.setState({
                Genetictestingdata: data,
            });
            var url = apiroute + '/api/HealthIndex/DeleteHIPGeneticTestingDataforComparison?id=' + id + '';

        } else if (fieldType === "SeePhysicianTxt") {
            let data = this.state.SeePhysician;
            let errors = this.state.errors;
            errors.SeePhysicianTxt = "";

            data.splice(i, 1)

            this.setState({
                SeePhysician: data,
            });
            var url = apiroute + '/api/HealthIndex/DeleteHIPReasonForPracVisit?id=' + id + '';

        } else if (fieldType === "TakingmedicationsTxt") {
            let data = this.state.Takingmedications;
            let errors = this.state.errors;
            errors.TakingmedicationsTxt = "";

            data.splice(i, 1)

            this.setState({
                Takingmedications: data,
            });
            var url = apiroute + '/api/HealthIndex/DeleteHIPTakingMedicationsorSupplement?id=' + id + '';

        } else if (fieldType === "FemaleAdditional") {
            let data = this.state.FemaleOnly;
            let errors = this.state.errors;
            errors.FemaleAdditional = "";

            data.splice(i, 1)

            this.setState({
                FemaleOnly: data,
            });
            var url = apiroute + '/api/HealthIndex/DeleteHIPAdditionalHealthProblem?id=' + id + '';

        } else if (fieldType === "allergy") {
            let data = this.state.Allergies;
            let errors = this.state.errors;
            errors.allergy = "";

            data.splice(i, 1)

            this.setState({
                Allergies: data,
            });
            var url = apiroute + '/api/HealthIndex/DeleteHIPAllergy?id=' + id + '';

        } else if (fieldType === "SupplementAllergiesTxt") {
            let data = this.state.SupplementAllergies;
            let errors = this.state.errors;
            errors.SupplementAllergiesTxt = "";

            data.splice(i, 1)

            this.setState({
                SupplementAllergies: data,
            });
            var url = apiroute + '/api/HealthIndex/DeleteHIPSupplementAllergy?id=' + id + '';

        } else if (fieldType === "RecentDiagnosticTxt") {
            let data = this.state.RecentDiagnostic;
            let errors = this.state.errors;
            errors.RecentDiagnosticTxt = "";

            data.splice(i, 1)

            this.setState({
                RecentDiagnostic: data,
            });
            var url = apiroute + '/api/HealthIndex/DeleteHIPDiagnosticTestResult?id=' + id + '';

        }

        if (id) {
            axios.delete(url, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            loading: false
                        });
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            loading: false,
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    //console.log(error);
                    this.setState({
                        loading: false,
                    });
                    toast.error(error.message)
                });
        } else {
            this.setState({ loading: false })
        }
        this.toggle()

    }

    //Add Improve
    addImprove(e) {
        debugger;
        let data = this.state.improve;
        let length = data.length;
        let errors = this.state.errors;
        errors.Healthimprove = "";

        if (length == 0) {
            data.push({ rowId: 0, healthimprove: '' });
        } else {
            let lastData = data[length - 1];
            if (lastData.healthimprove != undefined && lastData.healthimprove != "") {
                data.push({ rowId: 0, healthimprove: '' });
                errors.Healthimprove = ""
            } else {
                errors.Healthimprove = "Please enter your concerns."
            }
        }
        this.setState({
            improve: data
        });
    }

    handleInputChangeImprove(event, index, fieldType) {
        //alert(fieldType);
        let data = this.state.improve;
        let errors = this.state.errors;
        errors.Healthimprove = ""

        console.log(data);
        this.setState({
            improve: []
        });
        const elementsIndex = index;
        //if (id != 0) {
        //  elementsIndex = data.findIndex(element => element.id == id);
        //}
        //alert(elementsIndex);
        if (fieldType == "Healthimprove") {
            data[elementsIndex].healthimprove = event.target.value;
        }
        // if (fieldType == "Healthimprove") {
        //     if (event.target.value == '') {
        //         errors.Healthimprove = " Can not empty."
        //     }
        // }
        console.log(data);
        this.setState({
            improve: data
        });
    }

    //Add Family Health
    addFamilyHistory() {
        debugger;
        let data = this.state.FamilyHistory;
        let length = data.length;
        let errors = this.state.errors;
        errors.FamilyHistory = "";

        if (length == 0) {
            data.push({ rowId: 0, familyHealth: '' });
        } else {
            let lastData = data[length - 1];
            if (lastData.familyHealth != undefined && lastData.familyHealth != "") {
                data.push({ rowId: 0, familyHealth: '' });
            } else {
                errors.FamilyHistory = "Please enter family's health conditions.";
            }
        }
        this.setState({
            FamilyHistory: data
        });
    }

    handleInputChangeFamilyHistory(event, index, fieldType) {
        //alert(fieldType);
        let data = this.state.FamilyHistory;
        let errors = this.state.errors;
        errors.FamilyHistory = "";

        console.log(data);
        this.setState({
            FamilyHistory: []
        });
        const elementsIndex = index;
        //if (id != 0) {
        //  elementsIndex = data.findIndex(element => element.id == id);
        //}
        //alert(elementsIndex);
        if (fieldType == "FamilyHealth") {
            data[elementsIndex].familyHealth = event.target.value;
        }
        console.log(data);
        this.setState({
            FamilyHistory: data
        });
    }

    //AddGeneticTesting
    addGeneticTesting() {
        debugger;
        let data = this.state.GeneticTesting;
        let length = data.length;
        let errors = this.state.errors;

        if (length == 0) {
            data.push({ rowId: 0, geneticTest: '' });
        } else {
            let lastData = data[length - 1];
            if (lastData.geneticTest != undefined && lastData.geneticTest != "") {
                data.push({ rowId: 0, geneticTest: '' });
                errors.GeneticTest = "";
            } else {
                errors.GeneticTest = "Please enter previous genetic tests.";
            }
        }
        this.setState({
            GeneticTesting: data
        });
    }

    handleInputChangeGeneticTesting(event, index, fieldType) {
        //alert(fieldType);
        let data = this.state.GeneticTesting;
        let errors = this.state.errors;
        errors.GeneticTest = "";

        console.log(data);
        this.setState({
            GeneticTesting: []
        });
        const elementsIndex = index;
        //if (id != 0) {
        //  elementsIndex = data.findIndex(element => element.id == id);
        //}
        //alert(elementsIndex);
        if (fieldType == "GeneticTest") {
            data[elementsIndex].geneticTest = event.target.value;
        }
        console.log(data);
        this.setState({
            GeneticTesting: data
        });
    }

    //addFemaleOnly
    addFemaleOnly() {
        debugger;
        let data = this.state.FemaleOnly;
        let length = data.length;
        let errors = this.state.errors

        if (length == 0) {
            data.push({ rowId: 0, femaleAdditional: '' });
        } else {
            let lastData = data[length - 1];
            if (lastData.femaleAdditional != undefined && lastData.femaleAdditional != "") {
                data.push({ rowId: 0, femaleAdditional: '' });
                errors.FemaleAdditional = ""
            } else {
                errors.FemaleAdditional = "Please enter medical conditions."
            }
        }
        this.setState({
            FemaleOnly: data
        });
    }

    handleInputChangeaddFemaleOnly(event, index, fieldType) {
        //alert(fieldType);
        let data = this.state.FemaleOnly;
        let errors = this.state.errors;
        errors.FemaleAdditional = "";

        console.log(data);
        this.setState({
            FemaleOnly: []
        });
        const elementsIndex = index;
        //if (id != 0) {
        //  elementsIndex = data.findIndex(element => element.id == id);
        //}
        //alert(elementsIndex);
        if (fieldType == "FemaleAdditional") {
            data[elementsIndex].femaleAdditional = event.target.value;
        }
        console.log(data);
        this.setState({
            FemaleOnly: data
        });
    }

    //AddAllergies
    addAllergies() {
        debugger;
        let data = this.state.Allergies;
        let length = data.length;
        let errors = this.state.errors;

        if (length == 0) {
            data.push({ rowId: 0, allergy: '' });
        } else {
            let lastData = data[length - 1];
            if (lastData.allergy != undefined && lastData.allergy != "") {
                data.push({ rowId: 0, allergy: '' });
                errors.allergy = ""
            } else {
                errors.allergy = "Please enter known allergies."
            }
        }
        this.setState({
            Allergies: data
        });
    }

    handleInputChangeAllergies(event, index, fieldType) {
        //alert(fieldType);
        let data = this.state.Allergies;
        let errors = this.state.errors;
        errors.allergy = ""

        console.log(data);
        this.setState({
            Allergies: []
        });
        const elementsIndex = index;
        //if (id != 0) {
        //  elementsIndex = data.findIndex(element => element.id == id);
        //}
        //alert(elementsIndex);
        if (fieldType == "allergy") {
            data[elementsIndex].allergy = event.target.value;
        }
        console.log(data);
        this.setState({
            Allergies: data
        });
    }

    //SupplementAllergies
    addSupplementAllergies() {
        debugger;
        let data = this.state.SupplementAllergies;
        let length = data.length;
        let errors = this.state.errors;

        if (length == 0) {
            data.push({ rowId: 0, supplementAllergiesTxt: '' });
        } else {
            let lastData = data[length - 1];
            if (lastData.supplementAllergiesTxt != undefined && lastData.supplementAllergiesTxt != "") {
                data.push({ rowId: 0, supplementAllergiesTxt: '' });
                errors.SupplementAllergiesTxt = ""
            } else {
                errors.SupplementAllergiesTxt = "Please enter known supplement allergies."
            }
        }
        this.setState({
            SupplementAllergies: data
        });
    }

    handleInputChangeSupplementAllergies(event, index, fieldType) {
        //alert(fieldType);
        let data = this.state.SupplementAllergies;
        let errors = this.state.errors;
        errors.SupplementAllergiesTxt = ""

        console.log(data);
        this.setState({
            SupplementAllergies: []
        });
        const elementsIndex = index;
        //if (id != 0) {
        //  elementsIndex = data.findIndex(element => element.id == id);
        //}
        //alert(elementsIndex);
        if (fieldType == "SupplementAllergiesTxt") {
            data[elementsIndex].supplementAllergiesTxt = event.target.value;
        }
        console.log(data);
        this.setState({
            SupplementAllergies: data
        });
    }

    //RecentDiagnostic
    addRecentDiagnostic() {
        debugger;
        let data = this.state.RecentDiagnostic;
        let length = data.length;
        let errors = this.state.errors;

        if (length == 0) {
            data.push({ rowId: 0, recentDiagnosticTxt: '' });
        } else {
            let lastData = data[length - 1];
            if (lastData.recentDiagnosticTxt != undefined && lastData.recentDiagnosticTxt != "") {
                data.push({ rowId: 0, recentDiagnosticTxt: '' });
                errors.RecentDiagnosticTxt = ""
            } else {
                errors.RecentDiagnosticTxt = "Please enter recent diagnostic."
            }
        }
        this.setState({
            RecentDiagnostic: data
        });
    }

    handleInputChangeRecentDiagnostic(event, index, fieldType) {
        //alert(fieldType);
        let data = this.state.RecentDiagnostic;
        let errors = this.state.errors;
        errors.RecentDiagnosticTxt = ""

        console.log(data);
        this.setState({
            RecentDiagnostic: []
        });
        const elementsIndex = index;
        //if (id != 0) {
        //  elementsIndex = data.findIndex(element => element.id == id);
        //}
        //alert(elementsIndex);
        if (fieldType == "RecentDiagnosticTxt") {
            data[elementsIndex].recentDiagnosticTxt = event.target.value;
        }
        console.log(data);
        this.setState({
            RecentDiagnostic: data
        });
    }

    //Takingmedications
    addTakingmedications() {
        debugger;
        let data = this.state.Takingmedications;
        let length = data.length;
        let errors = this.state.errors;

        if (length == 0) {
            data.push({ rowId: 0, takingmedicationsTxt: '' });
        } else {
            let lastData = data[length - 1];
            console.log("lastData", lastData);
            if (lastData.takingmedicationsTxt != undefined && lastData.takingmedicationsTxt != "") {
                data.push({ rowId: 0, takingmedicationsTxt: '' });
                errors.TakingmedicationsTxt = ""
            } else {
                errors.TakingmedicationsTxt = "Please enter medication or supplements."
            }
        }
        this.setState({
            Takingmedications: data
        });
    }

    handleInputChangeTakingmedications(event, index, fieldType) {
        //alert(fieldType);
        let data = this.state.Takingmedications;
        let errors = this.state.errors;
        errors.TakingmedicationsTxt = ""

        console.log(data);
        this.setState({
            Takingmedications: []
        });
        const elementsIndex = index;
        //if (id != 0) {
        //  elementsIndex = data.findIndex(element => element.id == id);
        //}
        //alert(elementsIndex);
        if (fieldType == "TakingmedicationsTxt") {
            data[elementsIndex].takingmedicationsTxt = event.target.value;
        }
        console.log(data);
        this.setState({
            Takingmedications: data
        });
    }

    //SeePhysician
    addSeePhysician() {
        debugger;
        let data = this.state.SeePhysician;
        let length = data.length;
        let errors = this.state.errors

        if (length == 0) {
            data.push({ rowId: 0, seePhysicianTxt: '' });
        } else {
            let lastData = data[length - 1];
            if (lastData.seePhysicianTxt != undefined && lastData.seePhysicianTxt != "") {
                data.push({ rowId: 0, seePhysicianTxt: '' });
                errors.SeePhysicianTxt = ""
            } else {
                errors.SeePhysicianTxt = "Please enter reasons for visit."
            }
        }
        this.setState({
            SeePhysician: data
        });
    }

    handleInputChangeSeePhysician(event, index, fieldType) {
        //alert(fieldType);
        let data = this.state.SeePhysician;
        let errors = this.state.errors
        errors.SeePhysicianTxt = ""

        console.log(data);
        this.setState({
            SeePhysician: []
        });
        const elementsIndex = index;
        //if (id != 0) {
        //  elementsIndex = data.findIndex(element => element.id == id);
        //}
        //alert(elementsIndex);
        if (fieldType == "SeePhysicianTxt") {
            data[elementsIndex].seePhysicianTxt = event.target.value;
        }
        console.log(data);
        this.setState({
            SeePhysician: data
        });
    }

    //Genetictestingdata
    addGenetictestingdata() {
        debugger;
        let data = this.state.Genetictestingdata;
        let length = data.length;
        let errors = this.state.errors

        if (length == 0) {
            data.push({ rowId: 0, genetictestingdataTxt: '' });
        } else {
            let lastData = data[length - 1];
            if (lastData.genetictestingdataTxt != undefined && lastData.genetictestingdataTxt != "") {
                data.push({ rowId: 0, genetictestingdataTxt: '' });
                errors.GenetictestingdataTxt = ""
            } else {
                errors.GenetictestingdataTxt = "Please enter genetic test data."
            }
        }
        this.setState({
            Genetictestingdata: data
        });
    }

    handleInputChangeGenetictestingdata(event, index, fieldType) {
        //alert(fieldType);
        let data = this.state.Genetictestingdata;
        let errors = this.state.errors
        errors.GenetictestingdataTxt = ""

        console.log(data);
        this.setState({
            Genetictestingdata: []
        });
        const elementsIndex = index;
        //if (id != 0) {
        //  elementsIndex = data.findIndex(element => element.id == id);
        //}
        //alert(elementsIndex);
        if (fieldType == "GenetictestingdataTxt") {
            data[elementsIndex].genetictestingdataTxt = event.target.value;
        }
        console.log(data);
        this.setState({
            Genetictestingdata: data
        });
    }

    //getData(id) {

    //    const apiroute = window.$APIPath;
    //    const url = apiroute + '/api/PractitionerPatient/GetPatientDetailByPDId?id=' + id;

    //    axios.get(url, {
    //        headers: {
    //            'Content-Type': 'application/json; charset=utf-8'
    //        }
    //    })
    //        .then(result => {
    //            if (result.data.flag) {
    //                console.log(result.data.outdata);
    //                var rData = result.data.outdata;
    //                this.setState({
    //                    PractitionerId: rData.practitionerId, PractitionerPatientId: rData.practitionerPatientId, UserId: rData.userId,
    //                    PatientId: rData.patientId, Name: rData.firstName + '' + rData.middleName + '' + rData.lastName, UserName: rData.userName, Email: rData.email, Mobile: rData.mobile,
    //                    DateOfBirth: (rData.dateOfBirth == null ? "" : Moment(rData.dateOfBirth.slice(0, 10), "MM-DD-YYYY").format('YYYY-MM-DD')), Address: rData.address, Sex: rData.sex,
    //                    PhoneNumber: rData.phoneNumber, PostalCode: rData.postalCode, CountryId: rData.countryId, OCountryId: rData.oCountryId
    //                    //, StateId: rData.StateId, CityId: rData.CityId
    //                }, () => {
    //                    if (rData.diseaseId != null) {
    //                        //this.getTissueData(rData.diseaseId, rData.tissue)
    //                    }
    //                    if (rData.countryId != null) {
    //                        this.getStateData(rData.countryId, rData.stateId, rData.cityId)
    //                    }
    //                    else { this.setState({ loading: false }); }
    //                });
    //                //console.log(this.state);
    //            } else { this.setState({ loading: false }); }
    //        })
    //        .catch(error => {
    //            console.log(error);
    //            this.setState({ loading: false });
    //        });
    //}

    //componentDidMount() {
    //    this.getCountry();
    //}

    //GetCountry Commented Method
    // getCountry() {
    //     debugger;
    //    const apiroute = window.$APIPath;
    //    const url = apiroute + '/api/CognitoUserStore/getPatientDropdown';
    //    let data = JSON.stringify({
    //        isDeleted: true,
    //        searchString: ''
    //    });
    //    axios.post(url, data, {
    //        headers: {
    //            'Content-Type': 'application/json; charset=utf-8'
    //        }
    //    }).then(result => {
    //        if (result.data.flag) {
    //            console.log("dsada", result.data.outdata);
    //            this.setState({
    //                countries: result.data.outdata.countryData,
    //            }, () => {
    //                const param = this.props.match.params;

    //                if (param.id != undefined) {
    //                    this.setState({ PatientId: param.id });
    //                    this.getData(param.id);
    //                }
    //                else { this.setState({ loading: false }); }
    //            });
    //        }
    //    }).catch(error => {
    //        this.setState({ loading: false });
    //        console.log(error);
    //    });
    // }

    handleSubmit(e) {
        // debugger;
        e.preventDefault();
        console.log("this.state", this.state)
        this.setState({ loading: true });

        let path = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        let uid = 0;
        // if (this.state.GeneticData == true) {
        //     this.setState({
        //         GeneticData: 'Yes'
        //     })

        // }
        // if (this.state.GeneticData == false) {
        //     console.log("NO")
        //     this.setState({
        //         GeneticData: 'No'
        //     })
        // }
        if (userToken != null) {
            id = (userToken.practitionerId == null ? 0 : userToken.practitionerId);
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;

            if (this.state.SignatureFile !== "") {
                //upload
                let url = apiroute + '/api/HealthIndex/UploadFile';
                let files = this.state.SignatureFile;
                const signatureFile = new FormData();
                signatureFile.append(`file`, files)
                axios.post(url, signatureFile, {
                    // receive two    parameter endpoint url ,form data
                }).then(result => {
                    console.log(result);
                    if (result.data.flag) {
                        path = result.data.outdata.dbPath;
                    }
                    this.formDataUpload(path)
                }).catch(error => {
                    // console.log(error.message);
                    console.log("path-error");
                    this.formDataUpload(path)
                });
            } else {
                console.log("check")
                this.formDataUpload(path)
            }
        }
        else {
            this.setState({
                //authError: true,
                //errorType: 'danger',
                //error: "Please fill all the fields.",
                loading: false
            });
        }
    }

    formDataUpload = (path) => {

        const apiroute = window.$APIPath;
        let url = apiroute + '/api/HealthIndex/Save';
        console.log("save api called")

        let data = JSON.stringify({
            healthIndexId: parseInt(this.state.healthIndexId),
            PatientId: parseInt(this.state.PatientId),
            PatientAccessionId: parseInt(this.state.PatientAccessionId),
            patientSignature: path,
            // Name: this.state.Name,
            // Email: this.state.Email,
            // Address: this.state.Address,
            // PhoneNumber: this.state.PhoneNumber,
            // Mobile: this.state.Mobile,
            // PostalCode: this.state.PostalCode,
            // Sex: this.state.Sex,
            // DateOfBirth: (this.state.DateOfBirth != null && this.state.DateOfBirth != "" ? this.state.DateOfBirth : null),
            CityId: ((this.state.CityId == "" || this.state.CityId == 0) ? null : parseInt(this.state.CityId)),
            //CountryId: ((this.state.CountryId == "" || this.state.CountryId == 0) ? null : parseInt(this.state.CountryId)),
            StateId: ((this.state.StateId == "" || this.state.StateId == 0) ? null : parseInt(this.state.StateId)),
            //UserId: parseInt(this.state.UserId),
            //CreatedBy: parseInt(uid),
            //improve: this.state.improve,
            //FamilyHistory: this.state.FamilyHistory,
            //GeneticTesting: this.state.GeneticTesting,
            //FemaleOnly: this.state.FemaleOnly,
            //SeePhysician: this.state.SeePhysician,
            //Allergies: this.state.Allergies,
            //SupplementAllergies: this.state.SupplementAllergies,
            //Takingmedications: this.state.Takingmedications,
            //Genetictestingdata: this.state.Genetictestingdata,

            PreferredContact: this.state.PreferredContact,
            ReceiveMail: this.state.ReceiveMail.toLowerCase() == 'yes' ? true : false,
            AboutHealthIndex: this.state.AboutUs,
            EmergencyContactName: this.state.EContactName,
            Relationship: this.state.Relationship,
            EPhoneNumber: this.state.EPhoneNumber,
            hIPImproveYourHealthModels: this.state.improve,
            OtherConcern: this.state.OtherConcerns,
            //Are you currently receiving any treatments?
            CurrentlyReceivingAnyTreatments: this.state.treatments.toLowerCase() == 'yes' ? true : false,
            AnyTreatmentsIfYes: this.state.treatmentType,
            //FamilyHistroy
            hIPFamilyHistotyModels: this.state.FamilyHistory,
            //What type of work do you do
            HealthHistory: this.state.WorkType,
            //What is your genetic background?
            GeneticBackground: this.state.GeneticBackground,
            //any previous genetic testing
            hIPPreviousGeneticTestingModels: this.state.GeneticTesting,
            //Any current genetic data available ?
            CurrnetGeneticData: this.state.GeneticData.toLowerCase() == 'yes' ? true : false,

            hIPGeneticTestingDataforComparisons: this.state.Genetictestingdata,
            //Have you seen a physician / health practitioner in the past year?
            HealthPractitionerPastYear: this.state.SeenPhysician.toLowerCase() == 'yes' ? true : false,
            hIPReasonForPracVisitModels: this.state.SeePhysician,
            //Are you currently taking medications or supplements?
            CurrentlyTakingMedicationsorSupplements: this.state.TakingMedication.toLowerCase() == 'yes' ? true : false,
            hIPTakingMedicationsorSupplementModels: this.state.Takingmedications,
            GeneralHealth: this.state.GeneralHealth,
            FemaleAreYouCurrently: this.state.FemaleCurrently,
            PlaningOfpregnancy: this.state.NoOfPreg,
            CheckCondition: this.state.Condition,
            //Anyadditional health problems or medical conditions
            hIPAdditionalHealthProblems: this.state.FemaleOnly,
            //list any known allery
            HIPAllergyModel: this.state.Allergies,
            AllergicReactions: this.state.AllergicReaction,
            //SupplementAllergies
            hIPSupplementAllergyModels: this.state.SupplementAllergies,
            //RecentDiagnostic and Laboratory Test Results
            HIPDiagnosticTestResultModels: this.state.RecentDiagnostic,

            //PhotographicConsent
            PhotographicConsent: this.state.PhotographicConsent.toLowerCase() == 'yes' ? true : false,
            PhotographedforMarketing: this.state.Marketing.toLowerCase() == 'yes' ? true : false
        })

        console.log("data hi data", data);
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false,
                        redirect: true
                    });
                    toast.success(result.data.message)
                    this.props.history.push("/practitioner/dashboard")
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        // if (this.state.Name == undefined || this.state.Name == '') {
        //     errors.Name = 'Please enter name.';
        // }

        // if (this.state.PatientId == 0) {
        //     //if (this.state.UserName == undefined || this.state.UserName == '') {
        //     //    errors.UserName = 'Please enter user name.';
        //     //}
        //     if (this.state.Email == undefined || this.state.Email == '') {
        //         errors.Email = 'Please enter email.';
        //     }
        // }

        if (this.state.PhotographicConsent == undefined || this.state.PhotographicConsent == '') {
            errors.PhotographicConsent = 'Please select photographic consent';
        }

        if (this.state.Marketing == undefined || this.state.Marketing == '') {
            errors.Marketing = 'Please select Marketing';
        }

        // if (this.state.improve.length > 0) {
        //     if (this.state.Healthimprove == '') {
        //         errors.Healthimprove = " Can not empty valid."
        //     }
        // }

        // if (this.state.DateOfBirth == undefined || this.state.DateOfBirth == '') {
        //     errors.DateOfBirth = 'Please select date of birth';
        // }

        // if (this.state.PreferredContact == undefined) {
        //     errors.PreferredContact = 'Please select Preferred Contact';
        // }

        if (this.state.ReceiveMail == undefined || this.state.ReceiveMail == '') {
            errors.ReceiveMail = 'Please select Recieve mail notification';
        }

        if (this.state.treatments == undefined || this.state.treatments == '') {
            errors.treatments = 'Please select current treatment';
        }

        // if (this.state.GeneticData == undefined) {
        //     errors.GeneticData = 'Please select Genetic Data';
        // }

        if (this.state.SeenPhysician == undefined || this.state.SeenPhysician == '') {
            errors.SeenPhysician = 'Please select Physician visit';
        }

        if (this.state.TakingMedication == undefined || this.state.TakingMedication == '') {
            errors.TakingMedication = 'Please select taking medication';
        }


        // if (this.state.GeneralHealth == undefined || this.state.GeneralHealth == null) {
        //     errors.GeneralHealth = 'Please select general health';
        // }

        // if (this.state.treatments == undefined || this.state.treatments == '') {
        //     errors.treatments = 'Please select treatmens';
        // }

        if (this.state.improve.length > 0) {
            let data = this.state.improve;
            let length = data.length;
            let lastData = data[length - 1];
            if (lastData.healthimprove == "") {
                errors.Healthimprove = 'Please enter description.';
                // errors.improve = 'Please fill out empty data of health improve.';
            }
        }
        if (this.state.FamilyHistory.length > 0) {
            let data = this.state.FamilyHistory;
            let length = data.length;
            let lastData = data[length - 1];
            if (lastData.familyHealth == "") {
                errors.FamilyHistory = 'Please enter description.';
            }
        }
        if (this.state.GeneticTesting.length > 0) {
            let data = this.state.GeneticTesting;
            let length = data.length;
            let lastData = data[length - 1];
            if (lastData.geneticTest == "") {
                errors.GeneticTest = 'Please enter description.';
            }
        }
        if (this.state.FemaleOnly.length > 0) {
            let data = this.state.FemaleOnly;
            let length = data.length;
            let lastData = data[length - 1];
            if (lastData.femaleAdditional == "") {
                errors.femaleAdditional = 'Please enter description.';
            }
        }

        if (this.state.Allergies.length > 0) {
            let data = this.state.Allergies;
            let length = data.length;
            let lastData = data[length - 1];
            if (lastData.allergy == "") {
                errors.allergy = 'Please enter description.';
            }
        }
        if (this.state.SupplementAllergies.length > 0) {
            let data = this.state.SupplementAllergies;
            let length = data.length;
            let lastData = data[length - 1];
            if (lastData.supplementAllergiesTxt == "") {
                errors.SupplementAllergiesTxt = 'Please enter description.';
            }
        }
        if (this.state.RecentDiagnostic.length > 0) {
            let data = this.state.RecentDiagnostic;
            let length = data.length;
            let lastData = data[length - 1];
            if (lastData.recentDiagnosticTxt == "") {
                errors.RecentDiagnosticTxt = 'Please enter description.';
            }
        }
        // if (this.state.TakingMedication.toString() == 'true' || this.state.TakingMedication.toLowerCase == "yes"){
        // if (this.state.Takingmedications.length > 0) {
        //     let data = this.state.Takingmedications;
        //     let length = data.length;
        //     let lastData = data[length - 1];
        //     if (lastData.takingmedicationsTxt == "") {
        //         errors.TakingmedicationsTxt = 'Please enter description.';
        //     }
        // }
        if (this.state.GeneticData.toString() == 'true' || this.state.GeneticData.toLowerCase() == "yes") {
            // console.log("this.state.SeenPhysician.toString()",this.state.SeenPhysician.toString())
            // console.log("this.state.SeenPhysician.toLowerCase",this.state.SeenPhysician.toLowerCase())
            if (this.state.Genetictestingdata.length > 0) {
                let data = this.state.Genetictestingdata;
                let length = data.length;
                let lastData = data[length - 1];
                if (lastData.genetictestingdataTxt == "") {
                    errors.GenetictestingdataTxt = 'Please enter description.';
                }
            }

        } 

        if (this.state.TakingMedication.toString() == 'true' || this.state.TakingMedication.toLowerCase() == "yes") {
            if (this.state.Takingmedications.length > 0) {
                let data = this.state.Takingmedications;
                let length = data.length;
                let lastData = data[length - 1];
                if (lastData.takingmedicationsTxt == "") {
                    errors.TakingmedicationsTxt = 'Please enter description.';
                }
            }

        } 
        if (this.state.SeenPhysician.toString() == 'true' || this.state.SeenPhysician.toLowerCase() == "yes") {
            // console.log("this.state.SeenPhysician.toString()",this.state.SeenPhysician.toString())
            // console.log("this.state.SeenPhysician.toLowerCase",this.state.SeenPhysician.toLowerCase())
            if (this.state.SeePhysician.length > 0) {
                let data = this.state.SeePhysician;
                let length = data.length;
                let lastData = data[length - 1];
                if (lastData.seePhysicianTxt == "") {
                    errors.SeePhysicianTxt = 'Please enter description.';
                }
            }

        } 

      
       







        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    //Handle Input Change Condition
    handleChangeInputCondition(event) {
        debugger;
        const target = event.target;
        const value = target.value;
        const name = target.name;
        var ChangeCondition = this.state.ChangeCondition;
        var CheckValues = '';
        if (target.checked) {
            ChangeCondition.push(value)
        }
        else {
            let index = ChangeCondition.indexOf(value);
            ChangeCondition.splice(index, 1)
        }
        this.setState({
            ChangeCondition: ChangeCondition
        })
        if (ChangeCondition.length > 0) {
            for (var i = 0; i < this.state.ChangeCondition.length; i++) {
                CheckValues += ',' + this.state.ChangeCondition[i];
            }
        }
        this.state.Condition = CheckValues.substr(1);
    }


    //Handle Input Chnage  allergic reactions 
    handleChnageInputAllergicReaction(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        var ChangeAllergicReaction = this.state.ChangeAllergicReaction;
        var CheckValues = '';
        if (target.checked) {
            ChangeAllergicReaction.push(value)
        }
        else {
            let index = ChangeAllergicReaction.indexOf(value);
            ChangeAllergicReaction.splice(index, 1)
        }
        this.setState({
            ChangeAllergicReaction: ChangeAllergicReaction
        })
        if (ChangeAllergicReaction.length > 0) {
            for (var i = 0; i < this.state.ChangeAllergicReaction.length; i++) {
                CheckValues += ',' + this.state.ChangeAllergicReaction[i];
            }
        }
        this.state.AllergicReaction = CheckValues.substr(1);
    }

    toggle(e, index, name, id) {
        this.setState({
            modal: !this.state.modal,
            deleteRowIndex: index,
            deleteName: name,
            deleteId: id
        });
    }

    render() {
        if (localStorage.getItem('token') == null) {
            return <Redirect to="/login" />
        }

        const { Name, Sex, DateOfBirth, Address, CityId, cityName, StateId, stateName, PostalCode, PhoneNumber, Mobile, Email, PreferredContact, ReceiveMail, cities,
            AboutUs, EContactName, Relationship, EPhoneNumber, OtherConcerns, treatments, treatmentType, GeneticData, SeenPhysician, TakingMedication, GeneralHealth,
            FemaleCurrently, NoOfPreg, Condition, AllergicReaction, PhotographicConsent, Marketing, Date, improve, FamilyHistory, GeneticTesting, FemaleOnly, Allergies, SupplementAllergies,
            RecentDiagnostic, Takingmedications, SeePhysician, Genetictestingdata, ChangeCondition, ChangeAllergicReaction, states, WorkType, GeneticBackground, errors, error, modal, deleteName, deleteRowIndex, deleteId } = this.state;

        return (

            <React.Fragment>
                <div className='kt-grid kt-grid--hor kt-grid--root'>
                    <div className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page'>
                        <button className='kt-aside-close' id='kt_aside_close_btn'><i className="la la-close"></i></button>
                        <div className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper' id='kt_wrapper'>
                            <div className='kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor' id='kt_content'>
                                <div className='kt-subheader   kt-grid__item' id='kt_subheader'>
                                    <div className='kt-container  kt-container--fluid'>
                                        <div className='kt-subheader__main'>
                                            <h3>
                                                <Link to="/practitioner/dashboard" className='kt-subheader__title'>
                                                    Dashboard
                                                </Link>
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <Link to="#" className="kt-subheader__breadcrumbs-link">
                                                    Health Index Form
                                                </Link>
                                            </div>
                                            {/* <div className="kt-subheader__main">
                                                <Link to="/practitioner/dashboard" className="btn btn-primary btnBorder">
                                                    List
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                                <div className='kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                                    <div className='kt-portlet form-body-wrapper'>
                                        <div className='kt-portlet__body'>
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                        <div>
                                                            <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                                <div className="alert-text">{error}</div>
                                                                <div className="alert-close">
                                                                    <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null} */}

                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='health-wrapper'>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <h4>In order to provide you with the most appropriate health analysis we need you to complete the
                                                                        following questionnaire. All information is strictly confidential.</h4>
                                                                </div>

                                                            </div>
                                                            <div className='form-group row my-4' style={{ pointerEvents: "none" }}>
                                                                <div className="col-md-4">
                                                                    <label className="col-12 col-form-label"> Name </label>
                                                                    <div className="col-12">
                                                                        <Input className="border-0 px-0 here" type="text" maxLength="200" tabIndex="1" placeholder="Patient name" name="Name" value={Name} />
                                                                        {/* {!Name && <span className='error'>{errors.Name}</span>} */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label className="col-12 col-form-label">Sex</label>
                                                                    <div className="col-12">
                                                                        <div className="kt-radio-inline">
                                                                            {Sex == "M" ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="M" checked id="Male" name="Sex" tabIndex="2" /> Male
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="M" id="Male" name="Sex" tabIndex="4" /> Male
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                            {Sex == "F" ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="F" id="FeMale" checked name="Sex" tabIndex="3" /> Female
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="F" id="FeMale" name="Sex" tabIndex="3" /> Female
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {/* <label className="kt-radio">
                                                                                <input type="radio" value="F" onChange="" checked id="Female" name="Sex" tabIndex="5" /> Female
                                                                                <span></span>
                                                                            </label>
                                                                            :
                                                                            <label className="kt-radio">
                                                                                <input type="radio" value="F" onChange="" id="Female" name="Sex" tabIndex="5" /> Female
                                                                                <span></span>
                                                                            </label> */}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label className="col-12 col-form-label">Date Of Birth</label>
                                                                    <div className="col-12">
                                                                        {/* <Input type="date" max="9999-12-31" name="DateOfBirth" tabIndex="4" min="1000-01-01" className="form-control" value={DateOfBirth} onChange={this.handleInputChange.bind(this)} placeholder="Enter patient date of birth" /> */}
                                                                        <DatePicker
                                                                            selected={DateOfBirth}
                                                                            // onChange={this.handleBirthDateChange.bind(this)}
                                                                            dateFormat="MM/dd/yyyy"
                                                                            placeholderText="mm/dd/yyyy"
                                                                            className="border-0 px-0"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                        />
                                                                        {!DateOfBirth && <span className='error'>{errors.DateOfBirth}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='form-group row my-4' style={{ pointerEvents: "none" }}>
                                                                <div className="col-md-12">
                                                                    <label className="col-12 col-form-label">Address</label>
                                                                    <div className="col-12">
                                                                        <textarea className="border-0 px-0 here" type="text" maxLength="500" tabIndex="5" name="Address" placeholder="Patient residential address" value={Address}></textarea>
                                                                        {/* {errors.Address.length > 0 && <span className='error'>{errors.Address}</span>} */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='form-group row my-4' style={{ pointerEvents: "none" }}>
                                                                <div className='col-md-4'>
                                                                    <label className="col-12 col-form-label">State</label>
                                                                    <div className="col-12">
                                                                        <Input type="text" className="border-0 px-0 here" tabIndex="7" name="StateId" value={stateName} placeholder="Patient residential state" />
                                                                        {/* <Input type="select" className="custom-select mb-3" tabIndex="7" name="StateId" value={StateId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Resident State</option>
                                                                        {states
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input> */}
                                                                        {/* {errors.StateId.length > 0 && <span className='error'>{errors.StateId}</span>} */}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label className="col-12 col-form-label">City</label>
                                                                    <div className="col-12">
                                                                        <Input type="text" className="border-0 px-0  here" tabIndex="6" name="CityId" value={cityName} placeholder="Patient residential city" />
                                                                        {/* <Input type="select" className="custom-select mb-3" tabIndex="6" name="CityId" value={CityId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Resident City</option>
                                                                        {cities
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input> */}
                                                                        {/* {errors.CityId.length > 0 && <span className='error'>{errors.CityId}</span>} */}
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-4'>
                                                                    <label className="col-12 col-form-label">Zip code</label>
                                                                    <div className="col-12">
                                                                        <Input className="border-0 px-0 here" type="text" maxLength="20" tabIndex="8" name="PostalCode" placeholder="Patient residential zip code" value={PostalCode} />
                                                                        {/* {errors.PostalCode.length > 0 && <span className='error'>{errors.PostalCode}</span>} */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='form-group row my-4' style={{ pointerEvents: "none" }}>
                                                                <div className="col-md-4">
                                                                    <label className="col-12 col-form-label">Cell Phone</label>
                                                                    <div className="col-12">
                                                                        <Input className="border-0 px-0 here" type="text" maxLength="20" tabIndex="9" name="PhoneNumber" placeholder="Patient cellphone" value={PhoneNumber} />
                                                                        {/* {errors.PhoneNumber.length > 0 && <span className='error'>{errors.PhoneNumber}</span>} */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label className="col-12 col-form-label">Home Phone</label>
                                                                    <div className="col-12">
                                                                        <Input className="border-0 px-0 here" type="text" maxLength="20" tabIndex="10" name="Mobile" placeholder="Patient home phone" value={Mobile} />
                                                                        {/* {errors.Mobile.length > 0 && <span className='error'>{errors.Mobile}</span>} */}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label className="col-12 col-form-label">Email</label>
                                                                    <div className="col-md-12">
                                                                        <Input className="border-0 px-0 here" type="text" maxLength="50" tabIndex="11" name="Email" placeholder="Patient email address" value={Email} autoComplete="Email" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='form-group row my-4'>

                                                                <div className='col-md-4'>
                                                                    <label className="col-12 col-form-label">Preferred Contact<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className="col-12">
                                                                        <div className="kt-radio-inline">
                                                                            {
                                                                                console.log("PreferredContact", this.state.PreferredContact)
                                                                            }
                                                                            {PreferredContact == 'Home' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Home" checked id="chkHome" name="PreferredContact" tabIndex="12" onChange={this.handleInputChange.bind(this)} /> Home
                                                                                    <span></span>
                                                                                </label>

                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Home" id="chkHome" name="PreferredContact" tabIndex="12" onChange={this.handleInputChange.bind(this)} /> Home
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {PreferredContact == 'Cell' ?

                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Cell" checked id="chkCell" name="PreferredContact" tabIndex="13" onChange={this.handleInputChange.bind(this)} /> Cell
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Cell" id="chkCell" name="PreferredContact" tabIndex="13" onChange={this.handleInputChange.bind(this)} /> Cell
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {PreferredContact == 'Email' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Email" checked id="chkEmail" name="PreferredContact" tabIndex="14" onChange={this.handleInputChange.bind(this)} /> Email
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Email" id="chkEmail" name="PreferredContact" tabIndex="14" onChange={this.handleInputChange.bind(this)} /> Email
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                            {PreferredContact == 'Others' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Others" checked id="chkOthers" name="PreferredContact" tabIndex="15" onChange={this.handleInputChange.bind(this)} /> Others
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Others" id="chkOthers" name="PreferredContact" tabIndex="15" onChange={this.handleInputChange.bind(this)} /> Others
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                        {!PreferredContact && <span className='error'>{errors.PreferredContact}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-8'>
                                                                    <label className="col-12 col-form-label">Would you like to be on our email list to receive appointment reminders and info on
                                                                        product/service specials?<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-radio-inline'>
                                                                            {ReceiveMail == 'true' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" checked id="chkYMail" name="ReceiveMail" tabIndex="16" onChange={this.handleInputChange.bind(this)} /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" id="chkYMail" name="ReceiveMail" tabIndex="16" onChange={this.handleInputChange.bind(this)} /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                            {ReceiveMail == 'false' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" checked id="chkNMail" name="ReceiveMail" tabIndex="17" onChange={this.handleInputChange.bind(this)} /> NO
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" id="chkNMail" name="ReceiveMail" tabIndex="17" onChange={this.handleInputChange.bind(this)} /> NO
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                        {!ReceiveMail && <span className='error'>{errors.ReceiveMail}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='form-group row my-4'>

                                                                <div className='col-md-12'>
                                                                    <label className="col-12 col-form-label">How did you hear about VibrantHealthX?</label>
                                                                    <div className="col-12">
                                                                        <Input className="form-control here" type="textarea" maxLength="200" tabIndex="18" placeholder="Enter about where did you hear of us" name="AboutUs" value={AboutUs} onChange={this.handleInputChange.bind(this)} />
                                                                        {/* {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>} */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-4'>
                                                                    <label className="col-12 col-form-label">Emergency Contact Name</label>
                                                                    <div className="col-12">
                                                                        <Input className="form-control here" type="text" maxLength="20" tabIndex="19" name="EContactName" placeholder="Enter emergency contact name" value={EContactName} onChange={this.handleInputChange.bind(this)} />
                                                                        {/* {errors.PostalCode.length > 0 && <span className='error'>{errors.PostalCode}</span>} */}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label className="col-12 col-form-label">Relationship</label>
                                                                    <div className="col-12">
                                                                        <Input className="form-control here" type="text" maxLength="20" tabIndex="20" name="Relationship" placeholder="Enter relationship" value={Relationship} onChange={this.handleInputChange.bind(this)} />
                                                                        {/* {errors.PostalCode.length > 0 && <span className='error'>{errors.PostalCode}</span>} */}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label className="col-12 col-form-label">Phone Number</label>
                                                                    <div className="col-12">
                                                                        <Input className="form-control here" type="text" maxLength="20" tabIndex="21" name="EPhoneNumber" placeholder="Enter phone number" value={EPhoneNumber?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')} onChange={this.handleInputChange.bind(this)} />
                                                                        {/* {errors.PostalCode.length > 0 && <span className='error'>{errors.PostalCode}</span>} */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <div className='d-flex justify-content-between align-items-center label-wrapper'>
                                                                        <label className="col col-form-label">What conditions would you like to improve about your health? Please
                                                                            list your concerns
                                                                        </label>
                                                                        <Button className="customize-btn" color="primary" size="sm" onClick={this.addImprove.bind(this)}>Add</Button>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        {/* <Button className="btn btn-primary btn-md" onClick={this.addImprove.bind(this)}>Add</Button> */}
                                                                        {improve?.length > 0 ? (
                                                                            improve
                                                                                .map((ddata, i) => {
                                                                                    return (
                                                                                        <div className="form-group row my-4">
                                                                                            <div className='col-md-12'>
                                                                                                <div className="col d-flex align-items-center">
                                                                                                    <h5>{i + 1}.)</h5>
                                                                                                    {/* </div>
                                                                                                <div className="col-md-11"> */}
                                                                                                    <Input type="text" className="mx-2 my-0" placeholder="Enter about what you want to improve in your health" name="Healthimprove" value={ddata?.healthimprove} onChange={e => this.handleInputChangeImprove(e, i, 'Healthimprove')} />
                                                                                                    <Button color="danger" outline onClick={e => this.toggle(e, i, "Healthimprove", ddata?.id)}>Delete</Button>
                                                                                                </div>
                                                                                                {errors.Healthimprove?.length > 0 && !ddata?.healthimprove && <span className='error'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{errors.Healthimprove}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })) : null}
                                                                        {errors.improve.length > 0 && <span className='error'>{errors.improve}</span>}
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <label className="col-12 col-form-label">Other Concerns</label>
                                                                    <div className="col-12">
                                                                        <Input className="form-control here" type="textarea" maxLength="200" tabIndex="23" placeholder="Enter other concerns" name="OtherConcerns" value={OtherConcerns} onChange={this.handleInputChange.bind(this)} />
                                                                        {/* {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>} */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-6'>
                                                                    <label className="col-12 col-form-label">Are you currently receiving any treatments?<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-radio-inline'>
                                                                            {treatments == 'true' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" checked onChange={this.handleInputChange.bind(this)} id="rbTreatmetnsYes" name="treatments" tabIndex="24" /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" onChange={this.handleInputChange.bind(this)} id="rbTreatmetnsYes" name="treatments" tabIndex="24" /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                            {treatments == 'false' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" checked onChange={this.handleInputChange.bind(this)} id="rbTreatmetnsNo" name="treatments" tabIndex="25" /> NO
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" onChange={this.handleInputChange.bind(this)} id="rbTreatmetnsNo" name="treatments" tabIndex="25" /> NO
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                        {!treatments && <span className='error'>{errors.treatments}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    {
                                                                        this.state.treatmentTypeDisabled &&
                                                                        <div className="col-md-12">
                                                                            <label className="col-12 col-form-label">If yes what type?</label>
                                                                            <div className="col-12">
                                                                                <textarea className="form-control here" type="text" maxLength="500" tabIndex="26" name="treatmentType" placeholder="Enter about your treatment" value={treatmentType} onChange={this.handleInputChange.bind(this)}></textarea>
                                                                                {/* {errors.Address.length > 0 && <span className='error'>{errors.Address}</span>} */}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='family-wrapper'>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <h4>FAMILY HISTORY</h4>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <div className='d-flex justify-content-between align-items-center label-wrapper'>
                                                                        <label className="col-form-label">List any relevant family related health conditions</label>
                                                                        <Button className="btn btn-primary btn-sm customize-btn" onClick={this.addFamilyHistory.bind(this)}>Add</Button>
                                                                    </div>
                                                                    <div className="col-12">

                                                                        {FamilyHistory?.length > 0 ? (
                                                                            FamilyHistory
                                                                                .map((ddata, i) => {
                                                                                    return (
                                                                                        <div className="form-group row my-4">
                                                                                            <div className="col-md-12">
                                                                                                <div className="col d-flex align-items-center">
                                                                                                    <h5>{i + 1}.)</h5>
                                                                                                    {/* </div>
                                                                                                    <div className="col-md-11"> */}
                                                                                                    <Input type="text" className="mx-2 my-0" placeholder="Enter about your Family Health" name="FamilyHealth" value={ddata?.familyHealth} onChange={e => this.handleInputChangeFamilyHistory(e, i, 'FamilyHealth')} />
                                                                                                    <Button color="danger" outline onClick={e => this.toggle(e, i, "FamilyHealth", ddata?.id)}>Delete</Button>
                                                                                                </div>
                                                                                                {errors.FamilyHistory?.length > 0 && !ddata?.familyHealth && <span className='error'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{errors.FamilyHistory}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='healthing-wrapper'>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <h4>HEALTH HISTORY</h4>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-6'>
                                                                    <label className="col-12 col-form-label">What type of work do you do?</label>
                                                                    <div className="col-12">
                                                                        <Input className="form-control" type="textarea" maxLength="500" tabIndex="28" name="WorkType" placeholder="Enter about your work" value={WorkType} onChange={this.handleInputChange.bind(this)} />

                                                                        {/* {errors.Address.length > 0 && <span className='error'>{errors.Address}</span>} */}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label className="col-12 col-form-label">What is your genetic background?  (Example: German, Hispanic, Indian, French, etc....)</label>
                                                                    <div className="col-12">
                                                                        <Input className="form-control" type="textarea" maxLength="500" tabIndex="29" name="GeneticBackground" placeholder="Enter your genetic background" value={GeneticBackground} onChange={this.handleInputChange.bind(this)} />
                                                                        {/* {errors.Address.length > 0 && <span className='error'>{errors.Address}</span>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <div className='d-flex justify-content-between align-items-center label-wrapper'>
                                                                        <label className="col-form-label">Any Previous Genetic Testing ?
                                                                            Please list test and performing laboratory
                                                                        </label>
                                                                        <Button className="btn btn-primary btn-sm customize-btn" onClick={this.addGeneticTesting.bind(this)}>Add</Button>
                                                                    </div>

                                                                    <div className="col-12">
                                                                        {GeneticTesting?.length > 0 ? (
                                                                            GeneticTesting
                                                                                .map((ddata, i) => {
                                                                                    return (
                                                                                        <div className="form-group row my-4">
                                                                                            <div className="col-md-12">
                                                                                                <div className="col d-flex align-items-center">
                                                                                                    <h5>{i + 1}.)</h5>
                                                                                                    {/* </div>
                                                                                                    <div className="col-md-11"> */}
                                                                                                    <Input type="text" className="mx-2 my-0" placeholder="Enter about your Genetic Test" name="GeneticTest" value={ddata?.geneticTest} onChange={e => this.handleInputChangeGeneticTesting(e, i, 'GeneticTest')} />
                                                                                                    <Button color="danger" outline onClick={e => this.toggle(e, i, "GeneticTest", ddata.id)}>Delete</Button>
                                                                                                </div>
                                                                                                {errors.GeneticTest?.length > 0 && !ddata?.geneticTest && <span className='error'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{errors.GeneticTest}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <label className="col-12 col-form-label">Any current genetic data available?<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-radio-inline'>
                                                                           
                                                                            {this.state.GeneticData == "true" ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" checked id="rblGenDataYEs" name="GeneticData" tabIndex="31" onChange={this.handleInputChange.bind(this)} /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" id="rblGenDataYEs" name="GeneticData" tabIndex="31" onChange={this.handleInputChange.bind(this)} /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                            }


                                                                            {this.state.GeneticData == "false" ?

                                                                                <label className="kt-radio">
                                                                                    <input type="radio" checked value="No" id="rblGenDataNo" name="GeneticData" tabIndex="32" onChange={this.handleInputChange.bind(this)} /> NO
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" id="rblGenDataNo" name="GeneticData" tabIndex="32" onChange={this.handleInputChange.bind(this)} /> NO
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                        </div>
                                                                        {/* {<span className='error'>{errors.GeneticData}</span>} */}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    {this.state.GenetictestingdataDisabled && <>
                                                                        <div className="col-md-12 d-flex justify-content-between align-items-center label-wrapper">
                                                                            <label className="col-form-label">Optional: It is recommended to provide genetic testing data for comparison and precision
                                                                                analysis
                                                                            </label>
                                                                            <Button className="btn btn-primary btn-sm customize-btn" onClick={this.addGenetictestingdata.bind(this)}>Add</Button>
                                                                        </div>
                                                                        {/* <label className="col-12 col-form-label">List data source</label> */}
                                                                        <div className="col-12">
                                                                            {Genetictestingdata?.length > 0 ? (
                                                                                Genetictestingdata
                                                                                    .map((ddata, i) => {
                                                                                        return (
                                                                                            <div className="form-group row my-4">
                                                                                                <div className="col-md-12">
                                                                                                    <div className="col d-flex align-items-center">
                                                                                                        <h5>{i + 1}.)</h5>
                                                                                                        {/* </div>
                                                                                                        <div className="col-md-11"> */}
                                                                                                        <Input type="text" className="mx-2 my-0" placeholder="Enter Genetic Testing Data" name="GenetictestingdataTxt" value={ddata?.genetictestingdataTxt} onChange={e => this.handleInputChangeGenetictestingdata(e, i, 'GenetictestingdataTxt')} />
                                                                                                        <Button color="danger" outline onClick={e => this.toggle(e, i, "GenetictestingdataTxt", ddata?.id)}>Delete</Button>
                                                                                                    </div>
                                                                                                    {errors.GenetictestingdataTxt.length > 0 && !ddata?.genetictestingdataTxt && <span className='error'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{errors.GenetictestingdataTxt}</span>}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })) : null}
                                                                        </div>
                                                                    </>}
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <label className="col-12 col-form-label">Have you seen a physician / health practitioner in the past year?<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-radio-inline'>
                                                                            {SeenPhysician == 'true' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" checked id="rbSeePhyY" name="SeenPhysician" tabIndex="34" onChange={this.handleInputChange.bind(this)} /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" id="rbSeePhyY" name="SeenPhysician" tabIndex="34" onChange={this.handleInputChange.bind(this)} /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {SeenPhysician == 'false' ?

                                                                                < label className="kt-radio">
                                                                                    <input type="radio" checked value="No" id="rbSeePhyN" name="SeenPhysician" tabIndex="35" onChange={this.handleInputChange.bind(this)} /> NO
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                < label className="kt-radio">
                                                                                    <input type="radio" value="No" id="rbSeePhyN" name="SeenPhysician" tabIndex="35" onChange={this.handleInputChange.bind(this)} /> NO
                                                                                    <span></span>
                                                                                </label>

                                                                            }
                                                                        </div>
                                                                        {!SeenPhysician && <span className='error'>{errors.SeenPhysician}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    {this.state.SeePhysicianDisabled && <>
                                                                        <div className="col-md-12 d-flex justify-content-between align-items-center label-wrapper">
                                                                            <label className="col-form-label">If yes, please list reason for visit</label>
                                                                            <Button className="btn btn-primary btn-sm customize-btn" onClick={this.addSeePhysician.bind(this)}>Add</Button>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            {/*//VisitReason*/}
                                                                            {SeePhysician?.length > 0 ? (
                                                                                SeePhysician
                                                                                    .map((ddata, i) => {
                                                                                        return (
                                                                                            <div className="form-group row my-4">
                                                                                                <div className="col-md-12">
                                                                                                    <div className="col d-flex align-items-center">
                                                                                                        <h5>{i + 1}.)</h5>
                                                                                                        {/* </div>
                                                                                                        <div className="col-md-11"> */}
                                                                                                        <Input type="text" className="mx-2 my-0" placeholder="Enter Reason for Visit Physician" name="SeePhysicianTxt" value={ddata?.seePhysicianTxt} onChange={e => this.handleInputChangeSeePhysician(e, i, 'SeePhysicianTxt')} />
                                                                                                        <Button color="danger" outline onClick={e => this.toggle(e, i, "SeePhysicianTxt", ddata.id)}>Delete</Button>
                                                                                                    </div>
                                                                                                    {errors.SeePhysicianTxt?.length > 0 && !ddata?.seePhysicianTxt && <span className='error'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{errors.SeePhysicianTxt}</span>}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })) : null}
                                                                        </div>
                                                                    </>}
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <label className="col-12 col-form-label">Are you currently taking medications or supplements?<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-radio-inline'>
                                                                            {TakingMedication == 'true' ?
                                                                                < label className="kt-radio">
                                                                                    <input type="radio" value="Yes" onChange={this.handleInputChange.bind(this)} id="rbMedY" name="TakingMedication" tabIndex="37" checked /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" onChange={this.handleInputChange.bind(this)} id="rbMedY" name="TakingMedication" tabIndex="37" /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {TakingMedication == 'false' ?

                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" checked onChange={this.handleInputChange.bind(this)} id="rbMedN" name="TakingMedication" tabIndex="38" /> NO
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" onChange={this.handleInputChange.bind(this)} id="rbMedN" name="TakingMedication" tabIndex="38" /> NO
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                        </div>
                                                                        {!TakingMedication && <span className='error'>{errors.TakingMedication}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    {this.state.TakingmedicationsDisabled && <>
                                                                        <div className="col-md-12 d-flex justify-content-between align-items-center label-wrapper">
                                                                            <label className="col-form-label">If yes, please list</label>
                                                                            <Button className="btn btn-primary btn-sm customize-btn" onClick={this.addTakingmedications.bind(this)}>Add</Button>
                                                                        </div>
                                                                        <div className="">
                                                                            {Takingmedications?.length > 0 ? (
                                                                                Takingmedications
                                                                                    .map((ddata, i) => {
                                                                                        return (
                                                                                            <div className="form-group row my-4">
                                                                                                <div className="col-md-12">
                                                                                                    <div className="col d-flex align-items-center">
                                                                                                        <h5>{i + 1}.)</h5>
                                                                                                        {/* </div>
                                                                                                        <div className="col-md-11"> */}
                                                                                                        <Input type="text" className="mx-2 my-0" placeholder="Enter about what your  health" name="TakingmedicationsTxt" value={ddata?.takingmedicationsTxt} onChange={e => this.handleInputChangeTakingmedications(e, i, 'TakingmedicationsTxt')} />
                                                                                                        <Button color="danger" outline onClick={e => this.toggle(e, i, "TakingmedicationsTxt", ddata.id)}>Delete</Button>
                                                                                                    </div>
                                                                                                    {errors.TakingmedicationsTxt.length > 0 && !ddata?.takingmedicationsTxt && <span className='error'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{errors.TakingmedicationsTxt}</span>}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })) : null}
                                                                        </div>
                                                                    </>}
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-6'>
                                                                    <label className="col-12 col-form-label">How is your general health? (check one)<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-radio-inline'>
                                                                            {
                                                                                console.log("this.state", this.state)

                                                                            }
                                                                            {GeneralHealth == 'Excellent' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Excellent" checked onChange={this.handleInputChange.bind(this)} id="Ecxl" name="GeneralHealth" tabIndex="40" /> Excellent
                                                                                    <span></span>
                                                                                </label>

                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Excellent" onChange={this.handleInputChange.bind(this)} id="Ecxl" name="GeneralHealth" tabIndex="40" /> Excellent
                                                                                    <span></span>
                                                                                </label>

                                                                            }

                                                                            {GeneralHealth == 'good' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="good" checked onChange={this.handleInputChange.bind(this)} id="good" name="GeneralHealth" tabIndex="41" /> Good
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="good" onChange={this.handleInputChange.bind(this)} id="good" name="GeneralHealth" tabIndex="41" /> Good
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {GeneralHealth == 'Fair' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Fair" checked onChange={this.handleInputChange.bind(this)} id="fair" name="GeneralHealth" tabIndex="42" /> Fair
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Fair" onChange={this.handleInputChange.bind(this)} id="fair" name="GeneralHealth" tabIndex="42" /> Fair
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {GeneralHealth == 'Poor' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Poor" checked onChange={this.handleInputChange.bind(this)} id="poor" name="GeneralHealth" tabIndex="43" /> Poor
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Poor" onChange={this.handleInputChange.bind(this)} id="poor" name="GeneralHealth" tabIndex="43" /> Poor
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                        {!GeneralHealth && <span className='error'>{errors.GeneralHealth}</span>}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='female-wrapper'>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <h4>FEMALE ONLY</h4>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <label className="col-12 col-form-label">Are you currently :</label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-checkbox-inline'>
                                                                            {FemaleCurrently == 'Nursing' ?
                                                                                <label className="kt-checkbox">
                                                                                    <Input type="checkbox" value="Nursing" checked onChange={this.handleInputChange.bind(this)} id="chkNursing" name="FemaleCurrently" /> Nursing
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-checkbox">
                                                                                    <Input type="checkbox" value="Nursing" onChange={this.handleInputChange.bind(this)} id="chkNursing" name="FemaleCurrently" /> Nursing
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {FemaleCurrently == 'Pregnant' ?

                                                                                <label className="kt-checkbox">
                                                                                    <Input type="checkbox" value="Pregnant" checked onChange={this.handleInputChange.bind(this)} id="chkPrg" name="FemaleCurrently" /> Pregnant
                                                                                    <span></span>
                                                                                </label>
                                                                                :

                                                                                <label className="kt-checkbox">
                                                                                    <Input type="checkbox" value="Pregnant" onChange={this.handleInputChange.bind(this)} id="chkPrg" name="FemaleCurrently" /> Pregnant
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {FemaleCurrently == 'Planing' ?
                                                                                <label className="kt-checkbox">
                                                                                    <Input type="checkbox" value="Planing" checked onChange={this.handleInputChange.bind(this)} id="chkPlantoPreg" name="FemaleCurrently" /> Planning to become pregnant
                                                                                    <span></span>
                                                                                </label> : <label className="kt-checkbox">
                                                                                    <Input type="checkbox" value="Planing" onChange={this.handleInputChange.bind(this)} id="chkPlantoPreg" name="FemaleCurrently" /> Planning to become pregnant
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <label className="col-12 col-form-label"># of pregnancies :</label>
                                                                    <div className='col-12'>
                                                                        <Input className="form-control here" type="text" maxLength="200" tabIndex="22" placeholder="# of Pregnancies" name="NoOfPreg" value={NoOfPreg} onChange={this.handleInputChange.bind(this)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>

                                                                    <label className="col-12 col-form-label">Please check any of the following conditions you have experienced</label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-checkbox-inline'>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Cancer (Chemo/Radiation)")} value="Cancer (Chemo/Radiation)" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="44" /> Cancer (Chemo/Radiation)
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Autism")} value="Autism" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="45" /> Autism
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Genetic related concerns")} value="Genetic related concerns" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="46" /> Genetic related concerns
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Circulatory problems")} value="Circulatory problems" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="47" />Circulatory problems
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Implantable devices")} value="Implantable devices" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="48" />Implantable devices
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("High blood pressure")} value="High blood pressure" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="50" />High blood pressure
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Low blood pressure")} value="Low blood pressure" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="51" />Low blood pressure
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Cardiovascular problems")} value="Cardiovascular problems" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="52" />Cardiovascular problems
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Heart attack")} value="Heart attack" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="53" />Heart attack
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Stroke")} value="Stroke" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="54" />Stroke
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Arthritis")} value="Arthritis" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="55" />Arthritis
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Bone Loss / Osteoporosis")} value="Bone Loss / Osteoporosis" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="56" /> Bone Loss / Osteoporosis
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("HIV/AIDS")} value="HIV/AIDS" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="57" />HIV/AIDS
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Hepatitis")} value="Hepatitis" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="58" />Hepatitis
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Fainting")} value="Fainting" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="59" />Fainting
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Fatigue")} value="Fatigue" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="60" />Fatigue
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Eczema")} value="Eczema" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="61" />Eczema
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Dizziness")} value="Dizziness" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="62" />Dizziness
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Keloid scarring")} value="Keloid scarring" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="63" />Keloid scarring
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Skin Disease")} value="Skin Disease" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="64" />Skin Disease
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Skin lesions")} value="Skin lesions" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="65" />Skin lesions
                                                                                <span></span>
                                                                            </label>

                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Psoriasis")} value="Psoriasis" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="66" />Psoriasis
                                                                                <span></span>
                                                                            </label>

                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Cystic acne")} value="Cystic acne" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="67" />Cystic acne
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Hormone imbalance")} value="Hormone imbalance" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="68" />Hormone imbalance
                                                                                <span></span>
                                                                            </label>

                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Thyroid imbalance")} value="Thyroid imbalance" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="69" />Thyroid imbalance
                                                                                <span></span>
                                                                            </label>

                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Any active infection")} value="Any active infection" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="71" />Any active infection
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Diabetes")} value="Diabetes" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="72" />Diabetes
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Lupus")} value="Lupus" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="73" />Lupus
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Neurodegenerative disease")} value="Neurodegenerative disease" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="74" />Neurodegenerative disease
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("ALS")} value="ALS" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="75" />ALS
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("MS")} value="MS" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="76" />MS
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Hirsutism")} value="Hirsutism" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="77" />Hirsutism
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Arthritis")} value="Arthritis" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="78" />Arthritis
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Cold sores")} value="Cold sores" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="79" />Cold sores
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Herpes")} value="Herpes" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="80" />Herpes
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Autoimmunity")} value="Autoimmunity" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="81" />Autoimmunity
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Viral infection")} value="Viral infection" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="82" />Viral infection
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Bacterial Infection")} value="Bacterial Infection" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="83" />Bacterial Infection
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Sexual performance decline")} value="Sexual performance decline" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="84" />Sexual performance decline
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox col-2">
                                                                                <input type="checkbox" checked={ChangeCondition.includes("Fitness performance decline")} value="Fitness performance decline" onChange={this.handleChangeInputCondition.bind(this)} id="chkCondition" name="Condition" tabIndex="85" />Fitness performance decline
                                                                                <span></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <div className='d-flex justify-content-between align-items-center label-wrapper'>
                                                                        <label className="col-form-label">Any additional health problems or medical conditions? Please list</label>
                                                                        <Button className="btn btn-primary btn-sm customize-btn" onClick={this.addFemaleOnly.bind(this)}>Add</Button>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        {FemaleOnly?.length > 0 ? (
                                                                            FemaleOnly
                                                                                .map((ddata, i) => {
                                                                                    return (
                                                                                        <div className="form-group row my-4">
                                                                                            <div className="col-md-12">
                                                                                                <div className="col d-flex align-items-center">
                                                                                                    <h5>{i + 1}.)</h5>
                                                                                                    {/* </div>
                                                                                                    <div className="col-md-11"> */}
                                                                                                    <Input type="text" className="mx-2 my-0" placeholder="Enter about what your health" name="FemaleAdditional" value={ddata?.femaleAdditional} onChange={e => this.handleInputChangeaddFemaleOnly(e, i, 'FemaleAdditional')} />
                                                                                                    <Button color="danger" outline onClick={e => this.toggle(e, i, "FemaleAdditional", ddata?.id)}>Delete</Button>
                                                                                                </div>
                                                                                                {errors.FemaleAdditional?.length > 0 && !ddata?.femaleAdditional && <span className='error'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{errors.FemaleAdditional}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='allergies-wrapper'>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <h4>ALLERGIES</h4>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <div className='d-flex justify-content-between align-items-center label-wrapper'>
                                                                        <label className="col-form-label">Please list any known allergies</label>
                                                                        <Button className="btn btn-primary btn-sm customize-btn" onClick={this.addAllergies.bind(this)}>Add</Button>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        {Allergies?.length > 0 ? (
                                                                            Allergies
                                                                                .map((ddata, i) => {
                                                                                    return (
                                                                                        <div className="form-group row my-4">
                                                                                            <div className="col-md-12">
                                                                                                <div className="col d-flex align-items-center">
                                                                                                    <h5>{i + 1}.)</h5>
                                                                                                    {/* </div>
                                                                                                    <div className="col-md-11"> */}
                                                                                                    <Input type="text" className="mx-2 my-0" placeholder="Enter known allergies" name="allergy" value={ddata?.allergy} onChange={e => this.handleInputChangeAllergies(e, i, 'allergy')} />
                                                                                                    <Button color="danger" outline onClick={e => this.toggle(e, i, "allergy", ddata.id)}>Delete</Button>
                                                                                                </div>
                                                                                                {errors.allergy?.length > 0 && !ddata?.allergy && <span className='error'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{errors.allergy}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <label className="col-form-label">Have you ever had any allergic reactions to the following? Please check all that apply</label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-checkbox-inline'>
                                                                            <label className="kt-checkbox">
                                                                                <input type="checkbox" checked={ChangeAllergicReaction.includes("Aspirin")} value="Aspirin" onChange={this.handleChnageInputAllergicReaction.bind(this)} id="chkAspirin" name="AllergicReaction" tabIndex="52" /> Aspirin
                                                                                <span></span>
                                                                            </label>

                                                                            <label className="kt-checkbox">
                                                                                <input type="checkbox" checked={ChangeAllergicReaction.includes("Salicylates")} value="Salicylates" onChange={this.handleChnageInputAllergicReaction.bind(this)} id="chkSalicylates" name="AllergicReaction" tabIndex="53" /> Salicylates
                                                                                <span></span>
                                                                            </label>

                                                                            <label className="kt-checkbox">
                                                                                <input type="checkbox" checked={ChangeAllergicReaction.includes("Milk")} value="Milk" onChange={this.handleChnageInputAllergicReaction.bind(this)} id="chkMilk" name="AllergicReaction" tabIndex="54" /> Milk
                                                                                <span></span>
                                                                            </label>

                                                                            <label className="kt-checkbox">
                                                                                <input type="checkbox" checked={ChangeAllergicReaction.includes("Grapes")} value="Grapes" onChange={this.handleChnageInputAllergicReaction.bind(this)} id="chkGrapes" name="AllergicReaction" tabIndex="55" /> Grapes
                                                                                <span></span>
                                                                            </label>

                                                                            <label className="kt-checkbox">
                                                                                <input type="checkbox" checked={ChangeAllergicReaction.includes("Fish")} value="Fish" onChange={this.handleChnageInputAllergicReaction.bind(this)} id="chkFish" name="AllergicReaction" tabIndex="56" /> Fish
                                                                                <span></span>
                                                                            </label>

                                                                            <label className="kt-checkbox">
                                                                                <input type="checkbox" checked={ChangeAllergicReaction.includes("Latex")} value="Latex" onChange={this.handleChnageInputAllergicReaction.bind(this)} id="chkLatex" name="AllergicReaction" tabIndex="57" /> Latex
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox">
                                                                                <input type="checkbox" checked={ChangeAllergicReaction.includes("Foods")} value="Foods" onChange={this.handleChnageInputAllergicReaction.bind(this)} id="chkFoods" name="AllergicReaction" tabIndex="58" /> Foods
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox">
                                                                                <input type="checkbox" checked={ChangeAllergicReaction.includes("Medications")} value="Medications" onChange={this.handleChnageInputAllergicReaction.bind(this)} id="chkMedications" name="AllergicReaction" tabIndex="59" /> Medications
                                                                                <span></span>
                                                                            </label>
                                                                            <label className="kt-checkbox">
                                                                                <input type="checkbox" checked={ChangeAllergicReaction.includes("Antibiotics")} value="Antibiotics" onChange={this.handleChnageInputAllergicReaction.bind(this)} id="chkAntibiotics" name="AllergicReaction" tabIndex="60" /> Antibiotics
                                                                                <span></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <div className='d-flex justify-content-between align-items-center label-wrapper'>
                                                                        <label className="col-form-label">List Medications or Supplement Allergies</label>
                                                                        <Button className="btn btn-primary btn-sm customize-btn" onClick={this.addSupplementAllergies.bind(this)}>Add</Button>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        {SupplementAllergies?.length > 0 ? (
                                                                            SupplementAllergies
                                                                                .map((ddata, i) => {
                                                                                    return (
                                                                                        <div className="form-group row my-4">
                                                                                            <div className="col-md-12">
                                                                                                <div className="col d-flex align-items-center">
                                                                                                    <h5>{i + 1}.)</h5>
                                                                                                    {/* </div>
                                                                                                    <div className="col-md-11"> */}
                                                                                                    <Input type="text" className="mx-2 my-0" placeholder="Enter known Supplement Allergies" name="SupplementAllergiesTxt" value={ddata?.supplementAllergiesTxt} onChange={e => this.handleInputChangeSupplementAllergies(e, i, 'SupplementAllergiesTxt')} />
                                                                                                    <Button color="danger" outline onClick={e => this.toggle(e, i, "SupplementAllergiesTxt", ddata.id)}>Delete</Button>
                                                                                                </div>
                                                                                                {errors.SupplementAllergiesTxt?.length > 0 && !ddata?.supplementAllergiesTxt && <span className='error'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{errors.SupplementAllergiesTxt}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <div className='d-flex justify-content-between align-items-center label-wrapper'>
                                                                        <label className="col-form-label">Recent Diagnostic and Laboratory Test Results
                                                                            Please list any and provide copies of relevant reports
                                                                        </label>
                                                                        <Button className="btn btn-primary btn-sm customize-btn" onClick={this.addRecentDiagnostic.bind(this)}>Add</Button>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        {RecentDiagnostic?.length > 0 ? (
                                                                            RecentDiagnostic
                                                                                .map((ddata, i) => {
                                                                                    return (
                                                                                        <div className="form-group row my-4">
                                                                                            <div className="col-md-12">
                                                                                                <div className="col d-flex align-items-center">
                                                                                                    <h5>{i + 1}.)</h5>
                                                                                                    {/* </div>
                                                                                                    <div className="col-md-11"> */}
                                                                                                    <Input type="text" className="mx-2 my-0" placeholder="Enter Recent Diagnostic" name="RecentDiagnosticTxt" value={ddata?.recentDiagnosticTxt} onChange={e => this.handleInputChangeRecentDiagnostic(e, i, 'RecentDiagnosticTxt')} />
                                                                                                    <Button color="danger" outline onClick={e => this.toggle(e, i, "RecentDiagnosticTxt", ddata?.id)}>Delete</Button>
                                                                                                </div>
                                                                                                {errors.RecentDiagnosticTxt?.length > 0 && !ddata?.recentDiagnosticTxt && <span className='error'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{errors.RecentDiagnosticTxt}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='photographic-wrapper'>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-12'>
                                                                    <h4>PHOTOGRAPHIC CONSENT</h4>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className='col-md-6'>
                                                                    <label className="col-12 col-form-label">I give consent to be photographed for the purpose of medical records<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-radio-inline'>
                                                                            {PhotographicConsent == 'true' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" checked onChange={this.handleInputChange.bind(this)} id="chkPhotoYes" name="PhotographicConsent" tabIndex="63" /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" onChange={this.handleInputChange.bind(this)} id="chkPhotoYes" name="PhotographicConsent" tabIndex="63" /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {PhotographicConsent == 'false' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" checked onChange={this.handleInputChange.bind(this)} id="chkPhotoNo" name="PhotographicConsent" tabIndex="64" /> No
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" onChange={this.handleInputChange.bind(this)} id="chkPhotoNo" name="PhotographicConsent" tabIndex="64" /> No
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                        {!PhotographicConsent && <span className='error'>{errors.PhotographicConsent}</span>}
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <label className="col-12 col-form-label">I give the consent to be anonymously photographed for marketing and/or publication<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                    <div className='col-12'>
                                                                        <div className='kt-radio-inline'>
                                                                            {Marketing == 'true' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" checked onChange={this.handleInputChange.bind(this)} id="chkMarketing" name="Marketing" tabIndex="65" /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="Yes" onChange={this.handleInputChange.bind(this)} id="chkMarketing" name="Marketing" tabIndex="65" /> Yes
                                                                                    <span></span>
                                                                                </label>
                                                                            }

                                                                            {Marketing == 'false' ?
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" checked onChange={this.handleInputChange.bind(this)} id="chkMarketing" name="Marketing" tabIndex="66" /> No
                                                                                    <span></span>
                                                                                </label>
                                                                                :
                                                                                <label className="kt-radio">
                                                                                    <input type="radio" value="No" onChange={this.handleInputChange.bind(this)} id="chkMarketing" name="Marketing" tabIndex="66" /> No
                                                                                    <span></span>
                                                                                </label>
                                                                            }
                                                                        </div>
                                                                        {!Marketing && <span className='error'>{errors.Marketing}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                            </div>
                                                            <div className='form-group row my-4'>
                                                                <div className="col-md-6">
                                                                    <label className="col-12 col-form-label">Patient Signature</label>
                                                                    <div className="col-12">
                                                                        {/* <i className="flaticon-attachment text-success icon-lg" title='Upload Signature' /> */}
                                                                        <Input type="file" className="form-control" id="File" name="signature" onChange={this.handleFileInputChange.bind(this)}></Input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="col-12 col-form-label">Date:</label>
                                                                    <div className="col-12">
                                                                        {/* <Input type="date" max="9999-12-31" name="Date" tabIndex="4" min="1000-01-01" className="form-control" value={Date} onChange={this.handleInputChange.bind(this)} placeholder="" /> */}
                                                                        <DatePicker
                                                                            selected={Date}
                                                                            onChange={this.handleDateChange.bind(this)}
                                                                            dateFormat="MM/dd/yyyy"
                                                                            placeholderText="mm/dd/yyyy"
                                                                            className="form-control"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                        />
                                                                        {/* {errors.DateOfBirth.length > 0 && <span className='error'>{errors.DateOfBirth}</span>} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* <div className='form-group row my-4'>
                                                            <div className='col-md-12'>
                                                                <h4>VibrantHealthX contact info</h4>
                                                            </div>
                                                        </div>

                                                        <div className='form-group row my-4'>
                                                            <label className="col-12 col-form-label">Email: office@neo7logix.com</label>
                                                        </div> */}

                                                        <div className='mt-4'>
                                                            <Button type="submit" color="primary">
                                                                {/* <i className="fa fa-dot-circle-o"></i> */}
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {this.loading()}
                    {modal &&
                        <div style={{ marginLeft: "36%" }}>
                            <Modal isOpen={modal} style={{ width: "500px" }} >
                                <ModalHeader>Confirm</ModalHeader>
                                <ModalBody>Are you sure want to delete this data?</ModalBody>
                                <ModalFooter>
                                    <Button outline color="danger" onClick={e => this.deleteRow(e, deleteRowIndex, deleteName, deleteId)}>Delete</Button>
                                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    }
                </div>
            </React.Fragment>
        )


    }


}