import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, Form, NavItem, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../style-new.css'
import '../../custom.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../CustomModal/CustomModal';

export class Register extends Component {
    static displayName = Register.name;

    constructor(props) {
        super(props);


        this.state = {
            RegisterType: 'Patient',
            Email: '',
            UserName: '',
            Password: '',
            ConfirmPassword: '',
            FirstName: '',
            MiddleName: '',
            LastName: '',
            loading: false,
            // authError: false,
            error: '',
            errorType: '',
            redirect: false,
            errors: {
                Email: '',
                UserName: '',
                Password: '',
                ConfirmPassword: '',
                FirstName: '',
                MiddleName: '',
                LastName: '',
                RegisterType: ''
            },
        };

        //this.handleRegisterTypeChange = this.handleRegisterTypeChange.bind(this);
        //this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        //this.handleMiddleNameChange = this.handleMiddleNameChange.bind(this);
        //this.handleLastNameChange = this.handleLastNameChange.bind(this);
        //this.handleEmailChange = this.handleEmailChange.bind(this);
        //this.handleUserNameChange = this.handleUserNameChange.bind(this);
        //this.handlePwdChange = this.handlePwdChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
    }

    //handleRegisterTypeChange(e) {
    //    this.setState({ RegisterType: e.target.value });
    //}

    //handleFirstNameChange(e) {
    //    this.setState({ FirstName: e.target.value });
    //}
    //handleMiddleNameChange(e) {
    //    this.setState({ MiddleName: e.target.value });
    //}
    //handleLastNameChange(e) {
    //    this.setState({ LastName: e.target.value });
    //}
    //handleEmailChange(e) {
    //    this.setState({ Email: e.target.value });
    //}

    //handleUserNameChange(e) {
    //    this.setState({ UserName: e.target.value });
    //}

    //handlePwdChange(e) {
    //    this.setState({ Password: e.target.value });
    //}

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/confirmation');
    //    }
    //}

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.RegisterType == undefined || this.state.RegisterType == '') {
            errors.RegisterType = 'Please select register type.';
        }
        //if (this.state.UserName == undefined || this.state.UserName == '') {
        //    errors.UserName = 'Please enter username.';
        //}
        if (this.state.Email == undefined || this.state.Email == '') {
            errors.Email = 'Please enter email.';
        }

        if (this.state.RegisterType == "Patient" || this.state.RegisterType == "Practitioner") {
            if (this.state.FirstName == undefined || this.state.FirstName == '') {
                errors.FirstName = 'Please enter firstname.';
            }
            if (this.state.LastName == undefined || this.state.LastName == '') {
                errors.LastName = 'Please enter lastname.';
            }
        } else if (this.state.RegisterType == "Institution") {
            if (this.state.FirstName == undefined || this.state.FirstName == '') {
                errors.FirstName = 'Please enter institute name.';
            }
        }
        else if (this.state.RegisterType == "NeoLab") {
            if (this.state.FirstName == undefined || this.state.FirstName == '') {
                errors.FirstName = 'Please enter lab name.';
            }
        }
        else {
            if (this.state.FirstName == undefined || this.state.FirstName == '') {
                errors.FirstName = 'Please enter company name.';
            }
        }
        //if (this.state.Password == undefined || this.state.Password == '') {
        //    errors.Password = 'Please enter password.';
        //}
        //if (this.state.ConfirmPassword == undefined || this.state.ConfirmPassword == '') {
        //    errors.ConfirmPassword = 'Please enter confirm password.';
        //}
        //else {
        //    const Password = this.state.Password;
        //    const CPassword = this.state.ConfirmPassword;

        //    if (Password != CPassword) {
        //        errors.ConfirmPassword = 'Password and confirm password are not same.';
        //    }
        //}

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    //input handle input change and validation
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        if (name == "RegisterType") {
            this.state.errors.FirstName = '';
            this.state.errors.LastName = '';
            this.state.errors.Email = '';
        }
        let errors = this.state.errors;

        switch (name) {
            //case 'UserName':
            //    errors.UserName = (!value) ? 'Please enter username.' : ''
            //    break;
            case 'Email':
                errors.Email = (!value) ? 'Please enter email.' : ''
                break;
            //case 'Password':
            //    errors.Password = (!value) ? 'Please enter password.' : ''
            //    break;
            //case 'ConfirmPassword':
            //    errors.ConfirmPassword = (!value) ? 'Please enter confirm password.' : ''
            //    break;
            case 'FirstName':
                if (this.state.RegisterType == "Patient" || this.state.RegisterType == "Practitioner") {
                    errors.FirstName = (!value) ? 'Please enter firstname.' : ''
                }
                else if (this.state.RegisterType == "Institution") {
                    errors.FirstName = (!value) ? 'Please enter institute name.' : ''
                }
                else if (this.state.RegisterType == "NeoLab") {
                    errors.FirstName = (!value) ? 'Please enter lab name.' : ''
                }
                else {
                    errors.FirstName = (!value) ? 'Please enter company name.' : ''
                }
                break;
            case 'LastName':
                errors.LastName = (!value) ? 'Please enter lastename.' : ''
                break;
            case 'MiddleName':
                errors.MiddleName = (!value) ? 'Please enter middlename.' : ''
                break;
            case 'RegisterType':
                errors.RegisterType = (!value) ? 'Please select register type.' : ''
                break;
            default:
                //(!value) ? '' :'This field is required.'
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            //console.log(errors)

        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            const url = apiroute + '/api/CognitoUserStore/register';

            const RegisterType = this.state.RegisterType;
            const FirstName = this.state.FirstName;
            const MiddleName = this.state.MiddleName;
            const LastName = this.state.LastName;

            const Email = this.state.Email;
            const UserName = this.state.Email;
            //const Password = this.state.Password;
            //const CPassword = this.state.ConfirmPassword;

            //if (Password == CPassword) {
            let data = JSON.stringify({
                RegisterType: RegisterType,
                FirstName: FirstName,
                MiddleName: MiddleName,
                LastName: LastName,
                UserName: UserName.toLowerCase(),
                Email: Email.toLowerCase(),
                //UserName: UserName,
                //Email: Email,
                Password: '@NeoTempUser12345'
                //Password: Password,
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                    //'Access-Control-Allow-Origin': '*',
                    //'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                    //'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        //this.setState({
                        //    modal: !this.state.modal,
                        //    modalTitle: 'Success',
                        //    modalBody: result.data.message,
                        //    redirect: true,
                        //    loading: false
                        //});
                        this.setState({
                            RegisterType: 'Patient',
                            Email: '',
                            UserName: '',
                            Password: '',
                            ConfirmPassword: '',
                            FirstName: '',
                            MiddleName: '',
                            LastName: '',
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false
                        }, () => {
                            this.setState({
                                RegisterType: 'Patient',
                                Email: '',
                                UserName: '',
                                Password: '',
                                ConfirmPassword: '',
                                FirstName: '',
                                MiddleName: '',
                                LastName: '',
                            })
                        });
                        toast.success(result.data.message)
                        //setTimeout(() => this.setState({ redirect: true }), 2000);

                    } else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({ 
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false });
                        toast.error(error.message)
                });
            // }
            //else {
            //    this.setState({
            //        modal: !this.state.modal,
            //        modalTitle: 'Error',
            //        modalBody: "Password and confirm password are not same.",
            //        loading: false
            //    });
            //    }
            //}
        }
        else {
            this.setState({ loading: false });
        }

    }

    //renderRedirect() {
    //    if (this.state.redirect) {
    //        return <Redirect from="/" to={"/confirmation/" + this.state.Email + ""} />
    //    }
    //}
    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/login" />
        }
    }

    //loading() {
    //    if (this.state.redirect) {
    //        return <div className="animated fadeIn pt-1 text-center">Loading...</div>
    //    }
    //}

    render() {

        const { loading, RegisterType, FirstName, MiddleName, LastName, UserName, Email, Password, ConfirmPassword, authError, error, errorType, redirect, errors } = this.state;

        return (
            <div className="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--transparent kt-aside--enabled kt-aside--fixed kt-page--loading"  >


                <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
                    <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed left-0">
                        <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand" kt-hidden-height="65">
                            <div className="kt-aside__brand-logo">
                                <Link to="/" >  <img alt="Logo" src="./assets/media/logos/logo-small.png" className="width-40" /></Link>
                            </div>
                        </div>
                        <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper opacity-1">
                            <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
                            </div>
                        </div>

                        {/*<div className="kt-header__topbar">
                            <div className="kt-header__topbar-item kt-header__topbar-item--user logo-menu">
                                <div className="kt-header__topbar-wrapper">
                                    <div className="kt-header__topbar-user">
                                        <Link to="/Services" >Services</Link>
                                    </div>
                                </div>
                                <div className="kt-header__topbar-wrapper">
                                    <div className="kt-header__topbar-user">
                                        <Link to="/HealthcareProfessional" >Healthcare Professionals</Link>
                                    </div>
                                </div>
                                <div className="kt-header__topbar-wrapper"> 
                                    <div className="kt-header__topbar-user">
                                        <Link to="/Caregivers" >Patients and Caregivers</Link>
                                    </div>
                                </div>
                            </div>
            </div> */}

                    </div>
                    <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v5 kt-login--signin" id="kt_login">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile" style={{ backgroundImage: 'url(./assets/media/bg/bg-3.jpg)' }}>
                            <div className="kt-login__left">
                                <div className="kt-login__wrapper">
                                    <div className="kt-login__content">
                                        <Link to="/" className="kt-login__logo"> <img src="./assets/media/logos/logo-small.png" /></Link>

                                        <h3 className="kt-login__title">JOIN OUR NEO7LOGIX PORTAL</h3>
                                        {/*<div className="form-group">
                                            <label htmlFor="exampleSelectd">Click Here to Get Full Prescribing Information</label>
                                            <select className="form-control" id="exampleSelectd">
                                                <option>Select Prodcut</option>
                                                <option>PBIMA - PES</option>
                                                <option>SOLVx-COVID-19</option>
                                            </select>
                                        </div>*/}
                        <ul className="location-link">
                            <li className="item">
                                <i className="fa fa-map-marker"></i>
                                <span style={{ "color": "#000", "fontWeight": "400" }}>539 W. Commerce St #2886 Dallas, TX 75208</span>
                            </li>
                            {/*<li className="item">
                                                <i className="fa fa-clock" aria-hidden="true"></i>
                                                <span>
                                                    <p>Monday - Friday</p>
                                                    <p>09.00AM to 08:00PM</p>
                                                </span>
                                            </li>*/}
                            <li className="item">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                <span><a href="mailto:office@neo7logix.com">office@neo7logix.com</a></span>
                            </li>
                            <li className="item">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                                <span style={{ "color": "#000", "fontWeight": "400" }}><a href="tel:+4696197252">+‪(469) 619-7252</a></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="kt-login__divider"><div></div></div>

            <div className="kt-login__right">
                <div className="kt-login__wrapper">
                    <div className="kt-login__signin" style={{ "marginTop": "60px" }}>
                        <div className="kt-login__head">
                            <h3 className="kt-login__title">Register Your Account Here!</h3>
                        </div>
                        <div className="kt-login__form">
                            <Form className="kt-form" onSubmit={this.handleSubmit.bind(this)}>
                                {/* {authError ?
                                    <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                        <div className="alert-text">{error}</div>
                                        <div className="alert-close">
                                            <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                        </div>
                                    </div>
                                    : null} */}
                                <div className="form-group mb-3">
                                    <select id="RegisterType" className="form-control" tabIndex="1" value={RegisterType} name="RegisterType" onChange={this.handleInputChange.bind(this)}>
                                        <option value="">Select Register Type</option>
                                        <option value="Patient">Patient</option>
                                        <option value="Practitioner">Practitioner</option>
                                        <option value="Institution">Institution</option>
                                        {/*<option value="NeoLab">Laboratory</option>
                                                        <option value="Manufacturer">Manufacturer</option>*/}
                                    </select>
                                    {errors.RegisterType.length > 0 && <span className='error'>{errors.RegisterType}</span>}
                                </div>
                                {RegisterType == "Patient" || RegisterType == "Practitioner" ?
                                    (
                                        <div>
                                            <div className="form-group mb-4">
                                                <Input type="text" tabIndex="2" maxLength="100" value={FirstName} placeholder="Enter your first name" name="FirstName" onChange={this.handleInputChange.bind(this)} autoComplete="FirstName" />
                                                {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>}
                                            </div>
                                            <div className="form-group">
                                                <Input className="mb-4" type="text" tabIndex="3" value={MiddleName} maxLength="100" placeholder="Enter your middle name" name="MiddleName" onChange={this.handleInputChange.bind(this)} autoComplete="MiddleName" />
                                            </div>
                                            <div className="form-group mb-4">
                                                <Input type="text" tabIndex="4" maxLength="100" value={LastName} placeholder="Enter your last name" name="LastName" onChange={this.handleInputChange.bind(this)} autoComplete="LastName" />
                                                {errors.LastName.length > 0 && <span className='error'>{errors.LastName}</span>}
                                            </div>
                                        </div>
                                    )
                                    :
                                    RegisterType == "Institution" ?
                                        (
                                            <div>
                                                <div className="form-group mb-4">
                                                    <Input type="text" tabIndex="2" maxLength="200" value={FirstName} placeholder="Enter your institute name" name="FirstName" onChange={this.handleInputChange.bind(this)} autoComplete="FirstName" />
                                                    {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>}
                                                </div>
                                            </div>
                                        )
                                        :
                                        RegisterType == "NeoLab" ?
                                            (<div>
                                                <div className="form-group mb-4">
                                                    <Input type="text" tabIndex="2" maxLength="200" value={FirstName} placeholder="Enter your lab name" name="FirstName" onChange={this.handleInputChange.bind(this)} autoComplete="FirstName" />
                                                    {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>}
                                                </div>
                                            </div>
                                            ) : RegisterType == "Manufacturer" ?
                                                (
                                                    <div>
                                                        <div className="form-group mb-4">
                                                            <Input type="text" tabIndex="2" maxLength="200" value={FirstName} placeholder="Enter your company name" name="FirstName" onChange={this.handleInputChange.bind(this)} autoComplete="FirstName" />
                                                            {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>}
                                                        </div>
                                                    </div>
                                                ) : (null)
                                }
                                {/*<div className="form-group mb-4">
                                                    <Input type="text" tabIndex="5" maxLength="100" placeholder="Enter your user name" name="UserName" onChange={this.handleInputChange.bind(this)} autoComplete="UserName" />
                                                    {errors.UserName.length > 0 && <span className='error'>{errors.UserName}</span>}
                                                </div>*/}
                                <div className="form-group mb-4">
                                    <Input type="text" name="Email" maxLength="100" tabIndex="5" value={Email} placeholder="Enter a valid email address" onChange={this.handleInputChange.bind(this)} autoComplete="Email" />
                                    {errors.Email.length > 0 && <span className='error'>{errors.Email}</span>}
                                </div>
                                {/*<div className="form-group mb-4">
                                                    <Input type="password" placeholder="Enter password" minLength="6" maxLength="99" tabIndex="7" name="Password" onChange={this.handleInputChange.bind(this)} autoComplete="current-password" />
                                                    {errors.Password.length > 0 && <span className='error'>{errors.Password}</span>}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <Input type="password" name="ConfirmPassword" minLength="7" maxLength="99" tabIndex="8" placeholder="Enter confirm password" onChange={this.handleInputChange.bind(this)} />
                                                    {errors.ConfirmPassword.length > 0 && <span className='error'>{errors.ConfirmPassword}</span>}
                                                </div>*/}
                                <div className="kt-login__actions">
                                    {loading ?
                                        <button tabIndex="8" type="submit" className="btn btn-brand btn-elevate kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Register</button>
                                        :
                                        <button tabIndex="8" type="submit" className="btn btn-brand btn-elevate">Register</button>
                                    }
                                    {/*btn-pill */}
                                </div>
                            </Form>
                            <div className="kt-login__actions">
                                <label htmlFor=""><p className="font-weight-bold">Already have an account?
                                                <Link to="/login" className="text-blue"> Login</Link>
                                </p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                        </div >
                    </div >
                </div >
            { this.renderRedirect() }
            </div >
        );
    }
}
