import React, { Component } from 'react';
import { Collapse, Container, Table, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
//import MyModal from './../../CustomModal/CustomModal';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import FilePreview from "react-file-preview-latest";
import downloadIcon from '../../../assets/svg/download.svg';
import closeIcon from '../../../assets/svg/x.svg';

export class ManufacturerPatient extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            patientId: 0,
            basicInfo: "",
            assignedpractitioner: "",
            diseases: "",
            diagnosticHistory: [],
            emergencyContact: [],
            insuranceDetail: [],
            prescription: [],
            treatmentReport: [],
            assignedlaboratory: "",
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            preview: false,
            url: "",
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.getData();
    //    }
    //}

    componentDidMount() {
        const param = this.props.match.params;

        if (param.id != undefined) {
            this.getData(param.id);
        }
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/Patient/getPatientsInfobyId?id=' + id + '';

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                console.log(result);
                if (result.data.flag) {
                    var rData = result.data.outdata;
                    this.setState({
                        basicInfo: rData,
                        patientId: rData.patientId,
                        assignedpractitioner: rData.practitionerPatient,
                        assignedlaboratory: rData.laboratoryPatient,
                        diseases: rData.patientDisease[0],
                        diagnosticHistory: rData.patientDiagnosticHistory,
                        emergencyContact: rData.patientEmergencyContacts,
                        insuranceDetail: rData.patientInsuranceDetails,
                        prescription: rData.patientPrescription,
                        treatmentReport: rData.patientTreatmentReport,
                        loading: false
                    });
                    console.log(this.state);
                }
                else {
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    toggleExpander = (e) => {
        const hiddenElement = e.currentTarget.nextSibling;
        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    }

    //file preview
    previewToggle (e, file) {
        this.setState({
        preview: !this.state.preview,
        url: file
        })
    }

    onError = (err) => console.log("Error:", err); // Write your own logic

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, patientId, assignedlaboratory, assignedpractitioner,
            diseases, diagnosticHistory, emergencyContact, insuranceDetail,
            prescription, treatmentReport, basicInfo, url, preview,
            error, errorType, authError } = this.state;

        return (

            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                            <Link to="/manufacturer/dashboard" className="kt-subheader__title">
                                                Dashboard                        
                                            </Link>
                           </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="#" className="kt-subheader__breadcrumbs-link">
                                                    Patient Detail                        </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <div className="kt-widget kt-widget--user-profile-3">
                                                <div className="kt-widget__top">
                                                    {/*<div className="kt-widget__media">
                                                        <img src="./assets/media/users/100_1.jpg" alt="image" />
                                                    </div>
                                                    */}
                                                    <div className="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-bolder kt-font-light kt-hidden">JM</div>
                                                    <div className="kt-widget__content">
                                                        <div className="kt-widget__head">
                                                            <div className="kt-widget__user"> <Link className="kt-widget__username">{basicInfo.firstName != null ? (basicInfo.firstName + " " + basicInfo.lastName) : ""}{basicInfo.accessionNo != null ? " [" + basicInfo.accessionNo.replace(/-/g, "") + "]" : ""}</Link>
                                                                <span className="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success">{basicInfo.sex == "M" ? "Male" : basicInfo.sex == "F" ? "Female" : "-"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="kt-widget__subhead"> <Link><i className="flaticon2-new-email"></i>{basicInfo.email != null ? basicInfo.email : ""}</Link>
                                                            <Link><i className="flaticon2-calendar"></i>
                                                                {
                                                                    basicInfo.dateOfBirth != null && basicInfo.dateOfBirth != "" ?
                                                                        <React.Fragment>
                                                                            <Moment format="DD MMM YYYY">
                                                                                {basicInfo.dateOfBirth}
                                                                            </Moment>
                                                                            <span>{"(" + basicInfo.age + ")"}</span>
                                                                        </React.Fragment>
                                                                        : "NA"
                                                                }</Link>
                                                            <Link><i className="flaticon2-phone"></i>{basicInfo.mobile != null ? basicInfo.mobile + (basicInfo.phoneNumber != null ? ", " + basicInfo.phoneNumber : ""): "NA"}</Link>
                                                        </div>
                                                        <div className="kt-widget__info">
                                                            <div className="kt-widget__desc">
                                                                <i className="flaticon2-placeholder">{basicInfo.address != null ? basicInfo.address + " - " + basicInfo.postalCode : "NA"}</i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*</div>*/}
                                        <div className="row">
                                            <div className="col-xl-4">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Basic Details</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        {/* {authError ?
                                                            <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                                <div className="alert-text">{error}</div>
                                                                <div className="alert-close">
                                                                    <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                                </div>
                                                            </div>
                                                            : null} */}
                                                        <div className="kt-portlet__body">
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">First Name:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.firstName != null ? (basicInfo.firstName) : ""}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Middle Name:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.middleName != null ? (basicInfo.middleName) : ""}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Last Name:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.lastName != null ? (basicInfo.lastName) : ""}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Email:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.email != null ? (basicInfo.email) : ""}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Date of Birth:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">
                                                                        {basicInfo.dateOfBirth != null && basicInfo.dateOfBirth != "" ?
                                                                            <React.Fragment>
                                                                                <Moment format="DD MMM YYYY">
                                                                                    {basicInfo.dateOfBirth}
                                                                                </Moment>
                                                                                <span>{"(" + basicInfo.age + ")"}</span>
                                                                            </React.Fragment>
                                                                            : "NA"
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Sex:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.sex == "M" ? "Male" : basicInfo.sex == "F" ? "Female" : "-"}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Primary Phone:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.mobile != null ? (basicInfo.mobile) : ""}</span>
                                                                </div>
                                                            </div>

                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Secondary Phone:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.phoneNumber != null ? (basicInfo.phoneNumber) : ""}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Address:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.address != null ? basicInfo.address + " - " + basicInfo.postalCode : "NA"}</span>
                                                                </div>
                                                            </div>

                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">Country:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.country != null ? (basicInfo.country) : ""}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">State:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.state != null ? (basicInfo.state) : ""}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-group form-group-xs row">
                                                                <label className="col-4 col-form-label">City:</label>
                                                                <div className="col-8">
                                                                    <span className="form-control-plaintext kt-font-bolder">{basicInfo.city != null ? (basicInfo.city) : ""}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Practioner</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        <div className="kt-portlet__body">
                                                            {assignedpractitioner != "" && assignedpractitioner != null ?
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <label className="col-form-label">Name:</label>
                                                                        <span className="form-control-plaintext kt-font-bolder">{assignedpractitioner.firstName + " " + assignedpractitioner.middleName + " " + assignedpractitioner.lastName}</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Laboratory</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        <div className="kt-portlet__body">

                                                            {assignedlaboratory != "" && assignedlaboratory != null ?
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <label className="col-form-label">Name</label>
                                                                        <span className="form-control-plaintext kt-font-bolder">{assignedlaboratory.ngsLaboratoryName}</span>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label className="col-form-label">Email</label>
                                                                        <span className="form-control-plaintext kt-font-bolder">{assignedlaboratory.ngsLaboratoryEmail}</span>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label className="col-form-label">Phone</label>
                                                                        <span className="form-control-plaintext kt-font-bolder">{assignedlaboratory.ngsLaboratoryPhone}</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <span className="form-control-plaintext kt-font-bolder">Not Assigned</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-label">
                                                            <h3 className="kt-portlet__head-title">
                                                                Disease</h3>
                                                        </div>
                                                    </div>
                                                    <div className="kt-form kt-form--label-right">
                                                        <div className="kt-portlet__body">
                                                            {diseases != "" && diseases != null ?
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <span className="form-control-plaintext kt-font-bolder">{diseases.diseaseName + " (" + diseases.diseaseCode + ")"}</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="form-group form-group-xs row">
                                                                    <div className="col-4">
                                                                        <span className="form-control-plaintext kt-font-bolder">Data Not Available</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-8">
                                                <div className="kt-portlet kt-portlet--tabs">
                                                    <div className="kt-portlet__head">
                                                        <div className="kt-portlet__head-toolbar">
                                                            <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand" role="tablist">
                                                                <li className="nav-item">
                                                                    <a className="nav-link active" data-toggle="tab" href="#kt_apps_contacts_view_tab_1" role="tab">Diagnostics</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_2" role="tab">Medication</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_3" role="tab">Treatments</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_4" role="tab">Emergency Contacts</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" data-toggle="tab" href="#kt_apps_contacts_view_tab_5" role="tab">Insurances</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="kt-portlet__body">
                                                        <div className="tab-content">
                                                            <div className="tab-pane active" id="kt_apps_contacts_view_tab_1" role="tabpanel">
                                                                <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                    <div className="kt-notes__items">
                                                                        {diagnosticHistory.length > 0 ? (
                                                                            diagnosticHistory
                                                                                .map((data, i) => {
                                                                                    return (
                                                                                        <div className="kt-notes__item">
                                                                                            <div className="kt-notes__media">
                                                                                                <span className="kt-notes__icon">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                                                            <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fillRule="nonzero"></path>
                                                                                                            <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "></path>
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="kt-notes__content">
                                                                                                <div className="date-info">
                                                                                                    <span className="kt-notes__desc">
                                                                                                        <Moment format="DD MMM YYYY">
                                                                                                            {data.diagnosticDate}
                                                                                                        </Moment>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="kt-notes__section">

                                                                                                    {/*<div className="kt-notes__info"> <p className="kt-notes__title">MRI
																		<br />Lumbo Sacral Spine<br />Findings</p>
                                                                                    </div>*/}
                                                                                                </div> <span className="kt-notes__body">
                                                                                                    {data.diagnosticAnalysis}
                                                                                                </span>
                                                                                                <div className="pt-2 note-dtails-steps"><span className="kt-widget1__number kt-font-danger">Outcome :  {data.outcome}</span></div>
                                                                                                <div className="kt-widget4">
                                                                                                    <br />
                                                                                                    {data.patientDiagnosticHistoryFile.length > 0 ? (
                                                                                                        data.patientDiagnosticHistoryFile
                                                                                                            .map((dataFile, i) => {
                                                                                                                return (
                                                                                                                    <div className="kt-widget4__item" key={dataFile.patientDiagnosticFileId} onClick={e => this.previewToggle(e, dataFile.filePath)}>
                                                                                                                        <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                                                            <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />
                                                                                                                        </div>
                                                                                                                        <a className="kt-widget4__title">
                                                                                                                            {dataFile.fileName}
                                                                                                                        </a>
                                                                                                                        {/*<div className="kt-widget4__tools">
                                                                                                                                <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">
                                                                                                                                    <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>
                                                                                                                                </a>
                                                                                                                            </div>*/}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })) : ("No files...")}
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    )
                                                                                })) : (
                                                                                <span className="kt-notes__body">No diagnostics found.</span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_2" role="tabpanel">
                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                    <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Prescription Date</th>
                                                                                <th colSpan="2">Description</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {prescription.length > 0 ? (
                                                                                prescription
                                                                                    .map((data, i) => {
                                                                                        return (
                                                                                            <React.Fragment>
                                                                                                <tr key={i} onClick={this.toggleExpander}>
                                                                                                    <td><Moment format="DD MMM YYYY">{data.prescribeDate}</Moment></td>
                                                                                                    <td>{data.prescriptionDescription}</td>
                                                                                                    <td className="tblexpand">
                                                                                                        {/* <img style={{ "width": "80%" }} src="./assets/media/icons/svg/Files/direct-download.svg" alt="" title="View Files" /> */}
                                                                                                        <svg id="color" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.456-.477-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .998.791.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z" fill="#00bcd4"/>
                                                                                                            <path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z" fill="#607d8b"/>
                                                                                                            <path d="m12 2h-1.25c-.689 0-1.25.561-1.25 1.25v5.75h-2.75c-.659 0-.998.791-.542 1.268l5.25 5.5c.141.148.337.232.542.232z" fill="#00a4b9"/>
                                                                                                            <path d="m12 18h-10.25c-.965 0-1.75.785-1.75 1.75v.5c0 .965.785 1.75 1.75 1.75h10.25z" fill="#546d79"/>
                                                                                                        </svg>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr className="collapse" key={data.patientPrescriptionId}>
                                                                                                    <td className="kt-widget4" colSpan="3">
                                                                                                        {data.patientPrescriptionFile.length > 0 ? (
                                                                                                            data.patientPrescriptionFile
                                                                                                                .map((dataFile, i) => {
                                                                                                                    return (
                                                                                                                        <div className="kt-widget4__item" key={dataFile.patientPrescriptionFileId} onClick={e => this.previewToggle(e, dataFile.filePath)}>
                                                                                                                            <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                                                                <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />
                                                                                                                            </div>
                                                                                                                            <a className="kt-widget4__title">
                                                                                                                                {dataFile.fileName}
                                                                                                                            </a>
                                                                                                                            {/*<div className="kt-widget4__tools">
                                                                                                                                <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">
                                                                                                                                    <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>
                                                                                                                                </a>
                                                                                                                            </div>*/}
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })) : ("No files...")}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })) : (
                                                                                    <tr>
                                                                                        <td colSpan="2" className="tdCenter">No prescription found.</td></tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_3" role="tabpanel">
                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                    <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Treatment Date</th>
                                                                                <th>Detail</th>
                                                                                <th colSpan="2">Description</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {treatmentReport.length > 0 ? (
                                                                                treatmentReport
                                                                                    .map((data, i) => {
                                                                                        return (
                                                                                            <React.Fragment>
                                                                                                <tr key={i} onClick={this.toggleExpander}>
                                                                                                    <td><Moment format="DD MMM YYYY">{data.treatmentDate}</Moment></td>
                                                                                                    <td>{data.treatmentDetail}</td>
                                                                                                    <td>{data.teatmentDescription}</td>
                                                                                                    <td className="tblexpand">
                                                                                                        {/* <img style={{ "width": "80%" }} src="./assets/media/icons/svg/Files/direct-download.svg" alt="" title="View Files" /> */}
                                                                                                        <svg id="color" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.456-.477-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .998.791.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z" fill="#00bcd4"/>
                                                                                                            <path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z" fill="#607d8b"/>
                                                                                                            <path d="m12 2h-1.25c-.689 0-1.25.561-1.25 1.25v5.75h-2.75c-.659 0-.998.791-.542 1.268l5.25 5.5c.141.148.337.232.542.232z" fill="#00a4b9"/>
                                                                                                            <path d="m12 18h-10.25c-.965 0-1.75.785-1.75 1.75v.5c0 .965.785 1.75 1.75 1.75h10.25z" fill="#546d79"/>
                                                                                                        </svg>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr className="collapse" key={data.patientTreatmentReportId}>
                                                                                                    <td className="kt-widget4" colSpan="4">
                                                                                                        {data.patientTreatmentReportFile.length > 0 ? (
                                                                                                            data.patientTreatmentReportFile
                                                                                                                .map((dataFile, i) => {
                                                                                                                    return (
                                                                                                                        <div className="kt-widget4__item" key={dataFile.patientTreatmentreportFileId} onClick={e => this.previewToggle(e, dataFile.filePath)}>
                                                                                                                            <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                                                                <img src="./assets/media/icons/svg/Files/Download.svg" alt="" />
                                                                                                                            </div>
                                                                                                                            <a className="kt-widget4__title">
                                                                                                                                {dataFile.fileName}
                                                                                                                            </a>
                                                                                                                            {/*<div className="kt-widget4__tools">
                                                                                                                                <a href={dataFile.filePath} className="btn btn-clean btn-icon btn-sm">
                                                                                                                                    <i className="flaticon2-download-symbol-of-down-arrow-in-a-rectangle"></i>
                                                                                                                                </a>
                                                                                                                            </div>*/}
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })) : ("No files...")}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })) : (
                                                                                    <tr>
                                                                                        <td colSpan="3" className="tdCenter">No treatment found.</td></tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_4" role="tabpanel">
                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                    <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Email</th>
                                                                                <th>Phone</th>
                                                                                <th>Address</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {emergencyContact.length > 0 ? (
                                                                                emergencyContact
                                                                                    .map((data, i) => {
                                                                                        return (<tr key={i}>
                                                                                            <td>{data.firstName + " " + data.lastName}</td>
                                                                                            <td>{data.email}</td>
                                                                                            <td>{data.mobile} <br />{data.phoneNumber}</td>
                                                                                            <td>{data.address + " - " + data.postalCode}</td>
                                                                                        </tr>
                                                                                        )
                                                                                    })) : (
                                                                                    <tr>
                                                                                        <td colSpan="4" className="tdCenter">No emergency contacts found.</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane" id="kt_apps_contacts_view_tab_5" role="tabpanel">
                                                                <div className="kt-notes kt-scroll kt-scroll--pull" data-scroll="true" style={{ "height": "650px" }}>
                                                                    <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Insurance Company</th>
                                                                                <th>Amount</th>
                                                                                <th>Policy Number</th>
                                                                                <th>Expiry Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {insuranceDetail.length > 0 ? (
                                                                                insuranceDetail
                                                                                    .map((data, i) => {
                                                                                        return (<tr key={i}>
                                                                                            <td>{data.insuranceCompany}</td>
                                                                                            <td>{data.insuranceAmount}</td>
                                                                                            <td>{data.policyNumber}</td>
                                                                                            <td>
                                                                                                {data.expiryDate == null ? "" :
                                                                                                    <Moment format="DD MMM YYYY">{data.expiryDate}</Moment>
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                        )
                                                                                    })) : (
                                                                                    <tr>
                                                                                        <td colSpan="4" className="tdCenter">No insurance detail found.</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    {this.loading()}
                </div>
                {preview &&
                    <>
                        <div className='preview-popup'>
                            <div className='preview-popup-modal'>
                                <div className='preview-popup-header'>
                                    {url.split(".").splice(-1)[0] === "pdf" ? null : 
                                        <a href={url} download target={`_blank`}>
                                            <img src={downloadIcon} style={{ margin:"0 12px", cursor: "pointer" }} alt='download' />
                                        </a>
                                    }
                                    <img src={closeIcon} style={{ cursor: "pointer" }} alt='close' onClick={e => this.previewToggle(e, "")} />
                                </div>
                                <iframe src={url} title="previewFile" width="100%" height="90%" />
                            </div>
                        </div>
                    </>
                }
            </React.Fragment >
        );
    }
}
