import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

export class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uToken: JSON.parse(localStorage.getItem('Usertoken'))?.userType,
            // pCurrentStatus: "",
            // loading: false
        };
    }

    // componentDidMount() {
    //     if (this.state.uToken === 2) {
    //         var userToken = JSON.parse(localStorage.getItem('Usertoken'));
    //         let id = 0;
    //         if (userToken != null) {
    //             id = (userToken.userId == null ? 0 : userToken.userId);
    //         }

    //         const apiroute = window.$APIPath;
    //         const url = apiroute + '/api/Practitioner/GetByUserId?id=' + id;

    //         axios.get(url, {
    //             headers: {
    //                 'Content-Type': 'application/json; charset=utf-8'
    //             }
    //         })
    //             .then(result => {
    //                 if (result.data.flag) {
    //                     var rData = result.data.outdata;

    //                     var irb = rData.irbStatus;
    //                     var irbno = rData.irbNo;
    //                     var firstnm = rData.firstName;
    //                     var lastnm = rData.lastName;
    //                     var phnno = rData.phoneNumber;
    //                     var postalcd = rData.residentPostalCode;

    //                     if ((irbno != null && irbno != "") && (firstnm != null && firstnm != "")
    //                         && (lastnm != null && lastnm != "") && (phnno != null && phnno != "")
    //                         && (postalcd != null && postalcd != "")) {

    //                         if (irb != "P" && irb != "R") {
    //                             this.setState({ loading: false });
    //                         } else {
    //                             if (irb == "P") {
    //                                 this.setState({ pCurrentStatus: "Your IRB No is not approved yet!!.." });
    //                             }
    //                             if (irb == "R") {
    //                                 this.getData()
    //                                 this.setState({ pCurrentStatus: "Your IRB number is not valid, please contact Customer Care at 469-619-7252 to register with the IRB’ (Done)!!.."});
    //                             }
    //                         }
    //                     } else {
    //                         this.setState({ pCurrentStatus: "Your profile is not updated. Please provide required details!!.." });
    //                     }

    //                 } else { this.setState({ loading: false }); }
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //                 this.setState({ loading: false });
    //             });

    //     }
    // }

    // getData() {
    //     var userToken = JSON.parse(localStorage.getItem('Usertoken'));
    //     if (userToken != null) {
    //         var irb = userToken.irbStatus;
    //         var irbno = userToken.irbNo;
    //         var firstnm = userToken.firstName;
    //         var lastnm = userToken.lastName;
    //         var phnno = userToken.phoneNumber;
    //         var postalcd = userToken.residentPostalCode;

    //         if ((irbno != null && irbno != "") && (firstnm != null && firstnm != "")
    //             && (lastnm != null && lastnm != "") && (phnno != null && phnno != "")
    //             && (postalcd != null && postalcd != "")) {
    //             if (irb != "P" && irb != "R") {
    //                 this.setState({ loading: false });
    //             } else {
    //                 if (irb == "P") {
    //                     this.setState({ loading: false, pCurrentStatus: "Your IRB No is not approved yet!!.." });
    //                 }
    //                 if (irb == "R") {
    //                     this.setState({ loading: false, pCurrentStatus: "Your IRB No is Rejected!!.." });
    //                 }
    //             }
    //         } else {
    //             this.setState({ loading: false, pCurrentStatus: "Your profile is not updated. Please provide required details!!.." });
    //         }
    //     }
    // }

    render() {
        //   const { pCurrentStatus } = this.state

        return (
            <div>
                <div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
                    <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
                        <div className="kt-aside__brand-logo">
                            <Link to="/" style={{ "textAlign": "center" }}>
                                <img alt="Logo" src="./assets/media/logos/logo-small.png" />
                            </Link>
                        </div>
                        <div className="kt-aside__brand-tools">
                            <button className="kt-aside__brand-aside-toggler" id="kt_aside_toggler"><span></span></button>
                        </div>
                    </div>
                    <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                        <div
                            id="kt_aside_menu"
                            className="kt-aside-menu "
                            data-ktmenu-vertical="1"
                            data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">

                            {/* Patients profile global sidebar */}

                            {this.state.uToken === 1 &&
                                <ul className="kt-menu__nav">
                                    <li className={window.location.pathname.includes("/patient/dashboard") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/patient/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                    </li>
                                    {/* <li className={window.location.pathname.includes("/patient/profile") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/patient/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Patient Information</span></Link>
                                </li> */}
                                    <li className={window.location.pathname.includes("/patient/emergency") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/patient/emergency/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-phone"></i><span className="kt-menu__link-text">Emergency Contact</span></Link>
                                    </li>
                                    <li className={window.location.pathname.includes("/patient/insurance") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/patient/insurance/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-document"></i><span className="kt-menu__link-text">Insurance Information</span></Link>
                                    </li>
                                    {/*<li className={window.location.pathname.includes("/patient/payment/") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/patient/payment/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></Link>
                                </li>*/}
                                    {/* <li className={window.location.pathname.includes("/changepassword") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/changepassword" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-lock"></i><span className="kt-menu__link-text">Change Password</span></Link>
                                </li> */}
                                </ul>
                            }

                            {/* Practioners profile global sidebar */}

                            {this.state.uToken === 2 &&
                                <ul className="kt-menu__nav">
                                    {/* <li className={window.location.pathname.includes("/practitioner/patient/analysisprogress") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/practitioner/patient/analysisprogress" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-chart"></i><span className="kt-menu__link-text">Patient Analysis Progress</span></Link>
                                </li> */}
                                    <li className={(window.location.pathname.includes("/practitioner/dashboard") || window.location.pathname.includes("/practitioner/patient/profile") || window.location.pathname.includes("/practitioner/patient/detail") || window.location.pathname.includes("/practitioner/patient/modify") || window.location.pathname.includes("/practitioner/patient/vibranthealthform")) ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/practitioner/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                    </li>
                                    {/* <li className={window.location.pathname.includes("/practitioner/profile") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/practitioner/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Practitioner Information</span></Link>
                                </li> */}
                                    <li className={window.location.pathname.includes("/practitioner/contact") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/practitioner/contact/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-phone"></i><span className="kt-menu__link-text">Contact Persons</span></Link>
                                    </li>
                                    <li className={window.location.pathname.includes("/practitioner/medical") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/practitioner/medical/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-medical-records"></i><span className="kt-menu__link-text">Medical License</span></Link>
                                    </li>
                                    {/* <li className={window.location.pathname.includes("/practitioner/patient/payment") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><span className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></span>
                                </li>
                                <li className={window.location.pathname.includes("/practitioner/patient/specimencollection") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><span className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-document"></i><span className="kt-menu__link-text">Specimen Collection</span></span>
                                </li> */}
                                    {/* <li className={window.location.pathname.includes("/changepassword") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/changepassword" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-lock"></i><span className="kt-menu__link-text">Change Password</span></Link>
                                </li> */}
                                </ul>
                            }

                            {/* Institute profile global sidebar */}

                            {this.state.uToken === 3 &&
                                <ul className="kt-menu__nav">
                                    <li className={window.location.pathname.includes("/institute/patient/analysisprogress") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/institute/patient/analysisprogress" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-chart"></i><span className="kt-menu__link-text">Patient Analysis Progress</span></Link>
                                    </li>
                                    <li className={(window.location.pathname.includes("/institute/dashboard") || window.location.pathname.includes("/institute/patient/profile") || window.location.pathname.includes("/institute/patient/detail") || window.location.pathname.includes("/institute/patient/modify")) ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/institute/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                    </li>
                                    {/* <li className={window.location.pathname.includes("/institute/profile") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/institute/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Institution Information</span></Link>
                                </li> */}
                                    <li className={window.location.pathname.includes("/institute/contact") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/institute/contact/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-phone"></i><span className="kt-menu__link-text">Contact Persons</span></Link>
                                    </li>
                                    <li className={window.location.pathname.includes("/institute/practitioner") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/institute/practitioner/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user"></i><span className="kt-menu__link-text">Practioners</span></Link>
                                    </li>
                                    <li className={window.location.pathname.includes("/institute/patient/payment") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/institute/patient/payment" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></Link>
                                    </li>
                                    {/* <li className={window.location.pathname.includes("/changepassword") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/changepassword" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-lock"></i><span className="kt-menu__link-text">Change Password</span></Link>
                                </li> */}
                                </ul>
                            }

                            {/* laboratory profile global sidebar */}

                            {this.state.uToken === 4 &&
                                <ul className="kt-menu__nav">
                                    {/* <li className={window.location.pathname.includes("/laboratory/patientanalysisprogress") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/laboratory/patientanalysisprogress" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-chart"></i><span className="kt-menu__link-text">Patient Analysis Progress</span></Link>
                                </li> */}
                                    <li className={(window.location.pathname.includes("/laboratory/dashboard") || window.location.pathname.includes("/laboratory/patient/") || window.location.pathname.includes("/laboratory/analysis") || window.location.pathname.includes("/laboratory/datafile")) ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/laboratory/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                    </li>
                                    {/* <li className={window.location.pathname.includes("/laboratory/profile") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/laboratory/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Laboratory Information</span></Link>
                                </li> */}
                                    <li className={window.location.pathname.includes("/laboratory/contact") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/laboratory/contact/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-phone"></i><span className="kt-menu__link-text">Contact Persons</span></Link>
                                    </li>
                                    {/* <li className={window.location.pathname.includes("/laboratory/payment") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/laboratory/payment" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></Link>
                                </li> */}
                                    {/* <li className={window.location.pathname.includes("/changepassword") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/changepassword" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-lock"></i><span className="kt-menu__link-text">Change Password</span></Link>
                                </li> */}
                                </ul>
                            }

                            {/* manufacturer profile global sidebar */}

                            {this.state.uToken === 6 &&
                                <ul className="kt-menu__nav">
                                    <li className={window.location.pathname.includes("/manufacturer/patientanalysisprogress") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/manufacturer/patientanalysisprogress" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-chart"></i><span className="kt-menu__link-text">Patient Analysis Progress</span></Link>
                                    </li>
                                    <li className={(window.location.pathname.includes("/manufacturer/dashboard") || window.location.pathname.includes("/manufacturer/patient") || window.location.pathname.includes("/manufacturer/analysis")) ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/manufacturer/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                    </li>
                                    {/* <li className={window.location.pathname.includes("/manufacturer/profile") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/manufacturer/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Manufacturer Information</span></Link>
                                </li> */}
                                    <li className={window.location.pathname.includes("/manufacturer/payment") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/manufacturer/payment" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></Link>
                                    </li>
                                    {/* <li className={window.location.pathname.includes("/changepassword") ? "kt-menu__item  kt-menu__item--active" : "kt-menu__item  kt-menu__item"} aria-haspopup="true"><Link to="/changepassword" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-lock"></i><span className="kt-menu__link-text">Change Password</span></Link>
                                </li> */}
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
