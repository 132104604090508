import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../style-new.css'
import axios from 'axios';

export class PrivacyStatements extends Component {
    static displayName = PrivacyStatements.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            logout: false,
        };

        this.handleClickLogout = this.handleClickLogout.bind(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleClickLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('Usertoken');
        localStorage.setItem('isLoggedIn', false);
        this.setState({ logout: true });
    }

    renderRedirecttohome() {
        if (this.state.logout) {
            return <Redirect from="/" to="/login" />
        }
    }


    render() {

        return (

            <React.Fragment>
                <header className="header-area" id="header-area">
                    <div className="dope-nav-container breakpoint-off">
                        <div className="container">
                            <div className="row">
                                {/* dope Menu */}
                                <nav className="dope-navbar justify-content-between" id="dopeNav">

                                    {/* Logo */}
                                    <Link to="/" className="nav-brand"><img src="img/header-logo.jpg" alt="" /></Link>

                                    {/* Navbar Toggler */}
                                    <div className="dope-navbar-toggler">
                                        <span className="navbarToggler">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </div>

                                    {/* Menu */}
                                    <div className="dope-menu">

                                        {/* close btn */}
                                        <div className="dopecloseIcon">
                                            <div className="cross-wrap">
                                                <span className="top"></span>
                                                <span className="bottom"></span>
                                            </div>
                                        </div>



                                        {localStorage.getItem('token') != null ?
                                            (
                                                <div className="dopenav">
                                                    <div className="top-rightside">
                                                        {(JSON.parse(localStorage.getItem('Usertoken')) != null ?
                                                            (JSON.parse(localStorage.getItem('Usertoken')).userType != 1 ?
                                                                (JSON.parse(localStorage.getItem('Usertoken')).userType != 2 ?
                                                                    (JSON.parse(localStorage.getItem('Usertoken')).userType != 3 ?
                                                                        (JSON.parse(localStorage.getItem('Usertoken')).userType == 4 ?
                                                                            <Link to="/laboratory/dashboard" className="primary-btn" >Dashboard</Link>

                                                                            :
                                                                            <Link to="/manufacturer/dashboard" className="primary-btn" >Dashboard</Link>

                                                                        )
                                                                        :
                                                                        <Link to="/institute/dashboard" className="primary-btn" >Dashboard</Link>

                                                                    )
                                                                    :
                                                                    <Link to="/practitioner/dashboard" className="primary-btn" >Dashboard</Link>

                                                                )
                                                                :
                                                                <Link to="/patient/dashboard" className="primary-btn" >Dashboard</Link>

                                                            )
                                                            : "")
                                                        }
                                                        <Link onClick={this.handleClickLogout} className="primary-btn">Logout</Link>
                                                    </div>
                                                    <ul id="nav">
                                                        <li>
                                                            <Link to="/Services" >Services</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/HealthcareProfessional" >Healthcare Professionals </Link>

                                                        </li>
                                                        <li>
                                                            <Link to="/Caregivers" >Patients and Caregivers</Link>

                                                        </li>
                                                    </ul>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className="dopenav">
                                                    <div className="top-rightside">
                                                        <div className="">
                                                            <ul id="nav">
                                                                <li>
                                                                    <Link to="/Services" >Services</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/HealthcareProfessional" >Healthcare Professionals</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/Caregivers" >Patients and Caregivers</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/login" className="primary-btn">Login</Link>
                                                                </li>
                                                            </ul>

                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>


                <section className="resources-2">

                    <div className="container margintop">
                        <h3 >Privacy Statement</h3>
                        <p><span >Neo7Logix USA LLC&nbsp;("Neo7Logix USA LLC", "we", "us")&nbsp;is committed to protecting the privacy of your personal information.</span></p>
                        <p><span >This privacy policy explains how we&nbsp;manage the personal information that we collect, use and disclose, and how to contact us if you have any further queries about our management of, or would like to request access to, your personal information.</span></p>
                        <p><span >Personal information is information or an opinion about an identified individual, or an individual who is reasonably identifiable, whether the information or opinion is true or not, and whether the information is recorded in a material form or not.&nbsp;</span></p>

                        <p><strong><span>Collection of personal information by&nbsp;Neo7Logix USA LLC</span></strong></p>
                        <p><span >The types of information we may collect include:</span></p>
                        <p><span >a) &nbsp; &nbsp; identifying and contact information (for example, your name, address, age, date of birth, gender, and email address);</span></p>
                        <p><span >b) &nbsp; &nbsp;financial information (for example, credit or debit card details);</span></p>
                        <p><span >c) &nbsp; &nbsp; health information (for example, where a customer may suffer an adverse reaction to a products/services); and</span></p>
                        <p><span >d) &nbsp; &nbsp;if you visit our website, register an account with us and/or purchase products/services through our website, your username and password, IP address, information about your purchases and your other activity on our website (for example,&nbsp;&nbsp;&nbsp; tracking which areas of the website you visit and the content you view);</span></p>
                        <p><span >e) &nbsp; &nbsp; other information which you may include in your profile or in other communications to Neo7Logix USA LLC; and</span></p>
                        <p><span >f) &nbsp; &nbsp; other information with your consent or where required by law.</span></p>
                        <p><span >We collect personal information in connection with your use or purchase of Neo7Logix USA LLC products/services and services, to provide you with the products/services and services you request, as well as information on other products/services and services offered by us. &nbsp;</span></p>
                        <p><span >Where possible, we will collect personal information directly from you or a person authorised to provide this information on your behalf. &nbsp;This can be through our website, in one of our stockists, over the phone or by email. &nbsp;Should we collect your personal information from a third party, we will take reasonable steps to ensure that the third party has obtained your consent to provide your personal information to us.</span></p>
                        <p><span >If you do not provide personal information requested of you to Neo7Logix USA LLC or, withdraw your consent to our collection, use and disclosure of your personal information at any time (subject to contractual and legal restrictions and reasonable notice), we may be unable to provide you the products/services or services you have requested or contact you in the future.&nbsp;</span></p>
                        <p><span >If you no longer wish to receive copies of our newsletter, or information about our products/services, services, store launches, events or other promotional information you may unsubscribe by contacting us as described below. &nbsp;For communications sent by e-mail or other electronic means you will also be able to unsubscribe using the unsubscribe mechanism in the message itself.&nbsp;</span></p>

                        <p><strong><span>Cookies</span></strong></p>
                        <p><span >If you web browser is set up to accept cookies, a cookie will be stored on your hard drive when you visit our website. &nbsp;Cookies allow us to collect information about your computer, which may include your IP address (a number assigned to your computer when you register with an Internet Service Provider), type of browser, operating system, domain name, and the details of any website which has referred you to our website. &nbsp;We use cookies to track and collect information about which parts our website and newsletter (including links to other websites) are being visited by you.</span></p>
                        <p><span >Cookies also allow Neo7Logix USA LLC to recognise your computer while you are on our website, and to send you to the country of origin and language you selected on your first visit to our site. This information is used to maintain the quality of our service and to provide tracking and statistics regarding the use of our website.&nbsp;</span></p>
                        <p><span >If you would rather not have this information stored on your computer, you can configure your browser so it does not accept cookies. &nbsp;However, if you disable cookies you may not be able to access all parts of this website, including the purchase section.</span></p>

                        <p><strong><span>Use of personal information by Neo7Logix USA LLC</span></strong></p>
                        <p><span >Generally, Neo7Logix USA LLC&nbsp;will use your personal information for purposes connected with our business operations, which may include:</span></p>
                        <p><span >a) &nbsp; &nbsp; to develop, market, sell or otherwise provide products/services, services or information;</span></p>
                        <p><span >b) &nbsp; &nbsp;fulfil your purchase orders for our products/services, services and/or gift certificates;</span></p>
                        <p><span >c) &nbsp; &nbsp; provide you with copies of our newsletter or information about our products/services, store launches, partnerships, events or other marketing or promotional information;</span></p>
                        <p><span >d) &nbsp; &nbsp;to maintain, update and service your account with us;</span></p>
                        <p><span >e) &nbsp; &nbsp; to maintain, administer and improve our systems;</span></p>
                        <p><span >f) &nbsp; &nbsp; improve our website, including to modify it to your usage, history and preferences and troubleshoot problems; and</span></p>
                        <p><span >g) &nbsp; &nbsp;conduct internal administrative activities, research, analytics, planning and project development.</span></p>

                        <p><strong><span>Disclosure of personal information by&nbsp;Neo7Logix USA LLC</span></strong></p>
                        <p><span >In order to properly conduct our activities, we may disclose or transfer personal information to other persons or organisations including:</span></p>
                        <p><span >a) &nbsp; &nbsp; our service providers (including affiliates acting in this capacity) that provide services on our behalf, for example information technology, mailing, billing, marketing and/or data hosting or processing services; or otherwise, to collect use, disclose, store or process personal information on our behalf for the purposes described in this Privacy Policy;</span></p>
                        <p><span >b) &nbsp; &nbsp;parties connected with the proposed or actual financing, securitisation, insuring, sale, assignment or other disposal of all or part of Neo7Logix USA LLC or our business or assets, for the purposes of evaluating and/or performing the proposed transaction; and</span></p>
                        <p><span >c) &nbsp; &nbsp; other parties to whom we are authorised or required by law to disclose information.</span></p>

                        <p><strong><span>Data quality and security</span></strong></p>
                        <p><span >We&nbsp;takes reasonable steps to:</span></p>
                        <p><span >a) &nbsp; &nbsp; make sure that the personal information we collect, use and disclose is accurate, complete and up to date;</span></p>
                        <p><span >b) &nbsp; &nbsp;protect the personal information that we hold from misuse and loss and from unauthorised access, modification or disclosure; and</span></p>
                        <p><span >c) &nbsp; &nbsp; where permitted by law, destroy or permanently de-identify personal information that is no longer needed for purposes for which it was collected.</span></p>
                        <p><span >Neo7Logix USA LLC credit card transactions are fulfilled by an authorised banking institution. When collecting credit card information for online purchases, we offer secured server transactions that encrypt your information in transit to help prevent others from accessing it. &nbsp;Personal information is stored on servers that are protected by appropriate safeguards, and will be accessible by authorised employees and agents who require access in connection with their responsibilities. Your credit card details are encrypted and then removed from our system once your order has been dispatched.</span></p>

                        <p><strong><span>Unsolicited personal information</span></strong></p>
                        <p><span >We don’t usually collect unsolicited personal information.</span></p>
                        <p><span >Where we receive unsolicited personal information, we’ll determine whether or not it would have been permissible to collect that personal information if it had been solicited.</span></p>
                        <p><span >If we determine that collection would not have been permissible, to the extent permitted by law, we’ll destroy or de-identify that personal information as soon as practicable.</span></p>

                        <p><strong><span>Anonymity</span></strong></p>
                        <p><span >Neo7Logix USA LLC&nbsp;will generally provide individuals with the option of not identifying themselves when entering into transactions when it is lawful and practicable to do so. However, on many occasions, we will not be able to do this. For example, we will need your address in order to deliver any products/services purchased through our website.</span></p>

                        <p><strong><span >Disclosure of personal information overseas</span></strong></p>
                        <p><span >Neo7Logix USA LLC&nbsp;may, in the course of carrying out our business, disclose personal information to overseas affiliates and service providers, including between members of the Neo7Logix USA LLC Group.</span></p>

                        <p><strong><span>Access to and Correction of Your Personal Information</span></strong></p>
                        <p><span >If you have any questions or concerns about how we handle your personal information, or would like to request a copy of the personal information we hold about you, please contact our Company Secretary at <em>office@neo7logix.com</em> or in writing at:&nbsp;</span></p>
                        <p><span >Company Secretary</span>
                            <br /><span >Neo7Logix USA LLC</span>
                            <br /><span >539 W. Commerce &nbsp;St #2886&nbsp;</span>
                            <br /><span >Dallas, Texas 75208</span>
                            <br /><span >Tel: +1-469-619-7252</span>
                        </p>
                        <p><span >Neo7Logix USA LLC will generally provide you with access to your personal information if practicable and, will take reasonable steps to amend any of your personal information which is inaccurate or out of date. &nbsp;If we provide you with copies of any information you have requested, we may charge you a reasonable fee to cover the administrative costs of providing you with that information.</span></p>

                        <p><strong><span>Changes to this policy</span></strong></p>
                        <p><span >This Privacy Policy is effective from 1 October 2020.</span></p>
                        <p><span >This Privacy Policy may be updated from time to time. &nbsp;To obtain a copy of the latest version at any time, visit our website at or contact us by email:&nbsp;</span><a href="mailto:help@budsbabes.com.au?subject=Privacy%20Policy" target="_self"><em><span style={{ fontSize: '15px', fontFamily: '"Times New Roman","serif"', color: '#0B5394', textDecoration: 'none' }}>office@neo7logix.com</span></em></a><span >. &nbsp;</span></p>

                        <p><strong><span>Complaints</span></strong></p>
                        <p><span >If you consider a breach of the applicable privacy laws or your rights in relation to privacy has occurred, you may contact us and we will attempt to resolve your complaint.</span></p>
                        <p><span >For Neo7Logix USA LLC customers located in USA, if you do not consider our response satisfactory, you may contact the USA Privacy Commissioner at its website&nbsp;</span><a href="http://www.oaic.giv.au/" target="_blank" title="Office of the Australian Information Commissioner"><span style={{ fontSize: '15px', fontFamily: '"Times New Roman","serif"', color: '#0B5394', textDecoration: 'none' }}>www.oaic.gov.au</span></a><span >&nbsp;or by telephone on 1300 363 992.&nbsp;</span></p>
                        <p><span >For Neo7Logix USA LLC customers located outside USA, please contact the body responsible for regulation and enforcement of privacy laws in the country in which you reside.</span></p>


                    </div>

                </section >

                <footer className="footer-section section-gap-half">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 footer-left">
                                <Link to="/">
                                    <img src="img/header-logo.jpg" alt="" />
                                </Link>
                                <p><i className="ti-location-pin"></i> 539 W. Commerce St #2886<br /> Dallas, TX 75208 </p>

                                <p className="copyright-text"> Copyright © 2020 Neo7Logix LLC- All Rights Reserved.</p>
                            </div>
                            <div className="col-lg-7">
                                <div className="footer-title">
                                    <h2>WE'RE HERE TO HELP</h2>
                                    <a href="tel:+12067185467">
                                        <p><i className="ti-mobile"></i>+1-206-718-5467</p>
                                    </a>
                                    <a href="mailto:office@neo7logix.com">
                                        <p><i className="ti-email"></i> office@neo7logix.com</p>
                                    </a>
                                </div>
                                <ul className="footer-menu">
                                    <li>
                                        <Link to="/Sitemap" >Sitemap</Link>
                                    </li>
                                    <li>
                                        <Link to="/TermsOfUse" >Terms of Use</Link>
                                    </li>
                                    <li>
                                        <Link to="/PrivacyStatements" >Privacy Statement</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>

                <div className="scroll-top">
                    <i className="ti-angle-up"></i>
                </div>
            </React.Fragment >


        );
    }
}
